import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {Api} from '../../../../now/api/api';
import {QuotationModel} from '../../../../models/quotation.model';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import * as moment from 'moment';
import {CustomerModel} from '../../../../models/customer.model';
import {ReceiptModel} from '../../../../models/receipt.model';
import {JobModel} from '../../../../models/job.model';
import {ProductModel} from '../../../../models/product.model';
import {Viewer} from '../../../../services/viewer';
import {InvoiceModel} from '../../../../models/invoice.model';

@Component({
    selector: 'app-receipt-detail-component',
    templateUrl: 'receiptDetail.component.html',
    styleUrls: ['receiptDetail.component.scss']
})
export class ReceiptDetailComponent implements OnInit {

    public invoice: InvoiceModel;
    public receipt: ReceiptModel;
    public tmp_receipt: ReceiptModel;
    public credit_terms: number;
    public percent: number;
    public task: TaskModel;
    public quotation: QuotationModel;
    public products: ProductModel[];
    public customer: CustomerModel;
    public job: JobModel;
    public is_new: boolean;
    public model: any;
    public type: number;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService,
        public viewer: Viewer
    ) {
        //
        this.receipt = new ReceiptModel();
        this.tmp_receipt = new ReceiptModel();
        // this.quotation = new QuotationModel();
        if (this.model && this.model.customer) {
            this.customer = this.model.customer;
        }
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.model) {
                this.model = this.bsModalRef.content.model;
                if (this.model && this.model.jobs) {
                    for (const job of this.model.jobs) {
                        if (job && job.id) {
                            job._checked = true;
                        }
                    }
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.type) {
                this.type = this.bsModalRef.content.type;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.products) {
                this.products = this.bsModalRef.content.products;
            }
            // if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.invoice) {
            //     this.invoice = this.bsModalRef.content.invoice;
            // }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.receipt) {
                // if (!this.bsModalRef.content.receipt.started_at) {
                //     this.bsModalRef.content.receipt.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                // }
                this.receipt = this.bsModalRef.content.receipt;
                this.tmp_receipt.started_at = this.receipt.started_at;
                this.tmp_receipt.ended_at = this.receipt.ended_at;
                this.tmp_receipt.id = this.receipt.id;
                this.tmp_receipt.receipt_no = this.receipt.receipt_no;
                this.tmp_receipt.required_at = this.receipt.required_at;
                this.tmp_receipt.remark = this.receipt.remark;
                this.tmp_receipt.status = (this.receipt.status) ? this.receipt.status : 2;
                this.tmp_receipt.grand_total = this.receipt.grand_total;
                this.tmp_receipt.total_price = this.receipt.total_price;
                this.tmp_receipt.modelable_type = this.model.model_name;
                this.tmp_receipt.modelable_id = this.model.id;
                // this.percent = 100;

                if (!this.tmp_receipt.started_at) {
                    this.tmp_receipt.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                // if (this.receipt.customer && this.receipt.customer.credit_terms > 0) {
                //     this.credit_terms = this.receipt.customer.credit_terms;
                // } else {
                //     this.credit_terms = 0;
                // }

                // if (!this.tmp_receipt.ended_at && this.tmp_receipt.started_at && this.credit_terms > 0) {
                //     this.tmp_receipt.ended_at = moment(this.tmp_receipt.started_at, 'YYYY-MM-DD HH:mm:ss')
                //         .add(this.credit_terms, 'days')
                //         .format('YYYY-MM-DD HH:mm:ss');
                // }

                this.is_new = this.receipt.isNew;
                // if (!this.tmp_receipt.total_price) {
                //     this.tmp_receipt.total_price = this.receipt.grand_total * .5;
                // }
                this.onTotalPriceChanged();
                this.onPercentChanged();
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
            }
        }, 0);
    }

    public onStartedChanged(e: any): void {
        if (e) {
            const started_moment_object: any = moment(e, 'YYYY-MM-DD HH:mm:ss');
            const ended_moment_object: any = moment(this.tmp_receipt.ended_at, 'YYYY-MM-DD HH:mm:ss');
            this.credit_terms = moment.duration(ended_moment_object.diff(started_moment_object)).asDays();
        }
    }

    public onEndedChagned(e: any): void {
        const started_moment_object: any = moment(this.tmp_receipt.started_at, 'YYYY-MM-DD HH:mm:ss');
        const ended_moment_object: any = moment(e, 'YYYY-MM-DD HH:mm:ss');
        this.credit_terms = moment.duration(ended_moment_object.diff(started_moment_object)).asDays();
    }

    public onTotalPriceChanged(e?: any): void {
        setTimeout(() => {
            const grand_total: number = this.receipt.grand_total;
            if (grand_total > 0) {
                this.percent = (this.tmp_receipt.total_price * 100) / grand_total;
            }
        }, 100);
    }

    public onPercentChanged(e?: any): void {
        setTimeout(() => {
            const grand_total: number = this.receipt.grand_total;
            this.tmp_receipt.total_price = (grand_total * this.percent) / 100;
        }, 100);
    }

    public delete(): void {
        if (this.receipt.status === 1) {
            return;
        }
        const name: string = (this.tmp_receipt.receipt_no)
            ? this.tmp_receipt.receipt_no : 'ใบวางเงินมัดจำ';
        this.swal.confirm('คุณต้องการลบเอกสารใบสั่งซื้อ "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.modelApi.delete(this.tmp_receipt)
                        .subscribe((response: any): void => {
                            const index: number = this.model.receipts.findIndex(i => i.id === this.tmp_receipt.id);
                            if (index > -1) {
                                this.model.receipts.splice(index, 1);
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public viewReceipt(): void {
        this.viewer.receipt(this.tmp_receipt);
    }

    public complete(): void {
        this.swal.confirm('ยืนยันการออกใบวางเงินมัดจำเป็นจำนวนเงิน "' + this.tmp_receipt.total_price + '" บาท ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.save()
                        .then(() => {
                            this.viewReceipt();
                            this.bsModalRef.content.receipt = this.receipt;
                            this.bsModalRef.content.is_submit = this.is_new;
                            this.bsModalRef.hide();
                        });
                } else {
                    //
                }
            });
    }

    public update(): void {
        if (this.tmp_receipt && !this.tmp_receipt.id) {
            this.swal.confirm('ยืนยันการออกใบเสร็จรับเงินใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('receipts', 'PUT', {}, {
                            customer_id: this.model.customer_id,
                            model_id: this.model.id,
                            type: this.type,
                            // tax_invoice_id: this.model.id,
                            modelable_id: this.tmp_receipt.modelable_id,
                            modelable_type: this.tmp_receipt.modelable_type,
                            grand_total: this.tmp_receipt.grand_total,
                            bill_id: this.model.bill_id,
                            customer_address_id: this.model.customer_address_id,
                            contact_id: this.model.contact_id,
                            incoterm_code: this.model.incoterm_code,
                            status: this.tmp_receipt.status,
                            started_at: this.tmp_receipt.started_at,
                            total_price: this.tmp_receipt.total_price,
                            remark: this.tmp_receipt.remark,
                            jobs: this.get_jobs()
                        }).subscribe((response: any): void => {
                            this.swal.success('ออกใบเสร็จรับเงินสำเร็จ');
                            this.receipt.clone(response.data);
                            this.bsModalRef.content.deposit = this.receipt;
                            this.bsModalRef.content.is_create = true;
                            this.bsModalRef.content.is_submit = this.is_new;
                            this.bsModalRef.content.submit = false;
                            this.tmp_receipt = this.receipt;
                            this.bsModalRef.hide();
                            this.viewReceipt();
                        }, error => {
                            //
                        });
                    }
                });
        } else {
            this.swal.confirm('ยืนยันการแก้ไขเอกสารใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('receipts/status', 'POST', {}, {
                            status: this.tmp_receipt.status,
                            id: this.tmp_receipt.id
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.bsModalRef.content.receipt.status = this.tmp_receipt.status;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                        /*this.api.request('receipts', 'POST', {}, {
                            id: this.tmp_receipt.id,
                            status: this.tmp_receipt.status,
                            started_at: this.tmp_receipt.started_at,
                            grand_total: this.tmp_receipt.grand_total,
                            remark: this.tmp_receipt.remark
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.bsModalRef.content.receipt.status = this.tmp_receipt.status;
                            this.bsModalRef.content.receipt.started_at = this.tmp_receipt.started_at;
                            this.bsModalRef.content.receipt.grand_total = this.tmp_receipt.grand_total;
                            this.bsModalRef.content.receipt.remark = this.tmp_receipt.remark;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });*/
                    }
                });
        }
    }

    private get_jobs(): any {
        let job_ids: any;
        job_ids = {};
        if (this.model && this.model.jobs) {
            for (const job of this.model.jobs) {
                if (job && job._checked) {
                    job_ids[job.id] = {
                        amount: job.pivot.amount,
                        price_per_unit: job.pivot.price_per_unit
                    };
                }
            }
        }
        return job_ids;
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const product_ids: any[] = this.get_product_ids();
            let request_data: any;
            request_data = {
                product_ids     : product_ids,
                // job_id          : this.job.id,
                customer_id     : this.model.customer_id,
                credit_terms    : this.tmp_receipt.credit_terms,
                grand_total     : this.tmp_receipt.grand_total,
                started_at      : this.tmp_receipt.started_at,
                remark          : this.tmp_receipt.remark,
                status          : this.tmp_receipt.status,
            };
            if (this.model && this.model.model_name === 'App\\Quotation') {
                request_data['quotation_id'] = this.model.id;
            } else if (this.model && this.model.model_name === 'App\\TaxInvoice') {
                request_data['tax_invoice_id'] = this.model.id;
            }
            this.api.request('receipts', 'PUT', {}, request_data)
                .subscribe((response: any): void => {
                    this.receipt.clone(response.data);
                    resolve();
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private get_product_ids(): any[] {
        let product_ids: any[];
        product_ids = [];
        for (let i = 0; i < this.products.length; i++) {
            product_ids.push(this.products[i].id);
        }
        return product_ids;
    }

    public onSubmit(form: NgForm): void {
        this.save()
            .then(() => {
                this.swal.success('บันทักข้อมูลใบวางเงินมัดจำสำเร็จ');
                this.bsModalRef.content.is_submit = this.is_new;
                this.bsModalRef.hide();
            });
    }

}
