import {Injectable} from '@angular/core';
import {TaskModel} from '../models/task.model';
import {TaskService} from './task.service';
import {Api} from '../now/api/api';
import {MaterialModel} from '../models/material.model';
import {JobModel} from '../models/job.model';
import {ItemModel} from '../models/item.model';
import {VendorModel} from '../models/vendor.model';
import {SupplierModel} from '../models/supplier.model';
import {PricingInquiryModel} from '../models/pricingInquiry.model';

@Injectable()
export class StoreService {

    constructor(private taskService: TaskService, private api: Api) {
        /*this.taskService.setStatus(this.task, 2, 'planning_check', 'sale')
            .then((task: TaskModel): void => {
                this.task.status = 2;
                this.swal.success('กรุณารอการอนุมัติในขั้นตอนต่อไป');
                this.leave(true);
            });*/
    }

    public genNO(item: ItemModel): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('store/item/no', 'POST', {}, {
                item_id: item.id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    // item.item_no = response.data;
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public prepareMaterial(job: JobModel, remark_message?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/prepare', 'POST', {}, {
                id: job.id,
                remark: remark_message
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public noticePurchaseDate(pricing_inquiry_id: string, tmp_items: any[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let items: any[];
            items = [];
            for (let i = 0; i < tmp_items.length; i++) {
                const tmp_item: any = tmp_items[i];
                if (tmp_item) {
                    items.push({
                        id: tmp_item.id,
                        dateTime: tmp_item.dateTime
                    });
                }
            }
            this.api.request('purchase/notice/datetime', 'POST', {}, {
                pricing_inquiry_id: pricing_inquiry_id,
                items: items
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public pricingInquiry(
        task: TaskModel,
        job_id: string,
        product_id: string,
        fvd_id?: string,
        items?: any[] | boolean,
        customer_id?: string,
        modelables?: VendorModel[] | SupplierModel[],
        condition?: string,
        required_at?: string,
        remark?: string,
        job_ids?: string[]
    ): Promise<PricingInquiryModel[]> {
        let promise: Promise<PricingInquiryModel[]>;
        promise = new Promise<PricingInquiryModel[]>((resolve, reject) => {
            // const _items: any[] = this.get_items_to_check(items);
            let _modelables: any[];
            _modelables = [];
            if (modelables && modelables.length) {
                for (let i = 0; i < modelables.length; i++) {
                    _modelables.push({
                        id: modelables[i].id,
                        type: modelables[i].model_name
                    });
                }
            }
            this.api.request('pricing/inquiries', 'PUT', {}, {
                customer_id     : (customer_id) ? customer_id : task.customer_id,
                item_ids        : items,
                job_id          : job_id,
                product_id      : product_id,
                fvd_id          : fvd_id,
                modelables      : _modelables,
                condition       : condition,
                required_at     : required_at,
                remark          : remark,
                job_ids         : job_ids
            }, null, null, null, null, true)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        let pricing_inquries: PricingInquiryModel[];
                        pricing_inquries = [];
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            if (dat && dat.id) {
                                let pricing_inquiry: PricingInquiryModel;
                                pricing_inquiry = new PricingInquiryModel();
                                pricing_inquiry.clone(dat);
                                pricing_inquries.push(pricing_inquiry);
                            }
                        }
                        resolve(pricing_inquries);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public purchase(
        task: TaskModel,
        job_id: string,
        product_id: string,
        fvd_id?: string,
        items?: any[],
        for_customer_id?: string,
        modelable?: any,
        objective?: string,
        gen_no?: boolean,
        required_at?: string,
        parent_task_id?: string,
        parent_id?: string,
        pricing_inquiry_id?: string,
        role?: string,
        process_slug?: string,
        request_man_id?: any
    ): Promise<Object> {
        //
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            // const _items: any[] = this.get_items_to_check(items);
            /*let _modelables: any[];
            _modelables = [];
            for (let i = 0; i < modelables.length; i++) {
                _modelables.push({
                    id: modelables[i].id,
                    type: modelables[i].model_name
                });
            }*/
            this.api.request('purchase/orders', 'PUT', {}, {
                task_id         : (task) ? task.id : '',
                fvd_id          : fvd_id,
                job_id          : job_id,
                product_id      : product_id,
                items           : items,
                gen_no          : (gen_no === true) ? true : false,
                modelable       : modelable,
                for_customer_id : for_customer_id,
                objective       : objective,
                required_at     : required_at,
                parent_task_id  : parent_task_id,
                parent_id       : parent_id,
                request_man_id  : request_man_id,
                pricing_inquiry_id  : pricing_inquiry_id,
                role                : role,
                process_slug        : process_slug
            }, null, null, null, null, true)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve();
                        /*if (task) {
                            let to_process: string;
                            let to_role: string;
                            to_role = 'request-purchase';
                            to_process = 'create_purchase_order';
                            if (fvd_id) {
                                to_role = 'po-fvd';
                                to_process = 'create_fvd_purchase_order';
                            }
                            this.taskService.setStatus(task, task.status, 'create_purchase_order', to_role, to_process)
                                .then(() => {
                                    resolve(task);
                                }, error => {
                                    reject(error);
                                });
                        } else {
                            resolve(response.data);
                        }*/
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public checkItems(task: TaskModel, product_id: string, items: any[]): Promise<TaskModel> {
        let promise: Promise<TaskModel>;
        promise = new Promise<TaskModel>((resolve, reject) => {
            const _items: any[] = this.get_items_to_check(items);
            this.api.request('store/check', 'POST', {}, {
                product_id: product_id,
                items: _items
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.taskService.setStatus(task, task.status, 'store_check', 'check-store', 'check-store')
                        .then(() => {
                            resolve(task);
                        }, error => {
                            reject(error);
                        });
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public preparedItems(task: TaskModel, product_id: string, items: any[], role?: string, process?: string): Promise<TaskModel> {
        let promise: Promise<TaskModel>;
        promise = new Promise<TaskModel>((resolve, reject) => {
            /*const _items: any[] = this.get_items_to_check(items);
            this.api.request('store/prepare', 'POST', {}, {
                product_id: product_id,
                items: _items
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.taskService.setStatus(task, task.status, 'create_planning', 'pd-planning', 'planning')
                        .then(() => {
                            resolve(task);
                        }, error => {
                            reject(error);
                        });
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });*/
            role = (role) ? role : 'pd-planning-2';
            process = (process) ? process : 'planning';
            this.taskService.setStatus(task, task.status, 'create_planning', role, process)
                .then(() => {
                    resolve(task);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private get_items_to_check(items: any[]): any[] {
        let _items: any[];
        _items = [];
        for (let i = 0; i < items.length; i++) {
            const item: any = items[i];
            if (item.prepared_status === 1 || item.prepared_status === 5) {
                _items.push({
                    modelable_type: item.model_name,
                    modelable_id: item.id,
                    item_id: item.item.id,
                    amount: item.amount,
                    remark: item.remark,
                    dateTime: item.dateTime,
                    prepared_status: item.prepared_status,
                    price: item.price,
                    vendor_id: item.vendor_id
                });
            }
            if (item && item.children) {
                _items = _items.concat(this.get_items_to_check(item.children));
            }
        }
        return _items;
    }

}
