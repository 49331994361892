import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CARModel} from '../../../models/car.model';
import {ViewRequest} from '../view/viewRequest';
import {Location} from '@angular/common';
import {SwalService} from '../../../services/swal.service';
import {ModalService} from '../../../services/modal.service';
import {LoaderService} from '../../../components/loader/loader.service';
import {Api} from '../../../now/api/api';
import {UserService} from '../../../services/user.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {TaskService} from '../../../services/task.service';
import {Viewer} from '../../../services/viewer';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../now/user/auth.service';
import {DocumentModel} from '../../../models/document.model';
import {UserModel} from '../../../now/user/user.model';
import * as moment from 'moment';
import {PROBLEM_DETAILS, PROBLEM_TYPES, TOOLS} from '../../../consts/ncr.const';
import {ProcessService} from '../../../services/process.service';
import {TaskModel} from '../../../models/task.model';

@Component({
    selector: 'request-car-task-component',
    templateUrl: 'requestCARTask.component.html',
    styleUrls: ['requestCARTask.component.scss']
})
export class RequestCARTaskComponent extends ViewRequest implements AfterViewInit {

    public carId;
    public car: CARModel;

    public processes: any[];
    public problem_details: string[] = PROBLEM_DETAILS;
    public problem_types: string[] = PROBLEM_TYPES;
    public tools: string[] = TOOLS;

    constructor(
        public location: Location,
        private swal: SwalService,
        public modal: ModalService,
        private loader: LoaderService,
        public api: Api,
        public userService: UserService,
        private modelApi: ModelApi,
        public taskService: TaskService,
        public viewer: Viewer,
        private processService: ProcessService,
        private route: ActivatedRoute,
        private authService: AuthService
    ) {
        //
        super({ taskService, api, modal, location, viewer });
        this.task = new TaskModel();
        this.car = new CARModel();
        this.processes = [];
    }

    ngAfterViewInit() {
        this.route.params
            .subscribe(params => {
                this.loader.show();
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            if (this.task && this.task.modelable_id) {
                                const carId = this.task.modelable_id;
                                this.carId = carId;
                                this.getCAR()
                                    .then(() => {
                                        this.ready = true;
                                        this.loader.hide();
                                        setTimeout(() => {
                                            this.setupCAR();
                                        }, 500);
                                    }, error => {
                                        console.error(error);
                                        this.loader.hide();
                                    });
                            }
                        });
                } else {
                    this.ready = true;
                    this.loader.hide();
                    setTimeout(() => {
                        this.setupCAR();
                    }, 500);
                }
                this.processService.getProcesses()
                    .then(processes => {
                        console.log(processes);
                        this.processes = [];
                        for (const process of processes) {
                            if (process && process.slug) {
                                this.processes.push({
                                    id: process.id,
                                    name: process.name,
                                    slug: process.slug
                                });
                            }
                        }
                        console.log(this.processes);
                    });
            });
    }

    public setupCAR(): void {
        if (!this.car.user || !this.car.user.id) {
            this.car.user = new UserModel();
            this.car.user.clone(this.authService.user);
            this.car.user_id = this.authService.user.id;
        }
        if (!this.car.started_at) {
            this.car.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        }
        if (this.car && this.car.equipments && Array.isArray(this.car.equipments)) {
            for (let i = 0; i < this.car.equipments.length; i++) {
                if (this.car.equipments[i]) {
                    //
                } else {
                    this.car.equipments[i] = '';
                }
            }
        } else {
            this.car.equipments = ['', '', '', '', ''];
        }
    }

    public getCAR() {
        const promise = new Promise<CARModel>((resolve, reject) => {
            this.api.request('cars/' + this.carId + '', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.car.clone(response.data);
                        resolve(this.car);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public updateCAR(): Promise<CARModel> {
        let promise: Promise<CARModel>;
        promise = new Promise<CARModel>((resolve, reject) => {
            this.api.request('cars/support', 'POST', {}, {
                id: this.carId,
                man_id: this.car.man_id,
                machine_id: this.car.machine_id,
                material_id: this.car.material_id,
                method_id: this.car.method_id,
                problem_type: this.car.problem_type,
                problem_details: this.car.problem_details,
                problem_description: this.car.problem_description,
                equipments: this.car.equipments,
                cause_description: this.car.cause_description,
                cause_preventive: this.car.cause_preventive,
            }).subscribe((response: any): void => {
                if (response && response.success === true && response.data && response.data.car) {
                    resolve();
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public createOrUpdateCAR(): Promise<CARModel> {
        if (!this.car.man_id) {
            this.swal.danger('โปรดระบุผู้ดำเนินการ (MAN)');
        } else {
            let promise: Promise<CARModel>;
            promise = new Promise<CARModel>((resolve, reject) => {
                this.api.request('cars/support', 'PUT', {}, {
                    started_at: this.car.started_at,
                    user_id: this.car.user_id,
                    man_id: this.car.man_id,
                    machine_id: this.car.machine_id,
                    material_id: this.car.material_id,
                    method_id: this.car.method_id,
                    problem_type: this.car.problem_type,
                    problem_details: this.car.problem_details,
                    problem_description: this.car.problem_description,
                    equipments: this.car.equipments,
                }).subscribe((response: any): void => {
                    if (response && response.success === true && response.data && response.data.car) {
                        this.car.id = response.data.car.id;
                        this.carId = this.car.id;
                        this.car.car_no = response.data.car.car_no;
                        this.swal.success('ออกเอกสาร CAR "' + this.car.car_no + '" สำเร็จ');

                        this.task_id = response.data.task.id;
                        this.task = new TaskModel();
                        this.task.id = this.task_id;

                        this.location.replaceState('/task/support_car/support_car/' + this.task_id + '');
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            });
            return promise;
        }
    }

    public generateCARNo(): void {
        if (this.car && this.car.man_id) {
            this.swal.confirm('ยืนยันการออกเอกสาร CAR ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.createOrUpdateCAR()
                            .then(() => {
                                this.swal.success('ออกเอกสาร CAR สำเร็จ');
                            });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('โปรดระบุผู้ดำเนินการ (MAN)');
        }
    }

    public sendMan() {
        const promise = new Promise((resolve, reject) => {
            if (this.task.process_slug === 'support_car') {
                this.swal.confirm('ยืนยันการส่งตรวจสอบ ใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this._save()
                                .then(() => {
                                    this.taskService.setStatus(this.task, this.task.status, 'notice_car', 'done_support_car', 'done_support_car', false, this.car.user_id)
                                        .then(() => {
                                            this.swal.success('ส่งตรวจสอบสำเร็จ');
                                            this.leave(true);
                                        });
                                });
                        } else {
                            //
                        }
                    });
            } else {
                if (this.car && this.car.man_id) {
                    this.swal.confirm('ยืนยันการแจ้งผู้กระทำผิดพลาด ใช่หรือไม่?')
                        .then((result: boolean): void => {
                            if (result === true) {
                                this._save()
                                    .then(() => {
                                        this.taskService.setStatus(this.task, this.task.status, 'notice_car', 'support_car', 'support_car', false, this.car.man.id)
                                            .then(() => {
                                                this.swal.success('แจ้งเรื่องรายงานข้อผิดพลาดสำเร็จ');
                                                this.leave(true);
                                            });
                                    });
                            } else {
                                //
                            }
                        });
                } else {
                    reject(this.car);
                }
            }
        });
        return promise;
    }

    public onManChange(e: any): void {
        this.car.man_id = (e && e.id) ? e.id : null;
    }

    public onMachineChange(e: any): void {
        this.car.machine_id = (e && e.id) ? e.id : null;
    }

    public onMaterialChange(e: any): void {
        this.car.material_id = (e && e.id) ? e.id : null;
    }

    public onMethodChange(e: any): void {
        this.car.method_id = (e && e.id) ? e.id : null;
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('cars/document', 'PUT', {}, {
            document: data,
            id: this.car.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.car.documents.push(new_document);
            } else {
                //
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public onSaveApiCallingBack(e: any): void {
        this._save()
            .then(() => {
                //
            });
    }

    public _save() {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.car && this.car.id) {
                this.updateCAR()
                    .then(result => {
                        resolve(result);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

}
