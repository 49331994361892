import {AfterViewInit, Component, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {ProcessModel} from '../../../../models/process.model';
import {ProcessService} from '../../../../services/process.service';
import {ProductModel} from '../../../../models/product.model';
import {NgForm} from '@angular/forms';
import {TaskModel} from '../../../../models/task.model';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {JobModel} from '../../../../models/job.model';
import {WarehouseModel} from '../../../../models/warehouse.model';
import {ShelfModel} from '../../../../models/shelf.model';
import {ProductProcessModel} from '../../../../models/productProcess.model';
import {JobService} from '../../../../services/job.service';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {LoaderService} from '../../../../components/loader/loader.service';
import {FVDService} from '../../../../services/fvd.service';

@Component({
    selector: 'complete-modal-component',
    templateUrl: 'completeModal.component.html',
    styleUrls: ['completeModal.component.scss']
})
export class CompleteModalComponent implements AfterViewInit {

    @ViewChild('completeModal', { static: true }) smartModal: NgxSmartModalComponent;

    @Input() product: ProductModel;
    @Input() task: TaskModel;
    @Input() product_processes: ProductProcessModel[];
    @Input() job: JobModel;

    @Output('onSubmitEvent') onSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

    public remark: string;
    public warehouse: WarehouseModel;
    public shelf: ShelfModel;
    public is_fvd: boolean;
    public is_not_found: boolean;
    public next_product_process: ProductProcessModel;
    public next_process: ProcessModel;
    public product_amount: number;
    public good_amount: number;
    public customer_product_amount: number;
    public product_pull_amount: number;
    public is_complete: boolean;
    public is_archive: boolean;
    public is_finish_good: boolean;
    public ready: boolean;

    constructor(
        private processService: ProcessService,
        private api: Api,
        private loaderService: LoaderService,
        private swal: SwalService,
        private ngZone: NgZone,
        private jobService: JobService,
        private modelApi: ModelApi,
        private fvdService: FVDService,
    ) {
        //
        this.next_process = new ProcessModel();
        this.is_archive = false;
        this.is_finish_good = false;
        this.ready = false;
        this.is_fvd = false;
        this.is_not_found = false;
    }

    ngAfterViewInit(): void {
        //
    }

    public onClose(e?: any): void {
        // this.removeRemark();
    }

    public onDismiss(e?: any): void {
        //
    }

    public onOpen(e?: any): void {
        setTimeout(() => {
            this.warehouse = new WarehouseModel();
            this.shelf = new ShelfModel();
            if (this.job && this.job.id) {
                this.getJobDetail()
                    .then(() => {
                        this.customer_product_amount = this.job.customer_product_amount;
                        // this.product_amount = this.job.current_product_process.amount;
                        this.product_amount = this.job.product_amount;
                        this.product_pull_amount = this.job.pull_amount;
                        if (this.job && this.job.current_product_process && this.job.current_product_process.id && this.product_processes.length > 0) {
                            if (this.job.current_product_process.process.slug === 'finish-good') {
                                this.is_finish_good = true;
                            } else {
                                this.is_finish_good = false;
                            }
                            // const current_product_process = this.job.current_product_process;
                            const next_product_process: ProductProcessModel = this.find_next_product_process();
                            if (next_product_process && next_product_process.process) {
                                this.next_product_process = next_product_process;
                                this.next_process = next_product_process.process;
                                this.is_fvd = next_product_process.is_fvd;
                                this.is_complete = false;
                                this.ready = true;
                            } else if (this.job.current_product_process && this.job.current_product_process.process.slug === 'shipping') {
                                this.processService.getProcesses()
                                    .then((processes: ProcessModel[]): void => {
                                        const accounting_process: ProcessModel = processes.find(i => i.slug === 'accounting');
                                        if (!this.next_process || !this.next_process.id) {
                                            this.is_complete = true;
                                            this.next_process = accounting_process;
                                        } else {
                                            this.is_complete = false;
                                        }
                                        this.is_fvd = false;
                                        this.ready = true;
                                    });
                            } else {
                                this.is_not_found = true;
                                this.is_complete = false;
                                this.ready = true;
                            }
                        }
                    });
            }
        }, 0);
    }

    private find_next_product_process(): ProductProcessModel {
        for (let i = 0; i < this.job.product_processes.length; i++) {
            const product_process: ProductProcessModel = this.job.product_processes[i];
            if (product_process
                && product_process.revision === this.job.revision
                && product_process.order_index > -1
                && this.job.current_product_process) {
                //
                if (product_process.id !== this.job.current_product_process.id
                    && product_process.order_index >= this.job.current_product_process.order_index) {
                    //
                    console.log(product_process);
                    return product_process;
                }
            }
        }
        return null;
    }

    private getJobDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.modelApi.sync(this.job)
                .subscribe((response: any): void => {
                    this.product.id = this.job.product_id;
                    resolve(this.job);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onSelectWarehouse(e: any): void {
        this.ngZone.run(() => {
            setTimeout(() => {
                this.warehouse.id = (e && e.id) ? e.id : null;
                if (this.warehouse.id) {
                    this.warehouse.shelves = [new ShelfModel()];
                }
            }, 300);
        });
    }

    public open(): void {
        this.smartModal.open();
    }

    public onFormSubmit(form?: NgForm): void {
        if (!this.ready) {
            return;
        }
        const total_amount: number = this.nc_amount + this.good_amount;
        if (this.nc_amount < 0) {
            this.swal.danger('ข้อมูลของจำนวนงานดีไม่ถูกต้อง');
        } else if (!this.good_amount && !this.is_archive) {
            this.swal.danger('กรุณากรอกข้อมูลให้สำเร็จ');
        } else if (total_amount > this.product_amount) {
            this.swal.danger('ข้อมูลของจำนวนงานดีและจำนวนงานมีปัญหา มีค่ามากกว่าจำนวนงานทั้งหมด');
        } else if (this.is_finish_good && this.nc_amount > 0 && (!this.warehouse.id || !this.warehouse.shelves[0].id)) {
            this.swal.danger('กรุณาระบุคลังเก็บและชั้นวางสินค้า');
        } else {
            let message: string;
            if (this.is_archive) {
                message = 'คุณต้องการจัดเก็บเข้าคลังสินค้าทั้งหมดใหม่หรือไม่?';
            } else {
                message = 'คุณต้องการส่งต่อกระบวนการ <strong class="text-primary">' + this.next_process.name + '</strong> ใช่หรือไม่?';
            }
            this.swal.confirm(message, true)
                .then((result: boolean): void => {
                    if (result === true) {
                        this.loaderService.show();
                        let warehouse_ids: any;
                        if (this.is_archive) {
                            this.good_amount = this.product_amount;
                            warehouse_ids = this.parse_warehouses_item(this.good_amount);
                        } else {
                            warehouse_ids = this.parse_warehouses_item(this.nc_amount);
                        }
                        this.jobService.schedulerComplete({
                            id                      : this.job.id,
                            // next_product_process_id : (this.next_product_process) ? this.next_product_process.id : null,
                            warehouse_ids           : warehouse_ids,
                            remark                  : this.remark,
                            to_process_id           : this.next_process.id,
                            good_amount             : this.good_amount,
                            nc_amount               : this.nc_amount,
                            is_archive              : this.is_archive
                        }).then(completed => {
                            if (this.job.current_product_process && this.job.current_product_process.is_fvd && this.job.current_product_process.fvd && this.job.current_product_process.fvd.id) {
                                this.fvdService.complete(this.job.current_product_process.fvd)
                                    .then(() => {
                                        this.loaderService.hide();
                                        if (completed && completed.success === false) {
                                            this.swal.danger('งานอาจถูกปรับแผนจากแผนก PD Control โปรดตรวจสอบอีกครั้ง หรือเข้าสู่ระบบใหม่อีกครั้ง')
                                                .then(() => {
                                                    this.smartModal.close();
                                                });
                                            return;
                                        }
                                        this.onSubmitEvent.emit({
                                            remark: this.remark
                                        });
                                        this.smartModal.close();
                                    });
                            } else {
                                this.loaderService.hide();
                                if (completed && completed.success === false) {
                                    this.swal.danger('งานอาจถูกปรับแผนจากแผนก PD Control โปรดตรวจสอบอีกครั้ง หรือเข้าสู่ระบบใหม่อีกครั้ง')
                                        .then(() => {
                                            this.smartModal.close();
                                        });
                                    return;
                                }
                                this.onSubmitEvent.emit({
                                    remark: this.remark
                                });
                                this.smartModal.close();
                            }
                        }, error => {
                            this.loaderService.hide();
                            this.swal.danger(error);
                        });
                    } else {
                        //
                    }
                });
        }
    }

    public parse_warehouses_item(amount: number): any[] {
        let data: any;
        data = [];
        if (this.warehouse.id) {
            data.push({
                warehouse_id: this.warehouse.id,
                shelf_id: (this.warehouse && this.warehouse.shelves && this.warehouse.shelves[0]) ? this.warehouse.shelves[0].id : null,
                amount: amount
            });
        }
        return data;
    }

    public get nc_amount(): number {
        return this.product_amount - this.good_amount;
    }

}
