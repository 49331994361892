import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {DivApiDirective} from '../../../now/divApi';
import {Api} from '../../../now/api/api';
import {UserService} from '../../../services/user.service';
import {ViewList} from '../../../pages/database/list/viewList';
import {MachineModel} from '../../../models/machine.model';

declare var $: any;

@Component({
    selector: 'machine-list-modal',
    templateUrl: 'machineList.modal.html',
    styleUrls: ['machineList.modal.scss']
})
export class MachineListModal extends ViewList implements AfterViewInit, OnDestroy {

    @Input() public type: string;
    @Input() public search_machines: MachineModel[];
    @Input() public is_searching: boolean;
    @Input() getting: boolean;
    @Input() got: boolean;

    @ViewChild(DivApiDirective, { static: false }) _divApi: DivApiDirective;

    @Output() public onSelectedItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onCancelSearching: EventEmitter<any> = new EventEmitter<any>();

    public machines: MachineModel[];
    public keyword: string;
    public editable: boolean;
    public total_machines: number;

    public sub: Subscription;
    public search_fields: string[];
    public search_values: string[];

    public timeout: any;

    public current_machine: MachineModel;
    public tmp_current_machine: MachineModel;

    constructor(
        private route: ActivatedRoute,
        private api: Api,
        public userService: UserService,
        private ngZone: NgZone,
    ) {
        //
        super();

        this.machines = [];
        this.search_machines = [];
        this.search_fields = [];
        this.search_values = [];
        this.is_searching = false;
        this.current_machine = new MachineModel();
        this.got = false;
        this.getting = false;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.type = (this.type) ? this.type : '';
            this.getMachines()
                .then(() => {
                    //
                });

            // $(window).bind('keydown', e => {
            //     if (e.keyCode === 38 || e.keyCode === 40) {
            //         if (this.machines && this.machines.length) {
            //             const current_machine_index: number = this.machines.findIndex(i => i.id === this.current_machine.id);
            //             if (current_machine_index < 0) {
            //                 this.gotoItemView(this.machines[0]);
            //             } else {
            //                 if (e.keyCode === 38) { // up
            //                     if (current_machine_index - 1 > -1) {
            //                         this.gotoItemView(this.machines[current_machine_index - 1]);
            //                     } else {
            //                         //
            //                     }
            //                 } else { // down
            //                     if (current_machine_index + 1 < this.machines.length) {
            //                         this.gotoItemView(this.machines[current_machine_index + 1]);
            //                     } else {
            //                         //
            //                     }
            //                 }
            //             }
            //         }
            //         e.stopPropagation();
            //         e.preventDefault();
            //     }
            // });
        }, 0);
    }

    ngOnDestroy(): void {
        $(window).unbind('keydown');
    }

    public refresh(updated_machine?: any): void {
        this.cancelSearch()
            .then(() => {
                if (this.machines && updated_machine && updated_machine.id) {
                    const finding_machine = this.machines.find(i => i.id === updated_machine.id);
                    if (finding_machine && finding_machine.id) {
                        finding_machine.clone(updated_machine);
                    }
                }
            });
    }

    public getMachines(): Promise<MachineModel[]> {
        let promise: Promise<MachineModel[]>;
        promise = new Promise<MachineModel[]>((resolve, reject) => {
            this.getting = true;
            this.got = false;
            this.api.request('machines', 'GET', {
                limit: 100,
                page: this.current_page,
                find: (this.keyword) ? this.keyword : ''
            }).subscribe((response: any): void => {
                this.ngZone.run(() => {
                    this.getting = false;
                    this.got = true;
                    if (response.meta) {
                        this.total_machines = response.meta.total_items;
                    } else {
                        this.total_machines = response.total_items;
                    }
                    if (response && response.data && Array.isArray(response.data)) {
                        this.clear_machines();
                        for (let i = 0; i < response.data.length; i++) {
                            let dat: any;
                            dat = response.data[i];
                            if (dat) {
                                let machine: MachineModel;
                                machine = new MachineModel();
                                machine.clone(dat);
                                this.machines.push(machine);
                            }
                        }
                    }
                    resolve(this.machines);
                });
            }, error => {
                this.ngZone.run(() => {
                    this.getting = false;
                    this.got = true;
                });
                reject(error);
            });
        });
        return promise;
    }

    public selectMachine(machine): void {
        this.onSelectedItem.emit(machine);
    }

    // public gotoItemView(machine: MachineModel): void {
    //     if (!this.current_machine) {
    //         this.current_machine = new MachineModel();
    //         this.current_machine.clone(machine);
    //         this.current_machine.modelable = machine.modelable;
    //         this.tmp_current_machine = machine;
    //     } else if (this.current_machine.id === machine.id) {
    //         this.current_machine = new MachineModel();
    //         if (this.current_machine && (!this.current_machine.warehouses || this.current_machine.warehouses.length === 0)) {
    //             this.current_machine.warehouses = [];
    //             this.current_machine.warehouses.push(new WarehouseModel());
    //         }
    //         if (this.type) {
    //             this.current_machine.type = this.type;
    //         }
    //         this.tmp_current_machine = null;
    //     } else {
    //         this.current_machine = new MachineModel();
    //         this.current_machine.clone(machine);
    //         this.current_machine.modelable = machine.modelable;
    //         this.tmp_current_machine = machine;
    //     }
    //
    //     if (this.current_machine && (!this.current_machine.warehouses || this.current_machine.warehouses.length === 0)) {
    //         this.current_machine.warehouses = [];
    //         this.current_machine.warehouses.push(new WarehouseModel());
    //     }
    //
    //     if (this.current_machine
    //         && this.current_machine.warehouses
    //         && this.current_machine.warehouses[0]
    //         && this.current_machine.warehouses[0].shelves
    //         && !this.current_machine.warehouses[0].shelves[0]) {
    //         //
    //         this.current_machine.warehouses[0].shelves[0] = new ShelfModel();
    //     }
    //
    //     if (this.type) {
    //         this.current_machine.type = this.type;
    //     }
    //
    //     if (!this.current_machine.modelable) {
    //         this.current_machine.modelable = {};
    //     }
    //
    //     this.onSelectedItem.emit(this.current_machine);
    // }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        if (this.keyword) {
            this.getting = true;
            this.got = false;
            this.timeout = setTimeout(() => {
                this.getMachines()
                    .then(() => {
                        this.clear_timeout();
                    });
            }, 300);
        }
    }

    public clear_machines(): void {
        if (this.machines) {
            this.machines.splice(0, this.machines.length);
        } else {
            this.machines = [];
        }
    }

    private clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = 0;
        }
    }

    public onError(e: any): void {
        //
    }

    private unsub(): void {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        } else {
            //
        }
    }

    public cancelSearch(): Promise<any> {
        const promise: Promise<any> = new Promise<any>((resolve) => {
            this.got = false;
            this.getting = false;
            this.tmp_current_machine = null;
            this.search_values.splice(0, this.search_values.length);
            this.search_fields.splice(0, this.search_fields.length);
            this.unsub();
            this.is_searching = false;
            setTimeout(() => {
                this.getMachines()
                    .then(() => {
                        resolve();
                    });
            }, 150);
            this.onCancelSearching.emit();
        });
        return promise;
    }

    public pageChange(page: number): void {
        this.current_page = page;
        this.getMachines()
            .then(() => {});
    }

}
