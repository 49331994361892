import {AfterViewInit, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {TaskListComponent} from '../../pages/task/list/taskList.component';
import {SwalService} from '../../services/swal.service';
import {DecimalPipe} from '@angular/common';

@Component({
    selector: 'account-modal',
    templateUrl: 'account.modal.html',
    styleUrls: ['account.modal.scss'],
    providers: [
        DecimalPipe,
    ]
})
export class AccountModal implements AfterViewInit {

    @ViewChild('modal', { static: true }) smartModal: NgxSmartModalComponent;

    @Output('submitting') onSubmitting: EventEmitter<any> = new EventEmitter<any>();
    @Output('submit') onSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output('uploaded') onUploaded: EventEmitter<any> = new EventEmitter<any>();

    public documents;
    public customer: any;
    public tasks: any[];
    public parent: TaskListComponent;
    public _all: boolean;
    public taxInvoices = [];
    public deliveryNotes = [];

    constructor(
        private decimalPipe: DecimalPipe,
        private swal: SwalService
    ) {
        //
        this.customer = {};
        this.tasks = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._all = false;
        }, 0);
    }

    public onClose(e?: any): void {
        // this.removeRemark();
    }

    public onDismiss(e?: any): void {
        //
    }

    public onOpen(e?: any): void {
        this._all = false;
        for (const task of this.tasks) {
            task._checked = false;
        }
    }

    public onAllChange(e: any): void {
        if (e) {
            for (const document of this.documents) {
                document._checked = true;
            }
        } else {
            for (const document of this.documents) {
                document._checked = false;
            }
        }
    }

    public open(customer: any, field: string): void {
        if (customer && field) {
            this.customer = customer;
            this.documents = [];
            const customerDocuments = this.customer[field];
            for (const document of customerDocuments) {
                if (document && document.status === 1) {
                    this.documents.push(document);
                }
            }
            this.smartModal.open();
        }
    }

    public close(): void {
        this.smartModal.close();
    }

    public submit(): void {
        let grandTotal = 0;
        const documents = [];
        for (const document of this.documents) {
            if (document && document._checked) {
                grandTotal += (document.grand_total > 0) ? document.grand_total : 0;
                documents.push(document);
            }
        }
        if (documents.length) {
            const grandTotalText = this.decimalPipe.transform(grandTotal, '.0-2');
            this.swal.confirm('ยืนยันการออกใบเสร็จรับเงิน "' + this.customer.customer_name + '" มูลค่า ' + grandTotalText + ' บาท ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.onSubmit.emit({
                            documents,
                            customer: this.customer
                        });
                        this.close();
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('เลือกรายการอย่างน้อย 1 รายการ');
        }
    }

}
