import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProcessService} from '../../../../services/process.service';
import {ProductModel} from '../../../../models/product.model';
import {NgForm} from '@angular/forms';
import {TaskModel} from '../../../../models/task.model';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {JobModel} from '../../../../models/job.model';
import {ProductProcessModel} from '../../../../models/productProcess.model';
import {ModelApi} from '../../../../now/modelApi/modelApi';

@Component({
    selector: 'start-modal-component',
    templateUrl: 'startModal.component.html',
    styleUrls: ['startModal.component.scss']
})
export class StartModalComponent implements AfterViewInit {

    public ready: boolean;
    public product: ProductModel;
    public task: TaskModel;
    public remark: string;
    public job: JobModel;
    public product_processes: ProductProcessModel[];
    public product_amount: number;
    public customer_product_amount: number;
    public product_pull_amount: number;
    public sale_product_amount: number;
    public ncr_amount: number;

    constructor(
        public bsModalRef: BsModalRef,
        private processService: ProcessService,
        private api: Api,
        private swal: SwalService,
        private modelApi: ModelApi
    ) {
        //
        this.product = new ProductModel();
        this.task = new TaskModel();
        this.ready = false;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.product) {
                this.product = this.bsModalRef.content.product;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.product_processes) {
                this.product_processes = this.bsModalRef.content.product_processes;
            } else {
                this.product_processes = [];
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
                this.getJobDetail()
                    .then(() => {
                        this.customer_product_amount = this.job.customer_product_amount; // ลูกค้าสั่งผลิต (customer)
                        // this.product_amount = this.job.current_product_process.amount; // งานที่สั่งผลิต (planning)
                        this.product_amount = this.job.product_amount;
                        this.sale_product_amount = this.job.sale_product_amount; // งานที่โหลด (sale-admin)
                        this.ncr_amount = this.job.ncr_amount; // งานมีปัญหา
                        this.ready = true;
                    });
            }
            this.bsModalRef.content.submit = false;
        }, 0);
    }

    private getJobDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.modelApi.sync(this.job)
                .subscribe((response: any): void => {
                    this.product.id = this.job.product_id;
                    resolve(this.job);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onSubmit(form: NgForm): void {
        if (!this.ready) {
            return;
        }
        if (form.valid) {
            if (!this.product_pull_amount) {
                this.swal.danger('โปรดระบุจำนวนที่รับเข้าทำงาน');
            } else if (this.remainder < 0) {
                this.swal.danger('ข้อมูลของจำนวนงานไม่ถูกต้อง');
            /*} else if (this.product_pull_amount < 1 || !this.product_pull_amount) {
                this.swal.danger('ข้อมูลของจำนวนงานไม่ถูกต้อง');*/
            } else {
                let message: string;
                message = 'คุณต้องการรับงานเป็นจำนวน <strong class="text-primary">'
                    + this.product_pull_amount + ' ' + this.product.unit +  '</strong> ใช่หรือไม่?';
                this.swal.confirm(message, true)
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.bsModalRef.content.product_pull_amount = this.product_pull_amount;
                            this.bsModalRef.content.remainder = this.remainder;
                            this.bsModalRef.content.remark = this.remark;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        } else {
                            //
                        }
                    });
            }
        } else {
            //
        }
    }

    public get remainder(): number {
        return this.product_amount - this.product_pull_amount;
    }

}
