import {Model} from '../now/model';
import {JobModel} from './job.model';
import {ProductModel} from './product.model';
import {UserModel} from '../now/user/user.model';
import {MachineModel} from './machine.model';
import {ProductProcessModel} from './productProcess.model';
import {SchedulerModel} from './scheduler.model';
import {DocumentModel} from './document.model';
import {MaterialModel} from './material.model';
import {ProcessModel} from './process.model';
import {FVD} from '../app/api/fvd';
import {DeliveryNoteModel} from './deliveryNote.model';

export class CARModel extends Model {

    public remarks: any[] = [];
    public started_at: any;
    public delivery_note_id: any;
    public car_no: string;
    public job: JobModel;
    public product: ProductModel;
    public issued_user: UserModel;
    public checked_user: UserModel;
    public approved_user: UserModel;
    public report_user: UserModel;
    public material: MaterialModel;
    public method: ProcessModel;
    public fvd_id: any;
    public fvd: FVD;
    public user_id;
    public to_user_id;
    public to_user: UserModel;
    public user: UserModel;
    public delivery_note: DeliveryNoteModel;
    public delivery_notes: DeliveryNoteModel[];
    public jobs: JobModel[];

    public is_man: boolean;
    public is_machine: boolean;
    public is_material: boolean;
    public is_method: boolean;

    public documents: DocumentModel[];

    public process_slug: string;
    public job_id: string;
    public product_id: string;
    public amount: number;
    public problem_description: string;
    public problem_type: string;
    public problem_details: string;
    public equipments: string[];
    public cause_description: string;
    public cause_preventive: string;
    public issued_description: string;
    public checked_description: string;
    public approved_description: string;
    public next_process: string;
    public next_process_amount: number;
    public accepted_date: string;
    public issued_user_id: string;
    public checked_user_id: string;
    public approved_user_id: string;
    public issued_date: string;
    public checked_date: string;
    public approved_date: string;
    public report_date: string;

    public issued_solve_message: string;
    public checked_solve_message: string;

    public man_id: string;
    public machine_id: string;
    public product_process_id: string;
    public scheduler_id: string;

    public man: UserModel;
    public machine: MachineModel;
    public product_process: ProductProcessModel;
    public scheduler: SchedulerModel;

    reporter?: UserModel;
    reporter_id?: string;

    public material_id: string;
    public method_id: string;

    constructor() {
        super('cars', 'App\\CAR');
        this.to_user = new UserModel();
        this.user = new UserModel();
        this.man = new UserModel();
        this.job = new JobModel();
        this.product = new ProductModel();
        this.scheduler = new SchedulerModel();
        this.machine = new MachineModel();
        this.equipments = [];
        this.documents = [];
        this.delivery_notes = [];
    }

    public clone(data: any): Model {
        if (data) {
            if (data.man) {
                this.man = new UserModel();
                this.man.clone(data.man);
                delete data.man;
            }
            if (data.product_process) {
                this.product_process = new ProductProcessModel();
                this.product_process.clone(data.product_process);
                delete data.product_process;
            }
            if (data.machine) {
                this.machine = new MachineModel();
                this.machine.clone(data.machine);
                delete data.machine;
            }
            if (data.report_user) {
                this.report_user = new UserModel();
                this.report_user.clone(data.report_user);
                delete data.report_user;
            }
            if (data.issued_user) {
                this.issued_user = new UserModel();
                this.issued_user.clone(data.issued_user);
                delete data.issued_user;
            }
            if (data.checked_user) {
                this.checked_user = new UserModel();
                this.checked_user.clone(data.checked_user);
                delete data.checked_user;
            }
            if (data.approved_user) {
                this.approved_user = new UserModel();
                this.approved_user.clone(data.approved_user);
                delete data.approved_user;
            }
            if (data.user) {
                this.user = new UserModel();
                this.user.clone(data.user);
                delete data.user;
            }
        }
        super.clone(data);
        return this;
    }

}
