import {FormModel} from '../now/formModel';
import {UserModel} from '../now/user/user.model';
import {ProductModel} from './product.model';
import {CarrierModel} from './carrier.model';
import {CustomerModel} from './customer.model';
import {RequirementModel} from './requirement.model';
import {PaymentModel} from './payment.model';
import {CustomerAddressModel} from './customerAddress.model';
import {DepositModel} from './deposit.model';
import {DocumentModel} from './document.model';
import {BillModel} from './bill.model';
import {ContactModel} from './contact.model';
import {Model} from '../now/model';
import {TaxInvoiceModel} from './taxInvoice.model';
import {JobModel} from './job.model';

export class InvoiceModel extends FormModel {

    public job_id: any;
    public receipts: any[] = [];
    public invoice_no: string;
    public quotation_no: string;
    public document_id: any;
    public document: any;
    public price_validity: number;
    public delivery_note_documents: any[];
    public remark: string;
    public tax_invoice_id: string;
    public jobs: JobModel[];
    public job_ids: any[];
    public task_id: string;
    public customer_id: string;
    public product_ids: any;
    public contact_ref: string;
    public requirement_id: string;
    public products: ProductModel[];
    public carrier: CarrierModel;
    public customer: CustomerModel;
    public sale: UserModel;
    public discount_type: number;

    public quotation_id: string;
    public credit_terms: number;
    public verified: boolean;

    public user: UserModel;

    public ref_no: string;

    public contact_id: string;

    public total_price: number;

    public customer_address_id: string;
    public sale_id: string;
    public shipping_id: string;
    public currency: string;
    public currency_symbol: string;
    public currency_rate: number;
    public currency_rated_at: string;
    public signature_date: string;
    public delivery_date: string;
    public delivery_time: string;

    public vat_percent: number;
    public vat_price: number;
    public discount_percent: number;
    public discount_price: number;
    public material_price: number;
    public frt_sh: number;
    public misc_chgs: number;
    public sub_total: number;
    public gst_tax: number;
    public grand_total: number;

    public started_at: string;
    public ended_at: string;

    public required_at: string;

    public requirement: RequirementModel;
    // public purchase_orders: PurchaseOrderModel[];
    public payments: PaymentModel[];

    public shipping: any;
    public incoterm_code: string;
    public carrier_id: string;
    public customer_address: CustomerAddressModel;

    public status: number;
    public is_include_vat: boolean;
    public sent_date: string;
    public sent_user: UserModel;
    public deposits: DepositModel[];
    public tax_invoices: TaxInvoiceModel[];
    public documents: DocumentModel[];
    public bill: BillModel;
    public contact: ContactModel;
    public bill_id: any;

    public job_no_list: string[];
    public product_no_list: string[];
    public drawing_no_list: string[];
    public product_name_list: string[];
    public purchase_order_no_list: string[];

    constructor() {
        super('invoices', 'App\\Invoice');
        //
        this.sale = new UserModel();
        this.bill = new BillModel();
        this.contact = new ContactModel();
        this.incoterm_code = '';
        this.product_ids = {};
        this.products = [];
        this.payments = [];
        this.tax_invoices = [];
        this.carrier = new CarrierModel();
        this.customer = new CustomerModel();
        this.requirement = new RequirementModel();
        this.document = new DocumentModel();
        this.sent_user = new UserModel();
        this.customer_address = new CustomerAddressModel();
        this.documents = [];
        this.deposits = [];
        this.delivery_note_documents = [];
    }

    public clone(data: any): Model {
        if (data && data.products) {
            for (let i = 0; i < data.products.length; i++) {
                const dat: any = data.products[i];
                if (dat) {
                    let product: ProductModel;
                    product = new ProductModel();
                    product.clone(dat);
                    product.price = (dat.pivot) ? dat.pivot.price : dat.price;
                    product.purchase_order_no = (dat.pivot) ? dat.pivot.purchase_order_no : dat.purchase_order_no;
                    this.products.push(product);
                }
            }
            delete data.products;
        }
        if (data && data.user) {
            this.user = new UserModel();
            this.user.clone(data.user);
            delete data.user;
        }
        super.clone(data);
        return this;
    }

    public cal_total_job_price(): number {
        this.material_price = 0;
        for (let i = 0; i < this.jobs.length; i++) {
            const job = this.jobs[i];
            if (job && job.pivot) {
                this.material_price += ((job.pivot.price_per_unit > 0) ? job.pivot.price_per_unit : 0) * ((job.pivot.amount > 0) ? job.pivot.amount : 0);
            }
        }

        const material_price: number = (this.material_price > 0) ? this.material_price : 0;
        const frt_sh: number = (this.frt_sh > 0) ? this.frt_sh : 0;
        const misc_chgs: number = (this.misc_chgs > 0) ? this.misc_chgs : 0;
        const sub_total = material_price + frt_sh + misc_chgs;
        const _discount_price = (this.discount_price) ? this.discount_price : 0;
        const _discount_percent = (this.discount_percent) ? this.discount_percent : 0;
        const _vat_percent = (this.vat_percent) ? this.vat_percent : 0;

        if (this.discount_type === 1) {
            this.discount_price = Math.round(((sub_total * _discount_percent) / 100) * 10000) / 10000;
        } else {
            //
        }
        this.sub_total = sub_total - this.discount_price;
        this.vat_price = Math.round(((this.sub_total * _vat_percent) / 100) * 10000) / 10000;
        this.grand_total = this.vat_price + this.sub_total;

        return this.grand_total;
    }

    public cal_total_price(): number {
        this.material_price = 0;
        for (let i = 0; i < this.products.length; i++) {
            const product: ProductModel = this.products[i];
            if (product && product.price_per_unit && product.customer_product_amount) {
                this.material_price += product.price_per_unit * product.customer_product_amount;
            }
        }

        const material_price: number = (this.material_price > 0) ? this.material_price : 0;
        const frt_sh: number = (this.frt_sh > 0) ? this.frt_sh : 0;
        const misc_chgs: number = (this.misc_chgs > 0) ? this.misc_chgs : 0;
        const sub_total = material_price + frt_sh + misc_chgs;
        const _discount_price = (this.discount_price) ? this.discount_price : 0;
        const _discount_percent = (this.discount_percent) ? this.discount_percent : 0;
        const _vat_percent = (this.vat_percent) ? this.vat_percent : 0;

        if (this.discount_type === 1) {
            this.discount_price = Math.round(((sub_total * _discount_percent) / 100) * 10000) / 10000;
        } else {
            //
        }
        this.sub_total = sub_total - this.discount_price;
        this.vat_price = Math.round(((this.sub_total * _vat_percent) / 100) * 10000) / 10000;
        this.grand_total = this.vat_price + this.sub_total;

        return this.grand_total;
    }

    public validPayment(): boolean {
        return true;
    }

}
