import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {LoaderService} from '../../../components/loader/loader.service';
import {Api} from '../../../now/api/api';
import {AppService} from '../../../app/app.service';
import {DecimalPipe} from '@angular/common';
import {UserModel} from '../../../now/user/user.model';
import {Viewer} from '../../../services/viewer';
import {Router} from '@angular/router';

@Component({
    selector: 'hr-report-page',
    templateUrl: 'hrReport.page.html',
    styleUrls: ['hrReport.page.scss'],
    providers: [DecimalPipe]
})
export class HRReportPage implements AfterViewInit, OnDestroy {

    public tmpDateRange: any;
    public startDate: any;
    public endDate: any;
    public date: any;
    public man_id: any;
    public getting: boolean;
    public tmp_man;
    public current_view;
    public columnDefs;
    public columnDefs2;
    public ready;
    public rowData;
    public rowData2;
    public current_zoom;

    constructor(
        private viewer: Viewer,
        private loader: LoaderService,
        private api: Api,
        private appService: AppService,
        private router: Router,
        private decimalPipe: DecimalPipe
    ) {
        //
        this.current_view = 'MONTHLY';
        this.date = moment(new Date());
        this.startDate = moment(new Date()).startOf('month');
        this.endDate = moment(new Date()).endOf('month');
        this.tmpDateRange = {
            startDate: this.startDate,
            endDate: this.endDate
        };
        console.log(this.tmpDateRange);
        this.tmp_man = new UserModel();
        this.current_zoom = 100;
        this.columnDefs2 = [
            {
                editable: false,
                headerName: 'พนักงาน',
                field: 'man',
                width: 120,
                cellStyle: {
                    'align-items': 'center',
                }
            },
            {
                editable: false,
                headerName: 'ลาพักร้อน',
                field: 'sum_vacation',
                width: 40,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data && params.data.sum_vacation > 0) {
                        bgColor = '#81C784';
                        color = '#ffffff';
                    }
                    return {
                        'background-color': bgColor,
                        'color': color
                    };
                }
            },
            {
                editable: false,
                headerName: 'ลากิจ',
                field: 'sum_personal',
                width: 40,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data && params.data.sum_personal > 0) {
                        bgColor = '#81C784';
                        color = '#ffffff';
                    }
                    return {
                        'background-color': bgColor,
                        'color': color
                    };
                }
            },
            {
                editable: false,
                headerName: 'ลาป่วย',
                field: 'sum_sick',
                width: 40,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data && params.data.sum_sick > 0) {
                        bgColor = '#81C784';
                        color = '#ffffff';
                    }
                    return {
                        'background-color': bgColor,
                        'color': color
                    };
                }
            },
            {
                editable: false,
                headerName: 'ลาขาด',
                field: 'sum_force',
                width: 40,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data && params.data.sum_force > 0) {
                        bgColor = '#81C784';
                        color = '#ffffff';
                    }
                    return {
                        'background-color': bgColor,
                        'color': color
                    };
                }
            },
            {
                editable: false,
                headerName: 'ลาคลอด',
                field: 'sum_maternity',
                width: 40,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data && params.data.sum_maternity > 0) {
                        bgColor = '#81C784';
                        color = '#ffffff';
                    }
                    return {
                        'background-color': bgColor,
                        'color': color
                    };
                }
            },
            /*{
                editable: false,
                headerName: 'ลาอื่นๆ',
                field: 'sum_other',
                width: 40,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data && params.data.sum_other > 0) {
                        bgColor = '#81C784';
                        color = '#ffffff';
                    }
                    return {
                        'background-color': bgColor,
                        'color': color
                    };
                }
            },*/
        ];
        this.columnDefs = [
            {
                editable: false,
                headerName: 'เรียกดูใบลา',
                field: 'documents',
                width: 100,
                cellStyle: {
                    'align-items': 'center',
                }
            },
            {
                editable: false,
                headerName: 'สถานะ',
                field: 'status',
                width: 100,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data) {
                        if (!params.data.canceled_at) {
                            if (params.data.approved_3) {
                                bgColor = '#81C784';
                                color = '#ffffff';
                            } else if ((params.data.approved_1 || params.data.approved_2 || params.data.approved_3) && (!params.data.rejected1_at && !params.data.rejected2_at && !params.data.rejected3_at)) {
                                bgColor = '#ffffff';
                                color = '#2196f3';
                            } else if (params.data.rejected1_at || params.data.rejected2_at || params.data.rejected3_at) {
                                bgColor = '#f44336';
                                color = '#ffffff';
                            }
                        } else {
                            //
                        }
                    }
                    return {
                        'line-height': '1.125rem',
                        'font-size': '1rem',
                        'align-items': 'center',
                        'background-color': bgColor,
                        'color': color
                    };
                }
            },
            {
                editable: false,
                headerName: 'วันที่ร้องขอ',
                field: 'request',
                width: 140,
                cellStyle: {
                    'align-items': 'center',
                }
            },
            {
                editable: false,
                headerName: 'วันที่เริ่มลา',
                field: 'start',
                width: 140,
                cellStyle: {
                    'align-items': 'center',
                }
            },
            {
                editable: false,
                headerName: 'ลาถึงวันที่',
                field: 'end',
                width: 140,
                cellStyle: {
                    'align-items': 'center',
                }
            },
            {
                editable: false,
                headerName: 'วัน',
                field: 'days',
                width: 50,
                cellStyle: {
                    'align-items': 'center',
                }
            },
            {
                editable: false,
                headerName: 'พนักงาน',
                field: 'man',
                width: 120,
                cellStyle: {
                    'align-items': 'center',
                }
            },
            {
                editable: false,
                headerName: 'หัวข้อ',
                field: 'subject',
                width: 120,
                cellStyle: {
                    'align-items': 'center',
                }
            },
            {
                editable: false,
                headerName: 'รายละเอียด',
                field: 'description',
                resizable: true,
                width: 320,
                cellStyle: {
                    'align-items': 'center',
                    'justify-content': 'flex-start'
                }
            },
            // {
            //     editable: false,
            //     headerName: 'ลาพักร้อน',
            //     field: 'vacation',
            //     width: 40,
            // },
            // {
            //     editable: false,
            //     headerName: 'ลากิจ',
            //     field: 'personal',
            //     width: 40,
            // },
            // {
            //     editable: false,
            //     headerName: 'ลาป่วย',
            //     field: 'sick',
            //     width: 40,
            // },
            // {
            //     editable: false,
            //     headerName: 'ลาขาด',
            //     field: 'force',
            //     width: 40,
            // },
            // {
            //     editable: false,
            //     headerName: 'ลาคลอด',
            //     field: 'maternity',
            //     width: 40,
            // },
            // {
            //     editable: false,
            //     headerName: 'ลาอื่นๆ',
            //     field: 'other',
            //     width: 40,
            // },
            {
                editable: false,
                headerName: 'อนุมัติ (1)',
                width: 140,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data) {
                        if (params.data.rejected1_at) {
                            bgColor = '#f44336';
                            color = '#ffffff';
                        } else if (params.data.approved1_at) {
                            bgColor = '#81C784';
                            color = '#ffffff';
                        }
                    }
                    return {
                        'line-height': '1.125rem',
                        'font-size': '1rem',
                        'align-items': 'center',
                        'background-color': bgColor,
                        'color': color
                    };
                },
                cellRenderer: params => {
                    const approve = (params.data.approved1_at) ? params.data.approved_1 + '<br/>' + params.data.approved1_at : (params.data.rejected1_at) ? params.data.rejected_1 + '<br/>' + params.data.rejected1_at : '';
                    return approve;
                }
            },
            {
                editable: false,
                headerName: 'อนุมัติ (2)',
                width: 140,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data) {
                        if (params.data.rejected2_at) {
                            bgColor = '#f44336';
                            color = '#ffffff';
                        } else if (params.data.approved2_at) {
                            bgColor = '#81C784';
                            color = '#ffffff';
                        }
                    }
                    return {
                        'line-height': '1.125rem',
                        'font-size': '1rem',
                        'align-items': 'center',
                        'background-color': bgColor,
                        'color': color
                    };
                },
                cellRenderer: params => {
                    const approve = (params.data.approved2_at) ? params.data.approved_2 + '<br/>' + params.data.approved2_at : (params.data.rejected2_at) ? params.data.rejected_2 + '<br/>' + params.data.rejected2_at : '';
                    return approve;
                }
            },
            {
                editable: false,
                headerName: 'อนุมัติ (3)',
                width: 140,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data) {
                        if (params.data.rejected3_at) {
                            bgColor = '#f44336';
                            color = '#ffffff';
                        } else if (params.data.approved3_at) {
                            bgColor = '#81C784';
                            color = '#ffffff';
                        }
                    }
                    return {
                        'line-height': '1.125rem',
                        'font-size': '1rem',
                        'align-items': 'center',
                        'background-color': bgColor,
                        'color': color
                    };
                },
                cellRenderer: params => {
                    const approve = (params.data.approved3_at) ? params.data.approved_3 + '<br/>' + params.data.approved3_at : (params.data.rejected3_at) ? params.data.rejected_3 + '<br/>' + params.data.rejected3_at : '';
                    return approve;
                }
            },
            {
                editable: false,
                headerName: 'ยกเลิกการลา',
                width: 140,
                cellStyle: params => {
                    let bgColor = '#ffffff';
                    let color = '#2196f3';
                    if (params.data) {
                        if (params.data.canceled_at) {
                            bgColor = '#f44336';
                            color = '#ffffff';
                        }
                    }
                    return {
                        'line-height': '1.125rem',
                        'font-size': '1rem',
                        'align-items': 'center',
                        'background-color': bgColor,
                        'color': color
                    };
                },
                cellRenderer: params => {
                    const canceled_at = (params.data.canceled_at) ? params.data.canceled_at : '';
                    return canceled_at;
                }
            }
        ];
    }

    ngAfterViewInit(): void {
        this.appService.fixPage();
        setTimeout(() => {
            this.getData();
        });
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
    }

    public onManChange(e: any): void {
        this.man_id = (e && e.id) ? e.id : null;
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public getData(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.rowData = [];
            this.rowData2 = [];
            this.ready = false;
            this.api.request('report/hr', 'POST', {}, {
                date        : (moment.isMoment(this.date)) ? this.date.format('DD/MM/YYYY') : moment(this.date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'),
                view        : this.current_view,
                startDate   : (moment.isMoment(this.startDate)) ? this.startDate.format('YYYY-MM-DD HH:mm:ss') : moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
                endDate     : (moment.isMoment(this.endDate)) ? this.endDate.format('YYYY-MM-DD HH:mm:ss') : moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
                man_id      : (this.man_id) ? this.man_id : '',
            }).subscribe((data: any) => {
                if (data && data.data && Array.isArray(data.data)) {
                    for (const dat of data.data) {
                        if (dat) {
                            const subject = this.createSubject(dat);
                            const description = dat.description;

                            const approved_1 = (dat.approved1_user && dat.approved1_user.id) ? dat.approved1_user.full_name : '';
                            const approved1_at = (dat.approved_1_at) ? dat.approved_1_at : '';
                            const rejected_1 = (dat.rejected1_user && dat.rejected1_user.id) ? dat.rejected1_user.full_name : '';
                            const rejected1_at = (dat.rejected_1_at) ? dat.rejected_1_at : '';

                            const approved_2 = (dat.approved2_user && dat.approved2_user.id) ? dat.approved2_user.full_name : '';
                            const approved2_at = (dat.approved_2_at) ? dat.approved_2_at : '';
                            const rejected_2 = (dat.rejected2_user && dat.rejected2_user.id) ? dat.rejected2_user.full_name : '';
                            const rejected2_at = (dat.rejected_2_at) ? dat.rejected_2_at : '';

                            const approved_3 = (dat.approved3_user && dat.approved3_user.id) ? dat.approved3_user.full_name : '';
                            const approved3_at = (dat.approved_3_at) ? dat.approved_3_at : '';
                            const rejected_3 = (dat.rejected3_user && dat.rejected3_user.id) ? dat.rejected3_user.full_name : '';
                            const rejected3_at = (dat.rejected_3_at) ? dat.rejected_3_at : '';

                            const canceled_at = (dat.canceled_at) ? dat.canceled_at : '';

                            // const approve_1 = (approved1_at) ? approved_1 + '<br/>' + approved1_at : (rejected1_at) ? rejected_1 + '<br/>' + rejected1_at : '';
                            // const approve_2 = (approved2_at) ? approved_2 + '<br/>' + approved2_at : (rejected2_at) ? rejected_2 + '<br/>' + rejected2_at : '';
                            // const approve_3 = (approved3_at) ? approved_3 + '<br/>' + approved3_at : (rejected3_at) ? rejected_3 + '<br/>' + rejected3_at : '';

                            if (!canceled_at) {
                                if (approved3_at !== '' && approved3_at) {
                                    console.log(dat);
                                    console.log(approved3_at);
                                    status = 'อนุมัติผ่าน';
                                } else if ((approved1_at || approved2_at || approved3_at) && (!rejected1_at && !rejected2_at && !rejected3_at)) {
                                    status = 'รอการอนุมัติ';
                                } else if (rejected1_at || rejected2_at || rejected3_at) {
                                    status = 'ไม่ผ่าน';
                                }
                            } else {
                                status = 'ยกเลิกการลา';
                            }

                            this.rowData.push({
                                status,
                                id          : dat.id,
                                documents   : dat.document_no,
                                request     : dat.created_at,
                                start       : dat.start,
                                end         : dat.end,
                                days        : dat.days,
                                man         : (dat.user && dat.user.full_name) ? dat.user.full_name : '',
                                approved_1, approved1_at, rejected_1, rejected1_at,
                                approved_2, approved2_at, rejected_2, rejected2_at,
                                approved_3, approved3_at, rejected_3, rejected3_at,
                                subject, description, canceled_at,
                            });

                            if (dat.user_id && dat.user && dat.user.full_name) {
                                let sum_vacation = 0;
                                let sum_personal = 0;
                                let sum_sick = 0;
                                let sum_maternity = 0;
                                let sum_force = 0;
                                let sum_other = 0;
                                if (!canceled_at && !rejected1_at && !rejected2_at && !rejected3_at) {
                                    if (dat.subject === 'VACATION') {
                                        sum_vacation += (dat.days) ? dat.days : 0;
                                    } else if (dat.subject === 'PERSONAL') {
                                        sum_personal += (dat.days) ? dat.days : 0;
                                    } else if (dat.subject === 'SICK') {
                                        sum_sick += (dat.days) ? dat.days : 0;
                                    } else if (dat.subject === 'MATERNITY') {
                                        sum_maternity += (dat.days) ? dat.days : 0;
                                    } else if (dat.subject === 'FORCE') {
                                        sum_force += (dat.days) ? dat.days : 0;
                                    } else if (dat.subject === 'OTHER') {
                                        sum_other += (dat.days) ? dat.days : 0;
                                    }
                                }
                                const findRowDat2 = this.rowData2.findIndex(i => i.user_id === dat.user_id);
                                if (findRowDat2 > -1) {
                                    this.rowData2[findRowDat2].sum_vacation += sum_vacation;
                                    this.rowData2[findRowDat2].sum_personal += sum_personal;
                                    this.rowData2[findRowDat2].sum_sick += sum_sick;
                                    this.rowData2[findRowDat2].sum_maternity += sum_maternity;
                                    this.rowData2[findRowDat2].sum_force += sum_force;
                                    this.rowData2[findRowDat2].sum_other += sum_other;
                                } else {
                                    this.rowData2.push({
                                        user_id: dat.user_id,
                                        man: dat.user.full_name,
                                        sum_vacation, sum_personal, sum_sick, sum_maternity, sum_force, sum_other
                                    });
                                }
                            }
                        }
                    }
                }
                this.ready = true;
                resolve();
            }, error => {
                //
            });
        });
        return promise;
    }

    public printReport(): void {
        const options = {
            date        : (moment.isMoment(this.date)) ? this.date.format('DD/MM/YYYY') : moment(this.date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'),
            view        : this.current_view,
            startDate   : (moment.isMoment(this.startDate)) ? this.startDate.format('YYYY-MM-DD HH:mm:ss') : moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate     : (moment.isMoment(this.endDate)) ? this.endDate.format('YYYY-MM-DD HH:mm:ss') : moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
            man_id      : (this.man_id) ? this.man_id : '',
        };
        this.viewer.hrReport(options)
            .then(() => {
                //
            });
    }

    public onCellClicked(e?: any): void {
        console.log(e.data);
        if (e && e.data && e.data.id) {
            this.api.request('tasks/hr', 'POST', {}, {
                id: e.data.id,
            }).subscribe((data: any) => {
                console.log(data);
                if (data && data.data && data.data.id) {
                    this.router.navigateByUrl('/task/hr/man/' + data.data.id);
                }
            });
        } else {
            //
        }
    }

    public createSubject(dat) {
        if (dat) {
            if (dat.subject === 'VACATION') {
                return 'ลาพักร้อน';
            } else if (dat.subject === 'PERSONAL') {
                return 'ลากิจ';
            } else if (dat.subject === 'SICK') {
                return 'ลาป่วย';
            } else if (dat.subject === 'MATERNITY') {
                return 'ลาคลอด';
            } else if (dat.subject === 'FORCE') {
                return 'ลาขาด';
            } else if (dat.subject === 'OTHER') {
                return 'อื่น ๆ';
            }
        }
        return dat;
    }

    public get_date(): string {
        if (this.current_view === 'YEARLY') {
            return this.date.format('YYYY');
        } else if (this.current_view === 'MONTHLY') {
            return this.date.format('MM/YYYY');
        } else if (this.current_view === 'DAILY') {
            return this.date.format('DD/MM/YYYY');
        } else if (this.current_view === 'RANGE') {
            const startDate = this.tmpDateRange.startDate.format('DD/MM/YYYY');
            const endDate = this.tmpDateRange.endDate.format('DD/MM/YYYY');
            return startDate + '-' + endDate;
        }
        return '';
    }

    public onSuccess(e: any): void {
        this.ready = true;
    }

    public onError(e: any): void {
        //
    }

    public onViewChange(e: any): void {
        if (e === 'DAILY') {
            this.date = moment(new Date());
        } else if (e === 'MONTHLY') {
            this.date = moment(new Date());
        } else if (e === 'QUARTERLY') {
            // this.date = moment(new Date());
        } else if (e === 'YEARLY') {
            this.date = moment(new Date());
        } else if (e === 'RANGE') {
            // this.dateRange = {
            //     startDate: this.startDate,
            //     endDate: this.endDate
            // };
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public onDaterangeChange(e: any): void {
        if (e && e[0] && e[1]) {
            this.startDate = e[0];
            this.endDate = e[1];
            setTimeout(() => {
                this.refresh();
            }, 500);
        }
    }

    public onDateChange(e: any, date: any): void {
        if (this.current_view === 'DAILY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        } else if (this.current_view === 'MONTHLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
        } else if (this.current_view === 'YEARLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
        } else if (this.current_view === 'RANGE') {
            //
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public refresh(): void {
        if (this.getting) {
            //
        } else {
            this.loader.show();
            this.getting = true;
            this.getData()
                .then(() => {
                    this.loader.hide();
                    this.getting = false;
                });
        }
    }

    public get contentHeight(): string {
        const window_height = window.innerHeight;
        const _height = '' + (((window_height * (100 / this.current_zoom)) - 0 - 0) * .65) + 'px';
        return _height;
    }

}
