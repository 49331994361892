import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskModel} from '../../../../models/task.model';
import {CustomerModel} from '../../../../models/customer.model';
import {ContactModel} from '../../../../models/contact.model';
import {ProductModel} from '../../../../models/product.model';
import {DocumentModel} from '../../../../models/document.model';
import {DivApiDirective} from '../../../../now/divApi';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {UserModel} from '../../../../now/user/user.model';
import {TaskService} from '../../../../services/task.service';
import {Api} from '../../../../now/api/api';
import {AddProductComponent} from '../addProduct/addProduct.component';
import {DrawingModel} from '../../../../models/drawing.model';
import {CADModel} from '../../../../models/cad.model';
import {DocumentDetailComponent} from '../documentDetail/documentDetail.component';
import {DrawingDetailComponent} from '../drawingDetail/drawingDetail.component';
import {CadDetailComponent} from '../cadDetail/cadDetail.component';
import {AuthService} from '../../../../now/user/auth.service';
import {QuotationModel} from '../../../../models/quotation.model';
import {SwalService} from '../../../../services/swal.service';
import {UserService} from '../../../../services/user.service';
import {PurchaseOrderModel} from '../../../../models/purchaseOrder.model';
import {PackingSlipModel} from '../../../../models/packingSlip.model';
import {TaxInvoiceModel} from '../../../../models/taxInvoice.model';
import {QuotationSelectorComponent} from '../quotationSelector/quotationSelector.component';
import {ProcessModel} from '../../../../models/process.model';
import {MachineModel} from '../../../../models/machine.model';
import {ProductProcessModel} from '../../../../models/productProcess.model';
import {CriticalPointModel} from '../../../../models/criticalPoint.model';
import {HardeningModel} from '../../../../models/hardening.model';
import {CosmeticModel} from '../../../../models/cosmetic.model';
import {MaterialModel} from '../../../../models/material.model';
import {PackagingModel} from '../../../../models/packaging.model';
import {IncotermModel} from '../../../../models/incoterm.model';
import {PusherService} from '../../../../services/pusher.service';
import {ModalService} from '../../../../services/modal.service';
import {CarrierModel} from '../../../../models/carrier.model';
import {ShippingModel} from '../../../../models/shipping.model';
import {AddRemarkComponent} from '../addRemark/addRemark.component';
import {ViewRemarkComponent} from '../viewRemark/viewRemark.component';
import {PageScrollService} from 'ngx-page-scroll-core';
import {Location} from '@angular/common';
import {ViewTask} from '../viewTask';
import {SpecialToolingModel} from '../../../../models/specialTooling.model';
import {Model} from '../../../../now/model';
import {SchedulerModel} from '../../../../models/scheduler.model';
import {ReportModalComponent} from '../../../../modals/reportModal/reportModal.component';
import {MaterialCreateModal} from './materialCreate/materialCreate.modal';
import {RequirementModel} from '../../../../models/requirement.model';
import {LoaderService} from '../../../../components/loader/loader.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {FVD} from '../../../../app/api/fvd';
import {SupplierModel} from '../../../../models/supplier.model';
import {ProductMaterial} from '../../../../models/productMaterial';
import {LocalStorageService} from '../../../../services/localStorageService';
import {Viewer} from '../../../../services/viewer';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Subscription} from 'rxjs/internal/Subscription';
import {AutoSaveDirective} from '../../../../directives/autoSave.directive';

declare var window: any;

const MAX_PRODUCT_PROCESS = 5;
const MAX_MATERIALS = 5;
const MAX_SUPPLIERS = 5;

@Component({
    selector    : 'app-product-task-component',
    templateUrl : 'productTask.component.html',
    styleUrls   : ['productTask.component.scss']
})
export class ProductTaskComponent extends ViewTask implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(AutoSaveDirective, { static: false }) autoSave: AutoSaveDirective;

    public quotation_id: string;
    public databaseConsts: any;

    public eng_user: UserModel;
    public qc_user: UserModel;

    public children: any[];
    public task_id: string;
    public customer: CustomerModel;
    public contact: ContactModel;
    public current_user: UserModel;
    public from_user: UserModel;
    public user: UserModel;
    public documents: DocumentModel[];
    public quotations: QuotationModel[];
    public purchase_orders: PurchaseOrderModel[];
    public proforma_invoices: any[];
    public tax_invoices: any[];
    public packing_slips: any[];
    public carriers: CarrierModel[];
    public purchase_order_document: DocumentModel;
    public new_document: DocumentModel;
    public new_drawing_document: DrawingModel;
    public current_role: string;

    public current_tab: string;
    public current_product: ProductModel;
    public current_product_index: number;
    public current_part_product_index: number;
    public requirement: RequirementModel;

    // process and estimated cost
    public materials: MaterialModel[];
    public critical_points: CriticalPointModel[];
    public processes: ProcessModel[];
    public special_toolings: SpecialToolingModel[];
    public machines: MachineModel[];
    public users: UserModel[];
    public hardenings: HardeningModel[];
    public cosmetics: CosmeticModel[];
    public packagings: PackagingModel[];
    public incoterms: IncotermModel[];
    public schedulers: SchedulerModel[];
    public tmp_contact: ContactModel;
    public product_id: string;
    public api_host: string;

    public current_product_process: ProductProcessModel;
    public current_fvd: FVD;

    public saveSubject: Subject<any> = new Subject<any>();
    public subscriptions: Subscription[] = [];

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private ngZone: NgZone,
        private storage: LocalStorageService,
        public api: Api,
        private loader: LoaderService,
        public modal: ModalService,
        private pusherService: PusherService,
        public location: Location,
        public userService: UserService,
        private swal: SwalService,
        private pageScrollService: PageScrollService,
        private router: Router,
        private smartModal: NgxSmartModalService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.task = new TaskModel();
        this.databaseConsts = undefined;

        this.carriers = [];
        this.machines = [];
        this.users = [];
        this.hardenings = [];
        this.cosmetics = [];
        this.packagings = [];
        this.materials = [];
        this.processes = [];
        this.critical_points = [];
        this.special_toolings = [];
        this.schedulers = [];
    }

    private init(): void {
        this.task = new TaskModel();
        this.contact = new ContactModel();
        this.current_product_index = -1;
        this.current_part_product_index = -1;

        this.children = [];

        this.new_document               = new DocumentModel();
        this.purchase_order_document    = new DocumentModel();
        this.new_document               = new DocumentModel();

        this.new_drawing_document       = new DrawingModel();

        this.purchase_orders            = [];
        this.quotations                 = [];
        this.proforma_invoices          = [];
        this.tax_invoices               = [];
        this.packing_slips              = [];
        this.documents                  = [];
    }

    ngOnInit() {
        this.subscriptions.push(
            this.saveSubject.pipe(
                debounceTime(3000),
            ).subscribe(async result => {
                if (this.ready === true) {
                    await this.save()
                        .catch((error: any) => console.warn(error));
                }
            })
        );

        this.getAllData()
            .then((data: any) => {
                if (data) {
                    if (data.special_toolings) {
                        this.special_toolings = data.special_toolings;
                    }
                    if (data.packagings) {
                        this.packagings = data.packagings;
                    }
                    if (data.incoterms) {
                        this.incoterms = data.incoterms;
                    }
                    if (data.carriers) {
                        this.carriers = data.carriers;
                    }
                    if (data.cosmetics) {
                        this.cosmetics = data.cosmetics;
                    }
                    if (data.critical_points) {
                        this.critical_points = data.critical_points;
                    }
                    if (data.hardenings) {
                        this.hardenings = data.hardenings;
                    }
                    if (data.processes) {
                        this.processes = data.processes;
                        const shipping_process = this.processes.findIndex(i => i.slug === 'shipping');
                        if (shipping_process > -1) {
                            this.processes.splice(shipping_process, 1);
                        }
                        const packing_process = this.processes.findIndex(i => i.slug === 'packing');
                        if (packing_process > -1) {
                            this.processes.splice(packing_process, 1);
                        }
                    }
                }
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.eng_user = new UserModel();
            this.qc_user = new UserModel();
            this.requirement = new RequirementModel();
            this.current_product = new ProductModel();

            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.product_id = params['product_id'];
                    this.task_id = params['id'];
                    this.init();
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.loader.show();
                        this.getTask()
                            .then(() => {
                                this.getTaskChildren();
                                this.onSuccess();
                            });
                    }
                });
        }, 0);
    }

    async ngOnDestroy() {
        if (this.autoSave && this.autoSave.canUpdate()) {
            await this.save()
                .catch((error: any) => console.warn(error));
        }
        this.subscriptions.forEach(i => i.unsubscribe());
    }

    public getNavigateStack(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const navigate_stack: any = this.storage.retrieve('navigate_stack');
            resolve(navigate_stack);
        });
        return promise;
    }

    public getTaskChildren(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/' + this.task.id + '/children')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        if (response.data.parent) {
                            const parent_task: any = response.data.parent;
                            this.children.push({
                                id: parent_task.id,
                                modelable_type: parent_task.modelable_type,
                                modelable_id: parent_task.modelable_id,
                                modelable: parent_task.modelable
                            });
                        }
                        if (response.data.children) {
                            for (let i = 0; i < response.data.children.length; i++) {
                                const dat: any = response.data.children[i];
                                if (dat) {
                                    this.children.push({
                                        id: dat.id,
                                        modelable_type: dat.modelable_type,
                                        modelable_id: dat.modelable_id,
                                        modelable: dat.modelable
                                    });
                                }
                            }
                        }
                        resolve(this.children);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getAllData(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.databaseConsts) {
                resolve(this.databaseConsts);
            } else {
                this.api.request('database/consts')
                    .toPromise()
                    .then((response: any): void => {
                        if (response && response.data) {
                            this.databaseConsts = response.data;
                            resolve(response.data);
                        } else {
                            resolve({});
                        }
                    });
            }
        });
        return promise;
    }

    /*public onBlur(product_process: ProductProcessModel, event: any): void {
        this.ngZone.run(() => {
            if (product_process) {
                product_process.cal_price();
            }
        });
    }*/

    public onSelectRanking(event: any): void {
        //
    }

    public onSelectTypeaheadProductProcess(model: any, data: any, price_model: any, field_price1: string, field_price2: string): void {
        if (data && field_price1 && field_price2 && price_model) {
            price_model[field_price1] = data[field_price2];
        }
        this.onSelectTypeahead(model, data);
    }

    public onSelectTypeaheadShipping(model: any, data: any, field1: string, field2: string): void {
        if (model && field1 && field2 && data) {
            model[field1] = data[field2];
        }
        if (model && data && (data.price || data.price === 0)) {
            model.price = data.price;
        }
    }

    public onSelectTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (model && data) {
            model.clone(data);
        }
        if (model_parent && field_name) {
            model_parent[field_name] = data.id;
        }
    }

    private onDrawingUploadedSuccess(data: any): void {
        console.log(data);
        if (this.current_product && data) {
            data.name = 'DRAWING ' + this.current_product.name;
            this.api.request('products/drawing', 'PUT', {}, {
                document: data,
                id: this.current_product.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let doc: DrawingModel;
                    doc = new DrawingModel();
                    doc.clone(response.data);
                    const index: number = this.current_product.drawings.push(doc) - 1;
                    this.viewDrawingDetail(doc, index);
                }
            }, error => {
                //
            });
        }
    }

    private onDrawingUploadedError(data: any): void {
        //
    }

    private onCADUploadedSuccess(data: any): void {
        if (this.current_product) {
            this.api.request('products/cad', 'PUT', {}, {
                document: data,
                id: this.current_product.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let doc: CADModel;
                    doc = new CADModel();
                    doc.clone(response.data);
                    const length: number = this.current_product.cads.push(doc);
                    this.viewCADDetail(doc, length - 1);
                }
            }, error => {
                //
            });
        }
    }

    private onCADUploadedError(data: any): void {
        //
    }

    public gotoParent(): void {
        if (this.children && this.children.length > 0) {
            this.router.navigateByUrl('/task/sale/detail/' + this.children[0].id);
        } else {
            //
        }
    }

    public removePart(): void {
        this.swal.confirm('คุณต้องการยกเลิกพาทนี้ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    for (let i = 0; i < this.children.length; i++) {
                        const child: any = this.children[i];
                        if (child && child.modelable_id === this.current_product.id) {
                            let _task: TaskModel;
                            _task = new TaskModel();
                            _task.clone({
                                id: child.id
                            });
                            this.modelApi.delete(_task)
                                .subscribe((response: any): void => {
                                    this.gotoParent();
                                }, error => {
                                    //
                                });
                            return;
                        }
                    }
                } else {
                    //
                }
            });
    }

    public addPart(): void {
        if (this.current_product && this.current_product.id) {
            let part_product: ProductModel;
            part_product = new ProductModel();
            part_product.unit = 'PC';
            part_product.amount = 1;
            part_product.customer_product_amount = 1;
            part_product.task_id = this.task_id;
            let parent_product_id: string;
            if (this.current_product && !this.current_product.parent_id) {
                parent_product_id = this.current_product.id;
            } else {
                parent_product_id = this.current_product.parent_id;
            }
            this.modal.show(AddProductComponent, {
                parent_product_id: parent_product_id,
                task: this.task,
                product: part_product,
                is_part_product: true,
                customer_id: this.task.customer_id
            }).then((content: any): void => {
                if (content && content.product_id) {
                    this.router.navigateByUrl('/task/sale/detail/' + this.task.id + '/' + content.product_id);
                } else {
                    //
                }
            });
        } else {
            this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการเพิ่มพาทชิ้นส่วน');
        }
    }

    public onCriticalPointChange(critical_point: any, i: number, e: any): void {
        if (e && e.id) {
            //
        } else {
            this.deleteEstimate(this.current_product.critical_points, critical_point, i, 'critical/points', true);
        }
    }

    public onSpecialToolingChange(special_tooling: any, i: number, e: any): void {
        if (e && e.id) {
            //
        } else {
            this.deleteEstimate(this.current_product.special_toolings, special_tooling, i, 'special/toolings', true);
        }
    }

    public onSelectSaleContactRef(e: any): void {
        this.current_product.requirement_id = (e && e.id) ? e.id : null;
    }

    public onSelectEngUser(e: any): void {
        this.current_product.eng_user_id = (e && e.id) ? e.id : null;
    }

    public onSelectQCUser(e: any): void {
        this.current_product.qc_user_id = (e && e.id) ? e.id : null;
    }

    public onStartUploader(data: any): void {
        if (this.current_product && this.current_product.id) {
            //
        } else {
            if (data && data.uploader) {
                data.uploader.cancel();
            }
            this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการอัพโหลดเอกสาร');
        }
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public onUploadedSuccess(data: any): void {
        this.api.request('products/document', 'PUT', {}, {
            document: data,
            id: this.current_product.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.documents.push(new_document);
                this.current_product.documents.push(new_document);
                // this.task.addDocument(new_document);
            }
        }, error => {
            //
        });
    }

    public onUploadError(data: any): void {
        //
    }

    public viewDrawingDetail(drawing: DrawingModel, index: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DrawingDetailComponent, {
            drawing: drawing,
            product: this.current_product,
            task: this.task,
            drawing_index: index
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewCADDetail(cad: CADModel, index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(CadDetailComponent, {
            cad: cad,
            cad_index: index,
            drawings: this.current_product.drawings,
            product: this.current_product,
            task: this.task
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewDocumentDetail(doc: DocumentModel, index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc,
            product: this.current_product,
            task: this.task,
            document_index: index
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public createTaxInvoice(): void {
        this.openQuotationSelector()
            .then((quotation: QuotationModel): void => {
                if (quotation) {
                    let tax_invoice: TaxInvoiceModel;
                    tax_invoice = new TaxInvoiceModel();
                    tax_invoice.quotation_no = quotation.quotation_no;
                    this.modelApi.create(tax_invoice, [
                        'quotation_no'
                    ]).subscribe((response: any): void => {
                        if (response && response.data && response.data.id) {
                            this.task.tax_invoices.push(tax_invoice);
                        } else {
                            //
                        }
                    });
                }
            });
    }

    public viewTaxInvoiceDetail(): void {

    }

    public viewTaxInvoicePreview(): void {

    }

    public createPackingSlip(): void {
        this.openQuotationSelector()
            .then((quotation: QuotationModel): void => {
                if (quotation) {
                    let packing_slip: PackingSlipModel;
                    packing_slip = new PackingSlipModel();
                    packing_slip.quotation_no = quotation.quotation_no;
                    this.modelApi.create(packing_slip, [
                        'quotation_no'
                    ]).subscribe((response: any): void => {
                        if (response && response.data && response.data.id) {
                            this.task.packing_slips.push(packing_slip);
                        } else {
                            //
                        }
                    });
                }
            });
    }

    private openQuotationSelector(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.modal.show(QuotationSelectorComponent, {
                quotations: this.quotations,
                task: this.task
            }, { backdrop: true, ignoreBackdropClick: true })
                .then((content: any): void => {
                    if (content && content.quotation) {
                        resolve(content.quotation);
                    } else {
                        resolve();
                    }
                });
        });
        return promise;
    }

    public onMaterialChange(e?: any): void {
        this.current_product.cal_price();
        this.saveSubject.next(true);
    }

    public addMoreCriticalPoint(): void {
        let critical_point: CriticalPointModel;
        critical_point = new CriticalPointModel();
        critical_point.critical_point = {};
        critical_point.index = this.current_product.critical_points.length;
        critical_point.checked = true;
        critical_point.current_role = 'estimate';
        this.current_product.critical_points.push(critical_point);
    }

    public addMoreSpecialTooling(): void {
        let special_tooling: SpecialToolingModel;
        special_tooling = new SpecialToolingModel();
        special_tooling.special_tooling = {};
        special_tooling.index = this.current_product.special_toolings.length;
        special_tooling.checked = true;
        special_tooling.current_role = 'estimate';
        this.current_product.special_toolings.push(special_tooling);
    }

    public addMoreProcess(): ProductProcessModel {
        let product_process: ProductProcessModel;
        product_process = new ProductProcessModel();
        product_process.index = this.current_product.product_processes.length;
        product_process.checked = true;
        product_process.current_role = 'estimate';
        this.current_product.product_processes.push(product_process);
        return product_process;
    }

    public onProductProcessDelete(product_process: ProductProcessModel, e: any): void {
        if (product_process) {
            if (product_process.id) {
                this.api.request('product/processes/delete', 'POST', {}, {
                    product_process_id: product_process.id
                }).subscribe((response: any): void => {
                    //
                });
            } else {
                //
            }
            product_process.ranking = null;
            product_process.modelable = null;
            product_process.modelable_data = 0;
            product_process.machine = new MachineModel();
            product_process.user = new UserModel();
            product_process.user_time = 0;
            product_process.machine_time = 0;
        } else {
            //
        }
    }

    public onDragulaModelChange(e: any): void {
        this.saveSubject.next(true);
    }

    public onCosmeticChange(product_process: ProductProcessModel, e: any): void {
        product_process.modelable_type = (e && e.id) ? 'App\\Cosmetic' : null;
        product_process.modelable_id = (e && e.id) ? e.id : null;
    }

    public onHardeningChange(product_process: ProductProcessModel, e: any): void {
        product_process.modelable_type = (e && e.id) ? 'App\\Hardening' : null;
        product_process.modelable_id = (e && e.id) ? e.id : null;
    }

    public onInstrumentChange(product_process: ProductProcessModel, e: any): void {
        product_process.instrument_id = (e && e.id) ? e.id : null;
    }

    public onMachineChange(product_process: ProductProcessModel, e: any): void {
        product_process.machine_id = (e && e.id) ? e.id : null;
    }

    public onManChange(product_process: ProductProcessModel, e: any): void {
        product_process.user_id = (e && e.id) ? e.id : null;
    }

    public onProductProcessSelect(product_process: ProductProcessModel, i: number, e: any): void {
        if (e && e.id) {
            if (product_process && product_process.process && product_process.process.slug) {
                switch (product_process.process.slug) {
                    case 'hardening':
                        product_process.modelable = new HardeningModel();
                        break;
                    case 'cosmetic':
                        product_process.modelable = new CosmeticModel();
                        break;
                    case 'packing':
                        product_process.modelable = new PackagingModel();
                        break;
                    case 'shipping':
                        product_process.modelable = new ShippingModel();
                        break;
                }
                if (product_process.modelable) {
                    product_process.modelable_type = product_process.modelable.model_name;
                } else {
                    product_process.modelable_type = null;
                }
            }
        } else {
            this.deleteProductProcessEstimate(this.current_product.product_processes, product_process, i);
        }
    }

    public onSuccess(data?: any): void {
        if (data) {
            this.task.clone(data.task);
        }
        this.current_tab = '#product';
        const product_id: string = (this.product_id) ? this.product_id : this.task.modelable_id;
        this.getProduct(product_id)
            .then(() => {
                this.init_product();
                this.getNavigateStack()
                    .then(result => {
                        this.navigate_stack = result;
                        if (this.navigate_stack && this.navigate_stack.data) {
                            if (this.navigate_stack.data.product_name) {
                                this.current_product.name = this.navigate_stack.data.product_name;
                            }
                            if (this.navigate_stack.data.quotation_id) {
                                this.quotation_id = this.navigate_stack.data.quotation_id;
                            }
                        }
                    });

                this.requirement.clone(this.current_product.requirement);
                this.eng_user.clone(this.current_product.eng_user);
                this.qc_user.clone(this.current_product.qc_user);

                this.doReady();
                this.loader.hide();
            });
    }

    public onError(e: any): void {
        //
    }

    public showMaterialDetail(material: any): void {
        if (material && material.item && material.item.id) {
            this.modal.show(MaterialCreateModal, {
                item_id: material.material_id,
                item_name: material.item.search_value,
                material_model: material,
                editabled: false
            }).then((data: any): void => {
                //
            });
        } else {
            //
        }
    }

    public getProduct(product_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('products/' + product_id)
                .subscribe((response: any) => {
                    if (response && response.success === true) {
                        this.current_product.clone(response.data);
                        if (this.current_product && this.current_product.amount < 0) {
                            this.current_product.amount = 1;
                        } else {
                            //
                        }
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private uploadDocument(obj: any): void {
        //
    }

    private uploadQuotationDocument(): void {
        //
    }

    private uploadPurchaseOrderDocument(): void {
        //
    }

    private init_product(): void {
        if (this.current_product.amount < 0) {
            this.current_product.amount = 1;
        }
        for (let j = 0; j < Math.max(MAX_PRODUCT_PROCESS, this.current_product.product_processes.length); j++) {
            let product_process: ProductProcessModel;
            if (this.current_product.product_processes && this.current_product.product_processes[j]) {
                product_process = this.current_product.product_processes[j];
            } else {
                product_process = this.addMoreProcess();
            }
            product_process.index = j;
            product_process.checked = true;
            product_process.current_role = 'estimate';
        }
        const i = 0;
        let critical_point: CriticalPointModel;
        if (this.current_product.critical_points && this.current_product.critical_points[i]) {
            critical_point = this.current_product.critical_points[i];
        } else {
            critical_point = new CriticalPointModel();
            this.current_product.critical_points.push(critical_point);
        }
        critical_point.checked = true;
        critical_point.current_role = 'estimate';
        critical_point.index = i;

        let material: ProductMaterial;
        if (this.current_product.materials && this.current_product.materials[i]) {
            material = this.current_product.materials[i];
        } else {
            material = new ProductMaterial();
            this.current_product.materials.push(material);
        }
        material.checked = true;
        material.current_role = 'estimate';
        material.index = i;

        let special_tooling: SpecialToolingModel;
        if (this.current_product.special_toolings && this.current_product.special_toolings[i]) {
            special_tooling = this.current_product.special_toolings[i];
        } else {
            special_tooling = new SpecialToolingModel();
            this.current_product.special_toolings.push(special_tooling);
        }
        special_tooling.checked = true;
        special_tooling.current_role = 'estimate';
        special_tooling.index = i;

        this.current_product.cal_price();

        if (!this.current_product.unit) {
            this.current_product.unit = 'PC';
        }

        if (this.current_product && !this.current_product.other_price) {
            this.current_product.other_price = {
                tool: 15,
                management: 15,
                risk: 50
            };
        }

        console.log(this.current_product);
        setTimeout(() => {
            console.log(this.current_product);
        }, 5000);
    }

    private reset_documents(): void {
        if (this.documents) {
            this.documents.splice(0, this.documents.length);
        } else {
            this.documents = [];
        }
    }

    public warning(): void {
        this.modal.show(ReportModalComponent, {
            task: this.task
        }).then((content: any): void => {
            if (content) {
                //
            } else {
                //
            }
        });
    }

    public createPlanning(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task,
            to_role: 'pd-planning'
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.taskService.setStatus(this.task, 1, 'create_planning', 'pd-planning', 'planning')
                        .then((task: TaskModel): void => {
                            this.leave(true);
                        });
                } else {
                    //
                }
            }, error => {
                //
            });
    }

    public deleteProductProcessEstimate(models: Model[], model: Model, index: number): void {
        if (models && model && index > -1) {
            this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        if (model.id) {
                            this.loader.show();
                            this.api.request('product/processes/delete', 'POST', {}, {
                                product_process_id: model.id
                            }).subscribe((response: any): void => {
                                if (response && response.success === true) {
                                    models.splice(index, 1);
                                    model.is_delete = true;
                                }
                                this.loader.hide();
                            });
                        } else {
                            models.splice(index, 1);
                            model.is_delete = true;
                            this.loader.hide();
                        }
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public deleteEstimate(models: Model[], model: Model, index: number, name: string, force?: boolean): void {
        if (models && model && index > -1) {
            if (force === true) {
                this.loader.show();
                if (model.id) {
                    this.api.request('product/' + name + '/delete', 'POST', {}, {
                        id: model.id
                    }).subscribe((response: any): void => {
                        models.splice(index, 1);
                        model.is_delete = true;
                        this.loader.hide();
                    });
                } else {
                    models.splice(index, 1);
                    model.is_delete = true;
                    this.loader.hide();
                }
            } else {
                this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.loader.show();
                            if (model.id) {
                                this.api.request('product/' + name + '/delete', 'POST', {}, {
                                    id: model.id
                                }).subscribe((response: any): void => {
                                    models.splice(index, 1);
                                    model.is_delete = true;
                                    this.loader.hide();
                                });
                            } else {
                                models.splice(index, 1);
                                model.is_delete = true;
                                this.loader.hide();
                            }
                        } else {
                            //
                        }
                    });
            }
        } else {
            //
        }
    }

    public create_or_update_product(product: ProductModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            product.customer_id = this.task.customer.id;
            product.task_id = this.task.id;
            product.customer_product_amount = this.task.customer_product_amount;
            const put_or_post_data: string[] = [
                'task_id', 'customer_id', 'ref_no', 'name', 'amount', 'unit',
                'note', 'ranking', 'assembly', 'width', 'height', 'length', 'weight',
                'market_price', 'customer_product_amount', 'requirement_id', 'remark',
                'other_price', 'processing_times', 'qc_data', 'qc_user_id', 'eng_user_id',
                'boi', 'material_type',
            ];
            this.modelApi.update(product, put_or_post_data, null, null, null, false)
                .subscribe((product_response: any): void => {
                    if (product_response.success === true) {
                        resolve(product_response);
                    } else {
                        reject(product_response.message);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getCheckedProductProcesses(product_processes: ProductProcessModel[]): any[] {
        let checked_product_processes: any[];
        checked_product_processes = [];
        if (product_processes) {
            for (let i = 0; i < product_processes.length; i++) {
                // if (product_processes[i] && (product_processes[i].machine_time || product_processes[i].user_time)) {
                if (product_processes[i] && product_processes[i].process && product_processes[i].process.id) {
                    const pp: ProductProcessModel = product_processes[i];
                    const c_pp: any = {
                        id              : (pp.id) ? pp.id : null,
                        ranking         : pp.ranking,
                        instrument_id   : pp.instrument_id,
                        process_id      : (pp.process) ? pp.process.id : null,
                        machine_id      : (pp.machine) ? pp.machine.id : null,
                        machine_time    : pp.machine_time,
                        user_id         : (pp.user) ? pp.user.id : null,
                        user_time       : pp.user_time,
                        current_role    : 'estimate',
                        checked         : true,
                        guid            : pp.guid,
                        is_delete       : pp.is_delete,
                        is_fvd          : pp.is_fvd,
                        fvd_id          : pp.fvd_id,
                        // supplier_id     : (pp.is_fvd === true) ? pp.supplier_id : null,
                        fvd_price       : (pp.is_fvd === true) ? pp.fvd_price : null,
                        price           : pp.price,
                        estimated_price : pp.estimated_price,
                        modelable_id    : pp.modelable_id,
                        modelable_type  : pp.modelable_type,
                        order_index     : i,
                        modelable_data  : {
                            current_role    : this.task.current_role,
                            checked         : 1
                        }
                    };
                    if (pp.modelable_data && pp.modelable_data.amount) {
                        c_pp.modelable_data.amount = pp.modelable_data.amount;
                    }
                    checked_product_processes.push(c_pp);
                }
            }
        }
        return checked_product_processes;
    }

    private getCheckedCriticalPoints(): any {
        let checked_critical_points: any[];
        checked_critical_points = [];
        if (this.current_product.critical_points) {
            for (let i = 0; i < this.current_product.critical_points.length; i++) {
                if (this.current_product.critical_points[i]
                    && this.current_product.critical_points[i].critical_point
                    && this.current_product.critical_points[i].critical_point.id) {
                    //
                    const critical_point: any = this.current_product.critical_points[i];
                    checked_critical_points.push({
                        critical_point_id   : (critical_point) ? critical_point.critical_point.id : null,
                        guid                : critical_point.guid,
                        price               : critical_point.price,
                        amount              : critical_point.amount,
                        current_role        : 'estimate',
                        checked             : true,
                    });
                }
            }
        }
        return checked_critical_points;
    }

    private getCheckedMaterials(): any {
        let checked_materials: any[];
        checked_materials = [];
        if (this.current_product.materials) {
            for (let i = 0; i < this.current_product.materials.length; i++) {
                const product_material: ProductMaterial = this.current_product.materials[i];
                if (product_material && product_material.item && product_material.item.id) {
                    checked_materials.push({
                        id: product_material.id,
                        prepared_status: product_material.prepared_status,
                        status: product_material.status,
                        cutting_id: product_material.cutting_id,
                        guid: product_material.guid,
                        price: product_material.price,
                        amount: product_material.amount,
                        parent_id: product_material.parent_id,
                        shipping_price: product_material.shipping_price,
                        profit_percentage: product_material.profit_percentage,
                        current_role: product_material.current_role,
                        checked: product_material.checked,
                        job_id: product_material.job_id,
                        remark: product_material.remark,
                        // material_id is item_id field in items table
                        material_id: (product_material && product_material.item) ? product_material.item.id : ''
                    });
                } else {
                    //
                }
            }
        }
        return checked_materials;
    }

    private getSpecialToolings(): any[] {
        let checked_special_toolings: any[];
        checked_special_toolings = [];
        if (this.current_product.special_toolings) {
            for (let i = 0; i < this.current_product.special_toolings.length; i++) {
                const special_tooling: SpecialToolingModel = this.current_product.special_toolings[i];
                if (special_tooling && special_tooling.special_tooling && special_tooling.special_tooling.id) {
                    checked_special_toolings.push({
                        special_tooling_id  : (special_tooling.special_tooling) ? special_tooling.special_tooling.id : null,
                        guid            : special_tooling.guid,
                        price           : special_tooling.price,
                        amount          : special_tooling.amount,
                        current_role    : 'estimate',
                        checked         : true,
                    });
                }
            }
        }
        return checked_special_toolings;
    }

    public estimate(product: ProductModel, product_processes: ProductProcessModel[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let checked_product_processes: any[];
            let checked_critical_points: any;
            let checked_materials: any;
            let checked_special_toolings: any;

            checked_product_processes   = this.getCheckedProductProcesses(product_processes);
            checked_materials           = this.getCheckedMaterials();
            checked_special_toolings    = this.getSpecialToolings();
            checked_critical_points     = this.getCheckedCriticalPoints();

            this.api.request('products/estimate', 'PUT', {}, {
                product_id          : product.id,
                product_processes   : checked_product_processes,
                critical_points     : checked_critical_points,
                materials           : checked_materials,
                special_toolings    : checked_special_toolings
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    if (response.data.product_processes) {
                        for (let i = 0; i < response.data.product_processes.length; i++) {
                            const r_pp: any = response.data.product_processes[i];
                            if (r_pp && r_pp.id) {
                                for (let j = 0; j < product_processes.length; j++) {
                                    const pp: any = product_processes[j];
                                    if (pp.guid === r_pp.guid) {
                                        pp.id = r_pp.id;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if (response.data.materials) {
                        for (let i = 0; i < response.data.materials.length; i++) {
                            const material: any = response.data.materials[i];
                            if (material.guid) {
                                const exist_material = this.current_product.materials.find(j => j.guid === material.guid);
                                if (exist_material) {
                                    exist_material.id = material.id;
                                } else {
                                    //
                                }
                            }
                        }
                    }
                    if (response.data.special_toolings) {
                        for (let i = 0; i < response.data.special_toolings.length; i++) {
                            const special_tooling: any = response.data.special_toolings[i];
                            if (special_tooling.guid) {
                                const exist_special_tooling = this.current_product.special_toolings
                                    .find(j => j.guid === special_tooling.guid);
                                if (exist_special_tooling) {
                                    exist_special_tooling.id = special_tooling.id;
                                } else {
                                    //
                                }
                            }
                        }
                    }
                    if (response.data.critical_points) {
                        for (let i = 0; i < response.data.critical_points.length; i++) {
                            const critical_point: any = response.data.critical_points[i];
                            if (critical_point.guid) {
                                const exist_critical_point = this.current_product.critical_points
                                    .find(j => j.guid === critical_point.guid);
                                if (exist_critical_point) {
                                    exist_critical_point.id = critical_point.id;
                                } else {
                                    //
                                }
                            }
                        }
                    }
                }
                if (this.current_product && this.current_product.id) {
                    this.api.request('products/price', 'POST', {}, {
                        product_id: this.current_product.id
                    }).subscribe((update_price_response: any): void => {
                        resolve(response);
                    }, error => {
                        reject(error);
                    });
                } else {
                    //
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private create_or_update_contact(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.tmp_contact && this.tmp_contact.id) {
                resolve(this.tmp_contact);
            } else if (this.tmp_contact && this.tmp_contact.full_name) {
                this.tmp_contact.customer_id = this.task.customer_id;
                this.modelApi.create(this.tmp_contact, ['full_name', 'position', 'telephone', 'email', 'customer_id'], 'customers/contact')
                    .subscribe((result: any): void => {
                        resolve(this.tmp_contact);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public _approveTask(check_drawing?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (check_drawing && (!this.current_product.drawings || this.current_product.drawings.length === 0)) {
                this.swal.danger('กรุณาแนบเอกสาร DRAWING ให้สำเร็จ');
                reject({});
            } else {
                super.approveTask()
                    .then(() => {
                        this.loader.show();
                        this.api.request('products/no', 'POST', null, {
                            task_id: this.task.id,
                            id: (this.current_product.parent_id) ? this.current_product.parent_id : this.current_product.id
                        }).subscribe(() => {
                            this.swal.success('อนุมัติสินค้าผ่านและได้รับรหัสสินค้าสำเร็จ');
                            this.loader.hide();
                            this.leave(true);
                            resolve();
                        });
                    }, error => {
                        this.loader.hide();
                        reject(error);
                    });
            }
        });
        return promise;
    }

    public issueTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if ((!this.current_product.cost || !this.current_product.market_price) && false) {
                this.swal.danger('ไม่สามารถดำเนินการได้เนื่องจากสินค้ายังไม่มีราคา');
                reject({});
            } else if (!this.current_product.drawings || this.current_product.drawings.length === 0) {
                this.swal.danger('กรุณาแนบเอกสาร DRAWING ให้สำเร็จ');
                reject({});
            } else {
                this.save()
                    .then(() => {
                        super.issueTask()
                            .then(() => {
                                this.swal.success('กรุณารอการอนุมัติในขั้นตอนต่อไป');
                                this.leave(true);
                                resolve({});
                            }, error => {
                                reject({});
                            });
                    }, error => {
                        reject({});
                    });
            }
        });
        return promise;
    }

    public rejectTaskToPlanning(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ยืนยันการแก้ไขสินค้าสำเร็จ');
                        this.leave(true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

    public rejectTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            super.rejectTask()
                .then(() => {
                    this.swal.success('ยืนยันรายการสินค้าไม่ผ่านการอนุมัติสำเร็จ');
                    this.leave(true);
                    resolve();
                }, error => {
                    reject();
                });
        });
        return promise;
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const is_new: boolean = this.task.isNew;
            if (this.current_product.amount < 0 || !this.current_product.amount) {
                this.current_product.amount = 1;
            }
            this.current_product.customer_product_amount = this.current_product.amount;
            this.task.customer_product_amount = this.current_product.amount;
            if (!this.current_product.amount) {
                reject();
            } else if (!this.task.customer_product_amount) {
                reject();
            } else if (this.task.customer_product_amount > this.current_product.amount) {
                reject();
            } else {
                this.create_or_update_contact()
                    .then(() => {
                        this.create_or_update_product(this.current_product)
                            .then((product_response: any): void => {
                                this.estimate(this.current_product, this.current_product.product_processes)
                                    .then((estimated_response: any): void => {
                                        if (estimated_response && estimated_response.success === true) {
                                            resolve();
                                        } else if (estimated_response && estimated_response.message) {
                                            reject();
                                        } else {
                                            reject();
                                        }
                                    }, error => {
                                        reject(error);
                                    });
                            }, error => {
                                reject(error);
                            });
                    }, error => {
                        reject(error);
                    });
            }
        });
        return promise;
    }

    private setTaskStatus(task_status: number, action?: string): void {
        if (task_status === 3) {
            //
        } else if (task_status === 4 || task_status === 6 || task_status === 8) { // fail1, fail2, fail3
            this.modal.show(AddRemarkComponent, {
                task: this.task
            }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
                .then((content: any): void => {
                    if (content && content.sent === true) {
                        /*let to_role: string;
                        if (task_status === 4) {
                            to_role = 'sale';
                        } else if (task_status === 6) {
                            to_role = 'pd-planning';
                        } else if (task_status === 8) {
                            to_role = 'qa';
                        }*/
                        this.taskService.setStatus(this.task, task_status, action, 'estimate')
                            .then((task: TaskModel): void => {
                                this.task.status = task_status;
                                this.leave(true);
                            });
                    } else {
                        //
                    }
                }, error => {
                    //
                });
        } else if (task_status === 5) {
            this.swal.confirm('คุณต้องการส่งตรวจสอบสินค้า "' + this.current_product.name + '" ในขั้นตอนที่ 2 ใช่หรือไม่?', null, null
                , 'ส่งตรวจสอบ', 'ปิด')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.taskService.setStatus(this.task, task_status, action, 'qa')
                            .then((task: TaskModel): void => {
                                this.task.status = task_status;
                                this.swal.success('กรุณารอการอนุมัติในขั้นตอนต่อไป');
                                this.leave(true);
                            });
                    } else {
                        //
                    }
                });
        } else if (task_status === 7) {
            this.swal.confirm('คุณต้องการส่งตรวจสอบสินค้า "' + this.current_product.name + '" ในขั้นตอนที่ 3 ใช่หรือไม่?', null, null
                , 'ส่งตรวจสอบ', 'ปิด')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.taskService.setStatus(this.task, task_status, action, 'sale-manager')
                            .then((task: TaskModel): void => {
                                this.task.status = task_status;
                                this.swal.success('กรุณารอการอนุมัติในขั้นตอนต่อไป');
                                this.leave(true);
                            });
                    } else {
                        //
                    }
                });
        } else if (task_status === 9) {
            if (!this.current_product.cost && !this.current_product.market_price) {
                this.swal.danger('ไม่สามารถดำเนินการได้เนื่องจากสินค้ายังไม่มีราคา');
            } else {
                this.swal.confirm('คุณต้องการอนุมัติสินค้า "' + this.current_product.name + '" และจะได้รับรหัสสินค้าทันที ใช่หรือไม่?',
                    null, null, 'อนุมัติผ่านทันที', 'ปิด')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.save()
                                .then(() => {
                                    this.taskService.setStatus(this.task, task_status, action, 'estimate')
                                        .then((task: TaskModel): void => {
                                            this.api.request('products/no', 'POST', null, {
                                                task_id: this.task.id,
                                                id: this.current_product.id
                                            }).subscribe(() => {
                                                this.task.status = task_status;
                                                this.swal.success('อนุมัติสินค้าผ่านและได้รับรหัสสินค้าสำเร็จ');
                                                this.leave(true);
                                            });
                                        });
                                }, error => {
                                    console.log(error);
                                });
                        } else {
                            console.log(result);
                        }
                    });
            }
        } else {
            //
        }
    }

    public createOrViewFVD(product_process: ProductProcessModel): void {
        this.current_product_process = product_process;
        this.current_fvd = (product_process && product_process.fvd && product_process.fvd.id) ? product_process.fvd : this.initFVD(product_process);
        setTimeout(() => {
            this.smartModal.open('fvdModal');
        }, 300);
    }

    public onFVDSubmit(e: any): void {
        console.log(e);
    }

    public initFVD(product_process: ProductProcessModel): FVD {
        let fvd: FVD;
        fvd = {
            details     : '',
            materials   : [],
            suppliers   : [],
            documents   : []
        };
        for (let i = 0; i < MAX_MATERIALS; i++) {
            if (this.current_product.materials[i] && this.current_product.materials[i].id) {
                fvd.materials.push(this.current_product.materials[i]);
            } else {
                fvd.materials.push(new MaterialModel());
            }
        }

        for (let i = 0; i < MAX_SUPPLIERS; i++) {
            let supplier: SupplierModel;
            supplier = new SupplierModel();
            fvd.suppliers.push(supplier);
        }

        if (!fvd.documents) {
            fvd.documents = [];
        }

        if (product_process && product_process.process && product_process.process.id) {
            fvd.product_id      = this.product_id;
            fvd.process         = product_process.process;
            fvd.process_id      = product_process.process.id;
            fvd.modelable       = product_process.modelable;
            fvd.modelable_id    = product_process.modelable_id;
            fvd.modelable_type  = product_process.modelable_type;
        } else {
            fvd.process = new ProcessModel();
        }

        return fvd;
    }

    public viewProductDetail(product: ProductModel): void {
        this.viewer.product(product);
    }

    public insertProductToQuotation(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('quotations/product', 'POST', {
                id          : this.quotation_id,
                product_id  : this.current_product.id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public continueQuotation(): void {
        if (this.navigate_stack && this.navigate_stack.action === 'create_quotation') {
            if (!this.current_product.drawings || this.current_product.drawings.length === 0) {
                this.swal.danger('กรุณาแนบเอกสาร DRAWING ให้สำเร็จ');
            } else {
                this.save()
                    .then(() => {
                        this.swal.confirm('ยืนยันการเพิ่มสินค้า "' + this.current_product.name + '" ในใบเสนอราคาใช่หรือไม่?')
                            .then((result: boolean): void => {
                                if (result === true) {
                                    super.approveTask()
                                        .then(() => {
                                            const parent_id: string = this.current_product.parent_id;
                                            this.loader.show();
                                            this.api.request('products/no', 'POST', null, {
                                                task_id: this.task.id,
                                                id: (parent_id) ? parent_id : this.current_product.id
                                            }).subscribe(() => {
                                                this.taskService.complete(this.task)
                                                    .then(() => {
                                                        this.insertProductToQuotation()
                                                            .then(() => {
                                                                const task: any = this.navigate_stack.data;
                                                                this.taskService.hire(task, null, true, null, null, {
                                                                    product: {
                                                                        id: this.current_product.id,
                                                                        product_no: this.current_product.product_no,
                                                                        name: this.current_product.name,
                                                                        price: this.current_product.price
                                                                    }
                                                                }).then(() => {
                                                                    // Go Go!
                                                                    this.loader.hide();
                                                                    this.storage.clear('navigate_stack');
                                                                });
                                                            }, error => {
                                                                this.loader.hide();
                                                            });
                                                    });
                                            }, error => {
                                                this.loader.hide();
                                            });
                                        }, error => {
                                            this.loader.hide();
                                        });
                                }
                            });
                    });
            }
        } else {
            //
        }
    }

    public viewRemark(): void {
        this.modal.show(ViewRemarkComponent, {
            task: this.task
        }, { class: 'modal-lg' }).then(() => {
            //
        });
    }

    public get other_price_tool(): number {
        return this.current_product.process_estimated_price * this.current_product.other_price.tool / 100;
    }

    public get other_price_management(): number {
        return (this.current_product.process_estimated_price + this.other_price_tool) * this.current_product.other_price.management / 100;
    }

    public get other_price_risk(): number {
        return (this.current_product.process_estimated_price + this.other_price_management + this.other_price_tool)
            * this.current_product.other_price.risk / 100;
    }

    public get product_total_price(): number {
        if (this.current_product) {
            // this.current_product.cal_price();
            return this.current_product.estimated_price;
        }
        return 0;
    }

    public onSaveApiCallingBack(e: any): void {
        console.log('--- onSaveApiCallingBack ---');
        this.current_product.cal_price();
        this.saveSubject.next(true);
    }

}
