import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {LoaderService} from '../../../components/loader/loader.service';
import {CAR} from '../../../app/api/car';
import {JobModel} from '../../../models/job.model';
import {Viewer} from '../../../services/viewer';
import * as moment from 'moment';

@Component({
    selector: 'car-list-component',
    templateUrl: 'carList.component.html',
    styleUrls: ['carList.component.scss']
})
export class CARListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public timeout: any;
    public sub: any;

    public sum_grand_total: number;
    public total_items: number;
    public cars: CAR[];
    public keyword: string;

    public date: any;
    public tmp_date: string;
    public current_view: string;
    public tmp_customer: any;

    public customer_id: any;
    public customer_name: string;
    public started_at: string;

    constructor(
        private router: Router,
        public viewer: Viewer,
        private taskService: TaskService,
        private api: Api,
        private loader: LoaderService
    ) {
        //
        super();
        this.cars = [];
        this.total_items = 0;
        this.current_view = 'MONTHLY';
        this.date = moment(new Date());
        this.tmp_date = this.date.format('MM/YYYY');
        this.started_at = this.tmp_date;
        this.sum_grand_total = 0;
        this.customer_name = '';
        this.customer_id = '';
        this.tmp_customer = {
            name: ''
        };
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onCustomerChange(e: any): void {
        if (this.divApi && this.divApi.refresh && e) {
            this.loader.show();
            // this.customer_name = (e && e.id && e.name) ? e.name : '';
            this.customer_id = (e && e.id) ? e.id : '';
            this.divApi.refresh({
                started_at: this.started_at,
            }).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }
    }

    public onDateChange(e: any): void {
        if (this.divApi && this.divApi.refresh && e) {
            this.loader.show();
            this.keyword = '';
            let started_at: string;
            if (this.current_view === 'DAILY') {
                started_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
            } else if (this.current_view === 'MONTHLY') {
                started_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
            } else if (this.current_view === 'YEARLY') {
                started_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
            }
            this.tmp_date = this.started_at = started_at;
            this.divApi.refresh({
                started_at: started_at,
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        } else {
            //
        }
    }

    public viewCAR(car: CAR): void {
        this.viewer.car(car)
            .then(() => {
                //
            });
    }

    public viewManufacture(job: JobModel): void {
        this.viewer.manufacture(job, true, true)
            .then(() => {
                //
            });
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: this.keyword
            }).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }, 1000);
    }

    private clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_cars();
            // this.sum_grand_total = response.sum_grand_total;
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let car: CAR;
                    car = dat;
                    this.cars.push(car);
                }
            }
            this.loader.hide();
        }
    }

    public onError(e: any): void {
        this.loader.hide();
    }

    private clear_cars(): void {
        if (this.cars) {
            this.cars.splice(0, this.cars.length);
        } else {
            this.cars = [];
        }
    }

}
