import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {ProductModel} from '../../../models/product.model';
import {TaskModel} from '../../../models/task.model';
import {UserService} from '../../../services/user.service';
import {CustomerModel} from '../../../models/customer.model';
import {Api} from '../../../now/api/api';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {environment} from '../../../environments/environment';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {IncotermService} from '../../../services/incoterm.service';
import {CarrierService} from '../../../services/carrier.service';
import * as moment from 'moment';
import {TaskService} from '../../../services/task.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ModalService} from '../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../now/user/auth.service';
import {ViewTask} from '../view/viewTask';
import {VendorModel} from '../../../models/vendor.model';
import {PurchaseModel} from '../../../models/purchase.model';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {JobModel} from '../../../models/job.model';
import {StoreService} from '../../../services/store.service';
import {Model} from '../../../now/model';
import {PricingInquiryService} from '../../../services/pricingInquiry.service';
import {ContactModel} from '../../../models/contact.model';
import {CustomerAddressModel} from '../../../models/customerAddress.model';
import {BillModel} from '../../../models/bill.model';
import {CustomerService} from '../../../services/customer.service';
import {BankModel} from '../../../models/bank.model';
import {PaymentModel} from '../../../models/payment.model';
import {PaymentDetailComponent} from '../view/paymentDetail/paymentDetail.component';
import {FVD} from '../../../app/api/fvd';
import {Viewer} from '../../../services/viewer';
import {LoaderService} from '../../../components/loader/loader.service';
import {DocumentModel} from '../../../models/document.model';

@Component({
    selector: 'purchase-order-task-component',
    templateUrl: 'purchaseOrderTask.component.html',
    styleUrls: ['purchaseOrderTask.component.scss'],
    providers: [DecimalPipe]
})
export class PurchaseOrderTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    public job: JobModel;
    public fvd: FVD;
    public purchase_order: PurchaseOrderModel;
    public sale: UserModel;
    public customer: CustomerModel;
    public tmp_contact: any;
    public current_tab: string;
    public incoterms: string[];
    public carriers: CarrierModel[];
    public currency_list: any[];
    public vendor: VendorModel;
    public new_tasks: TaskModel[];
    public items: ProductModel[];
    public job_no: string;
    public product_no: string;
    public tmp_currency;

    constructor(
        public userService: UserService,
        public api: Api,
        private loader: LoaderService,
        public viewer: Viewer,
        private swal: SwalService,
        private pricingInquiryService: PricingInquiryService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private customerService: CustomerService,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        private storeService: StoreService,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        private route: ActivatedRoute,
        private ngZone: NgZone,
        public location: Location,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.current_tab = '#information';
        this.incoterms = [];
        this.carriers = [];
        this.items = [];

        this.new_tasks = [];
        this.job_no = '';
        this.product_no = '';

        this.getNewTasks()
            .then(() => {
                //
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.task = new TaskModel();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.purchase_order = new PurchaseOrderModel();
                        this.vendor = new VendorModel();
                        this.sale = new UserModel();
                        this.job = new JobModel();
                        // this.purchase_order.task_id = this.task_id;
                        this.getTaskDetail();
                    } else {
                        //
                    }
                });

            this.getIncoterms();
            this.getCarriers();
            this.getCurrencies();
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public onSaleTypeaheadDeleted(event?: any): void {
        this.purchase_order.sale = new ContactModel();
    }

    public getNewTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks', 'GET', {
                process_slug: 'estimate',
                status: 9
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat = response.data[i];
                        if (dat) {
                            let task: TaskModel;
                            task = new TaskModel();
                            task.clone(dat);
                            this.new_tasks.push(task);
                        }
                    }
                }
                resolve(this.new_tasks);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public deletePurchase(purchase: PurchaseModel, i: number): void {
        if (purchase) {
            if (i > -1) {
                this.purchase_order.purchases.splice(i, 1);
                if (!this.purchase_order.purchases || !this.purchase_order.purchases.length) {
                    this.purchase_order.purchases.push(new PurchaseModel());
                }
            }
        }
    }

    public onCustomerChange(e: any): void {
        this.purchase_order.customer_id = (e && e.id) ? e.id : null;
    }

    public onCustomerAddressChange(e: any): void {
        this.purchase_order.customer_address_id = (e && e.id) ? e.id : null;
    }

    public onVendorChange(e: any): void {
        this.purchase_order.vendor_id = (e && e.id) ? e.id : null;
    }

    public onSaleChange(e: any): void {
        this.purchase_order.sale_id = (e && e.id) ? e.id : null;
    }

    public onCarrierChange(e: any): void {
        this.purchase_order.carrier_id = (e && e.id) ? e.id : null;
    }

    private getCurrencies(): void {
        this.api.request('assets/json/currencies.json'
            , 'GET', null, null, null, environment.host, '')
            .subscribe((response: any): void => {
                this.currency_list = [];
                if (response && response.results) {
                    for (const key in response.results) {
                        if (key) {
                            const value: any = response.results[key];
                            this.currency_list.push(value);
                        }
                    }
                }
            }, error => {
                //
            });
    }

    private getCurrencyRate(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const current_currency_code: string = this.purchase_order.currency;
            this.api.request('currency/covert', 'GET', {
                from: 'THB',
                to: current_currency_code
            }).subscribe((response: any): void => {
                if (response && response.data && response.data['THB_' + current_currency_code]) {
                    resolve(response.data['THB_' + current_currency_code].val);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private getIncoterms(): void {
        this.incotermService.getIncoterms()
            .then((incoterms: IncotermModel[]): void => {
                this.incoterms = [];
                for (const incoterm of incoterms) {
                    this.incoterms.push(incoterm.name);
                }
            });
    }

    private getCarriers(): void {
        this.carrierService.getCarriers()
            .then((carriers: CarrierModel[]): void => {
                this.carriers = carriers;
            });
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                if (response && response.data) {
                    this.task.clone(response.data);
                    this.getPurchaseOrder(response.data.modelable_id)
                        .then(() => {
                            this.job = this.purchase_order.job;
                            if (this.job && this.job.job_no) {
                                this.job_no = this.job.job_no;
                            }
                            if (this.job && this.job.product && this.job.product.product_no) {
                                this.product_no = this.job.product.product_no;
                            }
                            if (this.purchase_order && this.purchase_order.fvd_id) {
                                this.getFVD(this.purchase_order.fvd_id)
                                    .then(() => {
                                        this.initPurchaseOrder();
                                        this.doReady();
                                    });
                            } else {
                                this.initPurchaseOrder();
                                this.doReady();
                            }
                        });
                }
            }, error => {
                //
            });
    }

    public getFVD(fvd_id: any): Promise<FVD> {
        let promise: Promise<FVD>;
        promise = new Promise<FVD>((resolve, reject) => {
            this.api.request('fvds/' + fvd_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.fvd = response.data;
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public initPurchaseOrder(): void {
        for (let i = 0; i < this.purchase_order.purchases.length; i++) {
            this.purchase_order.purchases[i].index = i;
            this.purchase_order.purchases[i].cal_price();
        }
        this.purchase_order.cal_total_price();
        // this.disabledRoleService.setTask(this.task);

        if (this.purchase_order && !this.purchase_order.purchase_user_id) {
            this.purchase_order.purchase_user_id = this.userService.getID();
            this.purchase_order.purchase_user = this.authService.user;
        }

        if (!this.purchase_order.currency) {
            this.purchase_order.currency = 'THB';
            this.purchase_order.currency_rate = 1;
        }

        if (this.purchase_order && !this.purchase_order.customer_id) {
            this.getDefaultCustomer()
                .then(customer => {
                    if (customer && customer.id) {
                        this.purchase_order.customer_id = customer.id;
                        this.purchase_order.customer = customer;

                        if (customer.bills && customer.bills[0]) {
                            this.purchase_order.bill_id = customer.bills[0].id;
                            this.purchase_order.bill = customer.bills[0];
                        }

                        if (customer.addresses && customer.addresses[0]) {
                            this.purchase_order.customer_address_id = customer.addresses[0].id;
                            this.purchase_order.customer_address = customer.addresses[0];
                        }
                    } else {
                        //
                    }
                }, error => {
                    //
                });
        }
    }

    public getDefaultCustomer(): Promise<CustomerModel> {
        let promise: Promise<CustomerModel>;
        promise = new Promise<CustomerModel>((resolve, reject) => {
            this.customerService.getDefaultCustomer()
                .then(customer => {
                    resolve(customer);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onCustomerTypeaheadDeleted(event?: any): void {
        this.purchase_order.customer = new CustomerModel();
        this.purchase_order.customer.credit_terms = 0;

        // this.purchase_order.contact = new ContactModel();
        this.purchase_order.customer_address = new CustomerAddressModel();
    }

    public onVendorTypeaheadDeleted(event?: any): void {
        this.purchase_order.vendor = new VendorModel();
    }

    public onBankTypeaheadDeleted(event?: any): void {
        this.purchase_order.bank = new BankModel();
        this.purchase_order.bank_id = null;
    }

    public addMorePurchase(): void {
        let purchase: PurchaseModel;
        purchase = new PurchaseModel();
        purchase.modelable = new Model();
        purchase.modelable_type = 'App\\Item';
        purchase.checked = true;
        this.purchase_order.purchases.push(purchase);
        this.purchase_order.cal_total_price();
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('purchase/orders/document', 'PUT', {}, {
            document    : data,
            id          : this.purchase_order.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.purchase_order.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public onPaymentUploadedSuccess(data: any): void {
        this.api.request('purchase/orders/payment', 'PUT', {}, {
            task_id: this.task.id,
            document: data,
            id: this.purchase_order.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_payment: PaymentModel;
                new_payment = new PaymentModel();
                new_payment.clone(response.data);
                this.purchase_order.payments.push(new_payment);
                this.viewPaymentDetail(new_payment);
            }
        }, error => {
            //
        });
    }

    public onPaymentUploadError(data: any): void {
        //
    }

    public viewPaymentDetail(payment: PaymentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PaymentDetailComponent, {
            payment     : payment,
            task        : this.task,
            task_id     : this.task_id,
            modelable_type  : 'App\\PurchaseOrder'
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    payment.clone(content.updated_payment);
                } else if (content && content.deleted === true) {
                    //
                }
            });
    }

    public getPurchaseOrder(purchase_order_id: string): Promise<PurchaseOrderModel> {
        let promise: Promise<PurchaseOrderModel>;
        promise = new Promise<PurchaseOrderModel>((resolve, reject) => {
            this.api.request('purchase/orders/' + purchase_order_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.purchase_order.clone(response.data);
                        resolve(this.purchase_order);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onPurchaseSelect(purchase: PurchaseModel, i: number, e: any): void {
        if (e && e.id) {
            purchase.id = e.id;
        } else {
            purchase.id = null;
            this.deletePurchase(purchase, i);
        }
    }

    private task_purchase_order_clone(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/clone', 'POST', {
                action: 'clone_purchase_order',
                id: this.purchase_order.task_id
            }).subscribe((response: any): void => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private generate_no(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (!this.purchase_order.purchase_order_no) {
                this.api.request('purchase/orders/no', 'POST', {
                    id: this.purchase_order.id
                }).subscribe((response: any): void => {
                    if (response && response.data && response.data.purchase_order_no) {
                        this.purchase_order.purchase_order_no = response.data.purchase_order_no;
                        resolve(this.purchase_order);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            } else {
                resolve(this.purchase_order);
            }
        });
        return promise;
    }

    public editPurchase(): void {
        if (this.purchase_order.purchase_order_no) {
            this.swal.confirm('คุณต้องการแก้ไขใบสั่งซื้อสินค้า "' + this.purchase_order.purchase_order_no + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.task_purchase_order_clone()
                            .then((response: any): void => {
                                if (response && response.data && response.data.id) {
                                    this.taskService.hire(response.data, null, true)
                                        .then((hire_response: any): void => {
                                            // Go Go!
                                        }, error => {
                                            //
                                        });
                                } else {
                                    //
                                }
                            });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public onCustomerSelect(data: any): void {
        //
    }

    public onSelectIncotermCode(e: any): void {
        //
    }

    public removePurchase(purchase: PurchaseModel): void {
        if (purchase && purchase.id) {
            this.swal.confirm('คุณต้องการยกเลิกรายการสินค้าใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        purchase.price = null;
                        if (purchase.pivot && purchase.pivot.price) {
                            purchase.pivot.price = null;
                        }
                        purchase.reset();
                        this.purchase_order.cal_total_price();
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public splitPO(): void {
        const items = [];
        for (let i = 0; i < this.purchase_order.purchases.length; i++) {
            const purchase = this.purchase_order.purchases[i];
            if (purchase && purchase._select) {
                items.push({
                    id              : purchase.id,
                    modelable_type  : purchase.modelable_type,
                    modelable_id    : purchase.modelable_id,
                    amount          : purchase.amount,
                    dateTime        : purchase.dateTime,
                    prepared_status : purchase.prepared_status,
                    price           : purchase.price,
                    remark          : purchase.remark,
                    item_description: purchase.description,
                    description     : purchase.description,
                    is_modelable    : true
                });
            }
        }
        if (items.length > 0 && items.length === this.purchase_order.purchases.length) {
            this.swal.danger('ไม่สามารถแยกรายการสินค้าไป PO ใหม่ได้');
            return;
        }
        if (items.length) {
            console.log(this.task);
            const parentId = this.task.modelable_id;
            const role = (this.task && this.task.current_role === 'po-purchase2') ? 'po-purchase2' : 'po-purchase';
            this.swal.confirm('ยืนยันการแยก PO ใหม่เป็นจำนวน ' + items.length + ' รายการ ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.loader.show();
                        this.storeService.purchase(
                            null,
                            (this.job && this.job.id) ? this.job.id : '',
                            (this.job && this.job.product_id) ? this.job.product_id : '',
                            null,
                            items,
                            (this.job && this.job.customer_id) ? this.job.customer_id : '',
                            null,
                            this.purchase_order.objective,
                            true,
                            this.purchase_order.required_at,
                            this.task.id,
                            parentId,
                            null,
                            role,
                            'create_po_purchase',
                            this.purchase_order.request_man_id
                        ).then(purchase_response => {
                            this.swal.success('ออกใบจัดซื้อจัดจ้างสำเร็จ');
                            this.leave(true);
                            this.loader.hide();
                        }, error => {
                            this.swal.danger(error);
                            this.loader.hide();
                        });
                    } else {
                        //
                    }
                });
        }
    }

    public addRemark(purchase: PurchaseModel): void {
        this.modal.show(RemarkModalComponent, {
            description: purchase.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                purchase.description = content.description;
                this._save();
            } else {
                //
            }
        });
    }

    public onSelectTypeaheadCurrency(data: any): void {
        this.purchase_order.currency_symbol = data.currencySymbol;
        this.purchase_order.currency = data.id;
        if (this.purchase_order && this.purchase_order.currency) {
            this.getCurrencyRate()
                .then((response: any): void => {
                    this.purchase_order.currency_rated_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    this.purchase_order.currency_rate = response;
                });
        }
    }

    public onBlur(e: any): void {
        if (this.purchase_order) {
            this.purchase_order.cal_total_price();
        }
    }

    public save(): void {
        if (this.task) {
            this._save()
                .then(() => {
                    this.swal.success('บันทึกข้อมูลสำเร็จ');
                });
        }
    }

    private create_or_update_contact(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.tmp_contact && this.tmp_contact.id) {
                resolve(this.tmp_contact);
            } else if (this.tmp_contact && this.tmp_contact.full_name) {
                // this.tmp_contact.vendor_id = this.task.vendor_id;
                this.modelApi.create(this.tmp_contact, ['full_name', 'position', 'telephone', 'email', 'customer_id'], 'customers/contact')
                    .subscribe((result: any): void => {
                        resolve(this.tmp_contact);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private get_purchase_ids(): any[] {
        let purchase_ids: any[];
        purchase_ids = [];
        for (let i = 0; i < this.purchase_order.purchases.length; i++) {
            const purchase = this.purchase_order.purchases[i];
            if (purchase.is_modelable && purchase.modelable && purchase.modelable_id) {
                purchase_ids.push({
                    purchase_id: (purchase.id) ? purchase.id : '',
                    modelable_id: purchase.modelable_id,
                    modelable_type: purchase.modelable_type,
                    dateTime: purchase.dateTime,
                    discount_price: purchase.discount_price,
                    amount: purchase.amount,
                    description: purchase.description,
                    price: purchase.price,
                    days: purchase.days,
                    is_modelable: purchase.is_modelable,
                    item_description: purchase.item_description
                });
            } else if (purchase.item_description) {
                purchase_ids.push({
                    purchase_id: (purchase.id) ? purchase.id : '',
                    dateTime: purchase.dateTime,
                    amount: purchase.amount,
                    discount_price: purchase.discount_price,
                    description: purchase.description,
                    price: purchase.price,
                    days: purchase.days,
                    is_modelable: purchase.is_modelable,
                    item_description: purchase.item_description
                });
            }
        }
        return purchase_ids;
    }

    public onModelChanged(e: any): void {
        this.ngZone.run(() => {
            if (this.purchase_order) {
                this.purchase_order.cal_total_price();
            }
        });
    }

    public onModelableSelect(data?: any): void {
        //
    }

    public vatUpdate(e?: any): void {
        setTimeout(() => {
            this.purchase_order.cal_total_price();
        }, 0);
    }

    public onDiscountPercentChange(e?: any): void {
        setTimeout(() => {
            // this.quotation.discount_price = Math.round(this.quotation.material_price * (this.quotation.discount_percent / 100) * 10000) / 10000;
            this.purchase_order.cal_total_price();
        }, 0);
    }

    public onDiscountPriceChange(e?: any): void {
        setTimeout(() => {
            // this.quotation.discount_percent = Math.round(this.quotation.discount_price * (100 / this.quotation.material_price) * 10000) / 10000;
            this.purchase_order.cal_total_price();
        }, 0);
    }

    public onRequestManChange(e: any): void {
        this.purchase_order.request_man_id = (e && e.id) ? e.id : null;
    }

    public onForCustomerChange(e: any): void {
        this.purchase_order.for_customer_id = (e && e.id) ? e.id : null;
    }

    public onPurchaseUserChange(e: any): void {
        this.purchase_order.purchase_user_id = (e && e.id) ? e.id : null;
    }

    public onBankSelect(e: any): void {
        this.purchase_order.bank_id = (e && e.id) ? e.id : null;
    }

    public onCustomerAddressTypeaheadDeleted(event?: any): void {
        this.purchase_order.customer_address = new CustomerAddressModel();
    }

    public onBillTypeaheadDeleted(event?: any): void {
        this.purchase_order.bill = new BillModel();
    }

    public sendToStore(): void {
        if (this.purchase_order && this.purchase_order.payments && this.purchase_order.payments.length) {
            for (let i = 0; i < this.purchase_order.payments.length; i++) {
                const payment: PaymentModel = this.purchase_order.payments[i];
                if (payment && payment.verified === true) {
                    this.swal.confirm('ยืนยันการส่งต่อให้กับแผนก Store เพื่อรอรับสินค้าใช่หรือไม่?')
                        .then((result: boolean): void => {
                            if (result === true) {
                                this.taskService.setStatus(this.task, this.task.status, 'wait_product', 'po-store', 'po-store')
                                    .then((task_response: any): void => {
                                        this.swal.success('ส่งต่อให้แผนก Store สำเร็จ');
                                        this.leave(true);
                                    });
                            }
                        });
                    return;
                }
            }
        }
        this.swal.danger('หลักฐานการชำระเงินยังไม่ได้ถูกแนบ');
    }

    public archivePO() {
        if (this.purchase_order.payment_terms > 0 && !this.purchase_order.payments.length) {
            this.swal.confirm('ยืนยันการจัดเก็บ P/O โดยไม่แนบหลักฐานการชำระเงินใช่หรือไม่?')
                .then((result: boolean) => {
                    if (result === true) {
                        this.api.request('tasks/archive', 'POST', null, {
                            id: this.task.id
                        }).subscribe((response: any): void => {
                            this.swal.success('จัดเก็นงานเข้าฐานข้อมูลสำเร็จ');
                            this.leave(true);
                        }, error => {
                            console.log(error);
                            this.swal.danger(error);
                        });
                    }
                });
        } else {
            this.archive();
        }
    }

    public cancelPO(remark: string): void {
        this.swal.confirm('คุณต้องการยกเลิก PO "' + this.purchase_order.purchase_order_no + '" ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.loader.show();
                    this.api.request('purchase/orders/cancel', 'POST', {}, {
                        id: this.purchase_order.id,
                        task_id: this.task_id,
                        remark,
                    }).subscribe(res => {
                        this.taskService.cancel(this.task)
                            .then(() => {
                                this.swal.success('ยกเลิก PO สำเร็จ');
                                this.loader.hide();
                                this.leave(true);
                            });
                    });
                } else {
                    //
                }
            });
    }

    public viewRef(document_no: string): void {
        this.viewer.purchaseRequisition(document_no);
    }

    public sendPurchaseOrder(): void {
        if (this.purchase_order.purchase_order_no && this.purchase_order.vendor && this.purchase_order.vendor.id) {
            if (this.purchase_order.vendor.email) {
                this.swal.confirm('คุณต้องการส่งใบจัดซื้อจัดจ้าง <span>"' + this.purchase_order.purchase_order_no + '"</span><br/>' +
                    ' กับ <i>' + this.purchase_order.vendor.name + '</i><br/>' +
                    ' ผ่านทางอีเมล <strong class="text-primary">"' + this.purchase_order.vendor.email + '"</strong>' +
                    ' ใช่หรือไม่?', true)
                    .then((result: boolean): void => {
                        if (result === true) {
                            if (this.task.current_role === 'payment') {
                                this.api.request('mail/purchase/order', 'POST', null, {
                                    task_id             : this.task.id,
                                    purchase_order_id   : this.purchase_order.id
                                }).subscribe((response: any): void => {
                                    this.swal.success('ส่งใบจัดซื้อจัดสร้าง "' + this.purchase_order.purchase_order_no + '"' +
                                        ' ผ่านทางอีเมลสำเร็จ')
                                        .then(() => {

                                        });
                                    this.leave(true);
                                }, error => {
                                    this.swal.danger(error);
                                });
                            } else {
                                let role: string;
                                role = (this.purchase_order && this.purchase_order.vendor && this.purchase_order.vendor.credit_terms > 0) ? 'po-store' : 'payment';
                                this.taskService.setStatus(this.task, 3, 'sent_purchase_order', role, role)
                                    .then((task_response: any): void => {
                                        this.api.request('mail/purchase/order', 'POST', null, {
                                            task_id: this.task.id,
                                            purchase_order_id: this.purchase_order.id
                                        }).subscribe((response: any): void => {
                                            if (role === 'po-store') {
                                                this.taskService.createNewTask({
                                                    parent_id       : this.task.id,
                                                    process_slug    : 'payment',
                                                    current_role    : 'payment',
                                                    action          : 'payment',
                                                    modelable_type  : 'App\\PurchaseOrder',
                                                    modelable_id    : this.purchase_order.id
                                                }).then(() => {
                                                    this.swal.success('ส่งใบจัดซื้อจัดสร้าง "' + this.purchase_order.purchase_order_no + '"' +
                                                        ' ผ่านทางอีเมล VENDOR สำเร็จ')
                                                        .then(() => {
                                                            //
                                                        });
                                                    this.leave(true);
                                                }, error => {
                                                    this.swal.danger(error);
                                                });
                                            } else {
                                                this.swal.success('ส่งใบจัดซื้อจัดสร้าง "' + this.purchase_order.purchase_order_no + '"' +
                                                    ' ผ่านทางอีเมลสำเร็จ')
                                                    .then(() => {
                                                        //
                                                    });
                                                this.leave(true);
                                            }
                                        }, error => {
                                            this.swal.danger(error);
                                        });
                                    });
                            }
                        } else {
                            //
                        }
                    });
            } else {
                this.swal.input('กรุณากรอกอีเมล VENDOR สำหรับการส่งใบจัดซื้อจัดจ้าง', 'ข้อมูลอีเมล', 'อีเมล', 'ส่งใบจัดซื้อจัดจ้าง')
                    .then((result: any): void => {
                        if (result) {
                            this.api.request('vendors/email', 'POST', {}, {
                                id: this.purchase_order.vendor.id,
                                email: result
                            }).subscribe((customer_response: any): void => {
                                if (customer_response && customer_response.success === false) {
                                    this.swal.danger(customer_response.message);
                                } else {
                                    if (this.task.current_role === 'payment') {
                                        this.api.request('mail/purchase/order', 'POST', null, {
                                            task_id             : this.task.id,
                                            purchase_order_id   : this.purchase_order.id
                                        }).subscribe((response: any): void => {
                                            this.swal.success('ส่งใบจัดซื้อจัดสร้าง "' + this.purchase_order.purchase_order_no + '"' +
                                                ' ผ่านทางอีเมลสำเร็จ')
                                                .then(() => {
                                                    // this.leave(true);
                                                });
                                        }, error => {
                                            //
                                        });
                                    } else {
                                        let role: string;
                                        role = (this.purchase_order && this.purchase_order.vendor && this.purchase_order.vendor.credit_terms > 0) ? 'po-store' : 'payment';
                                        this.taskService.setStatus(this.task, 3, 'sent_purchase_order', role, role)
                                            .then((task_response: any): void => {
                                                this.api.request('mail/purchase/order', 'POST', null, {
                                                    task_id: this.task.id,
                                                    purchase_order_id: this.purchase_order.id
                                                }).subscribe((response: any): void => {
                                                    if (role === 'po-store') {
                                                        this.taskService.createNewTask({
                                                            parent_id       : this.task.id,
                                                            process_slug    : 'payment',
                                                            current_role    : 'payment',
                                                            action          : 'payment',
                                                            modelable_type  : 'App\\PurchaseOrder',
                                                            modelable_id    : this.purchase_order.id
                                                        }).then(() => {
                                                            this.swal.success('ส่งใบจัดซื้อจัดสร้าง "' + this.purchase_order.purchase_order_no + '"' +
                                                                ' ผ่านทางอีเมล VENDOR สำเร็จ')
                                                                .then(() => {
                                                                    //
                                                                });
                                                            this.leave(true);
                                                        }, error => {
                                                            //
                                                        });
                                                    } else {
                                                        this.swal.success('ส่งใบจัดซื้อจัดสร้าง "' + this.purchase_order.purchase_order_no + '"' +
                                                            ' ผ่านทางอีเมล VENDOR สำเร็จ')
                                                            .then(() => {
                                                                //
                                                            });
                                                        this.leave(true);
                                                    }
                                                }, error => {
                                                    //
                                                });
                                            });
                                    }
                                }
                            }, error => {
                                this.swal.danger(error);
                            });
                        } else {
                            //
                        }
                    }, error => {
                        //
                    });
            }
        } else {
            this.swal.danger('ยังไม่ได้ระบุข้อมูลผู้ขาย/รับจ้าง');
        }
    }

    private _save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.purchase_order.purchase_ids = this.get_purchase_ids();
            this.modelApi.update(this.purchase_order, [
                'purchase_ids', 'request_man_id', 'vendor_id', 'quotation_ref', 'bank_id',
                'for_customer_id', 'objective', 'sale_id', 'customer_id', 'customer_address_id', 'bill_id',
                'shipping', 'incoterm_code', 'carrier_id', 'discount_percent', 'vat_percent', 'frt_sh', 'misc_chgs',
                'place_name', 'customer_id', 'purchase_user_id', 'currency', 'currency_rate', 'ref', 'required_at',
                'discount_type', 'discount_price', 'is_include_vat',
                'inventory_date', 'other_ref_no', 'ref_delivery_no', 'ref_tax_invoice_no', 'ref_purchase_order_no',
            ], null, null, null, false)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public approveTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.approveTask()
                    .then(() => {
                        this.swal.success('ผ่านการอนุมัติสำเร็จ');
                        this.leave(true, true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

    public rejectTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ไม่ผ่านการอนุมัติสำเร็จ');
                        this.leave(true, true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

    public get total_price(): number {
        if (this.purchase_order) {
            this.purchase_order.cal_total_price();
            return this.purchase_order.total_price;
        }
        return 0;
    }

    public onSaveApiCallingBack(e: any): void {
        if (this.task.approved) {
            //
        } else {
            this._save()
                .then(() => {
                    //
                });
        }
    }

    public onRemarkModalSubmit(e) {
        console.log(e);
        this.cancelPO(e.remark_message);
    }

}
