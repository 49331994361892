import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import * as moment from 'moment';
import {CustomerModel} from '../../../../models/customer.model';
import {JobModel} from '../../../../models/job.model';
import {ProductModel} from '../../../../models/product.model';
import {Viewer} from '../../../../services/viewer';
import {CreditNote} from '../../../../app/api/creditNote';

@Component({
    selector: 'app-credit-detail-component',
    templateUrl: 'creditNoteDetail.component.html',
    styleUrls: ['creditNoteDetail.component.scss']
})
export class CreditNoteDetailComponent implements OnInit {

    public creditNote: CreditNote;
    public tmp_creditNote: CreditNote;
    public credit_terms: number;
    public percent: number;
    public task: TaskModel;
    public model: any;
    public products: ProductModel[];
    public customer: CustomerModel;
    public job: JobModel;
    public is_new: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService,
        public viewer: Viewer,
    ) {
        //
        this.creditNote = {};
        this.tmp_creditNote = {};
        this.products = [];
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.model) {
                this.model = this.bsModalRef.content.model;
                if (this.model && this.model.jobs) {
                    for (const job of this.model.jobs) {
                        if (job && job.id) {
                            job._checked = true;
                        }
                    }
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.products) {
                this.products = this.bsModalRef.content.products;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.creditNote) {
                // if (!this.bsModalRef.content.creditNote.started_at) {
                //     this.bsModalRef.content.creditNote.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                // }
                this.creditNote = this.bsModalRef.content.creditNote;
                // this.tmp_creditNote.clone(this.creditNote);
                this.tmp_creditNote.started_at = this.creditNote.started_at;
                this.tmp_creditNote.ended_at = this.creditNote.ended_at;
                this.tmp_creditNote.id = this.creditNote.id;
                this.tmp_creditNote.document_no = this.creditNote.document_no;
                this.tmp_creditNote.required_at = this.creditNote.required_at;
                this.tmp_creditNote.remark = this.creditNote.remark;
                this.tmp_creditNote.status = (this.creditNote.status) ? this.creditNote.status : 2;
                this.tmp_creditNote.grand_total = this.creditNote.grand_total;
                this.tmp_creditNote.material_price = this.creditNote.material_price;
                this.tmp_creditNote.total_price = this.creditNote.total_price;
                this.tmp_creditNote.remark = this.creditNote.remark;
                this.tmp_creditNote.ref = this.creditNote.ref;

                if (this.creditNote.customer && this.creditNote.customer.credit_terms > 0) {
                    this.credit_terms = this.creditNote.customer.credit_terms;
                } else {
                    this.credit_terms = 0;
                }

                if (!this.tmp_creditNote.started_at) {
                    this.tmp_creditNote.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                } else {
                    this.tmp_creditNote.started_at = moment(this.tmp_creditNote.started_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                }

                this.onTotalPriceChanged();
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
            }
        }, 0);
    }

    public onStartedChanged(e: any): void {
        if (e) {
            const started_moment_object: any = moment(e, 'YYYY-MM-DD HH:mm:ss');
            const ended_moment_object: any = moment(this.tmp_creditNote.ended_at, 'YYYY-MM-DD HH:mm:ss');
            this.credit_terms = moment.duration(ended_moment_object.diff(started_moment_object)).asDays();
        }
    }

    public onTotalPriceChanged(e?: any): void {
        setTimeout(() => {
            const material_price = (this.creditNote.material_price) ? this.creditNote.material_price : 0;
            this.tmp_creditNote.difference_price = material_price - ((this.tmp_creditNote.total_price) ? this.tmp_creditNote.total_price : 0);
        }, 100);
    }

    public delete(): void {
        if (this.creditNote.status === 1) {
            return;
        }
        const name: string = (this.tmp_creditNote.document_no)
            ? this.tmp_creditNote.document_no : 'ใบเพิ่มหนี้';
        this.swal.confirm('คุณต้องการลบเอกสารใบสั่งซื้อ "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.modelApi.delete(this.tmp_creditNote)
                        .subscribe((response: any): void => {
                            const index: number = this.model.creditNotes.findIndex(i => i.id === this.tmp_creditNote.id);
                            if (index > -1) {
                                this.model.creditNotes.splice(index, 1);
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public viewCreditNote(): void {
        this.viewer.creditNote(this.tmp_creditNote);
    }

    public update(): void {
        if (this.tmp_creditNote && !this.tmp_creditNote.id) {
            this.swal.confirm('ยืนยันการออกใบเพิ่มหนี้ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('credit/notes', 'PUT', {}, {
                            customer_id: this.model.customer_id,
                            model_id: this.model.id,
                            contact_id: this.model.contact_id,
                            ref: this.tmp_creditNote.ref,
                            status: this.tmp_creditNote.status,
                            started_at: this.tmp_creditNote.started_at,
                            material_price: this.creditNote.material_price,
                            grand_total: this.creditNote.grand_total,
                            total_price: this.tmp_creditNote.total_price,
                            difference_price: this.tmp_creditNote.difference_price,
                            remark: this.tmp_creditNote.remark,
                            jobs: this.get_jobs()
                        }).subscribe((response: any): void => {
                            this.swal.success('ออกใบเพิ่มหนี้สำเร็จ');
                            this.bsModalRef.content.creditNote = response.data;
                            this.bsModalRef.content.is_create = true;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        } else {
            this.swal.confirm('ยืนยันการแก้ไขเอกสารใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('credit/notes/status', 'POST', {}, {
                            status: this.tmp_creditNote.status,
                            id: this.tmp_creditNote.id
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.bsModalRef.content.creditNote.status = this.tmp_creditNote.status;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        }
    }

    private get_jobs(): any {
        let job_ids: any;
        job_ids = {};
        if (this.model && this.model.jobs) {
            for (const job of this.model.jobs) {
                if (job && job._checked) {
                    job_ids[job.id] = {
                        amount: job.pivot.amount,
                        price_per_unit: job.pivot.price_per_unit
                    };
                }
            }
        }
        return job_ids;
    }

    public complete(): void {
        if (this.tmp_creditNote.total_price <= 0 || !this.tmp_creditNote.total_price) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากไม่มียอดเงินวางมัดจำ');
        } else {
            this.swal.confirm('ยืนยันการออกใบเพิ่มหนี้เป็นจำนวนเงิน "' + this.tmp_creditNote.total_price + '" บาท ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.save()
                            .then(() => {
                                this.viewCreditNote();
                                this.bsModalRef.content.creditNote = this.creditNote;
                                this.bsModalRef.content.is_submit = this.is_new;
                                this.bsModalRef.content.submit = false;
                                this.bsModalRef.hide();
                            });
                    } else {
                        //
                    }
                });
        }
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const product_ids: any[] = this.get_product_ids();
            let request_data: any;
            request_data = {
                product_ids     : product_ids,
                customer_id     : this.model.customer_id,
                grand_total     : this.tmp_creditNote.grand_total,
                total_price     : this.tmp_creditNote.total_price,
                started_at      : this.tmp_creditNote.started_at,
                remark          : this.tmp_creditNote.remark,
                status          : this.tmp_creditNote.status
            };
            if (this.model && this.model.model_name === 'App\\Quotation') {
                request_data['quotation_id'] = this.model.id;
            } else if (this.model && this.model.model_name === 'App\\TaxInvoice') {
                request_data['tax_invoice_id'] = this.model.id;
            }
            this.api.request('creditNotes', 'PUT', {}, request_data)
                .subscribe((response: any): void => {
                    this.creditNote = Object.assign({}, response.data);
                    resolve();
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private get_product_ids(): any[] {
        let product_ids: any[];
        product_ids = [];
        for (let i = 0; i < this.products.length; i++) {
            product_ids.push(this.products[i].id);
        }
        return product_ids;
    }

}
