import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';

@Injectable()
export class UserTaskServices {

    public userTasksData;
    public userRolesData;

    constructor(private api: Api) {
        this.userRolesData = {};
    }

    public clear() {
        console.log('userTask.services.clear()');
        this.userRolesData = {};
        this.userTasksData = undefined;
    }

    public getUserRoles(slug: string): Promise<Object> {
        let promise: Promise<Object>;
        slug = (slug) ? slug : '_';
        promise = new Promise<Object>(async (resolve, reject) => {
            let response;
            if (this.userRolesData && slug && this.userRolesData[slug]) {
                response = {
                    data: JSON.parse(JSON.stringify(this.userRolesData[slug]))
                };
                resolve(response.data);
            } else {
                response = await this.api.request('users/roles', 'GET', {
                    parent: (slug && slug !== '_') ? slug : ''
                }).toPromise();
                if (response && response.data && response.data.length) {
                    this.userRolesData[slug] = response.data;
                    resolve(response.data);
                } else {
                    resolve([]);
                }
            }
        });
        return promise;
    }

    public getUserTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            console.log(this.userTasksData);
            if (this.userTasksData) {
                resolve(JSON.parse(JSON.stringify(this.userTasksData)));
            } else {
                this.api.request('tasks/users', 'GET')
                    .subscribe((response: any): void => {
                        if (response && response.data) {
                            this.userTasksData = response.data;
                            resolve(response.data);
                        } else {
                            reject(response);
                        }
                    }, error => {
                        reject(error);
                    });
            }
        });
        return promise;
    }

}
