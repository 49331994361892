import {Model} from '../now/model';
import {VendorModel} from './vendor.model';
import {UserModel} from '../now/user/user.model';
import {PurchaseModel} from './purchase.model';
import {ProductModel} from './product.model';
import {JobModel} from './job.model';
import {CustomerModel} from './customer.model';
import {ContactModel} from './contact.model';
import {BillModel} from './bill.model';
import {CustomerAddressModel} from './customerAddress.model';
import {CarrierModel} from './carrier.model';
import {BankModel} from './bank.model';
import {PaymentModel} from './payment.model';
import {Remark} from '../app/api/remark';
// import {Bank} from '../app/api/bank';

export class PurchaseOrderModel extends Model {

    public inventory_date: any;
    public other_ref_no: string;
    public ref_delivery_no: string;
    public ref_tax_invoice_no: string;
    public ref_purchase_order_no: string;

    public canceled_at: any;
    public documents: any[];
    public purchase_order_no: string;
    public quotation_no: string;
    public ref_no: string;
    public quotation_ref: string;
    public document: any;
    public sale_id: string;
    public sale: ContactModel;
    public ref: string;
    public fvd_id: string;
    public payments: any[];
    public payment_terms: number;
    public status: number;
    public remarks: Remark[];
    public items: any[];

    public discount_type: number;
    public modelable: any;
    public modelable_type: string;
    public modelable_id: any;

    public bank: BankModel;
    public bank_id: string;

    public incoterm_code: string;
    public job_id: string;
    public job: JobModel;
    public job_no: string;
    public product_id: string;
    public product: ProductModel;
    public product_no: string;

    public objective: string; // PO, NG, SELL, OTHER, FVD
    public objective_other: string;

    public started_at: string;
    public purchase_ids: any;

    public currency: string;
    public currency_symbol: string;
    public currency_rate: number;
    public currency_rated_at: string;

    public bill_id: string;
    public bill: BillModel;

    public is_include_vat: boolean;

    public task_id: string;
    public customer_id: string;
    public for_customer_id: string;
    public customer_address_id: string;
    public product_ids: any;
    public carrier_id: string;

    public vat_percent: number;
    public vat_price: number;
    public discount_percent: number;
    public discount_price: number;
    public material_price: number;
    public material_price1: number;
    public material_price2: number;
    public material_price3: number;
    public frt_sh: number;
    public misc_chgs: number;
    public sub_total: number;
    public gst_tax: number;
    public grand_total: number;

    public purchases: PurchaseModel[];

    public verified: boolean;
    public total_price: number;

    public vendor_id: string;
    public vendor: VendorModel;
    public purchase_user_id: string;
    public purchase_user: UserModel;
    public carrier: CarrierModel;

    public man: UserModel;
    public man_id: string;

    public request_man_id: string;
    public request_man: UserModel;

    public vendor1_id: string;
    public vendor2_id: string;
    public vendor3_id: string;

    public vendor1: VendorModel;
    public vendor2: VendorModel;
    public vendor3: VendorModel;

    public customer: CustomerModel;
    public for_customer: CustomerModel;
    public customer_address: CustomerAddressModel;

    public required_at: string;
    public delivery_date: string;

    constructor() {
        super('purchase/orders', 'App\\PurchaseOrder');
        this.incoterm_code = '';
        this.payments = [];
        this.purchase_ids = [];
        this.purchases = [];
        this.vendor1 = new VendorModel();
        this.vendor2 = new VendorModel();
        this.vendor3 = new VendorModel();
        this.discount_type = 1;
        this.documents = [];
    }

    public clone(data: any): Model {
        if (data && data.purchases) {
            for (let i = 0; i < data.purchases.length; i++) {
                const dat: any = data.purchases[i];
                if (dat) {
                    let purchase: PurchaseModel;
                    purchase = new PurchaseModel();
                    purchase.clone(dat);
                    this.purchases.push(purchase);
                }
            }
            delete data.purchases;
        }
        if (data && data.payments) {
            for (let i = 0; i < data.payments.length; i++) {
                const dat: any = data.payments[i];
                if (dat) {
                    let payment: PaymentModel;
                    payment = new PaymentModel();
                    payment.clone(dat);
                    this.payments.push(payment);
                }
            }
            delete data.payments;
        }
        if (data && data.bank) {
            this.bank = new BankModel();
            this.bank.clone(data.bank);
            delete data.bank;
        }
        if (data && data.request_man) {
            this.request_man = new UserModel();
            this.request_man.clone(data.request_man);
            delete data.request_man;
        }
        if (data && data.vendor) {
            this.vendor = new VendorModel();
            this.vendor.clone(data.vendor);
            delete data.vendor;
        }
        if (data && data.purchase_user) {
            this.purchase_user = new UserModel();
            this.purchase_user.clone(data.purchase_user);
            delete data.purchase_user;
        }
        if (data && data.customer) {
            this.customer = new CustomerModel();
            this.customer.clone(data.customer);
            delete data.customer;
        }
        if (data && data.for_customer) {
            this.for_customer = new CustomerModel();
            this.for_customer.clone(data.for_customer);
            delete data.for_customer;
        }
        if (data && data.sale) {
            this.sale = new ContactModel();
            this.sale.clone(data.sale);
            delete data.sale;
        }
        if (data && data.bill) {
            this.bill = new BillModel();
            this.bill.clone(data.bill);
            delete data.bill;
        }
        if (data && data.customer_address) {
            this.customer_address = new CustomerAddressModel();
            this.customer_address.clone(data.customer_address);
            delete data.customer_address;
        }
        if (data && data.carrier) {
            this.carrier = new CarrierModel();
            this.carrier.clone(data.carrier);
            delete data.carrier;
        }

        return super.clone(data);
    }

    public cal_total_price(): number {
        /*this.material_price = 0;

        for (let i = 0; i < this.purchases.length; i++) {
            const purchase: PurchaseModel = this.purchases[i];
            if (purchase && purchase.price && purchase.amount) {
                this.material_price += purchase.price * purchase.amount;
            }
        }

        const material_price: number = (this.material_price > 0) ? this.material_price : 0;
        const frt_sh: number = (this.frt_sh > 0) ? this.frt_sh : 0;
        const misc_chgs: number = (this.misc_chgs > 0) ? this.misc_chgs : 0;
        const sub_total = material_price + frt_sh + misc_chgs;
        const _discount_percent = (this.discount_percent) ? this.discount_percent : 0;
        const _vat_percent = (this.vat_percent) ? this.vat_percent : 0;

        this.discount_price = (sub_total * _discount_percent) / 100;
        this.sub_total = sub_total - this.discount_price;
        this.vat_price = (this.sub_total * _vat_percent) / 100;
        this.grand_total = this.vat_price + this.sub_total;

        return this.grand_total;*/

        const _vat_percent = (this.vat_percent) ? this.vat_percent : 0;

        let material_price_with_vat: number;
        material_price_with_vat = 0;
        this.material_price = 0;
        for (let i = 0; i < this.purchases.length; i++) {
            const purchase: PurchaseModel = this.purchases[i];
            if (purchase && purchase.price && purchase.amount) {
                const discountPrice = (purchase.discount_price > 0) ? purchase.discount_price : 0;
                if (this.is_include_vat === false) {
                    this.material_price += (purchase.price * purchase.amount) - discountPrice;
                } else {
                    // this.material_price += (Math.round(((purchase.price_per_unit * purchase.customer_purchase_amount) / 1.07) * 10000) / 10000);
                    this.material_price += (((purchase.price * purchase.amount) - discountPrice) / 1.07);
                    material_price_with_vat += (purchase.price * purchase.amount) - discountPrice;
                }
            }
        }

        const material_price: number = (this.material_price > 0) ? this.material_price : 0;
        const frt_sh: number = (this.frt_sh > 0) ? (this.is_include_vat === false) ? this.frt_sh : this.frt_sh / (1 + (this.vat_percent / 100)) : 0;
        const misc_chgs: number = (this.misc_chgs > 0) ? (this.is_include_vat === false) ? this.misc_chgs : this.misc_chgs / (1 + (this.vat_percent / 100)) : 0;
        // const _discount_price = (this.discount_price) ? this.discount_price : 0;
        const _discount_percent = (this.discount_percent) ? this.discount_percent : 0;
        const sub_total = material_price + frt_sh + misc_chgs;

        if (this.discount_type === 1) {
            // this.discount_price = (this.is_include_vat === false)
            //     ? Math.round(((sub_total * _discount_percent) / 100) * 10000) / 10000 : Math.round((((sub_total * _discount_percent) / 100) / 1.07) * 10000) / 10000;
            this.discount_price = Math.round(((material_price * _discount_percent) / 100) * 10000) / 10000;
        } else {
            //
        }

        const discount_price_with_vat = Math.round(((material_price_with_vat * _discount_percent) / 100) * 10000) / 10000;

        if (this.is_include_vat === false) {
            this.sub_total = sub_total - this.discount_price;
            this.vat_price = Math.round(((this.sub_total * _vat_percent) / 100) * 10000) / 10000;
        } else {
            this.sub_total = Math.round((((material_price_with_vat + ((this.frt_sh) ? this.frt_sh : 0) + ((this.misc_chgs) ? this.misc_chgs : 0)) - discount_price_with_vat) / 1.07) * 10000) / 10000;
            this.vat_price = ((material_price_with_vat + ((this.frt_sh) ? this.frt_sh : 0) + ((this.misc_chgs) ? this.misc_chgs : 0)) - discount_price_with_vat) - this.sub_total;
        }
        this.grand_total = this.vat_price + this.sub_total;

        return this.grand_total;
    }

}
