import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TaskModel} from '../../../models/task.model';
import {CustomerModel} from '../../../models/customer.model';
import {ContactModel} from '../../../models/contact.model';
import {ProductModel} from '../../../models/product.model';
import {DocumentModel} from '../../../models/document.model';
import {DivApiDirective} from '../../../now/divApi';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {UserModel} from '../../../now/user/user.model';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {AuthService} from '../../../now/user/auth.service';
import {QuotationModel} from '../../../models/quotation.model';
import {SwalService} from '../../../services/swal.service';
import {UserService} from '../../../services/user.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {PusherService} from '../../../services/pusher.service';
import {ModalService} from '../../../services/modal.service';
import {CarrierModel} from '../../../models/carrier.model';
import {CarrierService} from '../../../services/carrier.service';
import {JobModel} from '../../../models/job.model';
import {SchedulerModel} from '../../../models/scheduler.model';
import * as moment from 'moment';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {SchedulerHistoryModel} from '../../../models/schedulerHistory.model';
import {ViewTask} from '../view/viewTask';
import {DOCUMENT, Location} from '@angular/common';
import {PageScrollService} from 'ngx-page-scroll-core';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'notice-task-component',
    templateUrl: 'noticeTask.component.html',
    styleUrls: ['noticeTask.component.scss']
})
export class NoticeTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild('noticeRemark', { static: true }) noticeRemark: RemarkModal;

    public schedulers: SchedulerModel[];
    public shipping_date: string;
    public job_id: string;
    public product_id: string;
    public product: ProductModel;
    public task_id: string;
    public customer: CustomerModel;
    public contact: ContactModel;
    public current_user: UserModel;
    public from_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public documents: DocumentModel[];
    public quotations: QuotationModel[];
    public purchase_orders: PurchaseOrderModel[];
    public proforma_invoices: any[];
    public tax_invoices: any[];
    public confirmed_delivery_date: any;
    public carriers: CarrierModel[];
    public current_role: string;

    public pdfInfo: any;
    public numPages: number[];

    public scheduler_histories: SchedulerHistoryModel[];
    public current_job_document_path: string;

    public job: JobModel;
    public planning_events: any[];

    public current_tab: string;
    public product_index: number;
    public current_part_product_index: number;
    public is_ready: boolean;

    public ready: boolean;
    public options: any;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private ngZone: NgZone,
        public api: Api,
        public modal: ModalService,
        public pageScrollService: PageScrollService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        public location: Location,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.schedulers = [];
        this.is_ready = false;
        this.confirmed_delivery_date = '';
    }

    private init(): void {
        this.task = new TaskModel();
        this.contact = new ContactModel();
        this.product = new ProductModel();
        this.product_index = -1;
        this.current_part_product_index = -1;

        this.job = new JobModel();
        this.ready = false;
        this.planning_events = [];
        this.documents = [];
        this.options = {
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'month'
            },
            events: []
        };

        this.numPages = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTaskDetail();
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public onDeliveryDateChange(e: any): void {
        setTimeout(() => {
            this.refreshCalendar();
        }, 0);
    }

    public addConfirmedDeliveryDate(date: string, title?: string): any {
        const date_moment_obj: any = moment(date, 'YYYY-MM-DD HH:mm:ss');
        return {
            title: (title) ? title : 'ส่งมอบสินค้า',
            start: date_moment_obj,
            end: date_moment_obj,
            className: 'event-solid'
        };
    }

    public sendDelivery(): void {
        if (this.job.planning_started_at) {
            if (!this.confirmed_delivery_date) {
                this.swal.danger('ไม่ได้ระบุวันแจ้งเลื่อนกับลูกค้า');
                return;
            }
        }
        this.noticeRemark.open();
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.task.clone(response.data);
                    this.onSuccess(response.data);
                } else {
                    //
                }
            }, error => {
                this.onError(error);
            });
    }

    public onSuccess(data: any): void {
        this.job_id = this.task.modelable_id;
        this.getJob(this.job_id)
            .then(() => {
                if (this.job && this.job.product) {
                    this.product_id = this.job.product_id;
                    this.getProductDetail()
                        .then(() => {
                            this.refreshCalendar();
                            this.shipping_date = this.find_shipping_date();
                            this.viewer.manufacture(this.job, false, this.job.revision)
                                .then(path => {
                                    this.current_job_document_path = path;
                                    this.is_ready = true;
                                });
                        });
                } else {
                    //
                }
            });
    }

    public onRemarkModalSubmit(e: any): void {
        if (this.job.planning_started_at) {
            const shipping_date: any = this.find_shipping_date();
            if (shipping_date) {
                this.api.request('jobs/notice', 'POST', null, {
                    id: this.job_id,
                    task_id: this.task_id,
                    remark: e.remark_message,
                    shipping_date: shipping_date,
                    confirmed_delivery_date: this.confirmed_delivery_date
                }).subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.api.request('mail/delivery/notice', 'POST', null, {
                            task_id: this.task.id
                        }).subscribe((mail_response: any): void => {
                            if (mail_response && mail_response.success === true) {
                                this.taskService.setStatus(this.task, this.task.status, 'sent_notice_delivery', 'pd-control', 'control', false)
                                    .then(() => {
                                        this.swal.success('แจ้งเลื่อนวันส่งมอบสินค้ากับลูกค้าสำเร็จ');
                                        this.leave(true);
                                    });
                            } else {
                                this.swal.danger(mail_response.message);
                            }
                        }, error => {
                            this.swal.danger(error);
                        });
                    } else {
                        //
                    }
                });
            } else {
                this.swal.danger('ไม่ได้ระบุวันจาก Shipping');
            }
        } else {
            this.api.request('job/remarks', 'PUT', {}, {
                message: e.remark_message,
                tag: 'job_ignore',
                job_id: this.job.id,
            }).subscribe((response: any): void => {
                this.api.request('jobs/ignore', 'POST', {}, {
                    id: this.job.id,
                    ignore: true
                }).subscribe((ignore_response: any): void => {
                    if (ignore_response && ignore_response.success === true) {
                        this.taskService.setStatus(this.task, this.task.status, 'job_ignore', 'pd-planning-2', 'planning', false)
                            .then(() => {
                                this.swal.success('ส่งคำร้องขอให้ Sale Admin สำเร็จ');
                                this.leave(true);
                            });
                    } else {
                        //
                    }
                });
            });
        }
    }

    public onRejectRemarkModalSubmit(e: any): void {
        this.api.request('jobs/reject/notice', 'POST', {}, {
            task_id: this.task_id,
            id: this.job.id,
            remark: e.remark_message,
        }).subscribe((response: any): void => {
            if (response && response.success === true) {
                let role: string;
                let process: string;
                if (this.job.planning_started_at) {
                    role = 'pd-control';
                    process = 'control';
                } else {
                    role = 'pd-planning';
                    process = 'planning';
                }
                this.taskService.setStatus(this.task, this.task.status, 'reject_notice', role, process, false)
                    .then(() => {
                        this.swal.success('ไม่ผ่านการอนุมัติสำเร็จ');
                        this.leave(true);
                    });
            }
        });
    }

    private find_shipping_date(): string {
        for (let i = 0; i < this.product.product_processes.length; i++) {
            const product_process = this.product.product_processes[i];
            if (product_process.process.slug === 'shipping') {
                if (product_process && product_process.checked && product_process.revision === this.job.revision) {
                    for (let j = product_process.schedulers.length - 1; j >= 0; j--) {
                        const scheduler = product_process.schedulers[j];
                        if (scheduler && scheduler.start && scheduler.checked && scheduler.revision === this.job.revision) {
                            return scheduler.start;
                        }
                    }
                } else if (product_process && product_process.children && product_process.children.length) {
                    for (let k = 0; k < product_process.children.length; k++) {
                        const child: any = product_process.children[k];
                        if (child && child.checked && child.revision === this.job.revision) {
                            for (let j = child.schedulers.length - 1; j >= 0; j--) {
                                const scheduler = child.schedulers[j];
                                if (scheduler && scheduler.start && scheduler.checked && scheduler.revision === this.job.revision) {
                                    return scheduler.start;
                                }
                            }
                        }
                    }
                }
            }
        }
        return null;
    }

    public refreshCalendar(): void {
        if (this.schedulers) {
            this.schedulers.splice(0, this.schedulers.length);
        } else {
            this.schedulers = [];
        }
        if (this.options.events) {
            this.options.events.splice(0, this.options.events.length);
        } else {
            this.options.events = [];
        }
        for (let i = 0; i < this.product.product_processes.length; i++) {
            const product_process = this.product.product_processes[i];
            if (product_process && product_process.checked && product_process.revision === this.job.revision) {
                for (let j = 0; j < product_process.schedulers.length; j++) {
                    const scheduler = product_process.schedulers[j];
                    if (scheduler && scheduler.checked && scheduler.revision === this.job.revision) {
                        const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                        const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                        const event_scheduler: any = {
                            title: ((product_process.is_fvd) ? '(FVD)' : '') + product_process.process.name,
                            start: start,
                            end: end,
                        };
                        this.schedulers.push(event_scheduler);
                        this.options.events.push(event_scheduler);
                    }
                }
            }
        }
        this.options.events.push(this.addConfirmedDeliveryDate(this.job.confirmed_delivery_date));
        this.options.events.push(this.addConfirmedDeliveryDate(this.confirmed_delivery_date, 'แจ้งวันส่งมอบสินค้าใหม่'));
        if (this.is_ready === true) {
            this.fullCalendarComponent.removeEvents();
            this.fullCalendarComponent.addEventSource(this.options.events);
            this.fullCalendarComponent.rerenderEvents();
        }
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onError(e: any): void {
        //
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.task.modelable_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public onPdfLoadCompleted(e: any): void {
        //
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

}
