import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {DocumentModel} from '../../../../models/document.model';
import {DocumentDetailComponent} from '../../../task/view/documentDetail/documentDetail.component';
import {VendorModel} from '../../../../models/vendor.model';
import {Location} from '@angular/common';
import {ContactModel} from '../../../../models/contact.model';
import {Bank} from '../../../../app/api/bank';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'vendor-view-component',
    templateUrl: 'vendorView.component.html',
    styleUrls: ['vendorView.component.scss']
})
export class VendorViewComponent implements OnInit {

    public vendor: VendorModel;
    public vendor_id: string;
    public current_tab: string;

    constructor(
        private route: ActivatedRoute,
        private api: Api,
        private location: Location,
        private appService: AppService,
        private modelApi: ModelApi,
        private modal: ModalService,
        private swal: SwalService,
        private viewer: Viewer,
    ) {
        //
        this.vendor = new VendorModel();
        this.route.params
            .subscribe(params => {
                this.vendor_id = params['id'];
            });

        this.current_tab = 'profile';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getVendor()
                .then(() => {
                    if (this.vendor.contacts && this.vendor.contacts.length === 0) {
                        this.vendor.contacts.push(new ContactModel());
                    }
                    if (this.vendor.banks && this.vendor.banks.length === 0) {
                        this.vendor.banks.push({});
                    }
                });
        }, 0);
    }

    public getVendor(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.vendor_id) {
                this.api.request('vendors/' + this.vendor_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.vendor.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.vendor);
            }
        });
        return promise;
    }

    public addContact(): void {
        this.vendor.contacts.push(new ContactModel());
    }

    public addBank(): void {
        this.vendor.banks.push({});
    }

    public save_contact(contact: ContactModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            contact.modelable_type = 'App\\Vendor';
            contact.modelable_id = this.vendor.id;
            //
            this.modelApi.createOrUpdate(contact, [
                'full_name', 'telephone', 'telephone2', 'email', 'fax', 'position', 'modelable_type', 'modelable_id'
            ], null, null, null, true, true)
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public save_bank(bank: Bank): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            bank.modelable_id = this.vendor.id;
            bank.modelable_type = 'App\\Vendor';
            this.api.request('banks', 'POST', {}, bank, null, null, null, null, true)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public save(): void {
        if (!this.vendor.name) {
            this.swal.danger('กรุณากรอกชื่อผู้ขาย');
        } else if (!this.vendor.branch && false) {
            this.swal.danger('กรุณากรอกสาขาผู้ขาย');
        } else if (!this.vendor.email && false) {
            this.swal.danger('กรุณากรอกอีเมลผู้ขาย');
        } else if (!this.vendor.telephone && false) {
            this.swal.danger('กรุณากรอกโทรศัพท์ติดต่อ');
        } else {
            this.modelApi.createOrUpdate(this.vendor, [
                // profile
                'avatar', 'name', 'tax_no', 'branch', 'ranking',
                'telephone', 'telephone2', 'email', 'fax', 'email', 'website', 'address',
                'city', 'state', 'postal_code', 'country', 'credit_terms'
            ], null, null, null, false)
                .subscribe((response: any): void => {
                    this.vendor.id = response.data.id;
                    let _promise_list: Promise<any>[];
                    _promise_list = [];
                    for (let i = 0; i < this.vendor.banks.length; i++) {
                        const bank: Bank = this.vendor.banks[i];
                        _promise_list.push(this.save_bank(bank));
                    }
                    for (let i = 0; i < this.vendor.contacts.length; i++) {
                        const contact: ContactModel = this.vendor.contacts[i];
                        if (contact && (contact.full_name || contact.id)) {
                            _promise_list.push(this.save_contact(contact));
                        }
                    }
                    Promise.all(_promise_list)
                        .then(() => {
                            this.location.replaceState('/database/vendor/' + this.vendor.id + '/detail');
                            this.swal.success('บันทึกข้อมูลผู้ขายสำเร็จ');
                        }, error => {
                            this.swal.danger(error);
                        });
                }, error => {
                    this.swal.danger(error);
                });
        }
    }

    public onUploadedSuccess(data: any): void {
        if (data && data.preview) {
            this.vendor.avatar = data.preview;
            this.modelApi.update(this.vendor, ['avatar'])
                .subscribe(() => {});
        }
    }

    public onUploadError(error: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('vendors/document', 'PUT', {}, {
            document: data,
            id: this.vendor.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.vendor.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public viewDocument(doc, event?): void {
        if (event) {
            event.stopPropagation();
        }
        this.viewer.document(doc);
    }

    public viewDocumentDetail(doc: DocumentModel, i, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            // user: this.customer,
            document: doc,
            deletable: true,
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((data: any) => {
                if (data && data.deleted === true) {
                    this.modelApi.delete(doc, 'documents/delete')
                        .subscribe((response: any): void => {
                            this.vendor.documents.splice(i, 1);
                        }, error => {
                            //
                        });
                }
            });
    }

    public onStartUploader(data: any): void {
        if (this.vendor && this.vendor.id) {
            //
        } else {
            if (data && data.uploader) {
                data.uploader.cancel();
            }
            this.swal.danger('กรุณาบันทึกข้อมูลผู้ขายก่อนทำการอัพโหลดเอกสาร');
        }
    }

    public createVendor(): void {
        this.save();
    }

}
