import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProcessService} from '../../../../services/process.service';
import {ProductModel} from '../../../../models/product.model';
import {NgForm} from '@angular/forms';
import {TaskModel} from '../../../../models/task.model';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {JobModel} from '../../../../models/job.model';
import {ProductProcessModel} from '../../../../models/productProcess.model';
import {ModelApi} from '../../../../now/modelApi/modelApi';

@Component({
    selector: 'end-modal-component',
    templateUrl: 'endModal.component.html',
    styleUrls: ['endModal.component.scss']
})
export class EndModalComponent implements AfterViewInit {

    public product: ProductModel;
    public task: TaskModel;
    public remark: string;
    public job: JobModel;
    public product_processes: ProductProcessModel[];
    public product_amount: number;
    public product_push_amount: number;
    public product_pull_amount: number;
    public customer_product_amount: number;
    public sale_product_amount: number;

    constructor(
        public bsModalRef: BsModalRef,
        private processService: ProcessService,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService
    ) {
        //
        this.product = new ProductModel();
        this.task = new TaskModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.product) {
                this.product = this.bsModalRef.content.product;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.product_processes) {
                this.product_processes = this.bsModalRef.content.product_processes;
            } else {
                this.product_processes = [];
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
                this.getJobDetail()
                    .then(() => {
                        this.customer_product_amount = this.job.customer_product_amount;
                        this.product_amount = this.job.current_product_process.amount;
                        this.sale_product_amount = this.job.sale_product_amount;
                        this.product_pull_amount = this.job.pull_amount;
                        console.log('product_pull_amount : ' + this.job.pull_amount);
                    });
            }
        }, 0);
    }

    private getJobDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.modelApi.sync(this.job)
                .subscribe((response: any): void => {
                    this.product.id = this.job.product_id;
                    resolve(this.job);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            if (this.product_push_amount > this.product_pull_amount) {
                this.swal.danger('ข้อมูลของจำนวนงานไม่ถูกต้อง');
            } else if (this.product_push_amount > 0 && this.product_push_amount <= this.product_pull_amount) {
                let message: string;
                message = 'คุณกำลังคืนงานจำนวนทั้งหมด <strong class="text-primary">'
                    + this.product_push_amount + ' ' + this.product.unit +  '</strong> ใช่หรือไม่?';
                this.swal.confirm(message, true)
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.bsModalRef.content.product_push_amount = this.product_push_amount;
                            this.bsModalRef.content.remark = this.remark;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        } else {
                            //
                        }
                    });
            }
        } else {
            //
        }
    }

}
