import {AfterViewInit, Component} from '@angular/core';
import {ViewRequest} from '../view/viewRequest';
import {Location} from '@angular/common';
import {SwalService} from '../../../services/swal.service';
import {ModalService} from '../../../services/modal.service';
import {TaskModel} from '../../../models/task.model';
import {DocumentModel} from '../../../models/document.model';
import {FMTModel} from '../../../models/fmt.model';
import {DocumentDetailComponent} from '../../task/view/documentDetail/documentDetail.component';
import {environment} from '../../../environments/environment';
import {Api} from '../../../now/api/api';
import {TaskService} from '../../../services/task.service';
import {Viewer} from '../../../services/viewer';
import {MachineModel} from '../../../models/machine.model';
import * as moment from 'moment';
import {UserModel} from '../../../now/user/user.model';
import {AuthService} from '../../../now/user/auth.service';
import {LoaderService} from '../../../components/loader/loader.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {SupplierModel} from '../../../models/supplier.model';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {MaterialCreateModal} from '../../task/view/product/materialCreate/materialCreate.modal';
import {MachineTypeModal} from '../../../modals/machineType/machineType.modal';

const MAX_EQUIPMENTS = 5;
declare var $: any;

@Component({
    selector: 'request-fmt-task-component',
    templateUrl: 'requestFMTTask.component.html',
    styleUrls: ['requestFMTTask.component.scss']
})
export class RequestFMTTaskComponent extends ViewRequest implements AfterViewInit {

    public fmt: FMTModel;
    public fmt_id: string;
    public machine: MachineModel;
    public user_informer: UserModel;
    public remarks: any[];
    public documents: any[] = [];

    constructor(
        public location: Location,
        private swal: SwalService,
        public modal: ModalService,
        private loader: LoaderService,
        public api: Api,
        public userService: UserService,
        private modelApi: ModelApi,
        public taskService: TaskService,
        public viewer: Viewer,
        private route: ActivatedRoute,
        private authService: AuthService
    ) {
        //

        super({ taskService, api, modal, location, viewer });

        this.remarks = [];
        const now = moment(new Date());
        this.task = new TaskModel();
        this.fmt = new FMTModel();
        this.fmt.informed_date = now.format('YYYY-MM-DD HH:mm:ss');
        // this.fmt.issued_date = now.format('YYYY-MM-DD HH:mm:ss');
        // this.fmt.issued_time = now.format('HH:mm:ss');
        this.fmt.user_informer = this.authService.user;
        this.fmt.user_informer_id = this.authService.user.id;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.loader.show();
                    this.task_id = params['id'];
                    this.getTask()
                        .then(() => {
                            if (this.task && this.task.modelable_id) {
                                this.fmt_id = this.task.modelable_id;
                                this.getFMT()
                                    .then(() => {
                                        for (let i = 0; i < MAX_EQUIPMENTS; i++) {
                                            if (this.fmt && this.fmt.equipments && this.fmt.equipments[i]) {
                                                //
                                            } else {
                                                this.fmt.equipments[i] = '';
                                            }
                                        }
                                        this.loader.hide();
                                    });
                            } else {
                                for (let i = 0; i < MAX_EQUIPMENTS; i++) {
                                    if (this.fmt && this.fmt.equipments && this.fmt.equipments[i]) {
                                        //
                                    } else {
                                        this.fmt.equipments[i] = '';
                                    }
                                }
                                this.loader.hide();
                            }
                        });
                });
        }, 0);
    }

    public onDocumentUploadedSuccess(data: any): void {
        const newDocument = new DocumentModel();
        newDocument.clone(data);
        this.documents.push(newDocument);
    }

    public getDocuments() {
        const documents = [];
        for (const document of this.documents) {
            documents.push({
                native_path: document.native_path,
                mime_type: document.mime_type,
                name: document.name,
                path: document.path,
                preview: document.preview,
                thumbnail: document.thumbnail
            });
        }
        return documents;
    }

    public accept(): void {
        //
    }

    public reject(): void {
        //
    }

    public onMachineMore(e: any): void {
        this.modal.show(MachineTypeModal, {
            //
        }, {
            //
        }).then((data: any): void => {
            if (data && data.submit === true && data.item) {
                console.log(data.item);
                this.fmt.machine = data.item;
                this.fmt.machine_id = (data && data.item && data.item.modelable && data.item.modelable.id) ? data.item.modelable.id : (data && data.item && data.item.id) ? data.item.id : null;
                this.fmt.machine_type = (data && data.item && data.item.modelable) ? data.item.modelable.modelable_type : (data && data.item && data.item.id) ? 'App\\Machine' : null;
                // if (e && e.event && e.event.select) {
                //     e.event.select(data.item);
                // } else {
                // }
            }
        });
    }

    public onMachineChange(e: any): void {
        console.log(e);
        this.fmt.machine_id = (e && e.modelable_id) ? e.modelable_id : (e && e.id) ? e.id : null;
        this.fmt.machine_type = (e && e.modelable_type) ? e.modelable_type : (e && e.id) ? 'App\\Machine' : null;
    }

    public onUserInformedChange(e: any): void {
        this.fmt.user_informer_id = (e && e.id) ? e.id : null;
    }

    public onSupplierChange(e: any): void {
        this.fmt.supplier_id = (e && e.id) ? e.id : null;
    }

    public onUserCheckedChange(e: any): void {
        this.fmt.user_checked_id = (e && e.id) ? e.id : null;
    }

    public onUploadError(data: any): void {
        //
    }

    public onStartUploader(data: any): void {
        if (data && data.uploader) {
            data.uploader.cancel();
        }
        this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการอัพโหลดเอกสาร');
    }

    public getFMT(): Promise<FMTModel> {
        const promise = new Promise<FMTModel>((resolve, reject) => {
            this.api.request('fmts/' + this.fmt_id + '', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        const machine = new MachineModel();
                        const user_informer = new UserModel();
                        const user_checked = new UserModel();
                        const supplier = new SupplierModel();
                        if (response.data.machine) {
                            machine.clone(response.data.machine);
                        }
                        if (response.data.user_informer) {
                            user_informer.clone(response.data.user_informer);
                        }
                        if (response.data.user_checked) {
                            user_checked.clone(response.data.user_checked);
                        }
                        if (response.data.supplier) {
                            supplier.clone(response.data.supplier);
                        }
                        this.fmt.clone(response.data);
                        this.fmt.machine = machine;
                        this.fmt.user_informer = user_informer;
                        this.fmt.user_checked = user_checked;
                        this.fmt.supplier = supplier;
                        this.remarks = this.fmt.remarks;

                        if (this.fmt.documents && this.fmt.documents.length) {
                            for (const doc of this.fmt.documents) {
                                this.documents.push(doc);
                            }
                        }

                        resolve(this.fmt);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public viewDocumentDetail(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc,
            task: this.task
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public sendRequest(): void {
        if (this.valid()) {
            this.swal.confirm('ยืนยันการส่งคำร้องขอแจ้งซ่อม/สร้างเครื่องจักรและอุปกรณ์ใช่หรือไม่?')
                .then((result: boolean): void => {
                    this.loader.show();
                    if (result === true) {
                        this.fmt.documents = this.getDocuments();
                        this.modelApi.create(this.fmt, [
                            'document_no', 'machine_id', 'machine_type', 'type', 'informed_date', 'user_informer_id', 'issued_date', 'place_name', 'before_description', 'before_reason', 'checked_date', 'user_checked_id', 'other',
                            'supplier_id', 'can_repaired', 'estimated_repaired_at', 'equipments', 'after_description', 'warranty', 'fixed_at', 'machine_status',
                            'documents',
                        ], null, false)
                            .subscribe((response: any): void => {
                                this.taskService.createNewTask({
                                    process_slug: 'request-fmt',
                                    current_role: 'fmt',
                                    action: 'request-fmt',
                                    modelable_type: 'App\\FMT',
                                    modelable_id: response.data.id
                                }).then(() => {
                                    this.loader.hide();
                                    this.swal.success('ส่งคำร้องขอการแจ้งซ่อม/สร้างเครื่องจักรและอุปกรณ์');
                                    this.navBack();
                                }, error => {
                                    console.error(error);
                                    this.swal.danger(error);
                                    this.loader.hide();
                                });
                            }, error => {
                                console.error(error);
                                this.swal.danger(error);
                                this.loader.hide();
                            });
                    } else {
                        this.loader.hide();
                    }
                });
        } else {
            //
        }
    }

    public valid(): boolean {
        if ((this.fmt.type === 'machine_repair1' || this.fmt.type === 'machine_repair2') && !this.fmt.machine_id) {
            this.swal.danger('โปรดระบุเครื่องจักร');
            return false;
        } else if (this.fmt.type === 'other' && !this.fmt.other) {
            this.swal.danger('โปรดระบุหัวข้ออื่น ๆ');
            return false;
        } else if (!this.fmt.type) {
            this.swal.danger('โปรดระบุหัวข้อที่แจ้งเรื่อง');
            return false;
        } else if (!this.fmt.informed_date) {
            this.swal.danger('โปรดระบุวันที่แจ้งเรื่อง');
            return false;
        } else if (!this.fmt.user_informer_id) {
            this.swal.danger('โปรดระบุผู้แจ้งเรื่อง');
            return false;
        } else if (!this.fmt.issued_date) {
            this.swal.danger('โปรดระบุวันที่เริ่มเกิดปัญหา');
            return false;
        } else if (!this.fmt.before_description) {
            this.swal.danger('โปรดระบุรายละเอียดของปัญหา');
            return false;
        } else if (!this.fmt.before_reason) {
            this.swal.danger('โปรดระบุสาเหตุของปัญหา');
            return false;
        }
        return true;
    }

    public save(): void {
        //
    }

}
