import {Model} from '../now/model';
import {FormModel} from '../now/formModel';
import {VendorModel} from './vendor.model';
import {ItemModel} from './item.model';

export class PurchaseModel extends FormModel {

    public inventory_date;
    public price: number;
    public discount_price;
    public amount: number;
    public type: number;
    public discount: number;
    public description: string;
    public vendor_id: string;
    public prepared_status: number;
    public is_modelable: boolean;
    public modelable: any;
    public days: number;
    public item_description: string;
    public dateTime: string;
    public remark: string;
    public item_name: string;
    public item_amount: number;
    public item_required_at: string;
    public modelable_id: string;
    public modelable_type: string;
    public price1: number;
    public price2: number;
    public price3: number;
    public days1: number;
    public days2: number;
    public days3: number;
    public search_value: string;
    public pivot_amount: number;
    public item: ItemModel;

    public _select: boolean;

    constructor() {
        super('purchases', 'App\\Purchase');
        this.modelable = {
            item: {}
        };
    }

    public clone(data: any): Model {
        if (data && data.modelable && data.modelable.price) {
            if (!data.price) {
                this.price = data.modelable.price;
            } else {
                this.price = +data.price;
            }
            delete data.price;
        }
        return super.clone(data);
    }

    public cal_price(): void {
        //
    }

}
