import {Pipe, PipeTransform} from '@angular/core';
import {TaskModel} from '../models/task.model';

@Pipe({
    name: 'taskStatus'
})
export class TaskStatusPipe implements PipeTransform {

    transform(action: string, task: TaskModel = null, is_previous?: boolean): string {
        if (action === 'generate_quotation_no') {
            return 'ออกใบเสนอราคา';
        } else if (action === 'start_job') {
            return 'เริ่มการผลิต';
        } else if (action === 'send_confirmed_po') {
            return 'ส่งอีเมลได้รับใบคำสั่งซื้อ';
        } else if (action === 'generate_purchase_order') {
            return 'ออกใบคำสั่งซื้อ';
        } else if (action === 'generate_product_no') {
            return 'ออกหมายเลข S/N';
        } else if (action === 'load_job') {
            return 'Load Job';
        } else if (action === 'hire') {
            return 'เข้าทำงาน';
        } else if (action === 'leave') {
            return 'ออกงาน';
        } else if (action === 'request-purchase') {
            return 'ร้องขอจัดซื้อ/จัดจ้าง';
        } else if (action === 'request-purchase-2') {
            return 'ร้องขอจัดซื้อ/จัดจ้าง';
        } else if (action === 'create_requirement') {
            if (task && task.current_user_id) {
                return 'กำลังบันทึกข้อมูล';
            }
            return 'รอการบันทึกข้อมูล';
        } else if (action === 'notice_ncr') {
            if (task && task.current_user_id) {
                return 'รับทราบข้อกล่าวหา';
            }
            return 'ถูกแจ้งข้อกล่าวหา';
        } else if (action === 'notice_ncr_1') {
            if (task && task.current_user_id) {
                return 'รับทราบข้อกล่าวหา';
            }
            return 'ถูกแจ้งข้อกล่าวหา';
        } else if (action === 'notice_ncr_2') {
            if (task && task.current_user_id) {
                return 'รับทราบข้อกล่าวหา';
            }
            return 'ถูกแจ้งข้อกล่าวหา';
        } else if (action === 'notice_car') {
            if (task && task.current_user_id) {
                return 'รับทราบข้อกล่าวหา';
            }
            return 'ถูกแจ้งข้อกล่าวหา';
        } else if (action === 'notice_car_1') {
            if (task && task.current_user_id) {
                return 'รับทราบข้อกล่าวหา';
            }
            return 'ถูกแจ้งข้อกล่าวหา';
        } else if (action === 'notice_car_2') {
            if (task && task.current_user_id) {
                return 'รับทราบข้อกล่าวหา';
            }
            return 'ถูกแจ้งข้อกล่าวหา';
        } else if (action === 'request-fmt') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'ร้องขอ FMT';
        } else if (action === 'reject_request_fmt') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'ปฏิเสธงาน FMT';
        } else if (action === 'reject_leave') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'ปฏิเสธการลางาน';
        } else if (action === 'fmt-fixed') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'ตรวจสอบ/สร้างเครื่องจักรสำเร็จ';
        } else if (action === 'approved_hr') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'อนุมัติการลาสำเร็จ';
        } else if (action === 'sent_leave_request') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'ร้องขอการอนุมัติลา';
        } else if (action === 'hr') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'ร้องขอการอนุมัติลา (อีกครั้ง)';
        } else if (action === 'approve_hr') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'ร้องขอการอนุมัติลา';
        } else if (action === 'fmt-complete') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'รอผลการตรวจรับงาน';
        } else if (action === 'fmt-apply') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบข้อมูล';
            }
            return 'รอผลการตรวจรับงาน';
        } else if (action === 'create_product') {
            if (task && task.current_user_id) {
                return 'กำลังเพิ่มสินค้าใหม่';
            }
            return 'รอการเพิ่มสินค้าใหม่';
        } else if (action === 'create_quotation') {
            if (task && task.current_user_id) {
                return 'กำลังเสนอราคา';
            }
            return 'รอการเสนอราคา';
        } else if (action === 'issue') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'รอการตรวจสอบ';
        } else if (action === 'check') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'รอการตรวจสอบ';
        } else if (action === 'reject') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'ไม่ผ่านการตรวจสอบ';
        } else if (action === 'approve') {
            if (task && task.current_user_id) {
                return 'กำลังดำเนินการ';
            }
            return 'ผ่านการตรวจสอบ';
        } else if (action === 'send_quotation') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ P/O';
            }
            return 'รอการตอบรับ P/O';
        } else if (action === 'edit_quotation') {
            if (task && task.current_user_id) {
                return 'กำลังแก้ไขใบเสนอราคา';
            }
            return 'แก้ไขใบเสนอราคา';
        } else if (action === 'feedback_quotation') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'รอการตอบกลับจากลูกค้า';
        } else if (action === 'got_purchase_order') {
            if (task && task.current_user_id) {
                return 'สั่งวางแผนการผลิต';
            }
            return 'ได้รับ P/O สำเร็จ';
        } else if (action === 'create_planning') {
            if (task && task.current_user_id) {
                return 'กำลังวางแผน';
            }
            return 'รอการวางแผน';
        } else if (action === 'control') {
            if (task && task.current_user_id) {
                return 'กำลังสั่งการผลิต';
            }
            return 'สั่งผลิต';
        } else if (action === 'prepare_material') {
            if (task && task.current_user_id) {
                return 'กำลังเตรียมวัสดุ';
            }
            return 'รอการเตรียมวัสดุ';
        } else if (action === 'sent_delivery_date') {
            if (task && task.current_user_id) {
                return 'กำลังแจ้งวันส่งมอบ';
            }
            return 'รอการแจ้งวันส่งมอบ';
        } else if (action === 'feedback_process') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'รอการตอบกลับจากลูกค้า';
        } else if (action === 'create_ncr') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'เกิด NCR';
        } else if (action === 'store_check') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบวัสดุ';
            }
            return 'ตรวจสอบวัสดุก่อนผลิต';
        } else if (action === 'create_purchase_order') {
            if (task && task.current_user_id) {
                return 'กำลังจัดซื้อจัดจ้าง';
            }
            return 'รอการจัดซื้อจัดจ้าง';
        } else if (action === 'purchase_date_time') {
            if (task && task.current_user_id) {
                return 'กำลังวางแผน';
            }
            return 'แจ้งวันจัดซื้อวัสดุสำเร็จ';
        } else if (action === 'rework') {
            if (task && task.current_user_id) {
                return 'กำลังปรับแผนใหม่';
            }
            return 'ปรับแผนใหม่';
        } else if (action === 'cutting') {
            if (task && task.current_user_id) {
                return 'กำลังตัดวัสดุ';
            }
            return 'รอการตัดวัสดุ';
        } else if (action === 'prepare_cutting') {
            return 'รอเตรียมวัสดุ';
        } else if (action === 'ready_to_shipping') {
            if (task && task.current_user_id) {
                return 'กำลังส่งสินค้า';
            }
            return 'สินค้าพร้อมส่ง';
        } else if (action === 'planning_reject') {
            if (task && task.current_user_id) {
                return 'ปฏิเสธงานจาก Planning';
            }
            return 'กำลังตรวจสอบการปฏิเสธ';
        } else if (action === 'delivery') {
            return 'แจ้งวันส่งมอบ';
        } else if (action === 'creating_ncr') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'งานมีปัญหา';
        } else if (action === 'sent_notice_delivery') {
            if (task && task.current_user_id) {
                return 'รอการสั่งผลิต';
            }
            return 'แจ้งวันเลื่อนส่งมอบสำเร็จ';
        } else if (action === 'send_notice_delivery_date') {
            return 'แจ้งวันเลื่อนส่งมอบกับลูกค้า';
        } else if (action === 'create_fvd_purchase_order') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'รอการอนุมัติ P/O FVD';
        } else if (action === 'create_fvd') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'ร้องขอ FVD';
        } else if (action === 'sent_purchase_order') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบสินค้า';
            }
            return 'รอรับสินค้า';
        } else if (action === 'create_po_purchase') {
            if (task && task.current_user_id) {
                return 'กำลังส่งเอกสาร PO';
            }
            return 'รอการส่งเอกสาร PO';
        } else if (action === 'start_process') {
            if (task && task.current_user_id) {
                return 'กำลังผลิต';
            }
            return 'เริ่มกระบวนการผลิต';
        } else if (action === 'ncr_qa') {
            if (task && task.current_user_id) {
                return 'รอ QA ตรวจสอบ';
            }
            return 'QA กำลังตรวจสอบ';
        } else if (action === 'ncr') {
            if (task && task.current_user_id) {
                return 'กำลังปรับแผนใหม่ (NCR)';
            }
            return 'สั่งผลิตใหม่ (NCR)';
        } else if (action === 'reject_notice') {
            if (task && task.current_user_id) {
                return 'กำลังปรับแผนใหม่ (แจ้งเลื่อน)';
            }
            return 'ปฏิเสธการแจ้งเลื่อน';
        } else if (action === 'reject_pr') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'ปฏิเสธการรองขอจัดซื้อ/จัดจ้าง';
        } else if (action === 'notice') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบ';
            }
            return 'แจ้งเลยกำหนดวันส่งมอบ';
        } else if (action === 'create_pricing_inquiry') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบวัสดุ';
            }
            return 'ตรวจสอบวัสดุ';
        } else if (action === 'job_ignore') {
            if (task && task.current_user_id) {
                return 'กำลังวางแผน';
            }
            return 'มอบรับกำหนดเลยวันส่งมอบ';
        } else if (action === 'wait_product') {
            if (task && task.current_user_id) {
                return 'กำลังตรวจสอบสินค้า';
            }
            return 'รอรับสินค้า';
        } else if (action === 'prepared_purchase') {
            if (task && task.current_user_id) {
                return 'ได้รับวัสดุจาก PR สำเร็จ';
            }
            return 'ได้รับวัสดุจาก PR สำเร็จ';
        } else if (action === 'payment') {
            if (task && task.current_user_id) {
                return 'กำลัวตรวจสอบข้อมูล';
            }
            return 'รอการชำระสินค้า/บริการ';
        } else if (action === 'request-requisition') {
            return 'เบิกจ่ายวัตถุดิบ/วัสดุ';
        } else if (action === 'reject_request_requisition') {
            return 'ปฏิเสธการร้องขอเบิกจ่ายวัตถุดิบ/วัสดุ';
        } else if (action === 'prepared_request_requisition') {
            return 'เตรียมวัตถุดิบ/วัสดุสำเร็จ';
        } else if (action === 'trial') {
            return 'ทดลองใช้งาน';
        } else if (action === 'delivery_note_done') {
            return 'ส่งสินค้าเพื่อทดลองใช้สำเร็จ';
        } else if (action === 'close_quotation') {
            return 'ปิดการโหลดงาน';
        } else if (action === 'open_quotation') {
            return 'เปิดการโหลดงาน';
        }
        return action;
    }

}
