import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {TaskModel} from '../../models/task.model';
import {TaxInvoiceModel} from '../../models/taxInvoice.model';
import {JobModel} from '../../models/job.model';
import {SwalService} from '../../services/swal.service';
import {Api} from '../../now/api/api';
import {UploaderComponent} from '../uploader/uploader.component';
import {DocumentModel} from '../../models/document.model';
import {TaxInvoiceDetailComponent} from '../../pages/task/view/taxInvoiceDetail/taxInvoiceDetail.component';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'tax-invoice-table-component',
    templateUrl: 'taxInvoiceTable.component.html',
    styleUrls: ['taxInvoiceTable.component.scss']
})
export class TaxInvoiceTableComponent implements AfterViewInit {

    @ViewChild(UploaderComponent, { static: true }) uploader: UploaderComponent;
    @Input() taxInvoices: TaxInvoiceModel[];
    @Input() model: any;
    @Input() task: TaskModel;
    @Input() job: JobModel;

    public current_tax_invoice: TaxInvoiceModel;

    constructor(
        public viewer: Viewer,
        private modal: ModalService,
        private swal: SwalService,
        private api: Api
    ) {
        this.taxInvoices = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public createTaxInvoice(customer?): void {
        if (this.model && this.model.customer_address_id && this.model.bill_id) {
            const tax_invoice: TaxInvoiceModel = new TaxInvoiceModel();
            tax_invoice.grand_total = this.model.grand_total;
            this.modal.show(TaxInvoiceDetailComponent, {
                tax_invoice: tax_invoice,
                model: this.model,
                task: this.task,
                customer
            }, {backdrop: true, ignoreBackdropClick: true, class: 'modal-lg'})
                .then((content: any): void => {
                    if (content && content.submit === true) {
                        if (content.is_create === true) {
                            tax_invoice.clone(content.tax_invoice);
                            // this.receipts.push(receipt);
                            this.model.tax_invoices.push(tax_invoice);
                            this.viewTaxInvoicePreview(tax_invoice);
                        }
                    } else {
                        //
                    }
                });
        } else if (this.model && !this.model.customer_address_id) {
            this.swal.danger('โปรดระบุที่อยู่สำหรับจัดส่ง');
        } else if (this.model && !this.model.bill_id) {
            this.swal.danger('โปรดระบุที่อยู่เรียกเก็บบิล');
        }
    }

    public viewTaxInvoiceDetail(taxInvoice: TaxInvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(TaxInvoiceDetailComponent, {
            tax_invoice : taxInvoice,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.tax_invoice) {
                    taxInvoice.status = content.tax_invoice.status;
                }
            });
    }

    public viewTaxInvoicePreview(taxInvoice: TaxInvoiceModel, e?: any): void {
        this.viewer.taxInvoice(taxInvoice);
    }

    public attachFile(tax_invoice: TaxInvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.current_tax_invoice = tax_invoice;
        this.uploader.trigger();
    }

    public viewFile(tax_invoice: TaxInvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(tax_invoice.document_id);
    }

    public removeFile(tax_invoice: TaxInvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.swal.confirm('คุณต้องการลบเอกสารแนบใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('tax/invoices/document/delete', 'POST', {}, {
                        id: tax_invoice.id
                    }).subscribe((response: any): void => {
                        this.swal.success('ลบเอกสารแนบสำเร็จ');
                        tax_invoice.document_id = null;
                        tax_invoice.document = null;
                    });
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.current_tax_invoice && this.current_tax_invoice.id) {
            this.api.request('tax/invoices/document', 'PUT', {}, {
                document: data,
                id: this.current_tax_invoice.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.current_tax_invoice.document_id = new_document.id;
                    this.current_tax_invoice.document = new_document;
                    this.viewFile(this.current_tax_invoice);
                }
                this.current_tax_invoice = null;
            }, error => {
                this.current_tax_invoice = null;
            });
        }
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
