// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    api_host            : 'https://api.erpsiamtool.com/',
    prefix              : 'api/',
    host                : 'https://erpsiamtool.com/',
    production          : true,
    pusher_key          : '82b75ea74e9bc7cfe800',
    pusher_cluster      : 'ap1',
    schedulerLicenseKey : '0272464799-fcs-1530869183',
    sale_email          : 'sales@siamtool.co.th'
};

