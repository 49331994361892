import {Model} from '../now/model';
import {DocumentModel} from './document.model';
import {ProductModel} from './product.model';
import {ContactModel} from './contact.model';
import {CustomerModel} from './customer.model';
import {UserModel} from '../now/user/user.model';
import {JobModel} from './job.model';
import {QuotationModel} from './quotation.model';
import {PurchaseOrderModel} from './purchaseOrder.model';
import {TaxInvoiceModel} from './taxInvoice.model';
import {PackingSlipModel} from './packingSlip.model';

export class TaskModel extends Model {

    public completed_at: string;
    public contact_ref_no: string;
    public product_no: string;
    public drawing_no: string;
    public job_no: string;
    public ncr_code: string;
    public revision: string;
    public quotation_no: string;
    public purchase_order_no: string;
    public product_name: string;
    public quantity: string;
    public requested_at: string;
    public tax_invoice_no: string;

    public type;
    public user_id;
    public next_role: string;
    public assignment: UserModel;
    public assignment_id: string;

    // PROCESS, ISSUE, CHECK, APPROVE
    public role_status: string;

    public relate_task_id: string;

    public contact_ref: string;
    public name: string;
    public description: string;
    public parent_id: string;
    public children: any[];
    public product: ProductModel;
    public product_ids: any[];

    public _checked: boolean;

    public num_tasks: number;
    public to_user: UserModel;
    public to_user_id: string;

    public parent: TaskModel;
    public job_id: string;
    public contact_id: string;
    public customer_id: string;
    public product_id: string;
    public guid: string;

    public sale_product_amount: number;
    public customer_product_amount: number;
    public product_amount: number;
    public shipping_product_amount: number;

    public product_unit: string;
    public ref_no: string;

    public quotations: QuotationModel[];
    public purchase_orders: PurchaseOrderModel[];
    public purchase_order: PurchaseOrderModel;
    public documents: DocumentModel[];
    public tax_invoices: TaxInvoiceModel[];
    public packing_slips: PackingSlipModel[];
    public products: ProductModel[];

    public contact: ContactModel;
    public customer: CustomerModel;
    public user: UserModel;
    public current_user: UserModel;
    public from_user: UserModel;
    public job: JobModel;

    public issued_date: string;
    public issued_user_id: string;
    public issued_role: string;

    public checked_date: string;
    public checked_user_id: string;
    public checked_role: string;

    public approved_date: string;
    public approved_user_id: string;
    public approved_role: string;

    public rejected_date: string;
    public rejected_user_id: string;
    public rejected_role: string;

    public current_user_id: string;
    public remarks: any[];

    public started_at: string;
    public required_at: string;
    public delivery_date: string;
    public responded_at: string;

    public current_role: string;
    public process_slug: string;

    public drawing: any[];
    public po_no: string[];

    public status_before: number;
    public status: number;

    public histories: any[];
    public action: string;
    public previous_action: string;

    public modelable: any;
    public modelable_type: string;
    public modelable_id: string;

    public previous_role: string;
    public previous_process_slug: string;
    public previous_status: number;
    public hidden: boolean;
    public status_date: string;
    public modelable_data: any;
    public confirmed_delivery_date: string;

    public priority: string; // primary, normal or empty, warning, danger

    constructor() {
        super('tasks', 'App\\Task');
        this.documents          = [];
        this.products           = [];
        this.quotations         = [];
        this.purchase_orders    = [];
        this.tax_invoices       = [];
        this.packing_slips      = [];
        this.product_ids        = [];
        this.po_no              = [];
        this.children           = [];
        this.histories          = [];
        this.remarks            = [];
        this.drawing            = [];
        this.modelable          = {};

        this.purchase_order = new PurchaseOrderModel();
        this.customer = new CustomerModel();
        this.contact = new ContactModel();
        this.product = new ProductModel();
        this.to_user = new UserModel();
        this.assignment = new UserModel();

        this.hidden = false;
        this.num_tasks = 0;

        this._checked = false;
    }

    public clone(data: any): Model {
        if (data) {
            if (data.documents) {
                this.documents = [];
                for (let i = 0; i < data.documents.length; i++) {
                    const dat: any = data.documents[i];
                    if (dat && dat.id) {
                        let doc: DocumentModel;
                        doc = new DocumentModel();
                        doc.clone(dat);
                        this.documents.push(doc);
                    }
                }
                delete data.documents;
            }
            if (data.current_user) {
                this.current_user = new UserModel();
                this.current_user.clone(data.current_user);
                delete data.current_user;
            }
            if (data.user) {
                this.user = new UserModel();
                this.user.clone(data.user);
                delete data.user;
            }
            if (data.customer) {
                this.customer = new CustomerModel();
                this.customer.clone(data.customer);
                delete data.customer;
            }
            if (data.contact) {
                this.contact = new ContactModel();
                this.contact.clone(data.contact);
                delete data.contact;
            }
            if (data.from_user) {
                this.from_user = new UserModel();
                this.from_user.clone(data.from_user);
                delete data.from_user;
            }
            if (data.to_user) {
                this.to_user = new UserModel();
                this.to_user.clone(data.to_user);
                delete data.to_user;
            }
        }
        return super.clone(data);
    }

    public addProduct(product: ProductModel): void {
        let index: number;
        index = this.products.findIndex(i => i.id === product.id);
        if (index > -1) {
            //
        } else {
            this.products.push(product);
        }
    }

    public deleteDocument(doc: DocumentModel): void {
        let index: number;
        index = this.documents.findIndex(i => i.id === doc.id);
        if (index > -1) {
            this.documents.splice(index, 1);
        } else {
            //
        }
    }

    public deletePurchaseOrder(purchase_order: PurchaseOrderModel): void {
        let index: number;
        index = this.purchase_orders.findIndex(i => i.id === purchase_order.id);
        if (index > -1) {
            this.purchase_orders.splice(index, 1);
        } else {
            //
        }
    }

    public addDocument(doc: DocumentModel): void {
        let index: number;
        index = this.documents.findIndex(i => i.id === doc.id);
        if (index > -1) {
            //
        } else {
            this.documents.push(doc);
        }
    }

    public setModelable(modelable: Model): void {
        this.modelable_type = modelable.model_name;
        this.modelable_id = modelable.id;
    }

    public get valid(): boolean {
        return this.valid_documents && this.valid_information && this.valid_products;
    }

    public get valid_products(): boolean {
        if (this.products.length > 0) {
            for (let i = 0; i < this.products.length; i++) {
                let product: ProductModel;
                product = this.products[i];
                if (!product.valid) {
                    return false;
                }
            }
        } else if (this.products.length === 0) {
            return false;
        }
        return true;
    }

    public get valid_information(): boolean {
        /*if (this.name && this.description && this.started_at && this.required_at) {
            return true;
        } else {
            return false;
        }*/
        if (this.started_at && this.required_at) {
            return true;
        }
        return false;
    }

    public get valid_quotation(): boolean {
        for (let i = 0; i < this.quotations.length; i++) {
            if (this.quotations[i]) {
                if (!this.quotations[i].quotation_no) {
                    return false;
                }
            }
        }
        return true;
    }

    public get valid_purchase_order(): boolean {
        for (let i = 0; i < this.purchase_orders.length; i++) {
            if (this.purchase_orders[i]) {
                if (!this.purchase_orders[i].purchase_order_no) {
                    return false;
                }
            }
        }
        return true;
    }

    public get valid_tax_invoice(): boolean {
        for (let i = 0; i < this.tax_invoices.length; i++) {
            if (this.tax_invoices[i]) {
                if (!this.tax_invoices[i].tax_invoice_no) {
                    return false;
                }
            }
        }
        return true;
    }

    public get valid_packing_slip(): boolean {
        for (let i = 0; i < this.packing_slips.length; i++) {
            if (this.packing_slips[i]) {
                if (!this.packing_slips[i].packing_slip_no) {
                    return false;
                }
            }
        }
        return true;
    }

    public get issued(): boolean {
        // if (!this.issued_user_id && !this.issued_date && !this.issued_role) {
        if (!this.issued_date) {
            return false;
        }
        return true;
    }

    public get checked(): boolean {
        // if (!this.checked_user_id && !this.checked_date && !this.checked_role) {
        if (!this.checked_date) {
            return false;
        }
        return true;
    }

    public get approved(): boolean {
        // if (!this.approved_user_id && !this.approved_date && !this.approved_role) {
        if (!this.approved_date) {
            return false;
        }
        return true;
    }

    public get rejected(): boolean {
        // if (!this.rejected_user_id && !this.rejected_date && !this.rejected_role) {
        if (!this.rejected_date) {
            return false;
        }
        return true;
    }

    public get valid_documents(): boolean {
        if (this.documents.length > 0) {
            for (let i = 0; i < this.documents.length; i++) {
                let doc: DocumentModel;
                doc = this.documents[i];
                if (!doc.valid) {
                    return false;
                }
            }
        }
        return true;
    }

    public get drawing_nos(): string[] {
        const _: string[] = ((this.drawing_no) ? (this.drawing_no.trim()) : '').split(',');
        return _;
    }

    public get product_nos(): string[] {
        const _: string[] = ((this.product_no) ? (this.product_no.trim()) : '').split(',');
        return _;
    }

    public get product_names(): string[] {
        const _: string[] = ((this.product_name) ? this.product_name : '').split(',');
        return _;
    }

    public get purchase_order_nos(): string[] {
        const _: string[] = ((this.purchase_order_no) ? this.purchase_order_no : '').split(',');
        return _;
    }

    public get job_nos(): string[] {
        const _: string[] = ((this.job_no) ? this.job_no : '').split(',');
        return _;
    }

    public get quotation_nos(): string[] {
        const _: string[] = ((this.quotation_no) ? this.quotation_no : '').split(',');
        return _;
    }

    public get contact_ref_nos(): string[] {
        const _: string[] = ((this.contact_ref_no) ? this.contact_ref_no : '').split(',');
        return _;
    }

}
