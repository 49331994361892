import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {UserModel} from '../../now/user/user.model';
import {Api} from '../../now/api/api';
import {UserService} from '../../services/user.service';
import {TaskModel} from '../../models/task.model';
import {TaskService} from '../../services/task.service';
import {SwalService} from '../../services/swal.service';
import {AuthService} from '../../now/user/auth.service';
import {DepartmentModel} from '../../models/department.model';
import {RoleService} from '../../services/role.service';
import {RoleModel} from '../../models/role.model';

@Component({
    selector: 'approved-list-component',
    templateUrl: 'approvedList.component.html',
    styleUrls: ['approvedList.component.scss']
})
export class ApprovedListComponent implements OnInit {

    public task: TaskModel;
    public men: UserModel[];
    public user: UserModel;
    public remark_message: string;
    public roles: RoleModel[];
    public departments: DepartmentModel[];
    public next_role: string;
    public skipMessage: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private roleService: RoleService,
        private swal: SwalService,
        private authService: AuthService,
        private userService: UserService,
        private taskService: TaskService
    ) {
        //
        this.skipMessage = false;
        this.men = [];
        this.user = this.authService.user;
        this.departments = [];
    }

    ngOnInit(): void {
        this.userService.getUsers(3, false)
            .then((users: UserModel[]): void => {
                this.men = users;
            });

        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
                if (this.task && this.task.next_role) {
                    this.next_role = this.task.next_role;
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.remark_message) {
                this.remark_message = this.bsModalRef.content.remark_message;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.skipMessage) {
                this.skipMessage = (this.bsModalRef.content.skipMessage === true) ? true : false;
            }
        }, 0);
    }

    public selectPlan(): void {
        if (this.next_role) {
            this.swal.confirm('ยืนยันการส่งตรวจสอบโดยแผนก "' + this.next_role + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        if (this.task) {
                            this.issueTask(this.next_role, this.user)
                                .then(() => {
                                    if (this.skipMessage === true) {
                                        //
                                    } else {
                                        this.swal.success('ส่งตรวจสอบสำเร็จ');
                                    }
                                    this.bsModalRef.content.submit = true;
                                    this.bsModalRef.hide();
                                }, error => {
                                    //
                                });
                        } else {
                            if (this.skipMessage === true) {
                                //
                            } else {
                                this.swal.success('ส่งตรวจสอบสำเร็จ');
                            }
                            this.bsModalRef.content.role = this.next_role;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public selectRole(role: RoleModel): void {
        this.swal.confirm('ยืนยันการส่งตรวจสอบโดยแผนก "' + role.display_name + '" ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    if (this.task) {
                        this.issueTask(role.name, this.user)
                            .then(() => {
                                if (this.skipMessage === true) {
                                    //
                                } else {
                                    this.swal.success('ส่งตรวจสอบสำเร็จ');
                                }
                                this.bsModalRef.content.submit = true;
                                this.bsModalRef.hide();
                            }, error => {
                                //
                            });
                    } else {
                        if (this.skipMessage === true) {
                            //
                        } else {
                            this.swal.success('ส่งตรวจสอบสำเร็จ');
                        }
                        this.bsModalRef.content.role = role;
                        this.bsModalRef.content.submit = true;
                        this.bsModalRef.hide();
                    }
                } else {
                    //
                }
            });
    }

    public issueTask(checked_role: string, user?: UserModel, to_user?: UserModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.taskService.issue(this.task, checked_role, user, to_user, this.remark_message)
                .then(() => {
                    resolve();
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public selectMan(man: UserModel): void {
        if (man && man.id) {
            this.swal.confirm('ยืนยันการส่งตรวจสอบโดย "' + man.full_name + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        if (this.task) {
                            this.issueTask('approved', this.user, man)
                                .then(() => {
                                    if (this.skipMessage === true) {
                                        //
                                    } else {
                                        this.swal.success('ส่งตรวจสอบสำเร็จ');
                                    }
                                    this.bsModalRef.content.submit = true;
                                    this.bsModalRef.hide();
                                }, error => {
                                    //
                                });
                        } else {
                            if (this.skipMessage === true) {
                                //
                            } else {
                                this.swal.success('ส่งตรวจสอบสำเร็จ');
                            }
                            this.bsModalRef.content.man = man;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }
                    } else {
                        //
                    }
                });
        }
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            //
        } else {
            //
        }
    }

}
