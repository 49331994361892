import {DivApiDirective} from '../../../now/divApi';

export class ViewList {

    public divApi: DivApiDirective;
    public total_items: number;
    public current_page: number;
    public item_per_page: number;

    constructor(divApi?: DivApiDirective) {
        this.current_page = 1;
        this.total_items = 1;
        this.item_per_page = 100;
        //
        this.divApi = divApi;
    }

    public pageChange(page: number): void {
        this.current_page = page;
        if (this.divApi) {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        } else {
            //
        }
    }

}
