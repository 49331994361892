import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CustomerModel} from '../../models/customer.model';
import {NgForm} from '@angular/forms';
import {DocumentModel} from '../../models/document.model';
import {DrawingModel} from '../../models/drawing.model';
import {QuotationModel} from '../../models/quotation.model';
import {RequirementModel} from '../../models/requirement.model';

@Component({
    selector: 'document-component',
    templateUrl: 'document.component.html',
    styleUrls: ['document.component.scss']
})
export class DocumentComponent implements AfterViewInit {

    public customer: CustomerModel;
    public documents: DocumentModel[];
    public quotation: QuotationModel;
    public drawings: DrawingModel[];
    public requirement: RequirementModel;

    constructor(public bsModalRef: BsModalRef) {
        this.documents = [];
        this.drawings = [];
        this.quotation = new QuotationModel();
        this.customer = new CustomerModel();
        this.requirement = new RequirementModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.customer) {
                this.customer = this.bsModalRef.content.customer;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.documents) {
                this.documents = ([]).concat(this.bsModalRef.content.documents);
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.quotation) {
                this.quotation = this.bsModalRef.content.quotation;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.drawings) {
                this.drawings = this.bsModalRef.content.drawings;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.requirement) {
                this.requirement = this.bsModalRef.content.requirement;
                this.requirement.checked = true;
            }
            if (this.bsModalRef && this.bsModalRef.content) {
                // this.bsModalRef.content.submit = false;
            }
        }, 0);
    }

    public get num_documents(): number {
        let _total: number;
        _total = 0;
        for (let i = 0; i < this.documents.length; i++) {
            if (this.documents[i] && this.documents[i].checked) {
                _total++;
            }
        }
        return _total;
    }

    /*public onStartUploader(data: any): void {
        //
    }

    public onDocumentUploadedSuccess(event?: any): void {
        this.modal.show(DocumentTypeModal, {
            document            : event,
            item_requirement    : this.current_item
        }).then((content: any): void => {
            if (content && content.submit === true) {
                if (content.item_requirement_document) {
                    this.current_item.item_requirement_documents.push(content.item_requirement_document);
                }
            } else {
                //
            }
        });
    }*/

    public onSubmit(form: NgForm): void {
        this.bsModalRef.content.submit = true;
        this.bsModalRef.hide();
    }

}
