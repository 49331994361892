import {Model} from '../now/model';
import {SchedulerModel} from './scheduler.model';
import {ProcessModel} from './process.model';
import {UserModel} from '../now/user/user.model';
import {RequirementModel} from './requirement.model';
import {ProductModel} from './product.model';
import {CustomerModel} from './customer.model';
import {DocumentModel} from './document.model';
import {TaxInvoiceModel} from './taxInvoice.model';
import {PackingSlipModel} from './packingSlip.model';
import {InspectionSheetModel} from './inspectionSheet.model';
import {ProductProcessModel} from './productProcess.model';
import {ReceiptModel} from './receipt.model';
import {DeliveryOrderModel} from './deliveryOrder.model';
import {PurchaseOrderModel} from './purchaseOrder.model';
import {InvoiceModel} from './invoice.model';
import {JobRemark} from '../app/api/job_remark';
import {FVD} from '../app/api/fvd';
import {DeliveryNoteModel} from './deliveryNote.model';
import {NCR} from '../app/api/ncr';

export class JobModel extends Model {

    public width: number;
    public height: number;
    public length: number;
    public weight: number;
    public netWeight: number;

    public index: number;
    public fvds: FVD[];
    public current_ncr: NCR;
    public ncr_process_slug: string;
    public notices: any[];
    public prepared_remark: string;
    public product_processes: any[];
    public planning_remark: string;
    public quotation_id: any;
    public loaded_remark: string;
    public current_pricing_inquiry_id: string;
    public payment_id: any;
    public ignore_required_date: boolean;
    public prepared_at: string;
    public planning_started_at: string;
    public revision: number;
    public ncr_id: string;
    public job_no: string;
    public parent_id: string;
    public children: JobModel[];
    public task_id: string;
    public product_amount: number;
    public customer_id: string;
    public product_id: string;
    public status: number;
    public note: string;
    public current_role: string;
    public process_slug: string;
    public reports: any;
    public required_at: string;
    public delivery_date: any;
    public confirmed_delivery_date: string;
    public old_confirmed_delivery_date: string;
    public started_at: string;
    public current_process_status: string;
    public current_scheduler_index: number;
    public processes: ProcessModel[];
    public schedulers: SchedulerModel[];
    public current_scheduler_id: string;
    public current_scheduler: SchedulerModel;
    public next_scheduler: SchedulerModel;
    public ncr_code: number;

    public remarks: JobRemark[];

    public pull_amount: number;
    public push_amount: number;

    public rework_code: number;
    public current_ncr_id: string;

    public parent_ncr_id: string;
    public current_product_process: ProductProcessModel;
    public next_product_process: ProductProcessModel;

    public final_inspection_sheets: InspectionSheetModel[];
    public process_inspection_sheets: InspectionSheetModel[];
    public receipts: ReceiptModel[];
    public delivery_orders: DeliveryOrderModel[];
    public delivery_notes: DeliveryNoteModel[];

    public planner: UserModel;
    public planner_id: string;

    public eng_user_id: string;
    public qc_user_id: string;

    public qc_data: boolean;
    public eng_user: UserModel;
    public qc_user: UserModel;

    public purchase_orders: PurchaseOrderModel[];

    public requirement_id: string;
    public requirement: RequirementModel;

    public product: ProductModel;
    public purchase_order_no: string;
    public quotation_no: string;
    public purchase_order_date: string;

    public customer: CustomerModel;
    public documents: DocumentModel[];

    public ncr: number;
    public ncr_description: string;
    public ncr_type: string;
    public ncr_details: string;
    public ncr_equipments: any[];
    public ncr_date: string;
    public num_ncrs: number;
    public ncr_user: UserModel;

    public customer_product_amount: number;
    public sale_product_amount: number;
    public ncr_amount: number;
    public merge_job_id: string;

    public invoices: InvoiceModel[];
    public tax_invoice: TaxInvoiceModel;
    public tax_invoices: TaxInvoiceModel[];
    public packing_slips: PackingSlipModel[];

    constructor() {
        super('jobs', 'App\\Job');
        //
        this.remarks = [];
        this.current_scheduler = new SchedulerModel();
        this.next_scheduler = new SchedulerModel();
        this.schedulers = [];
        this.processes = [];
        this.requirement = new RequirementModel();
        this.product = new ProductModel();
        this.documents = [];
        this.ncr_equipments = [];
        this.tax_invoices = [];
        this.packing_slips = [];
        this.tax_invoice = new TaxInvoiceModel();
        this.process_inspection_sheets = [];
        this.final_inspection_sheets = [];
        this.receipts = [];
        this.delivery_orders = [];
        this.delivery_notes = [];
        this.children = [];
        this.purchase_orders = [];
        this.invoices = [];
        this.fvds = [];
        this.notices = [];
    }

    public clone(data: any): Model {
        if (data) {
            if (data.schedulers) {
                for (let i = 0; i < data.schedulers.length; i++) {
                    const dat: any = data.schedulers[i];
                    if (dat && dat.id) {
                        let scheduler: SchedulerModel;
                        scheduler = new SchedulerModel();
                        scheduler.clone(dat);
                        this.schedulers.push(scheduler);
                    }
                }
                delete data.schedulers;
            }
            if (data.processes) {
                for (let i = 0; i < data.processes.length; i++) {
                    const dat: any = data.processes[i];
                    if (dat && dat.id) {
                        let process: ProcessModel;
                        process = new ProcessModel();
                        process.clone(dat);
                        this.processes.push(process);
                    }
                }
                delete data.processes;
            }
            if (data.eng_user) {
                this.eng_user = new UserModel();
                this.eng_user.clone(data.eng_user);
                delete data.eng_user;
            }
            if (data.qc_user) {
                this.qc_user = new UserModel();
                this.qc_user.clone(data.qc_user);
                delete data.qc_user;
            }
            if (data.curernt_product_process) {
                this.current_product_process = new ProductProcessModel();
                this.current_product_process.clone(data.curernt_product_process);
                delete data.curernt_product_process;
            }
            if (data.next_product_process) {
                this.next_product_process = new ProductProcessModel();
                this.next_product_process.clone(data.next_product_process);
                delete data.next_product_process;
            }
            if (data.customer) {
                this.customer = new CustomerModel();
                this.customer.clone(data.customer);
                delete data.customer;
            }
            if (data.ncr_user) {
                this.ncr_user = new UserModel();
                this.ncr_user.clone(data.ncr_user);
                delete data.ncr_user;
            }
            if (data.planner) {
                this.planner = new UserModel();
                this.planner.clone(data.planner);
                delete data.planner;
            }
        }
        super.clone(data);
        return this;
    }

}
