import {AfterViewInit, Component, Inject, NgZone, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AccountModel} from '../../../models/account.model';
import {ProductModel} from '../../../models/product.model';
import {TaskModel} from '../../../models/task.model';
import {UserService} from '../../../services/user.service';
import {CustomerModel} from '../../../models/customer.model';
import {CustomerAddressModel} from '../../../models/customerAddress.model';
import {Api} from '../../../now/api/api';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {environment} from '../../../environments/environment';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {IncotermService} from '../../../services/incoterm.service';
import {CarrierService} from '../../../services/carrier.service';
import * as moment from 'moment';
import {TaskService} from '../../../services/task.service';
import {PurchaseOrderDetailComponent} from '../view/purchaseOrderDetail/purchaseOrderDetail.component';
import {ModalService} from '../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../now/user/auth.service';
import {ViewTask} from '../view/viewTask';
import {DocumentComponent} from '../../../modals/document/document.component';
import {DrawingModel} from '../../../models/drawing.model';
import {PaymentModel} from '../../../models/payment.model';
import {TypeaheadComponent} from '../../../components/typeahead/typeahead.component';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {ReportModalComponent} from '../../../modals/reportModal/reportModal.component';
import {DepositModel} from '../../../models/deposit.model';
import {DepositDetailComponent} from '../view/depositDetail/depositDetail.component';
import {ContactModel} from '../../../models/contact.model';
import {BillModel} from '../../../models/bill.model';
import {RequirementModel} from '../../../models/requirement.model';
import {AddRemarkComponent} from '../view/addRemark/addRemark.component';
import {DocumentModel} from '../../../models/document.model';
import {LoaderService} from '../../../components/loader/loader.service';
import {JobService} from '../../../services/job.service';
import {Viewer} from '../../../services/viewer';

const MAX_PRODUCT = 10;
const DEFAULT_PRICE_VALIDITY = 30;

@Component({
    selector: 'account-task-component',
    templateUrl: 'accountTask.component.html',
    styleUrls: ['accountTask.component.scss'],
    providers: [DecimalPipe]
})
export class AccountTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChildren(TypeaheadComponent) typeaheadComponents: QueryList<TypeaheadComponent>;

    public payment_channel;
    public started_at;
    public account: AccountModel;
    public account_id: string;
    public sale: UserModel;
    public customerAddress: CustomerAddressModel;
    public tmp_customer: CustomerModel;
    public customer: CustomerModel;
    public tmp_product: any;
    public tmp_customer_address: any;
    public tmp_contact: any;
    public tmp_sale: any;
    public current_tab: string;
    public incoterms: string[];
    public carriers: CarrierModel[];
    public tmp_incoterm: any;
    public tmp_carrier: any;
    public tmp_currency: any;
    public currency_list: any[];
    public tmp_contact_ref: any;
    public new_tasks: TaskModel[];
    public products: ProductModel[];
    public documents = [];
    public remarks: any[] = [];

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private loader: LoaderService,
        private swal: SwalService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private ngZone: NgZone,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        public location: Location,
        private route: ActivatedRoute,
        private jobService: JobService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.payment_channel = '';
        this.started_at = '';
        this.current_tab = '#information';
        this.tmp_customer = new CustomerModel();
        // this.customer = new CustomerModel();
        this.sale = new UserModel();
        this.customerAddress = new CustomerAddressModel();
        this.tmp_product = {};
        this.tmp_customer_address = {};
        this.tmp_sale = new UserModel();
        this.incoterms = [];
        this.carriers = [];
        this.tmp_incoterm = {};
        this.tmp_carrier = {};
        this.tmp_currency = {};
        this.tmp_contact_ref = {};
        this.products = [];

        this.new_tasks = [];

        this.getNewTasks()
            .then(() => {});
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.task = new TaskModel();
                    this.account = new AccountModel();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTask()
                            .then(() => {
                                this.account_id = this.task.modelable_id;
                                this.getAccount()
                                    .then(() => {
                                        this.tmp_currency = {
                                            id: this.account.currency,
                                        };
                                        this.customerAddress = this.account.customer_address;
                                        this.ready = true;
                                    });
                            });
                    }
                    this.account.task_id = this.task_id;
                });

            this.getIncoterms();
            this.getCarriers();
            this.getCurrencies();
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public createDeposit(): void {
        const deposit: DepositModel = new DepositModel();
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            account   : this.account,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    // this.account.deposits.push(deposit);
                } else {
                    //
                }
            });
    }

    public onDiscountPercentChange(e?: any): void {
        setTimeout(() => {
            // this.account.discount_price = Math.round(this.account.material_price * (this.account.discount_percent / 100) * 100) / 100;
            this.account.cal_total_price();
        }, 0);
    }

    public onDiscountPriceChange(e?: any): void {
        setTimeout(() => {
            // this.account.discount_percent = Math.round(this.account.discount_price * (100 / this.account.material_price) * 100) / 100;
            this.account.cal_total_price();
        }, 0);
    }

    public getAccount(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.account.products) {
                this.account.products.splice(0, this.account.products.length);
            } else {
                this.account.products = [];
            }
            if (this.account_id) {
                this.api.request('accounts/' + this.account_id, 'GET')
                    .subscribe((response: any): void => {
                        if (response.data) {
                            const customer = new CustomerModel();
                            if (response.data.customer) {
                                customer.clone(response.data.customer);
                            }
                            this.account.clone(response.data);
                            this.account.customer = customer;
                            this.payment_channel = this.account.payment_channel;
                            this.started_at = this.account.started_at;
                            this.remarks = this.account.remarks;
                        }
                        this.documents = [];
                        for (let i = 0; i < this.account.delivery_notes.length; i++) {
                            if (this.account.delivery_notes[i] && this.account.delivery_notes[i].id) {
                                const deliveryNote = this.account.delivery_notes[i];
                                this.documents.push({
                                    type: 'deliveryNote',
                                    id: deliveryNote.id,
                                    index: 1,
                                    document_no: deliveryNote.delivery_note_no,
                                    delivery_date: deliveryNote.delivery_date,
                                    due_date: deliveryNote.due_date,
                                    balance: deliveryNote.balance,
                                    grand_total: deliveryNote.grand_total
                                });
                            } else {
                                // deliveryNote = new ProductModel();
                                // deliveryNote.index = i;
                                // deliveryNote.pivot.wh = 'TH';
                                // this.account.tax_invoices.push(deliveryNote);
                            }
                            // product.cal_price();
                        }
                        for (let i = 0; i < this.account.tax_invoices.length; i++) {
                            if (this.account.tax_invoices[i] && this.account.tax_invoices[i].id) {
                                const taxInvoice = this.account.tax_invoices[i];
                                this.documents.push({
                                    type: 'taxInvoice',
                                    id: taxInvoice.id,
                                    index: 1,
                                    document_no: taxInvoice.tax_invoice_no,
                                    delivery_date: taxInvoice.delivery_date,
                                    due_date: taxInvoice.due_date,
                                    balance: taxInvoice.balance,
                                    grand_total: taxInvoice.grand_total
                                });
                            } else {
                                // deliveryNote = new ProductModel();
                                // deliveryNote.index = i;
                                // deliveryNote.pivot.wh = 'TH';
                                // this.account.tax_invoices.push(deliveryNote);
                            }
                            // product.cal_price();
                        }
                        if (!this.account.sale || !this.account.sale.id) {
                            this.account.sale = new UserModel();
                            this.account.sale.clone(this.authService.user);
                            this.account.sale_id = this.authService.user.id;
                        }
                        if (this.account && !this.account.shipping) {
                            this.account.shipping = {};
                        }
                        // this.account.cal_total_price();
                        resolve(this.account);
                    }, error => {
                        reject(error);
                    });
            } else {
                this.account = new AccountModel();
                this.account.currency = 'THB';
                this.account.currency_rate = 1;
                for (let i = 0; i < MAX_PRODUCT; i++) {
                    let product: ProductModel;
                    product = new ProductModel();
                    product.index = i;
                    product.pivot.wh = 'TH';
                    this.account.products.push(product);
                }
                this.account.price_validity = DEFAULT_PRICE_VALIDITY;
                resolve(this.account);
            }
        });
        return promise;
    }

    public getNewTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks', 'GET', {
                process_slug: 'estimate',
                status: 9
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat = response.data[i];
                        if (dat) {
                            let task: TaskModel;
                            task = new TaskModel();
                            task.clone(dat);
                            this.new_tasks.push(task);
                        }
                    }
                }
                resolve(this.new_tasks);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private getCurrencies(): void {
        this.api.request('assets/json/currencies.json', 'GET', null, null, null, environment.host, '')
            .subscribe((response: any): void => {
                this.currency_list = [];
                if (response && response.results) {
                    for (const key in response.results) {
                        if (key) {
                            const value: any = response.results[key];
                            this.currency_list.push(value);
                        }
                    }
                }
            }, error => {
                //
            });
    }

    private getCurrencyRate(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const current_currency_code: string = this.account.currency;
            this.api.request('currency/covert', 'GET', {
                from: 'THB',
                to: current_currency_code
            }).subscribe((response: any): void => {
                if (response && response.data && response.data['THB_' + current_currency_code]) {
                    resolve(response.data['THB_' + current_currency_code].val);
                }
            }, error => {
                reject(error);
            });
            /*this.api.request('convert?q=THB_' + current_currency_code + '&compact=y',
            'GET', null, null, null, 'http://free.currencyconverterapi.com/', 'api/v5/')
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });*/
        });
        return promise;
    }

    private getIncoterms(): void {
        this.incotermService.getIncoterms()
            .then((incoterms: IncotermModel[]): void => {
                this.incoterms = [];
                for (const incoterm of incoterms) {
                    this.incoterms.push(incoterm.name);
                }
            });
    }

    private getCarriers(): void {
        this.carrierService.getCarriers()
            .then((carriers: CarrierModel[]): void => {
                this.carriers = carriers;
            });
    }

    public onProductDelete(product: ProductModel, e?: any): void {
        if (product) {
            product.name = '';
            product.market_price = 0;
            product.price = 0;
            product.customer_product_amount = 0;
            product.description = '';
        } else {
            //
        }
    }

    public onProductSelect(product: ProductModel, data: any): void {
        if (!product.wh) {
            product.wh = 'TH';
        }
        // if (!product.placeholder_price) {
        //     product.placeholder_price = Math.max(product.total_estimated_price, product.total_market_price);
        // }
    }

    private task_account_clone(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/clone', 'POST', {}, {
                action: 'edit_account',
                id: this.task.id
            }).subscribe((response: any): void => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private generate_no(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (!this.account.account_no) {
                this.api.request('accounts/no', 'POST', {
                    task_id: this.task.id,
                    id: this.account.id
                }).subscribe((response: any): void => {
                    if (response && response.data && response.data.account_no) {
                        this.account.account_no = response.data.account_no;
                        resolve(this.account);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            } else {
                resolve(this.account);
            }
        });
        return promise;
    }

    public editAccount(): void {
        if (this.account.account_no) {
            this.swal.confirm('คุณต้องการแก้ไขใบกำกับภาษี "' + this.account.account_no + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.task_account_clone()
                            .then((response: any): void => {
                                if (response && response.data && response.data.id) {
                                    this.taskService.hire(response.data, null, true)
                                        .then((hire_response: any): void => {
                                            // Go Go!
                                        }, error => {
                                            //
                                        });
                                } else {
                                    //
                                }
                            });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public createAccount(): void {
        if (this.account) {
            if (!this.account.customer_address_id && this.tmp_customer_address && this.tmp_customer_address.id) {
                this.account.customer_address_id = this.tmp_customer_address.id;
            }
            // if (!this.account.sale_id && this.tmp_sale && this.tmp_sale.id) {
            //     this.account.sale_id = this.tmp_sale.id;
            // }
            if (!this.account.customer || !this.account.customer.id) {
                if (this.tmp_customer && this.tmp_customer.id) {
                    this.account.customer = new CustomerModel();
                    this.account.customer.clone(this.tmp_customer);
                }
            }
        }
        if (!this.account.customer || !this.account.customer.id) {
            this.swal.danger('ไม่สามารถออกใบกำกับภาษีได้เนื่องจากไม่ได้ระบุข้อมูลลูกค้า');
            this.current_tab = '#information';
        // } else if (!this.account.customer_address_id) {
        //     this.swal.danger('ไม่สามารถออกใบกำกับภาษีได้เนื่องจากไม่ได้ระบุข้อมูลการส่งสินค้า');
        //     this.current_tab = '#shipping';
        } else if (!this.account.sale_id) {
            this.swal.danger('ไม่สามารถออกใบกำกับภาษีได้เนื่องจากไม่ได้ระบุข้อมูลผู้ดำเนินการ');
            this.current_tab = '#information';
        } else {
            this.viewAccount(this.account);
        }
    }

    public onSelectSaleContactRef(event: any): void {
        if (this.account.requirement
            && this.account.requirement.id
            && this.account.requirement.customer
            && this.account.requirement.customer.id) {
            //
            this.account.customer_id = this.account.requirement.customer.id;
            this.account.customer.clone(this.account.requirement.customer);
            this.task.customer_id = this.account.customer_id;
            if (this.account.customer.addresses && this.account.customer.addresses[0]
                && this.account.customer.addresses[0].id) {
                //
                this.account.customer_address.clone(this.account.customer.addresses[0]);
                this.account.customer_address_id = this.account.customer.addresses[0].id;
            }
        }
    }

    public addProduct(): void {
        let product: ProductModel;
        product = new ProductModel();
        product.index = this.account.products.push(product) - 1;
        product.pivot.wh = 'TH';
        product.cal_price();
        this.account.cal_total_price();
    }

    public addRemark(product: ProductModel): void {
        this.modal.show(RemarkModalComponent, {
            description: product.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                product.description = content.description;
                this.save(true);
            } else {
                //
            }
        });
    }

    private send_job_no(task_ids: string[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (task_ids && task_ids.length > 0) {
                this.api.request('mail/job', 'POST', {}, {
                    task_ids: task_ids
                }).subscribe(() => {
                    resolve();
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    /*private create_planning(product_ids: any, payment?: PaymentModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/products', 'PUT', null, {
                payment_id      : (payment) ? payment.id : null,
                id              : this.task.id,
                product_ids     : product_ids,
                current_role    : 'pd-planning',
                process_slug    : 'planning',
                previous_role   : 'estimate',
                customer_id     : this.task.customer_id,
                action          : 'create_planning',
                parent_id       : this.task.id,

                // payment
                // required_at     : payment.required_at,
                // started_at      : payment.started_at
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    resolve(response.data);
                } else {
                    resolve(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }*/

    /*public complete(): void {
        if (!this.account.payments || this.account.payments.length === 0) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากยังไม่ได้รับใบสั่งซื้อสินค้าเข้าระบบ')
                .then(() => {
                    this.current_tab = '#purchase_order';
                    this.pageScrollService.start(PageScrollInstance.simpleInstance(this.document, '#purchase_order'));
                });
        } else if (!this.account.validPayment()) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากหลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง')
                .then(() => {
                    this.current_tab = '#purchase_order';
                    this.pageScrollService.start(PageScrollInstance.simpleInstance(this.document, '#purchase_order'));
                });
        } else {
            this.modal.show(SelectPaymentComponent, {
                payments: this.account.payments
            }, { class: 'modal-lg' }).then((content: any): void => {
                if (content && content.checked_payments && content.is_submit === true) {
                    this.create_planning(content.checked_products)
                        .then((planning_response: any): void => {
                            this.send_job_no(planning_response)
                                .then(() => {
                                    this.swal.success('ส่งต่อกระบวนการการวางแผนการผลิตสินค้าสำเร็จ')
                                        .then(() => {
                                            //
                                        });
                                });
                        });
                } else {
                    //
                }
            });
        }
    }*/

    public warning(): void { // task status is 12
        this.modal.show(ReportModalComponent, {
            task: this.task
        }).then((content: any): void => {
            if (content && content.is_submit === true) {
                this.taskService.setStatus(this.task, this.task.status, 'feedback_account')
                    .then(() => {
                        this.swal.success('รอการตอบกลับจากลูกค้าภายหลัง');
                    }, error => {
                        this.swal.danger(error);
                    });
            } else {
                //
            }
        });
    }

    public rejectAccount(): void {
        /*let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ยืนยันใบกำกับภาษีไม่ผ่านการอนุมัติสำเร็จ');
                        this.leave(true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;*/
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.taskService.reject(this.task)
                        .then(() => {
                            this.swal.success('แจ้งใบกำกับภาษีไม่ผ่านการอนุมัติสำเร็จ');
                            this.leave(true);
                        }, error => {
                            //
                        });
                    return;
                }
                //
            });
    }

    public approveAccount(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this._save()
                        .then(() => {
                            this.taskService.approve(this.task)
                                .then(() => {
                                    this.swal.success('ผ่านการอนุมัติใบกำกับภาษีสำเร็จ');
                                    this.leave(true);
                                }, error => {
                                    //
                                });
                        });
                } else {
                    //
                }
            });
    }

    public viewDepositDetail(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            account   : this.account,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public viewPaymentDetail(payment: PaymentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PurchaseOrderDetailComponent, {
            payment     : payment,
            account   : this.account,
            task        : this.task,
            products    : this.account.products
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.checked_products && content.is_submit === true) {
                    if (payment.verified === true) {
                        this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order')
                            .then(() => {
                                //
                            });
                        //
                        payment.status = 1;
                        this.swal.success('ส่งต่อกระบวนการการวางแผนการผลิตสินค้าสำเร็จ')
                            .then(() => {
                                //
                            });
                    } else {
                        //
                    }
                } else {
                    //
                }
            });
    }

    public onPaymentUploadedSuccess(data: any): void {
        this.api.request('accounts/payment', 'PUT', {}, {
            task_id: this.task.id,
            document: data,
            id: this.account.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_payment: PaymentModel;
                new_payment = new PaymentModel();
                new_payment.clone(response.data);
                this.account.payments.push(new_payment);
                this.viewPaymentDetail(new_payment);
            }
        }, error => {
            //
        });
    }

    public onPaymentUploadError(data: any): void {
        //
    }

    public onPaymentSelect(product: ProductModel, e?: any): void {
        //
    }

    public onPaymentDelete(product: ProductModel, e?: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('accounts/document', 'PUT', {}, {
            document    : data,
            id          : this.account.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.account.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public createProduct(product_id?: string, force?: boolean): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'estimate';
        task.current_role = 'estimate';
        task.action = 'create_product';
        task.modelable_type = 'App\\Product';
        if (product_id) {
            task.modelable_id = product_id;
        } else {
            //
        }
        this.taskService.hire(task, null, ((!force) ? false : true), task.action, task.action)
            .then(() => {
                // Go Go!
            });
    }

    private getDrawings(): DrawingModel[] {
        this.account.document.checked = true;
        let drawings: DrawingModel[];
        drawings = [];
        for (let i = 0; i < this.account.products.length; i++) {
            const product: ProductModel = this.account.products[i];
            /*if (product && product.documents && product.documents.length > 0) {
                documents = documents.concat(this.account.products[i].documents);
            }*/
            if (product && product.drawings && product.drawings.length > 0) {
                for (let j = 0; j < product.drawings.length; j++) {
                    const drawing: DrawingModel = product.drawings[j];
                    if (drawing && drawing.document) {
                        drawing.checked = true;
                        drawings.push(drawing);
                    }
                }
            }
        }
        return drawings;
    }

    public verifyAccount(): void {
        if (this.account.account_no && this.account.customer) {
            super.issueTask()
                .then(() => {
                    this.leave(true);
                });
        } else {
            //
        }
    }

    private send_receipt() {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('mail/account', 'POST', null, {
                task_id         : this.task.id,
                account_id      : this.account.id,
            }).subscribe((response: any): void => {
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public complete(): void {
        this.swal.confirm('ยืนยันการจัดเก็บงาน "' + this.account.account_no + '" ใช่หรือไม่?', true)
            .then((result: boolean): void => {
                if (result === true) {
                    this.loader.show();
                    this.api.request('accounts/complete', 'POST', {}, {
                        task_id: this.task_id,
                        account_id: this.account_id
                    }).subscribe(res => {
                        this.swal.success('จัดเก็บงาน "' + this.account.account_no + '"' + ' สำเร็จ')
                            .then(() => {
                                //
                            });
                        this.loader.hide();
                        this.leave(true);
                    });
                    // this.taskService.complete(this.task)
                    //     .then((task_response: any): void => {
                    //         this.swal.success('จัดเก็บงาน "' + this.account.account_no + '"' + ' สำเร็จ')
                    //             .then(() => {
                    //                 //
                    //             });
                    //         this.loader.hide();
                    //         this.leave(true);
                    //     });
                }
            });
    }

    public duplicate(ac?) {
        const text = 'คุณต้องการออกใบเสร็จรับเงินใหม่ ใช่หรือไม่?';
        this.swal.confirm(text, false)
            .then((result: boolean): void => {
                if (result === true) {
                    this.loader.show();
                    this.api.request('accounts/duplicate', 'POST', {
                        id: this.account_id,
                    }).subscribe(res => {
                        this.loader.hide();
                        this.swal.success('ออกใบเสร็จรับเงินใหม่สำเร็จ');
                        this.leave(true);
                    }, error => {
                        console.warn(error);
                        this.loader.hide();
                    });
                } else {
                    //
                }
            });
    }

    public restore(): void {
        const text = 'คุณต้องการนำกลับใบเสร็จรับเงินเลขที่ "' + this.account.account_no + '" ใช่หรือไม่?';
        this.swal.confirm(text, false)
            .then((result: boolean): void => {
                if (result === true) {
                    this.loader.show();
                    this.api.request('accounts/restore', 'POST', {
                        id: this.account_id,
                    }).subscribe(res => {
                        this.loader.hide();
                        this.swal.success('นำกลับสำเร็จ');
                        this.account.status = 1;
                    }, error => {
                        console.warn(error);
                        this.loader.hide();
                    });
                } else {
                    //
                }
            });
    }

    public cancel(remark?: string): void {
        const text = 'คุณต้องการยกเลิกใบเสร็จรับเงินเลขที่ "' + this.account.account_no + '" ใช่หรือไม่?';
        this.swal.confirm(text, false)
            .then((result: boolean): void => {
                if (result === true) {
                    this.loader.show();
                    this.api.request('accounts/cancel', 'POST', {}, {
                        id: this.account_id,
                        remark,
                        task_id: this.task_id,
                    }).subscribe(res => {
                        this.loader.hide();
                        this.swal.success('ยกเลิกใบเสร็จสำเร็จ');
                        this.account.status = 2;
                        this.leave(true);
                    }, error => {
                        console.warn(error);
                        this.loader.hide();
                    });
                } else {
                    //
                }
            });
    }

    public sendAccount(): void {
        // if (!this.payment_channel) {
        //     this.swal.danger('โปรดระบุช่องทางการชำระ');
        //     return;
        // } else if (!this.started_at) {
        //     this.swal.danger('โปรดระบุวันที่ออกใบเสร็จรับเงิน');
        //     return;
        // }

        if (!this.started_at) {
            this.swal.danger('โปรดระบุวันที่ออกใบเสร็จรับเงิน');
        } else if (this.account && this.account.account_no) {
            if (this.account.customer.email) {
                this.swal.confirm('คุณต้องการส่งใบเสร็จรับเงิน <span>"' + this.account.account_no + '"</span><br/>' +
                    ' กับลูกค้า <i>' + this.account.customer.name + '</i><br/>' +
                    ' ผ่านทางอีเมล <strong class="text-primary">"' + this.account.customer.email + '"</strong>' +
                    ' ใช่หรือไม่?', true)
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.loader.show();
                            this.send_receipt()
                                .then(() => {
                                    this.swal.success('ส่งใบเสร็จรับเงิน "' + this.account.account_no + '"' +
                                        ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                        .then(() => {
                                            this.account.sent_date = true;
                                        });
                                    this.loader.hide();
                                }, error => {
                                    this.swal.danger('ไม่สามารถส่งใบเสร็จรับเงินให้กับลูกค้าได้ กรุณาลองใหม่อีกครั้ง');
                                    this.loader.hide();
                                });
                        } else {
                            //
                        }
                    });
            } else {
                this.swal.input('กรุณากรอกอีเมลลูกค้าสำหรับการส่งใบเสร็จรับเงิน', 'ข้อมูลอีเมล', 'อีเมล', 'ส่งใบเสร็จรับเงิน')
                    .then((result: any): void => {
                        if (result) {
                            this.api.request('customers/email', 'POST', {}, {
                                id: this.task.customer_id,
                                email: result
                            }).subscribe((customer_response: any): void => {
                                if (customer_response && customer_response.success === false) {
                                    this.swal.danger(customer_response.message);
                                } else {
                                    this.loader.show();
                                    this.send_receipt()
                                        .then(() => {
                                            this.swal.success('ส่งใบเสร็จรับเงิน "' + this.account.account_no + '"' +
                                                ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                                .then(() => {
                                                    this.account.sent_date = true;
                                                });
                                            this.loader.hide();
                                        }, error => {
                                            this.swal.danger('ไม่สามารถส่งใบเสร็จรับเงินให้กับลูกค้าได้ กรุณาลองใหม่อีกครั้ง');
                                            this.loader.hide();
                                        });
                                }
                            }, error => {
                                this.swal.danger(error);
                            });
                        } else {
                            //
                        }
                    }, error => {
                        //
                    });
            }
        }
    }

    public onSelectTypeaheadCurrency(data: any): void {
        this.account.currency_symbol = data.currencySymbol;
        this.account.currency = data.id;
        if (this.account && this.account.currency) {
            this.getCurrencyRate()
                .then((response: any): void => {
                    this.account.currency_rated_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    this.account.currency_rate = response;
                });
        }
    }

    /*public onSelectTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (model && data) {
            model.clone(data);
        }
        if (model_parent && field_name) {
            model_parent[field_name] = data.id;
        }
    }

    public onSelectTypeaheadProduct(model: any, data: any, model_parent?: any, field_name?: string): void {
        this.onSelectTypeahead(model, data, model_parent, field_name);
        if (model) {
            model.amount = 1;
            model.price = Math.max(model.market_price, model.price, model.cost);
        }
        this.account.cal_total_price();
    }

    public onSelectCustomerTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (data && data.addresses && data.addresses.length > 0) {
            this.tmp_customer_address = data.addresses[0];
        }
        this.onSelectTypeahead(model, data, model_parent, field_name);
    }*/

    public onBillTypeaheadDeleted(event?: any): void {
        this.account.bill = new BillModel();
    }

    public onSaleTypeaheadDeleted(event?: any): void {
        this.account.sale = new UserModel();
    }

    public onCustomerTypeaheadDeleted(event?: any): void {
        this.account.customer = new CustomerModel();
        this.account.customer.credit_terms = 0;

        this.account.contact = new ContactModel();
        this.account.customer_address = new CustomerAddressModel();
    }

    public onCustomerAddressTypeaheadDeleted(event?: any): void {
        this.account.customer_address = new CustomerAddressModel();
    }

    public onCustomerSelect(data: any): void {
        if (data && data.addresses && data.addresses.length > 0) {
            this.account.customer_address.clone(data.addresses[0]);
            this.account.customer_address_id = data.addresses[0].id;
        } else {
            //
        }
        if (data && data.bills && data.bills.length > 0) {
            this.account.bill.clone(data.bills[0]);
            this.account.bill_id = data.bills[0].id;
        } else {
            //
        }
    }

    public onBlur(e: any): void {
        if (this.account) {
            this.account.cal_total_price();
        }
    }

    public productPriceChange(product: ProductModel, e?: any): void {
        //
    }

    public onProductPriceBlur(e?: any): void {
        // this.vatUpdate();
    }

    public onModelChanged(e?: any): void {
        this.ngZone.run(() => {
            if (this.account) {
                this.account.cal_total_price();
            }
        });
    }

    private getCheckedProducts(): any {
        let checked_products: any[];
        checked_products = [];
        let product_length: number;
        product_length = 0;
        for (let i = 0; i < this.account.products.length; i++) {
            const _product: ProductModel = this.account.products[i];
            if (_product && _product.id) {
                product_length++;
                checked_products.push({
                    customer_product_amount : _product.customer_product_amount,
                    purchase_order_no       : _product.purchase_order_no,
                    amount                  : _product.amount,
                    price                   : _product.price,
                    price_per_unit          : _product.price_per_unit,
                    description             : _product.description,
                    // processing_times        : _product.processing_times,
                    wh                      : _product.wh,
                    product_id              : _product.id
                });
            }
        }
        return checked_products;
    }

    public archive(): void {
        this.taskService.archive(this.task)
            .then(() => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public onRemarkModalSubmit(event) {
        const remark = event.remark_message;
        this.cancel(remark);
    }

    public onSelectIncotermCode(e: any): void {
        if (e && e.name) {
            this.account.incoterm_code = e.name;
        } else {
            this.account.incoterm_code = e;
        }
    }

    public save(skip?: boolean): void {
        if (skip === true) {
            //
        } else {
            this.loader.show();
        }
        this._save(skip)
            .then(() => {
                if (skip === true) {
                    //
                } else {
                    this.swal.success('ทำการบันทึกข้อมูลสำเร็จ', null, 2000);
                    this.loader.hide();
                }
            }, error => {
                if (skip === true) {
                    //
                } else {
                    this.swal.danger(error);
                    this.loader.hide();
                }
            });
    }

    private create_or_update_sale(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.account.sale && this.account.sale.id) {
                this.modelApi.update(this.account.sale, ['full_name', 'telephone', 'email'], 'users/contact')
                    .subscribe((result: any): void => {
                        resolve(this.account.sale);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private create_or_update_contact(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.tmp_contact && this.tmp_contact.id) {
                resolve(this.tmp_contact);
            } else if (this.tmp_contact && this.tmp_contact.full_name) {
                this.tmp_contact.customer_id = this.task.customer_id;
                this.modelApi.create(this.tmp_contact, ['full_name', 'position', 'telephone', 'email', 'customer_id'], 'customers/contact')
                    .subscribe((result: any): void => {
                        resolve(this.tmp_contact);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private _save(skip?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const documents = this.getDocuments();
            console.log(this.account);
            this.api.request('accounts', 'POST', {}, {
                id: this.account_id,
                tax_invoices: documents.tax_invoices,
                // empty_rows: (this.account && this.account.empty_rows) ? this.account.empty_rows : 0,
                delivery_notes: documents.delivery_notes,
                payment_channel: this.payment_channel,
                started_at: this.started_at,
                customer_id: (this.account.customer) ? this.account.customer.id : '',
                contact_id: (this.account.contact) ? this.account.contact.id : '',
                bill_id: (this.account.bill) ? this.account.bill.id : '',
                customer_address_id: (this.account.customer_address) ? this.account.customer_address.id : '',
                carrier_id: (this.account.carrier) ? this.account.carrier.id : '',
                incoterm_code: (this.account.incoterm_code) ? this.account.incoterm_code : '',
                currency_symbol: (this.account.currency_symbol) ? this.account.currency_symbol : '',
                currency: (this.account.currency) ? this.account.currency : '',
                currency_rate: (this.account.currency_rate) ? this.account.currency_rate : '',
                currency_rated_at: (this.account.currency_rated_at) ? this.account.currency_rated_at : '',
                // frt_sh: (this.account.frt_sh) ? this.account.frt_sh : '',
                // misc_chgs: (this.account.misc_chgs) ? this.account.misc_chgs : '',
                price_validity: (this.account.price_validity) ? this.account.price_validity : '',
            }).subscribe((account_response: any): void => {
                if (account_response && account_response.success === true) {
                    resolve();
                } else {
                    reject(account_response.message);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onCarrierChange(e: any): void {
        this.account.carrier_id = (e && e.id) ? e.id : null;
    }

    public onSaleChange(e: any): void {
        this.account.sale_id = (e && e.id) ? e.id : null;
    }

    public onCustomerChange(e: any): void {
        this.account.customer_id = (e && e.id) ? e.id : null;
    }

    public onCustomerBillChange(e: any): void {
        this.account.bill_id = (e && e.id) ? e.id : null;
    }

    public onCustomerAddressChange(e: any): void {
        this.account.customer_address_id = (e && e.id) ? e.id : null;
    }

    public onContactChange(e: any): void {
        this.account.contact_id = (e && e.id) ? e.id : null;
    }

    private getDocuments() {
        const documents = {
            delivery_notes: [],
            tax_invoices: []
        };
        for (const document of this.documents) {
            if (document) {
                if (document.type === 'taxInvoice') {
                    documents.tax_invoices.push({
                        id: document.id,
                        delivery_date: document.delivery_date,
                        due_date: document.due_date,
                        balance: document.balance,
                        amount: document.amount,
                    });
                } else if (document.type === 'deliveryNote') {
                    documents.delivery_notes.push({
                        id: document.id,
                        delivery_date: document.delivery_date,
                        due_date: document.due_date,
                        balance: document.balance,
                        amount: document.amount,
                    });
                }
            }
        }
        return documents;
    }

    private getTaxInvoices() {
        const tax_invoices = [];
        for (const tax_invoice of this.account.tax_invoices) {
            if (tax_invoice) {
                tax_invoices.push({
                    id: tax_invoice.id,
                    delivery_date: tax_invoice.delivery_date,
                    due_date: tax_invoice.due_date,
                    balance: tax_invoice.balance,
                });
            }
        }
        return tax_invoices;
    }

    public vatUpdate(e?: any): void {
        setTimeout(() => {
            const vat_percent: number = (this.account.vat_percent) ? this.account.vat_percent : 0;
            for (let i = 0; i < this.account.products.length; i++) {
                const product: ProductModel = this.account.products[i];
                /*if (product && product.id) {
                    if (this.account.is_include_vat === true) {
                        product.price_per_unit = Math.round(product.price / (1 + (vat_percent / 100)));
                        product.vat_price = Math.round(product.price - product.price_per_unit);
                    } else {
                        product.price_per_unit = Math.round(product.price * (1 + (vat_percent / 100)));
                        product.vat_price = Math.round(product.price * (vat_percent / 100));
                    }
                } else {
                    //
                }*/
            }
        }, 0);
    }

    public nextProcess(): void {
        if (!this.account.payments || this.account.payments.length === 0) {
            this.swal.danger('ยังไม่ได้รับใบสั่งซื้อสินค้าเข้าระบบ');
        } else if (!this.account.validPayment()) {
            this.swal.danger('หลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง');
        } else {
            this.loader.show();
            this._save()
                .then(() => {
                    this.loader.hide();
                    this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order', 'proforma', 'proforma')
                        .then(() => {
                            this.leave(true);
                            this.swal.success('ส่งต่อให้กับแผนก Sale Admin สำเร็จ');
                        }, error => {
                            this.swal.danger(error);
                        });
                });
        }
    }

    public get total_price(): number {
        if (this.account) {
            this.account.cal_total_price();
            return this.account.grand_total;
        }
        return 0;
    }

    public onSaveApiCallingBack(e: any): void {
        console.log(e);
        this.save(true);
    }

}
