import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TaskModel} from '../../../models/task.model';
import {CustomerModel} from '../../../models/customer.model';
import {ContactModel} from '../../../models/contact.model';
import {ProductModel} from '../../../models/product.model';
import {DocumentModel} from '../../../models/document.model';
import {DivApiDirective} from '../../../now/divApi';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {UserModel} from '../../../now/user/user.model';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../../now/user/auth.service';
import {QuotationModel} from '../../../models/quotation.model';
import {SwalService} from '../../../services/swal.service';
import {UserService} from '../../../services/user.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {PusherService} from '../../../services/pusher.service';
import {ModalService} from '../../../services/modal.service';
import {CarrierModel} from '../../../models/carrier.model';
import {JobModel} from '../../../models/job.model';
import {SchedulerModel} from '../../../models/scheduler.model';
import * as moment from 'moment';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {SchedulerHistoryModel} from '../../../models/schedulerHistory.model';
import {ViewTask} from '../view/viewTask';
import {DOCUMENT, Location} from '@angular/common';
import {PageScrollService} from 'ngx-page-scroll-core';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'delivery-task-component',
    templateUrl: 'deliveryTask.component.html',
    styleUrls: ['deliveryTask.component.scss']
})
export class DeliveryTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;

    public schedulers: SchedulerModel[];
    public job_id: string;
    public product_id: string;
    public product: ProductModel;
    public task_id: string;
    public customer: CustomerModel;
    public contact: ContactModel;
    public current_user: UserModel;
    public from_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public documents: DocumentModel[];
    public quotations: QuotationModel[];
    public purchase_orders: PurchaseOrderModel[];
    public tax_invoices: any[];
    public carriers: CarrierModel[];
    public current_role: string;
    public pdfInfo: any;
    public numPages: number[];
    public scheduler_histories: SchedulerHistoryModel[];
    public current_job_document_path: string;

    public job: JobModel;
    public planning_events: any[];

    public current_tab: string;
    public product_index: number;
    public current_part_product_index: number;
    public is_ready: boolean;

    public ready: boolean;
    public options: any;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private ngZone: NgZone,
        public api: Api,
        public modal: ModalService,
        public pageScrollService: PageScrollService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        public location: Location,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.schedulers = [];
        this.is_ready = false;
    }

    private init(): void {
        this.task = new TaskModel();
        this.contact = new ContactModel();
        this.product = new ProductModel();
        this.product_index = -1;
        this.current_part_product_index = -1;

        this.job = new JobModel();
        this.ready = false;
        this.planning_events = [];
        this.documents = [];
        this.options = {
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'month'
            },
            events: []
        };

        this.numPages = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTaskDetail();
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public onDeliveryDateChange(e: any): void {
        setTimeout(() => {
            this.refreshCalendar();
        }, 0);
    }

    public sendDelivery(): void {
        this.swal.confirm('ยืนยันการแจ้งวันส่งมอบสินค้าใหม่กับลูกค้าใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this._save()
                        .then(() => {
                            this.api.request('mail/delivery', 'POST', null, {
                                task_id: this.task.id
                            }).subscribe((response: any): void => {
                                if (response && response.success === true) {
                                    this.taskService.setStatus(this.task, 2, 'sent_delivery', null, null, true)
                                        .then(() => {
                                            this.swal.success('แจ้งวันส่งมอบสินค้ากับลูกค้าสำเร็จ');
                                            this.leave(true);
                                        });
                                } else {
                                    this.swal.danger(response.message);
                                }
                            }, error => {
                                this.swal.danger(error);
                            });
                        });
                } else {
                    //
                }
            });
    }

    private _save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs', 'POST', {}, {
                id: this.job.id,
                task_id: this.task.relate_task_id,
                confirmed_delivery_date: this.job.confirmed_delivery_date
            }).subscribe((response: any): void => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.task.clone(response.data);
                    // this.disabledRoleService.set(this.task, ['pd-planning', 'sale-manager', 'qa'], ['estimate']);
                    this.onSuccess(response.data);
                } else {
                    //
                }
            }, error => {
                this.onError(error);
            });
    }

    public addConfirmedDeliveryDate(date: string): any {
        const date_moment_obj: any = moment(date, 'YYYY-MM-DD HH:mm:ss');
        return {
            title: 'ส่งมอบสินค้า',
            start: date_moment_obj,
            end: date_moment_obj,
            className: 'event-solid'
        };
    }

    public onSuccess(data: any): void {
        this.job_id = this.task.modelable_id;
        this.getJob(this.job_id)
            .then(() => {
                if (this.job && this.job.product) {
                    if (this.job && !this.job.confirmed_delivery_date) {
                        if (moment.isMoment(this.job.delivery_date)) {
                            this.job.confirmed_delivery_date = this.job.delivery_date.add(3, 'days')
                                .format('YYYY-MM-DD HH:mm:ss');
                        } else if (this.job.delivery_date) {
                            const delivery_date: any = this.job.delivery_date;
                            this.job.confirmed_delivery_date = moment((delivery_date.date) ? delivery_date.date : delivery_date
                                , 'YYYY-MM-DD HH:mm:ss')
                                .add(3, 'days')
                                .format('YYYY-MM-DD HH:mm:ss');
                        }
                    }
                    this.product_id = this.job.product_id;
                    this.getProductDetail()
                        .then(() => {
                            this.refreshCalendar();
                            this.viewer.manufacture(this.job, false, this.job.revision)
                                .then(path => {
                                    this.current_job_document_path = path;
                                    this.is_ready = true;
                                });
                        });
                } else {
                    //
                }
            });
    }

    public refreshCalendar(): void {
        if (this.schedulers) {
            this.schedulers.splice(0, this.schedulers.length);
        } else {
            this.schedulers = [];
        }
        if (this.options.events) {
            this.options.events.splice(0, this.options.events.length);
        } else {
            this.options.events = [];
        }
        for (let i = 0; i < this.product.product_processes.length; i++) {
            const product_process = this.product.product_processes[i];
            if (product_process && product_process.checked && product_process.revision === this.job.revision) {
                for (let j = 0; j < product_process.schedulers.length; j++) {
                    const scheduler = product_process.schedulers[j];
                    if (scheduler && scheduler.checked && scheduler.revision === this.job.revision) {
                        const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                        const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                        const event_scheduler: any = {
                            title: ((product_process.is_fvd) ? '(FVD)' : '') + product_process.process.name,
                            start: start,
                            end: end,
                        };
                        this.schedulers.push(event_scheduler);
                        this.options.events.push(event_scheduler);
                    }
                }
            }
        }
        this.options.events.push(this.addConfirmedDeliveryDate(this.job.confirmed_delivery_date));
        if (this.is_ready === true) {
            this.fullCalendarComponent.removeEvents();
            this.fullCalendarComponent.addEventSource(this.options.events);
            this.fullCalendarComponent.rerenderEvents();
        }
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onError(e: any): void {
        //
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.task.modelable_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public onPdfLoadCompleted(e: any): void {
        //
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

}
