import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ViewRequest} from '../view/viewRequest';
import {DecimalPipe, Location} from '@angular/common';
import {SwalService} from '../../../services/swal.service';
import {ModalService} from '../../../services/modal.service';
import {TaskModel} from '../../../models/task.model';
import {Api} from '../../../now/api/api';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {AuthService} from '../../../now/user/auth.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {TaskService} from '../../../services/task.service';
import {MaterialCreateModal} from '../../task/view/product/materialCreate/materialCreate.modal';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {Remark} from '../../../app/api/remark';
import {StoreModel} from '../../../models/store.model';
import {SchedulerHistoryModel} from '../../../models/schedulerHistory.model';
import {ItemModel} from '../../../models/item.model';
import {PurchaseModel} from '../../../models/purchase.model';
import {Viewer} from '../../../services/viewer';
import {JobModel} from '../../../models/job.model';
import {PurchaseRequisitionModel} from '../../../models/purchaseRequisition.model';
import {CustomerModel} from '../../../models/customer.model';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {ApprovedListComponent} from '../../../modals/approvedList/approvedList.component';
import {UserService} from '../../../services/user.service';
import {DocumentModel} from '../../../models/document.model';

const MAX_STORE_ITEMS = 5;

@Component({
    selector: 'request-purchase-task-component',
    templateUrl: 'requestPurchaseTask.component.html',
    styleUrls: ['requestPurchaseTask.component.scss'],
    providers: [DecimalPipe]
})
export class RequestPurchaseTaskComponent extends ViewRequest implements AfterViewInit {

    @ViewChild('remarkModal', { static : true }) remarkModal: RemarkModal;

    public store: StoreModel;
    public purchase_requisition: PurchaseRequisitionModel;
    public scheduler_histories: SchedulerHistoryModel[];
    public remarks: Remark[];
    public tmp_customer: CustomerModel;
    public tmp_job: JobModel;
    public documents: any[] = [];

    constructor(
        public userService: UserService,
        public viewer: Viewer,
        public location: Location,
        public api: Api,
        public route: ActivatedRoute,
        public modelApi: ModelApi,
        public swal: SwalService,
        public taskService: TaskService,
        public authService: AuthService,
        public modal: ModalService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.tmp_job = new JobModel();
        this.tmp_customer = new CustomerModel();
        this.task = new TaskModel();
        this.scheduler_histories = [];
        this.remarks = [];
        this.purchase_requisition = new PurchaseRequisitionModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.getTask()
                            .then(() => {
                                this.purchase_requisition.id = this.task.modelable_id;
                                this.getRequisition()
                                    .then(purchase_requisition => {
                                        this.purchase_requisition = purchase_requisition;
                                        this.remarks = this.purchase_requisition.remarks;
                                        if (!this.remarks) {
                                            this.remarks = [];
                                        }
                                        this.init();
                                    });
                            });
                    } else {
                        this.init();
                    }
                });
        });
    }

    public addMoreItem(): void {
        const item = new PurchaseModel();
        if (this.purchase_requisition && this.purchase_requisition.id && this.purchase_requisition.items && this.purchase_requisition.items[0] && this.purchase_requisition.items[0].id) {
            if (this.purchase_requisition.items[0].is_modelable) {
                item.is_modelable = true;
            } else {
                item.is_modelable = false;
            }
        } else {
            item.is_modelable = true;
        }
        this.purchase_requisition.items.push(item);
    }

    public init(): void {
        const numItems = (this.purchase_requisition && this.purchase_requisition.items && this.purchase_requisition.items.length) ? this.purchase_requisition.items.length : MAX_STORE_ITEMS;
        const maxStoreItems = Math.max(MAX_STORE_ITEMS, numItems);
        for (let i = 0; i < maxStoreItems; i++) {
            // let store_item: ItemModel;
            let item: PurchaseModel;
            item = new PurchaseModel();
            if (this.purchase_requisition && this.purchase_requisition.items && this.purchase_requisition.items[i] && this.purchase_requisition.items[i].id) {
                item.clone(this.purchase_requisition.items[i]);
                item.index = i;
                item.is_modelable = (item.is_modelable === false) ? false : true;
                item.pivot_amount = item.pivot.amount;
                this.purchase_requisition.items[i] = item;
            } else {
                item.is_modelable = true;
                item.index = i;
                item.pivot.vendor = {};
                item.pivot.vendor1 = {};
                this.purchase_requisition.items.push(item);
            }
        }
        if (this.purchase_requisition && (!this.purchase_requisition.request_man || !this.purchase_requisition.request_man.id)) {
            this.purchase_requisition.request_man = this.authService.user;
            this.purchase_requisition.request_man_id = this.authService.user.id;
        }

        if (this.purchase_requisition.documents && this.purchase_requisition.documents.length) {
            for (const doc of this.purchase_requisition.documents) {
                this.documents.push(doc);
            }
        }

        this.ready = true;
    }

    public onCustomerChange(event: any) {
      this.purchase_requisition.customer_id = (event && event.id) ? event.id : null;
    }

    public viewPurchaseRequisition() {
        this.viewer.purchaseRequisition(this.purchase_requisition);
    }

    public getRequisition(): Promise<PurchaseRequisitionModel> {
        let promise: Promise<PurchaseRequisitionModel>;
        promise = new Promise<PurchaseRequisitionModel>((resolve, reject) => {
            this.api.request('purchase/requisitions/' + this.purchase_requisition.id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    let purchase_requisition: PurchaseRequisitionModel;
                    purchase_requisition = new PurchaseRequisitionModel();
                    purchase_requisition.clone(response.data);
                    // for (response.data.items && response.data.items.length) {
                    //     for (const item_dat of response.data.items) {
                    //         if (item_dat) {
                    //             if (!purchase_requisition.items) {
                    //                 purchase_requisition.items = [];
                    //             }
                    //             purchase_requisition.items.push({
                    //
                    //             });
                    //         }
                    //     }
                    // }
                    if (response.data.request_man && response.data.request_man.id) {
                        let man: UserModel;
                        man = new UserModel();
                        man.clone(response.data.request_man);
                        purchase_requisition.request_man = man;
                    } else {
                        purchase_requisition.request_man = new UserModel();
                    }
                    if (response.data.job && response.data.job.id) {
                        const job = new JobModel();
                        job.clone(response.data.job);
                        purchase_requisition.job = job;
                        this.tmp_job = job;
                    } else {
                        purchase_requisition.job = new JobModel();
                    }
                    // if (response.data.customer && response.data.customer.id) {
                    //     const customer = new CustomerModel();
                    //     customer.clone(response.data.customer);
                    //     purchase_requisition.customer = customer;
                    //     this.tmp_customer = customer;
                    // } else {
                    //     purchase_requisition.customer = new CustomerModel();
                    // }
                    if (purchase_requisition && purchase_requisition.customer && purchase_requisition.customer.id) {
                        this.tmp_customer = purchase_requisition.customer;
                    }

                    if (this.purchase_requisition.documents && this.purchase_requisition.documents.length) {
                        for (const doc of this.purchase_requisition.documents) {
                            this.documents.push(doc);
                        }
                    }

                    resolve(purchase_requisition);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onJobChange(e: any): void {
        this.purchase_requisition.job_id = (e && e.id) ? e.id : null;
    }

    public onManChange(e: any): void {
        this.purchase_requisition.request_man_id = (e && e.id) ? e.id : null;
    }

    public onVendor1Change(item, index, event) {
        item.pivot.vendor1_id = (event && event.id) ? event.id : null;
    }

    public onVendor2Change(item, index, event) {
        item.pivot.vendor2_id = (event && event.id) ? event.id : null;
    }

    public onVendor3Change(item, index, event) {
        item.pivot.vendor3_id = (event && event.id) ? event.id : null;
    }

    public onDocumentUploadError(event?: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        const newDocument = new DocumentModel();
        newDocument.clone(data);
        this.documents.push(newDocument);
    }

    public getDocuments() {
        const documents = [];
        for (const document of this.documents) {
            documents.push({
                native_path: document.native_path,
                mime_type: document.mime_type,
                name: document.name,
                path: document.path,
                preview: document.preview,
                thumbnail: document.thumbnail
            });
        }
        return documents;
    }

    public showMaterialDetail(material: any): void {
        if (material && material.id) {
            this.modal.show(MaterialCreateModal, {
                viewable        : true,
                editable        : false,
                type            : material.type,
                item_id         : material.id,
                item_name       : material.search_value,
                material_model  : material,
                current_item    : material
            }).then((data: any): void => {
                //
            });
        } else {
            //
        }
    }

    public onMaterialSelect(item: any, index, e: any): void {
        console.log(item);
        console.log(e);
        if (e && e.id) {
            item.id = e.id;
            item.is_modelable = true;
            item.pivot = {
                vendor1: {}
            };
            e.pivot = {
                vendor1: {}
            };
        } else {
            item = {
                pivot: {
                    vendor1: {}
                },
                is_modelable: true
            };
        }
        setTimeout(() => {
            if (index > -1) {
                this.purchase_requisition.items[index].is_modelable = true;
            }
        }, 100);
    }

    public onDeleteItem(item, event) {
        item = {
            pivot: {
                vendor1: {}
            },
            is_modelable: true
        };
    }

    public addRemark(storeItem: any): void {
        this.modal.show(RemarkModalComponent, {
            description: (storeItem.pivot && storeItem.pivot.remark) ? storeItem.pivot.remark : ''
        }).then((content: any): void => {
            if (content && content.submit === true) {
                storeItem.pivot.remark = content.description;
                // this.save();
            } else {
                //
            }
        });
    }

    public valid_materials(): boolean {
        for (let i = 0; i < this.purchase_requisition.items.length; i++) {
            const item = this.purchase_requisition.items[i];
            if (item && item.id && !item.pivot_amount) {
                this.swal.danger('โปรดระบุจำนวน "' + item.search_value + '" ที่ต้องการจะจัดซื้อ/จัดจ้าง');
                return false;
            }
        }
        let found: boolean;
        found = false;
        for (let i = 0; i < this.purchase_requisition.items.length; i++) {
            const item = this.purchase_requisition.items[i];
            if (item && item.id && item.is_modelable) {
                found = true;
            } else if (item && !item.is_modelable && item.item_description) {
                found = true;
            }
        }
        if (found === false) {
            this.swal.danger('โปรดเลือกรายการวัตถุดิบ/วัสดุที่ต้องการจะจัดซื้อ/จัดจ้าง');
            return false;
        }
        return true;
    }

    public sendToApprove(): void {
        if (this.valid_materials()) {
            this.swal.confirm('ยืนยันการส่งการตรวจสอบขอการจัดซื้อ/จัดจ้างใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.remarkModal.open();
                    }
                });
        } else {
            //
        }
    }

    public sendRequest(): void {
        if (this.valid_materials()) {
            this.swal.confirm('ยืนยันการส่งคำร้องขอการจัดซื้อ/จัดจ้างใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        if (this.purchase_requisition.rejected_at) {
                            this.remarkModal.open();
                        } else {
                            this.purchase_requisition.items = this.getCheckedMaterials();
                            if (this.purchase_requisition && this.purchase_requisition.id && this.task.id) {
                                /*this.api.request('purchase/requisitions', 'POST', {
                                    ref_no: this.purchase_requisition.ref_no,
                                    job_id: this.purchase_requisition.job_id,
                                    customer_id: this.purchase_requisition.customer_id,
                                    objective: this.purchase_requisition.objective,
                                    objective_other: this.purchase_requisition.objective_other,
                                    items: this.purchase_requisition.items,
                                    place_name: this.purchase_requisition.place_name,
                                    required_at: this.purchase_requisition.required_at
                                }).subscribe(() => {
                                    /!*this.modal.show(ApprovedListComponent, {
                                        task: this.task
                                    }, {
                                        class: 'modal-lg'
                                    }).then((content: any): void => {
                                        if (content && content.submit === true) {
                                            resolve();
                                        } else {
                                            reject();
                                        }
                                    });*!/
                                    // this.taskService.setStatus(this.task, this.task.status, 'request-purchase-2', 'request-purchase-2', 'request-purchase-2', false)
                                    //     .then(() => {
                                    //         this.swal.success('ส่งคำร้องขอสำเร็จ');
                                    //         this.navBack();
                                    //     });
                                });*/
                            } else {
                                const documents = this.getDocuments();
                                this.purchase_requisition.documents = documents;
                                this.modelApi.create(this.purchase_requisition, [
                                    'documents', 'ref_no', 'job_id', 'customer_id', 'request_man_id', 'objective', 'objective_other', 'items', 'place_name', 'required_at',
                                    // new
                                    'tax_invoice_no', 'delivery_no', 'purchase_order_no', 'other_ref_no', 'inventory_date',
                                ], null, false)
                                    .subscribe((response: any): void => {
                                        this.purchase_requisition.id = response.data.id;
                                        this.taskService.createNewTask({
                                            process_slug    : 'request-purchase-2',
                                            current_role    : 'approved',
                                            action          : 'request-purchase-2',
                                            modelable_type  : 'App\\PurchaseRequisition',
                                            modelable_id    : this.purchase_requisition.id
                                        }).then(task => {
                                            this.modal.show(ApprovedListComponent, {
                                                task
                                            }, {
                                                class: 'modal-lg'
                                            }).then((content: any): void => {
                                                if (content && content.submit === true) {
                                                    this.swal.success('ส่งคำร้องขอสำเร็จ');
                                                    this.navBack();
                                                }
                                            });
                                        });
                                    });
                            }
                        }
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public onMaterialMoreHandler(material: any, e: any): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : false,
            editable        : false,
            submit_text     : 'เลือก',
            item_id         : material.id,
            item_name       : material.search_value,
            material_model  : material,
            current_item    : material
        }, {
            class: 'modal-xl'
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e && e.event && e.event.select) {
                    e.event.select(data.item);
                } else {
                    //
                }
            }
        });
    }

    public onMaterialCreating(material: PurchaseModel, e: any): void {
        setTimeout(() => { // add delay when model to select
            this.onClickCreateCuttingMaterial(material.item, e);
        }, 0);
    }

    public onClickCreateCuttingMaterial(item: ItemModel, e: any): void {
        // create a item into store
        console.log(item);
        this.modal.show(MaterialCreateModal, {
            // editable        : (this.editable === true) ? true : false,
            warehouse       : false,
            editable        : true,
            viewable        : true,
            type            : 'frw',
            submit_text     : 'เลือกเพื่อเบิก',
            item_name       : (item.search_value) ? item.search_value : item.name,
            current_item    : item,
            material_model  : item
        }, {
            class: 'modal-md'
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e && e.event && e.event.select) {
                    e.event.select(data.item);
                } else {
                    //
                }
            }
        });
    }

    public purchase(store_item: any): void {
        //
    }

    public onRemarkModalSubmit(event: any) {
        const items = this.getCheckedMaterials();
        this.api.request('purchase/requisitions', 'POST', {}, {
            documents: this.getDocuments(),
            id: this.purchase_requisition.id,
            ref_no: this.purchase_requisition.ref_no,
            job_id: this.purchase_requisition.job_id,
            customer_id: this.purchase_requisition.customer_id,
            objective: this.purchase_requisition.objective,
            objective_other: this.purchase_requisition.objective_other,
            items: items,
            place_name: this.purchase_requisition.place_name,
            required_at: this.purchase_requisition.required_at,
            rejected_at: '',
            reject_user_id: '',
            tax_invoice_no: this.purchase_requisition.tax_invoice_no,
            delivery_no: this.purchase_requisition.delivery_no,
            purchase_order_no: this.purchase_requisition.purchase_order_no,
            other_ref_no: this.purchase_requisition.other_ref_no,
            inventory_date: this.purchase_requisition.inventory_date,
        }).subscribe(() => {
            const remark_message = (event && event.remark_message) ? event.remark_message : null;
            this.modal.show(ApprovedListComponent, {
                task: this.task,
                remark_message
            }, {
                class: 'modal-lg'
            }).then((content: any): void => {
                if (content && content.submit === true) {
                    this.swal.success('ส่งคำร้องขอสำเร็จ');
                    this.navBack();
                }
            });
        });
    }

    public getCheckedMaterials(): any[] {
        let returned_data: any[];
        returned_data = [];
        for (let i = 0; i < this.purchase_requisition.items.length; i++) {
            const material: any = this.purchase_requisition.items[i];
            if (material && ((material.id && material.is_modelable) || (!material.is_modelable && material.item_description))) {
                returned_data.push({
                    product_material_id : null,
                    item_id             : material.id,
                    is_modelable        : material.is_modelable,
                    item_description    : (!material.is_modelable) ? material.item_description : null,
                    vendor1_id          : material.pivot.vendor1_id,
                    vendor2_id          : material.pivot.vendor2_id,
                    vendor3_id          : material.pivot.vendor3_id,
                    days1               : material.days1,
                    days2               : material.days2,
                    days3               : material.days3,
                    price1              : material.price1,
                    price2              : material.price2,
                    price3              : material.price3,
                    amount              : material.pivot_amount,
                    remark              : (material.pivot && material.pivot.remark) ? material.pivot.remark : '',
                });
            }
        }
        return returned_data;
    }

    public save() {
        if (this.purchase_requisition && this.purchase_requisition.id) {
            const items = this.getCheckedMaterials();
            this.api.request('purchase/requisitions', 'POST', {}, {
                id: this.purchase_requisition.id,
                ref_no: this.purchase_requisition.ref_no,
                job_id: this.purchase_requisition.job_id,
                customer_id: this.purchase_requisition.customer_id,
                objective: this.purchase_requisition.objective,
                objective_other: this.purchase_requisition.objective_other,
                items: items,
                place_name: this.purchase_requisition.place_name,
                required_at: this.purchase_requisition.required_at,
                rejected_at: '',
                reject_user_id: ''
            }).subscribe(() => {
                //
            });
        }
    }

    public onSaveApiCallingBack(e: any): void {
        this.save();
    }

}
