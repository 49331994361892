import {AfterViewInit, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TaskModel} from '../../models/task.model';
import {Api} from '../../now/api/api';
import {PusherService} from '../../services/pusher.service';
import {AppService} from '../../app/app.service';
import {Subscription} from 'rxjs';
import {TaskService} from '../../services/task.service';
import {RoleModel} from '../../models/role.model';
import {ModalService} from '../../services/modal.service';
import * as moment from 'moment';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {Viewer} from '../../services/viewer';

declare var window: any;
const MAX_ROWS = 5;

@Component({
    selector: 'app-dashboard-component',
    templateUrl: 'dashboard.component.html',
    styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('dashboardTableFixed', { static: false }) dashboardTableFixed: ElementRef;
    @ViewChild('dashboardTable', { static: false }) dashboardTable: ElementRef;

    public ready: boolean;
    public config: PerfectScrollbarConfigInterface;
    public pageWidth: string;

    public current_zoom: number;
    public filter_text: string;

    public current_document_path: string;
    public current_task: TaskModel;

    public now: any;
    public now_string: string;

    public num_tasks: number;
    public all_tasks_handle: any[];
    public all_tasks_idle: any[];
    public all_roles: RoleModel[];
    public num_handle_cells: any;
    public num_idle_cells: any;

    public num_idle_tasks: number;
    public num_handle_tasks: number;

    public all_tasks_idle_to_show: any;
    public all_tasks_handle_to_show: any;

    public num_search: number;

    public keyword: string;
    public personal_tasks: TaskModel[];

    private apiSubscription: Subscription;

    public rowData: any[];
    public columnDefs: any[];

    public gridApi: any;
    public gridColumnApi: any;
    public getRowHeight: any;
    public cellStyle: any;
    public cellRenderer: any;
    public timeout: any;

    constructor(
        private router: Router,
        private api: Api,
        private taskService: TaskService,
        private appService: AppService,
        private modal: ModalService,
        private pusherService: PusherService,
        private ngZone: NgZone,
        public viewer: Viewer,
        public pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        this.num_search = 0;
        this.ready = false;
        this.current_zoom = 100;

        this.all_tasks_handle = [];
        this.all_tasks_idle = [];
        this.num_tasks = 0;
        this.num_idle_tasks = 0;
        this.num_handle_tasks = 0;

        this.apiSubscription = new Subscription();

        this.all_roles = [];
        this.personal_tasks = [];

        this.pageWidth = 'calc(100vw - 16px)';
        this.config = {
            useBothWheelAxes: false,
            suppressScrollX: false,
            suppressScrollY: true
        };

        this.now_string = moment(new Date()).format('D MMMM YYYY');
        this.now = moment(new Date());

        this.columnDefs = [];
        this.rowData = [];
        this.getRowHeight = (params) => {
            if (params && params.data && params.data.rowHeight) {
                return params.data.rowHeight;
            } else {
                return 30;
            }
        };
        this.cellStyle = (params) => {
            if (params.value && params.value.type === 'idle') {
                return {
                    'background-color': '#f5f7f7'
                };
            } else if (params.value && params.value.status === 12) {
                return {
                    'background-color': '#ff0000'
                };
            } else {
                return {
                    'background-color': '#ffffff'
                };
            }
        };
        this.cellRenderer = (params) => {
            const value: any = params.value;
            if (value && value.type === 'task') {
                if (value.modelable_type === 'App\\Requirement') {
                    return this.mediaDisplay(value.user)
                        + ((value.contact_ref_no && value.contact_ref_no !== '') ? value.contact_ref_no : '');
                } else if (value.modelable_type === 'App\\Job') {
                    return this.mediaDisplay(value.user)
                        // + ((value.is_fvd) ? 'FVD<br/>' : '')
                        + ((value.job_no && value.job_no !== '') ? value.job_no + '<br>' : '') + '';
                        // + ((value.purchase_order_no && value.purchase_order_no !== '') ? value.purchase_order_no : '');
                } else if (value.modelable_type === 'App\\Quotation') {
                    return this.mediaDisplay(value.user)
                        + ((value.contact_ref_no && value.contact_ref_no !== '') ? value.contact_ref_no + '<br>' : '') + ''
                        + ((value.quotation_no && value.quotation_no !== '') ? value.quotation_no + '<br>' : '') + ''
                        + ((value.purchase_order_no && value.purchase_order_no !== '') ? value.purchase_order_no : '');
                } else if (value.modelable_type === 'App\\Product') {
                    return this.mediaDisplay(value.user)
                        + ((value.product_name && value.product_name !== '') ? value.product_name + '<br>' : '') + ''
                        + ((value.product_no && value.product_no !== '') ? value.product_no : '');
                } else if (value.modelable_type === 'App\\PricingInquiry') {
                    return this.mediaDisplay(value.user)
                        + ((value.job_no && value.job_no !== '') ? value.job_no : '');
                } else if (value.modelable_type === 'App\\FVD') {
                    return this.mediaDisplay(value.user)
                        + ((value.fvd_no && value.fvd_no !== '') ? value.fvd_no + '<br>' : '') + ''
                        + ((value.job_no && value.job_no !== '') ? value.job_no : '');
                } else {
                    return this.mediaDisplay(value.user)
                        + ((value.ref_no && value.ref_no !== '') ? value.ref_no + '<br>' : '') + ''
                        + ((value.job_no && value.job_no !== '') ? value.job_no : '');
                }
            } else if (value && value.type === 'idle') {
                let text: string;
                // text = '<span style="opacity: .5;color: #333 !important;">KUMBAN</span>';
                text = '';
                if (value.num_tasks > 0) {
                    text += '&nbsp;<u style="color: #333 !important;">' + (value.num_tasks <= 100) ? value.num_tasks : 'มากกว่า 100 งาน' + '</u>';
                }
                return text;
            }
            return value;
        };
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.appService.dashboardComponent = this;
            this.getRoles()
                .then(() => {
                    //
                });
        }, 0);
    }

    ngAfterViewInit(): void {
        this.appService.fixPage();
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
        this.unsubscribe();
        // this.pusherService.unsubscribe('erpst.task');
        // this.pusherService.unsubscribe('erpst.dashboard');
        this.appService.dashboardComponent = this;
        this.document.body.style = 'zoom:100%';
        this.current_zoom = 100;
        this.clear_timeout();
    }

    private clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public zoom(value: number): void {
        const _value: number = this.current_zoom + value;
        const ratio: number = 100 / _value;
        this.document.body.style = 'zoom:' + _value + '%';
        this.current_zoom = _value;
    }

    public toggleFullscreen(): void {
        if (!this.document.fullscreenElement) {
            this.document.documentElement.requestFullscreen();
        } else {
            this.document.exitFullscreen();
        }
    }

    public onCellClicked(e?: any): void {
        const value: any = e.value;
        if (value && value.task_id) {
            this.viewTask({
                id: value.task_id,
                modelable_type: value.modelable_type,
                modelable_id: value.modelable_id,
                revision: value.revision,
                current_role: value.current_role,
                process_slug: value.process_slug
            });
        }
    }

    public onGridReady(e: any): void {
        this.gridApi = e.api;
        this.gridColumnApi = e.columnApi;
    }

    public refresh(): void {
        if (this.gridApi) {
            this.gridApi.api.setRowData(this.rowData);
        }
    }

    public findHighLight(): void {
        const cells: any[] = this.document.querySelectorAll('.ag-cell');
        for (let i = 0; i < cells.length; i++) {
            const cell: any = cells[i];
            if (cell && cell.innerHTML
                && this.filter_text
                && ((cell.innerHTML).toString().toLowerCase()).indexOf(this.filter_text.toLowerCase()) > -1) {
                //
                cell.style.backgroundColor = '#2196f3';
                cell.style.color = '#ffffff';
            } else {
                cell.style.backgroundColor = '#ffffff';
                cell.style.color = '#2196f3';
            }
        }
    }

    public getRoles(): Promise<RoleModel[]> {
        let promise: Promise<RoleModel[]>;
        promise = new Promise<RoleModel[]>((resolve, reject) => {
            this.api.request('roles', 'GET', {}, {})
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.clear_roles();
                        for (let i = 0; i < response.data.length; i++) {
                            let dat: any;
                            dat = response.data[i];
                            if (dat) {
                                let role: RoleModel;
                                role = new RoleModel();
                                role.clone(dat);
                                role.checked = true;
                                if (role.parent_id) {
                                    if (role.name === 'prod-1' || role.name === 'prod-2' || role.name === 'prod-3') {
                                        this.all_roles.push(role);
                                    }
                                } else if (role.name !== 'production') {
                                    //
                                    this.all_roles.push(role);
                                }
                            }
                        }

                        for (let i = 0; i < this.all_roles.length; i++) {
                            const role: any = this.all_roles[i];
                            let _: any;
                            _ = {
                                width: 75,
                                height: 75,
                                editable: false,
                                headerName: role.display_name,
                                field: role.name,
                                cellStyle: this.cellStyle,
                                cellRenderer: this.cellRenderer,
                                cellClass: (params) => {
                                    if (params && params.value && params.value.job_no) {
                                        return params.value.job_no.toLowerCase();
                                    } else if (params && params.value && params.value.purchase_order_no) {
                                        return params.value.purchase_order_no.toLowerCase();
                                    }
                                    return '';
                                },
                                getQuickFilterText: (params) => {
                                    if (params && params.value && params.value.job_no) {
                                        return params.value.job_no;
                                    } else if (params && params.value && params.value.purchase_order_no) {
                                        return params.value.purchase_order_no;
                                    }
                                    return '';
                                }
                            };
                            if (role && role.children && role.children.length) {
                                _['children'] = [];
                                for (let j = 0; j < role.children.length; j++) {
                                    const child: any = role.children[j];
                                    if (child && child.name && child.display_name) {
                                        _.children.push({
                                            width: 75,
                                            height: 75,
                                            editable: false,
                                            headerName: child.display_name,
                                            field: child.name,
                                            cellStyle: this.cellStyle,
                                            cellRenderer: this.cellRenderer,
                                            cellClass: (params) => {
                                                if (params && params.value && params.value.job_no) {
                                                    return params.value.job_no.toLowerCase();
                                                } else if (params && params.value && params.value.purchase_order_no) {
                                                    return params.value.purchase_order_no.toLowerCase();
                                                }
                                                return '';
                                            },
                                            getQuickFilterText: (params) => {
                                                if (params && params.value && params.value.job_no) {
                                                    return params.value.job_no;
                                                } else if (params && params.value && params.value.purchase_order_no) {
                                                    return params.value.purchase_order_no;
                                                }
                                                return '';
                                            }
                                        });
                                    }
                                }
                            }
                            this.columnDefs.push(_);
                        }

                        this.getPersonalTasks()
                            .then(() => {
                                this.getAllTasks()
                                    .then(() => {
                                        this.ready = true;
                                        /*this.pusherService.subscribe('erpst.dashboard');
                                        this.pusherService.bind('erpst.dashboard', 'tasks.update', data => {
                                            if (this.ready === true) {
                                                this.clear_timeout();
                                                this.timeout = setTimeout(() => {
                                                    this.getPersonalTasks()
                                                        .then(() => {
                                                            this.getAllTasks()
                                                                .then(() => {
                                                                    this.clear_timeout();
                                                                    setTimeout(() => {
                                                                        this.refresh();
                                                                    }, 500);
                                                                });
                                                        });
                                                }, 500);
                                            }
                                        });*/

                                        setTimeout(() => {
                                            this.refresh();
                                        }, 500);
                                    });
                            });
                    }
                    resolve([]);
                });
        });
        return promise;
    }

    private mediaDisplay(user: any): string {
        if (user && user.id) {
            const avatar: string = (user.avatar) ? user.avatar : './assets/images/empty-avatar.png';
            return '<img class="img-sm img-circle" src="' + avatar + '" /> <br/>';
        }
        return '';
    }

    private unsubscribe(): void {
        if (this.apiSubscription) {
            this.apiSubscription.unsubscribe();
        } else {
            //
        }
    }

    public getPersonalTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.apiSubscription.add(this.api.request('tasks/personal')
                .subscribe((response: any): void => {
                    if (response) {
                        this.personalTasksRender(response);
                        resolve();
                    } else {
                        resolve();
                    }
                }, error => {
                    resolve();
                }));
        });
        return promise;
    }

    private insert_idle_rows(idle_data: any[]): any[] {
        let _: any;
        _ = {};
        for (let j = 0; j < this.all_roles.length; j++) {
            const role: any = this.all_roles[j];
            if (role.children) {
                for (let k = 0; k < role.children.length; k++) {
                    const role_child: any = role.children[k];
                    if (role_child && role_child.name) {
                        _[role_child.name] = {
                            type: 'idle',
                            num_tasks: (idle_data && idle_data[role_child.name]) ? idle_data[role_child.name].length : 0,
                        };
                    }
                }
            }
            _[role.name] = {
                type: 'idle',
                num_tasks: (idle_data && idle_data[role.name]) ? idle_data[role.name].length : 0,
            };
        }
        _.rowHeight = 30;
        return [_];
    }

    private parse_to_row_data(columnDefs: any[], data: any, height: number): any[] {
        let return_data: any[];
        return_data = [];
        let max_rows: number;
        max_rows = MAX_ROWS;
        for (const key in data) {
            if (key) {
                const value: any = data[key];
                if (value && value.length && Array.isArray(value)) {
                    if (max_rows < value.length) {
                        max_rows = value.length;
                    }
                }
            }
        }
        for (let i = 0; i < max_rows; i++) {
            let _: any;
            _ = {};
            for (let j = 0; j < columnDefs.length; j++) {
                const col: any = columnDefs[j];
                if (col.children && col.children.length) {
                    for (let k = 0; k < col.children.length; k++) {
                        const child: any = col.children[k];
                        if (child && child.field && data && data[child.field] && data[child.field][i] && data[child.field][i].id) {
                            const task: any = data[child.field][i];
                            const contact_ref_no: string = task.contact_ref_no;
                            const fvd_no: string = task.fvd_no;
                            const job_no: string = task.job_no;
                            const purchase_order_no: string = task.purchase_order_no;
                            const quotation_no: string = task.quotation_no;
                            const product_name: string = task.product_name;
                            const product_no: string = task.product_no;
                            const document_no = task.document_no;
                            const ref_no = task.ref_no;
                            _[child.field] = {
                                type: 'task',
                                task_id: task.id,
                                status: task.status,
                                id: task.id,
                                user: task.current_user,
                                modelable_type: task.modelable_type,
                                modelable_id: task.modelable_id,
                                revision: task.revision,
                                current_role: task.current_role,
                                process_slug: task.process_slug,
                                contact_ref_no: contact_ref_no,
                                job_no: job_no,
                                fvd_no: task.fvd_no,
                                is_fvd: task.is_fvd,
                                ref_no: ref_no,
                                document_no: document_no,
                                purchase_order_no: purchase_order_no,
                                quotation_no: quotation_no,
                                product_name: product_name,
                                product_no: product_no,
                                name: job_no
                            };
                        }
                    }
                } else {
                    if (col && col.field && data && data[col.field] && data[col.field][i] && data[col.field][i].id) {
                        const task: any = data[col.field][i];
                        const contact_ref_no: string = task.contact_ref_no;
                        const job_no: string = task.job_no;
                        const fvd_no: string = task.fvd_no;
                        const purchase_order_no: string = task.purchase_order_no;
                        const quotation_no: string = task.quotation_no;
                        const product_name: string = task.product_name;
                        const product_no: string = task.product_no;
                        const ref_no = task.ref_no;
                        _[col.field] = {
                            type: 'task',
                            task_id: task.id,
                            status: task.status,
                            id: task.id,
                            user: task.current_user,
                            modelable_type: task.modelable_type,
                            modelable_id: task.modelable_id,
                            revision: task.revision,
                            current_role: task.current_role,
                            process_slug: task.process_slug,
                            contact_ref_no: contact_ref_no,
                            job_no: job_no,
                            fvd_no: fvd_no,
                            is_fvd: task.is_fvd,
                            ref_no: ref_no,
                            purchase_order_no: purchase_order_no,
                            quotation_no: quotation_no,
                            product_name: product_name,
                            product_no: product_no,
                            value: job_no
                        };
                    }
                }
            }
            _.rowHeight = height;
            return_data.push(_);
        }
        return return_data;
    }

    private getAllTasks(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.apiSubscription.add(this.api.request('tasks/keyBy')
                .subscribe((response: any): void => {
                    this.rowData = [];
                    if (response && response.data) {
                        const handle: any = response.data.handle;
                        const idle: any = response.data.idle;

                        this.rowData = this.rowData.concat(this.parse_to_row_data(this.columnDefs, handle, 90));
                        this.rowData = this.rowData.concat(this.insert_idle_rows(idle));
                        this.rowData = this.rowData.concat(this.parse_to_row_data(this.columnDefs, idle, 60));

                        if (response.data.num_tasks) {
                            this.num_tasks = response.data.num_tasks;
                        }
                        if (response.data.num_idle_tasks) {
                            this.num_idle_tasks = response.data.num_idle_tasks;
                        }
                        if (response.data.num_handle_tasks) {
                            this.num_handle_tasks = response.data.num_handle_tasks;
                        }
                    }
                    resolve();
                }, error => {
                    reject(error);
                }));
        });
        return promise;
    }

    private render_personal_tasks(response: any): void {
        if (this.personal_tasks) {
            this.personal_tasks.splice(0, this.personal_tasks.length);
        } else {
            this.personal_tasks = [];
        }
        if (response && response.data) {
            for (let i = 0; i < response.data.length; i++) {
                const dat: any = response.data[i];
                if (dat) {
                    const index: number = this.personal_tasks.findIndex(_task  => _task.to_user_id === dat.to_user_id);
                    if (index > -1) {
                        this.personal_tasks[index].num_tasks++;
                    } else {
                        let task: TaskModel;
                        task = new TaskModel();
                        task.clone(dat);
                        task.num_tasks = 1;
                        this.personal_tasks.push(task);
                    }
                }
            }
        }
    }

    private render_tasks(response: any): void {
        if (response && response.data) {
            if (response.data.handle) {
                this.all_tasks_handle = response.data.handle;
                this.appService.tasks_handle = this.all_tasks_handle;
                // this.find_personal_tasks(this.all_tasks_handle);
                this.update_priority_tasks(this.all_tasks_handle);
            }
            if (response.data.idle) {
                this.all_tasks_idle = response.data.idle;
                this.appService.tasks_idle = this.all_tasks_idle;
                // this.find_personal_tasks(this.all_tasks_idle);
                this.update_priority_tasks(this.all_tasks_idle);
            }
            if (response.data.num_tasks) {
                this.num_tasks = response.data.num_tasks;
            }
            if (response.data.num_idle_tasks) {
                this.num_idle_tasks = response.data.num_idle_tasks;
            }
            if (response.data.num_handle_tasks) {
                this.num_handle_tasks = response.data.num_handle_tasks;
            }
        }
    }

    private update_priority_tasks(data: any[]): void {
        for (const key in data) {
            if (key) {
                const task_list: any = data[key];
                for (let i = 0; i < task_list.length; i++) {
                    const dat: any = task_list[i];
                    if (dat.status_date) {
                        if (dat.status_date.date) {
                            dat.status_date = dat.status_date.date;
                        }
                        const status_date: any = moment(dat.status_date);
                        const asMinutes: number = moment.duration(this.now.diff(status_date)).asMinutes();
                        if (dat.process_slug === 'estimate' || dat.process_slug === 'quotation') {
                            if (asMinutes > 5760) { // 4days
                                dat.priority = 'danger';
                            } else if (asMinutes > 4320) { // 3days
                                dat.priority = 'warning';
                            } else if (asMinutes > 60) { // 1hour
                                dat.priority = '';
                            } else {
                                dat.priority = 'primary';
                            }
                        } else {
                            /*if (asMinutes > 2880) { // 2days
                                dat.priority = 'danger';
                            } else if (asMinutes > 1440) { // 1day
                                dat.priority = 'warning';
                            } else if (asMinutes > 10) { // 10 mins
                                dat.priority = 'orange';
                            } else { // less than 30 mins
                                dat.priority = 'primary';
                            }*/
                            if (asMinutes > 2880) { // 2days
                                dat.priority = 'danger';
                            } else if (asMinutes > 1440) { // 1day
                                dat.priority = 'warning';
                            } else if (asMinutes > 10) { // 10 mins -> 1 hrs
                                dat.priority = 'orange';
                            } else { // less than 30 mins
                                dat.priority = 'primary';
                            }
                        }
                    }
                }
            }
        }
    }

    private find_personal_tasks(data: any[]): TaskModel[] {
        for (const key in data) {
            if (key) {
                const task_list: any = data[key];
                for (let i = 0; i < task_list.length; i++) {
                    const dat: any = task_list[i];
                    if (dat && dat.to_user_id) {
                        const index: number = this.personal_tasks.findIndex(_task  => _task.to_user_id === dat.to_user_id);
                        if (index > -1) {
                            this.personal_tasks[index].num_tasks++;
                        } else {
                            let task: TaskModel;
                            task = new TaskModel();
                            task.clone(dat);
                            task.num_tasks = 1;
                            this.personal_tasks.push(task);
                        }
                    }
                }
            }
        }
        return this.personal_tasks;
    }

    private render_table(response: any): void {
        let max_value: number;
        max_value = 10;
        if (response && response.data && response.data.idle) {
            for (const key in response.data.idle) {
                if (key) {
                    const value: any = response.data.idle[key];
                    if (max_value < value.length) {
                        max_value = value.length;
                    }
                }
            }
        }

        this.num_handle_cells = Array(5).fill(1);
        this.num_idle_cells = Array(max_value).fill(1);
    }

    /*private render(response: any): void {
        this.ngZone.run(() => {
            this.render_tasks(response);
            this.render_table(response);

            if (this.appService.task_id && this.appService.target) {
                setTimeout(() => {
                    this.start_search(this.appService.job_id, this.appService.task_id, this.appService.target);
                }, 1500);
            } else {
                //
            }
        });
    }*/

    private personalTasksRender(response: any): void {
        this.ngZone.run(() => {
            if (!this.filter_text) {
                this.render_personal_tasks(response);
            } else {
                //
            }
        });
    }

    public onKeywordInput(e: any): void {
        this.num_search = this.search_by_keyword(e, this.all_tasks_idle_to_show);
        this.num_search += this.search_by_keyword(e, this.all_tasks_handle_to_show);
    }

    private search_by_keyword(keyword: string, all_tasks: any): number {
        let num_search: number;
        num_search = 0;
        for (const key in all_tasks) {
            if (key) {
                let tasks: any[];
                tasks = all_tasks[key];
                if (tasks) {
                    for (let i = 0; i < tasks.length; i++) {
                        let task: any;
                        task = tasks[i];
                        if (keyword) {
                            if (task && task.current_user) {
                                if ((task.current_user.nick_name + ' ' + task.current_user.full_name).indexOf(keyword) > -1) {
                                    task.found = true;
                                    num_search++;
                                } else {
                                    task.found = false;
                                }
                            } else if (task) {
                                task.found = false;
                            }
                        } else if (task) {
                            task.found = false;
                        }
                    }
                }
            }
        }
        return num_search;
    }

    private clear_roles(): void {
        if (this.all_roles) {
            this.all_roles.splice(0, this.all_roles.length);
        } else {
            this.all_roles = [];
        }
    }

    public gotoTask(task: TaskModel): void {
        this.taskService.gotoTask(task);
    }

    public viewTask(task: any): void {
        if (task && task.id) {
            this.current_task = task;
            switch (task.modelable_type) {
                case 'App\\Job':
                    const job_id: any = task.modelable_id;
                    const job_revision: any = task.revision;
                    this.viewer.manufacture(job_id, true, job_revision);
                    break;
                case 'App\\Quotation':
                    this.viewer.quotation(task.modelable_id);
                    break;
                case 'App\\Product':
                    this.viewer.product(task.modelable_id);
                    break;
                default:
                    this.gotoTask(task);
                    break;
            }
        }
    }

    public get is_fullscreen(): boolean {
        return false;
    }

    public get contentHeight(): string {
        // const _height: string = 'calc((100vh * ' + (100 / this.current_zoom) + ') - 170px - 24px - 90px);';
        const window_height: number = window.innerHeight;
        const _height: string = '' + ((window_height * (100 / this.current_zoom)) - 170 - 24 - 90) + 'px';
        return _height;
    }

}
