import {FormModel} from '../now/formModel';
import {JobModel} from './job.model';
import {UserModel} from '../now/user/user.model';
import {ItemModel} from './item.model';
import {Remark} from '../app/api/remark';

export class RequisitionModel extends FormModel {

    public requirement_id: any;
    public document_no: string;
    public ref_no: string;
    public task_id: string;
    public user_id: string;
    public job_no: string;
    public objective: string;
    public returned_at: string;
    public objective_other: string;
    public material_ids: any[];
    public job: JobModel;
    public job_id: string;
    public man_id: string;
    public man: UserModel;
    public request_man_id: string;
    public request_man: UserModel;
    public materials: ItemModel[];
    public remarks: Remark[];
    public requested_at: string;
    public prepared_at: string;
    public prepare_user_id: any;
    public prepare_user: UserModel;

    constructor() {
        super('requisitions', 'App\\Requisition');
        this.materials = [];
        this.material_ids = [];
    }

}
