import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AppService} from '../../app/app.service';
import {AuthService} from '../../now/user/auth.service';
import {UserModel} from '../../now/user/user.model';

@Component({
    selector: 'app-task-component',
    templateUrl: 'task.component.html'
})
export class TaskComponent implements OnInit, AfterViewInit {

    public role: string;
    public is_ready: boolean;
    public role_error: boolean;

    public is_control: boolean;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService
    ) {
        //
        this.is_ready = false;
        this.role_error = false;
        this.is_control = false;
    }

    ngOnInit(): void {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.is_ready = false;
            this.role_error = false;
            if (this.route && this.route.firstChild) {
                this.route.params
                    .subscribe((params: Params): void => {
                        this.role = params.role;
                        const user: UserModel = this.authService.user;
                        const sale_role_index: number = user.roles.findIndex(i => i === 'estimate');
                        if (sale_role_index > -1) {
                            user.roles.push('contact');
                        } else {
                            //
                        }
                        const findAccounting = user.roles.indexOf('accounting') > -1;
                        if (findAccounting) {
                            user.roles.push('account');
                        }
                        user.roles.push('prepared-requisition', 'support_car', 'request_pr', 'fmt-complete', 'fmt-fixed', 'ncr', 'approved', 'hr', 'car', 'man', 'approved_hr', 'approve_hr', 'reject_hr', 'request-fmt');
                        if (!this.role) {
                            this.role_error = false;
                            this.is_ready = true;
                        } else {
                            if (user.roles.indexOf(this.role) > -1) {
                                this.role_error = false;
                            } else {
                                this.role_error = true;
                            }
                            this.is_ready = true;
                        }
                        this.is_control = user.roles.indexOf('pd-control') > -1;
                    });
            }
        }, 0);
    }

}
