import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';

@Injectable()
export class SkinService {

    public skinData: any;

    constructor(private api: Api) {
    }

    public updateSkin({ bg, image, message }) {
        return new Promise(resolve => {
            this.api.request('skin/update', 'POST', {}, {})
                .toPromise()
                .then((response: any) => {
                    if (response && response.success === true) {
                        this.skinData = undefined;
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, error => {
                    console.warn(error);
                    resolve(false);
                });
        });
    }

    public getSkin() {
        return new Promise(resolve => {
            if (this.skinData) {
                resolve(this.skinData);
            } else {
                this.api.request('public/skin', 'GET', {}, {})
                    .toPromise()
                    .then((response: any) => {
                        if (response && response.success === true) {
                            this.skinData = response.data;
                            resolve(response.data);
                        } else {
                            resolve({});
                        }
                    });
            }
        });
    }

}
