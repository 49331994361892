import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {TaskModel} from '../models/task.model';
import {SwalService} from './swal.service';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {ModelApi} from '../now/modelApi/modelApi';
import {Location} from '@angular/common';
import {Model} from '../now/model';
import {UserModel} from '../now/user/user.model';
import {AuthService} from '../now/user/auth.service';
import {LocalStorageService} from './localStorageService';
import {LoaderService} from '../components/loader/loader.service';
import {UserTaskServices} from './userTask.services';

@Injectable()
export class TaskService {

    constructor(
        private api: Api,
        private userTask: UserTaskServices,
        private swal: SwalService,
        private userService: UserService,
        private auth: AuthService,
        private router: Router,
        private location: Location,
        private storage: LocalStorageService,
        private modelApi: ModelApi,
        private loader: LoaderService,
    ) {
        //
    }

    public issue(task: TaskModel, checked_role?: string, user?: UserModel, to_user?: UserModel, remark_message?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/issue', 'POST', {}, {
                id              : task.id,
                to_user_id      : (to_user) ? to_user.id : null,
                issued_user_id  : (user) ? user.id : null,
                checked_role    : (to_user) ? 'approved' : checked_role,
                remark_message
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public check(task: TaskModel, approved_role: string, user?: UserModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/check', 'POST', {}, {
                id              : task.id,
                checked_user_id : (user) ? user.id : null,
                approved_role   : approved_role
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public approve(task: TaskModel, user?: UserModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/approve', 'POST', {}, {
                id                  : task.id,
                approved_user_id    : (user) ? user.id : null
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public reject(task: TaskModel | any, user?: UserModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/reject', 'POST', {}, {
                id                  : task.id,
                approved_user_id    : (user) ? user.id : null, // change to rejected_user_id ?
                rejected_user_id    : (user) ? user.id : null
            }, null, null, null, null, true)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public update(task: TaskModel, modelable_model: Model, put_or_post_data?: string[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            task.setModelable(modelable_model);
            if (put_or_post_data) {
                task.modelable = this.modelApi.buildPostOrPutData(modelable_model, put_or_post_data);
            } else {
                //
            }
            this.modelApi.update(task, [
                'modelable_type', 'modelable_id', 'modelable'
            ]).subscribe((response: any): void => {
                if (modelable_model && modelable_model.clone && response && response.data && response.data.modelable) {
                    // modelable_model.clone(response.data.modelable);
                    modelable_model.id = response.data.modelable_id;
                }
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public createNewTask(data: any): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject): void => {
            const task: TaskModel = new TaskModel();
            task.clone(data);
            const key_array: string[] = [];
            for (const key in data) {
                if (key) {
                    key_array.push(key);
                }
            }
            this.modelApi.create(task, key_array, null, null, true)
                .subscribe((task_response: any): void => {
                    resolve(task);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public setStatus(
        task: TaskModel | any,
        task_status: number,
        action?: string,
        to_role?: string,
        to_process?: string,
        completed?: boolean,
        to_user_id?: any,
        remark?: string
    ): Promise<Object> {
        //
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject): void => {
            this.api.request('tasks/status', 'POST', {}, {
                id              : (!task.id) ? task : task.id,
                to_role         : to_role,
                to_status       : task_status,
                to_process      : to_process,
                to_user_id      : to_user_id,
                action          : action,
                completed       : completed,
                remark          : remark,
            }, null, null, null, null, true)
                .subscribe((response: any): void => {
                    // task.status = task_status;
                    // task.current_role = (to_role) ? to_role : task.current_role;
                    // task.process_slug = (to_process) ? to_process : task.process_slug;
                    resolve(task);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public complete(task: TaskModel | TaskModel[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let request_data: any;
            request_data = {};
            if (Array.isArray(task)) {
                let ids: string[];
                ids = [];
                for (let i = 0 ; i < task.length; i++) {
                    ids.push(task[i].id);
                }
                request_data['ids'] = ids;
            } else {
                request_data['id'] = task.id;
            }
            this.api.request('tasks/complete', 'POST', null, request_data)
                .subscribe((response: any): void => {
                    resolve();
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public archive(task: TaskModel, leave?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.swal.confirm('ยืนยันการจัดเก็บงานใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('tasks/archive', 'POST', null, {
                            id: task.id
                        }).subscribe((response: any): void => {
                            this.swal.success('จัดเก็นงานเข้าฐานข้อมูลสำเร็จ');
                            if (leave === false) {
                                //
                            } else {
                                this.leave(task, true);
                            }
                            resolve();
                        }, error => {
                            reject(error);
                        });
                    } else {
                        reject();
                    }
                });
        });
        return promise;
    }

    public cancel(task: TaskModel, leave?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/cancel', 'POST', null, {
                id: task.id
            }).subscribe((response: any): void => {
                // this.swal.success('จัดเก็นงานเข้าฐานข้อมูลสำเร็จ');
                if (leave === false) {
                    //
                } else {
                    this.leave(task, true);
                }
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public delete(task: TaskModel, redirect?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.swal.confirm('คุณไม่สามารถนำงานนี้กลับเข้ามาในระบบได้อีกครั้ง, ยืนยันที่จะยกเลิกงานใช่หรือไม่?', false, null,
                'ยกเลิกงาน', 'ปิด')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.modelApi.delete(task)
                            .subscribe((response: any): void => {
                                if (redirect !== false) {
                                    this.router.navigateByUrl('/task/' + task.current_role);
                                } else {
                                    //
                                }
                                resolve(response);
                            }, error => {
                                reject(error);
                            });
                    } else {
                        reject();
                    }
                });
        });
        return promise;
    }

    public leave(task: TaskModel, force?: boolean, back?: boolean, remark?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject): void => {
            if (force === true) {
                if (task && task.id) {
                    this._leave(task, back, remark)
                        .then(() => {
                            resolve();
                        }, error => {
                            reject();
                        });
                } else {
                    if (back === false) {
                        //
                    } else {
                        // this.location.back();
                        this.router.navigateByUrl('/task/' + task.current_role, {
                            replaceUrl: true
                        });
                    }
                    this.userService.current_role = null;
                    resolve();
                }
            } else {
                this.swal.confirm('คุณต้องการออกจากงานตอนนี้ใช่หรือไม่?', false, null,
                    'ออกงาน', 'ปิด')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this._leave(task, back, remark)
                                .then(() => {
                                    resolve();
                                }, error => {
                                    reject(error);
                                });
                        } else {
                            reject();
                        }
                    });
            }
        });
        return promise;
    }

    private confirm_hire(error: any): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.swal.confirm(error.message.full_name  + ' ถูกมอบหมายให้ทำงานนี้ คุณต้องการทำแทนใช่หรือไม่?', false, null,
                'ยืนยันทำงานแทน', 'ปิด')
                .then((_result: boolean): void => {
                    if (_result === true) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
        return promise;
    }

    public hire(task: TaskModel, user_id?: string, force_hire?: boolean, action?: string, action_status?: string, data?: any, remark?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject): void => {
            const put_or_post_data: string[] = [
                'current_role',
                'process_slug',
                'product_ids',
                'customer_id',
                'action',
                'parent_id',
                'hidden',
                'modelable_type',
                'modelable_id',
                'modelable_data',
                'hidden',
                'next_role'
            ];
            if (force_hire === true) {
                if (task.isNew) {
                    this.loader.show();
                    this.modelApi.create(task, put_or_post_data)
                        .subscribe((task_response: any): void => {
                            this.force_hire(task, remark)
                                .then(() => {
                                    this.gotoTask(task, data);
                                    this.loader.hide();
                                    resolve();
                                }, error => {
                                    this.loader.hide();
                                    if (error && error.message && error.message.full_name) {
                                        this.swal.danger(error.message.full_name + ' กำลังทำงานนี้อยู่');
                                    } else {
                                        this.swal.danger(error);
                                    }
                                });
                        }, error => {
                            this.swal.danger(error);
                            this.loader.hide();
                        });
                } else {
                    this.loader.show();
                    this.force_hire(task, remark)
                        .then(() => {
                            this.gotoTask(task, data);
                            this.loader.hide();
                            resolve();
                        }, error => {
                            this.loader.hide();
                            if (error && error.message && error.message.full_name) {
                                this.swal.danger(error.message.full_name + ' กำลังทำงานนี้อยู่');
                            } else {
                                this.swal.danger(error);
                            }
                        });
                }
            } else {
                this.loader.show();
                this.api.request('users/task')
                    .subscribe((response: any): void => {
                        this.loader.hide();
                        if (response && response.data && response.data.id) {
                            this.swal.confirm('คุณไม่สามารถเข้าทำงานได้ เนื่องจาก ณ ตอนนี้คุณมีงานค้างอยู่ในระบบ', false, null,
                                'เริ่มทำงานใหม่', 'กลับทำงานที่ค้าง')
                                .then((result: boolean): void => {
                                    if (result === true) { // เริ่มทำงานใหม่
                                        if (task.isNew) {
                                            this.loader.show();
                                            this.modelApi.create(task, put_or_post_data)
                                                .subscribe((task_response: any): void => {
                                                    this.force_hire(task, remark)
                                                        .then(() => {
                                                            this.gotoTask(task);
                                                            this.loader.hide();
                                                            resolve();
                                                        }, error => {
                                                            this.loader.hide();
                                                            if (error && error.message && error.message.full_name) {
                                                                this.swal.danger('' + error.message.full_name + ' กำลังทำงานนี้อยู่');
                                                            } else {
                                                                this.swal.danger(error);
                                                            }
                                                        });
                                                }, error => {
                                                    this.swal.danger(error);
                                                    this.loader.hide();
                                                });
                                        } else {
                                            this.force_hire(task, remark)
                                                .then(() => {
                                                    this.gotoTask(task);
                                                    resolve();
                                                }, error => {
                                                    if (error && error.message && error.message.full_name) {
                                                        this.swal.danger('' + error.message.full_name + ' กำลังทำงานนี้อยู่');
                                                    } else {
                                                        this.swal.danger(error);
                                                    }
                                                });
                                        }
                                    } else { // กลับทำงานที่ค้าง
                                        this.userService.current_role = response.data.current_role;
                                        this.gotoTask(response.data);
                                    }
                                });
                        } else {
                            if (task.isNew) {
                                this.loader.show();
                                this.modelApi.create(task, put_or_post_data)
                                    .subscribe((task_response: any): void => {
                                        this.force_hire(task, remark)
                                            .then(() => {
                                                this.gotoTask(task);
                                                this.loader.hide();
                                                resolve();
                                            }, error => {
                                                this.loader.hide();
                                                if (error && error.message && error.message.full_name) {
                                                    this.swal.danger('' + error.message.full_name + ' กำลังทำงานนี้อยู่');
                                                } else {
                                                    this.swal.danger(error);
                                                }
                                            });
                                    }, error => {
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                            } else {
                                this.force_hire(task, remark)
                                    .then(() => {
                                        this.gotoTask(task);
                                        resolve();
                                    }, error => {
                                        if (error && error.message && error.message.full_name) {
                                            this.swal.danger('' + error.message.full_name + 'กำลังทำงานนี้อยู่');
                                        } else {
                                            this.swal.danger(error);
                                        }
                                    });
                            }
                        }
                    }, error => {
                        reject(error);
                    });
            }
        });
        return promise;
    }

    private _leave(task: TaskModel, back?: boolean, remark?: string): Promise<Object> {
        let promise: Promise<Object>;

        // for use ngOnDestory to autosave
        if (back === false) {
            //
        } else {
            // this.router.navigateByUrl('/task/' + task.current_role);
            this.router.navigateByUrl('/task/' + task.current_role, {
                replaceUrl: true
            });
        }

        promise = new Promise<Object>((resolve, reject) => {
            this.loader.show();
            this.api.request('tasks/leave', 'POST', {}, {
                id: task.id,
                remark: remark
            }).subscribe(response => {
                this.userTask.clear();

                task.current_user_id = null;
                task.current_user = null;
                this.userService.current_role = null;
                this.loader.hide();
                resolve(response);
            }, error => {
                this.loader.hide();
                reject(error);
            });
        });
        return promise;
    }

    private force_hire(task: TaskModel, remark?: any): Promise<Object> {
        let promise: Promise<Object>
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/hire', 'POST', {}, {
                id          : task.id,
                product_id  : task.product_id,
                force_hire  : true,
                remark      : remark
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    if (this.userService.getID()) {
                        task.current_user_id = this.userService.getID();
                    }
                    if (response && response.data && response.data.status) {
                        // task.status = response.data.status;
                    }
                    // this.disabledRoleService.setTask(task);
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public gotoTask(task: TaskModel | any, data?: any, force?: boolean): void {
        if (task && task.current_role) {
            console.log('ROLE AND SLUG : ' + task.current_role, task.process_slug);
            const current_role: string = task.current_role.toLowerCase();
            if (data) {
                this.storage.store('task_data', data);
            } else {
                //
            }
            if (task.modelable_type === 'App\\Quotation') {
                if (task.process_slug === 'quotation' || task.process_slug === 'deposit' || task.process_slug === 'proforma') {
                    this.router.navigateByUrl('/task/' + current_role + '/quotation/' + task.id);
                } else {
                    //
                }
            } else if (task.modelable_type === 'App\\FVD') {
                this.router.navigateByUrl('/task/' + current_role + '/request-fvd/' + task.id);
            } else if (current_role === 'dummy') {
                this.router.navigateByUrl('/task/' + current_role + '/ncr/' + task.id);
            } else if (current_role === 'fvd') {
                this.router.navigateByUrl('/task/' + current_role + '/prod/' + task.id);
            } else if (current_role === 'receipt') {
                this.router.navigateByUrl('/task/account/account/' + task.id);
            } else if (current_role === 'notice') {
                this.router.navigateByUrl('/task/' + current_role + '/notice/' + task.id);
            } else if (current_role === 'trial') {
                this.router.navigateByUrl('/task/' + current_role + '/trial/' + task.id);
            } else if (current_role === 'request_pr') {
                this.router.navigateByUrl('/task/' + current_role + '/pr/' + task.id);
            } else if (current_role === 'request-fvd') {
                this.router.navigateByUrl('/task/' + current_role + '/request-fvd/' + task.id);
            } else if (current_role === 'prod-1' || current_role === 'prod-2' || current_role === 'prod-3') {
                // this.router.navigateByUrl('/task/' + current_role + '/prod/' + task.id);
            } else if (current_role === 'rejected') {
                this.router.navigateByUrl('/task/' + current_role + '/rejected/' + task.id);
            } else if (current_role === 'hr') {
                this.router.navigateByUrl('/task/' + current_role + '/man/' + task.id);
            } else if (current_role === 'reject_hr') {
                this.router.navigateByUrl('/task/' + current_role + '/man/' + task.id);
            } else if (current_role === 'approved_hr') {
                this.router.navigateByUrl('/task/' + current_role + '/man/' + task.id);
            } else if (current_role === 'approve_hr') {
                this.router.navigateByUrl('/task/' + current_role + '/man/' + task.id);
            } else if (current_role === 'store') {
                this.router.navigateByUrl('/task/' + current_role + '/store/' + task.id);
            } else if (current_role === 'prepared-requisition') {
                this.router.navigateByUrl('/task/' + current_role + '/prepared/' + task.id);
            } else if (current_role === 'check-store') {
                this.router.navigateByUrl('/task/' + current_role + '/store/' + task.id);
            } else if (current_role === 'po-store') {
                this.router.navigateByUrl('/task/' + current_role + '/po-store/' + task.id);
            } else if (current_role === 'notice-shipping') {
                this.router.navigateByUrl('/task/' + current_role + '/notice-shipping/' + task.id);
            } else if (current_role === 'prepare-store') {
                this.router.navigateByUrl('/task/' + current_role + '/material/' + task.id);
            } else if (current_role === 'requisition') {
                this.router.navigateByUrl('/task/' + current_role + '/requisition/' + task.id);
            } else if (current_role === 'request-purchase-order') {
                this.router.navigateByUrl('/task/' + current_role + '/request-purchase-order/' + task.id);
            } else if (current_role === 'request-fmt') {
                this.router.navigateByUrl('/task/reject/fmt/' + task.id);
            } else if (current_role === 'return-store') {
                this.router.navigateByUrl('/task/' + current_role + '/return/' + task.id);
            } else if (current_role === 'archive') {
                this.router.navigateByUrl('/task/' + current_role + '/archive/' + task.id);
            } else if (current_role === 'request-purchase') {
                this.router.navigateByUrl('/task/' + current_role + '/request/' + task.id);
            } else if (current_role === 'request-purchase-2') {
                if (task.process_slug === 'request-purchase-3') {
                    this.router.navigateByUrl('/task/' + current_role + '/request3/' + task.id);
                } else {
                    this.router.navigateByUrl('/task/' + current_role + '/request2/' + task.id);
                }
            } else if (current_role === 'po-fvd') {
                this.router.navigateByUrl('/task/' + current_role + '/po/' + task.id);
            } else if (current_role === 'po-purchase2') {
                this.router.navigateByUrl('/task/' + current_role + '/po/' + task.id);
            } else if (current_role === 'po-purchase') {
                this.router.navigateByUrl('/task/' + current_role + '/po/' + task.id);
            } else if (current_role === 'pricing-inquiry') {
                this.router.navigateByUrl('/task/' + current_role + '/request/' + task.id);
            } else if (current_role === 'store-purchase') {
                this.router.navigateByUrl('/task/' + current_role + '/po/' + task.id);
            } else if (current_role === 'payment-proforma') {
                this.router.navigateByUrl('/task/' + current_role + '/po/' + task.id);
            } else if (current_role === 'payment') {
                this.router.navigateByUrl('/task/' + current_role + '/po/' + task.id);
            } else if (current_role === 'accounting' || current_role === 'finish') {
                this.router.navigateByUrl('/task/' + current_role + '/payment/' + task.id);
            } else if (current_role === 'approved') {
                if (task.process_slug === 'quotation') {
                    this.router.navigateByUrl('/task/' + current_role + '/quotation/' + task.id);
                } else if (task.process_slug === 'estimate') {
                    this.router.navigateByUrl('/task/' + current_role + '/detail/' + task.id);
                } else if (task.process_slug === 'po-purchase') {
                    this.router.navigateByUrl('/task/' + current_role + '/request/' + task.id);
                } else if (task.process_slug === 'pricing-inquiry') {
                    this.router.navigateByUrl('/task/' + current_role + '/request/' + task.id);
                } else if (task.process_slug === 'ncr') {
                    this.router.navigateByUrl('/task/' + current_role + '/ncr/' + task.id);
                } else if (task.process_slug === 'car') {
                    this.router.navigateByUrl('/task/' + current_role + '/car/' + task.id);
                } else if (task.process_slug === 'request-purchase-2') {
                    this.router.navigateByUrl('/task/' + current_role + '/request2/' + task.id);
                } else if (task.process_slug === 'request_pr') {
                    this.router.navigateByUrl('/task/' + current_role + '/request2/' + task.id);
                }
            } else if (current_role === 'qa') {
                if (task.process_slug === 'ncr') {
                    this.router.navigateByUrl('/task/' + current_role + '/ncr/' + task.id);
                } else if (task.process_slug === 'car') {
                    this.router.navigateByUrl('/task/' + current_role + '/car/' + task.id);
                } else if (task.process_slug === 'ask-ncr') {
                    this.router.navigateByUrl('/task/' + current_role + '/ncr/' + task.id);
                }
            } else if (current_role === 'waiver') {
                this.router.navigateByUrl('/task/' + current_role + '/ncr/' + task.id);
            } else if (current_role === 'preventive-ma') {
                this.router.navigateByUrl('/task/' + current_role + '/preventive-ma/' + task.id);
            } else if (current_role === 'fmt') {
                this.router.navigateByUrl('/task/' + current_role + '/fmt/' + task.id);
            } else if (current_role === 'fmt-progress') {
                this.router.navigateByUrl('/task/' + current_role + '/fmt/' + task.id);
            } else if (current_role === 'fmt-fixed') {
                this.router.navigateByUrl('/task/' + current_role + '/fmt/' + task.id);
            } else if (current_role === 'fmt-complete') {
                this.router.navigateByUrl('/task/' + current_role + '/fmt/' + task.id);
            } else if (current_role === 'fmt-apply') {
                this.router.navigateByUrl('/task/' + current_role + '/fmt/' + task.id);
            } else if (current_role === 'qa-trial') {
                this.router.navigateByUrl('/task/' + current_role + '/ncr/' + task.id);
            } else if (current_role === 'wait-response') {
                this.router.navigateByUrl('/task/' + current_role + '/ncr/' + task.id);
            } else if (current_role === 'request-requisition') {
                this.router.navigateByUrl('/request/store/' + task.id);
            } else {
                if (task.process_slug === 'estimate') {
                    this.router.navigateByUrl('/task/' + current_role + '/detail/' + task.id);
                } else if (task.process_slug === 'quotation') {
                    this.router.navigateByUrl('/task/' + current_role + '/quotation/' + task.id);
                } else if (task.process_slug === 'shipping-document') {
                    this.router.navigateByUrl('/task/' + current_role + '/payment/' + task.id);
                } else if (task.process_slug === 'tax-invoice') {
                    this.router.navigateByUrl('/task/' + current_role + '/tax/invoice/' + task.id);
                } else if (task.process_slug === 'invoice') {
                    this.router.navigateByUrl('/task/' + current_role + '/invoice/' + task.id);
                } else if (task.process_slug === 'receipt') {
                    this.router.navigateByUrl('/task/' + current_role + '/receipt/' + task.id);
                } else if (task.process_slug === 'deposit') {
                    this.router.navigateByUrl('/task/' + current_role + '/deposit/' + task.id);
                } else if (task.process_slug === 'packing-slip') {
                    this.router.navigateByUrl('/task/' + current_role + '/packing/slip/' + task.id);
                } else if (task.process_slug === 'po') {
                    // this.router.navigateByUrl('/task/' + current_role + '/po/' + task.id);
                } else if (task.process_slug === 'planning') {
                    this.router.navigateByUrl('/task/' + current_role + '/planning/' + task.id);
                } else if (task.process_slug === 'control' || task.process_slug === 'pd-control') {
                    this.router.navigateByUrl('/task/' + current_role + '/control/' + task.id);
                } else if (task.process_slug === 'store') {
                    this.router.navigateByUrl('/task/' + current_role + '/store/' + task.id);
                } else if (task.process_slug === 'requirement') {
                    this.router.navigateByUrl('/task/' + current_role + '/requirement/' + task.id);
                } else if (task.process_slug === 'delivery') {
                    this.router.navigateByUrl('/task/' + current_role + '/delivery/' + task.id);
                } else if (task.process_slug === 'ncr') {
                    this.router.navigateByUrl('/task/' + current_role + '/ncr/' + task.id);
                } else if (task.process_slug === 'car') {
                    this.router.navigateByUrl('/task/' + current_role + '/car/' + task.id);
                } else if (task.process_slug === 'support_car') {
                    this.router.navigateByUrl('/task/' + current_role + '/support_car/' + task.id);
                } else if (task.process_slug === 'done_support_car') {
                    this.router.navigateByUrl('/task/' + current_role + '/done_support_car/' + task.id);
                } else {
                    /*const user: UserModel = this.auth.user;
                    if (task.action === 'start_process' && user.roles.indexOf('pd-control') > -1 && !force) {
                        this.router.navigateByUrl('/task/' + current_role + '/planning/' + task.id);
                    } else {
                        this.router.navigateByUrl('/task/' + current_role + '/prod/' + task.id);
                    }*/
                  this.router.navigateByUrl('/task/' + current_role + '/prod/' + task.id);
                }
            }
        }
    }

}
