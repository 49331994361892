import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {RequirementModel} from '../../../models/requirement.model';
import {TaskModel} from '../../../models/task.model';
import {TaskService} from '../../../services/task.service';
import {UserService} from '../../../services/user.service';
import {AppointmentModel} from '../../../models/appointment.model';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../now/api/api';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {SwalService} from '../../../services/swal.service';
import {CustomerModel} from '../../../models/customer.model';
import {ViewTask} from '../view/viewTask';
import {IViewTask} from '../view/iViewTask';
import {DocumentModel} from '../../../models/document.model';
import {environment} from '../../../environments/environment';
import {ModalService} from '../../../services/modal.service';
import {UserModel} from '../../../now/user/user.model';
import {AuthService} from '../../../now/user/auth.service';
import {ItemRequirementModel} from '../../../models/itemRequirement.model';
import {UploaderComponent} from '../../../components/uploader/uploader.component';
import {DocumentTypeModal} from './documentType/documentType.modal';
import {ItemRequirementDocumentModel} from '../../../models/itemRequirementDocument.model';
import {LoaderService} from '../../../components/loader/loader.service';
import {Viewer} from '../../../services/viewer';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {Subscription} from 'rxjs/internal/Subscription';
import {debounceTime} from 'rxjs/operators';
import {AutoSaveDirective} from '../../../directives/autoSave.directive';

@Component({
    selector: 'requirement-task-component',
    templateUrl: 'requirementTask.component.html',
    styleUrls: ['requirementTask.component.scss']
})
export class RequirementTaskComponent extends ViewTask implements AfterViewInit, IViewTask, OnInit, OnDestroy {

    @ViewChild('uploader', { static: true }) uploader: UploaderComponent;
    @ViewChild(AutoSaveDirective, { static: false }) autoSave: AutoSaveDirective;

    public merge_quotation: boolean;

    public requirement: RequirementModel;
    public tmp_customer: any;
    public tmp_contact: any;
    public tmp_op: any;
    public tmp_man: any;
    public tmp_drawing: any;
    public current_tab: string;
    public current_item: ItemRequirementModel;
    public now: string;
    public todo_list: any[];

    public saveSubject: Subject<any> = new Subject<any>();
    public subscriptions: Subscription[] = [];

    constructor(
        public location: Location,
        public viewer: Viewer,
        public taskService: TaskService,
        private route: ActivatedRoute,
        public api: Api,
        private loader: LoaderService,
        private auth: AuthService,
        public modal: ModalService,
        private swal: SwalService,
        private modelApi: ModelApi,
        public userService: UserService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.requirement = new RequirementModel();
        this.requirement.start_time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.requirement.end_time = moment(new Date()).format('YYYY-MM-DD 17:00:00');
        this.tmp_customer = new CustomerModel();
        this.merge_quotation = true;
        this.tmp_contact = {};
        this.tmp_drawing = {};
        this.tmp_man = {};
        this.tmp_op = {};
        this.current_tab = '#customer';

        this.now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.todo_list = [
            {
                label: 'สร้างสินค้าใหม่ (SN)',
                value: 'CREATE_NEW_SN'
            },
            {
                label: 'ออกแบบ, เขียนแบบ',
                value: 'CREATE_DRAWING'
            },
            {
                label: 'นัดส่งแบบ',
                value: 'APPOINTMENT_DRAWING'
            },
            {
                label: 'เสนอราคา',
                value: 'QUOTATION'
            },
            {
                label: 'ติดตามใบเสนอราคา',
                value: 'FOLLOW_UP_QUOTATION'
            },
            {
                label: 'จัดทำตัวอย่าง',
                value: 'CREATE_DEMO'
            },
            {
                label: 'รับ P/O',
                value: 'TAKE_PO'
            },
            {
                label: 'Load JOB',
                value: 'LOAD_JOB'
            },
            {
                label: 'อื่นๆ',
                value: 'OTHER'
            }
        ];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.task = new TaskModel();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTask()
                            .then(() => {
                                if (this.task.modelable_type && this.task.modelable_id) {
                                    this.getRequirement(this.task.modelable_id)
                                        .then(() => {
                                            /*if (this.requirement && this.requirement.appointments
                                                && this.requirement.appointments.length === 0) {
                                                this.requirement.appointments.push(new AppointmentModel());
                                            }*/
                                            if (this.requirement && this.requirement.item_requirements
                                                && this.requirement.item_requirements.length === 0) {
                                                this.addMoreItemRequirement();
                                            }
                                            this.setup_sale();
                                            this.doReady();
                                        });
                                } else {
                                    if (this.requirement && this.requirement.item_requirements
                                        && this.requirement.item_requirements.length === 0) {
                                        this.addMoreItemRequirement();
                                    }
                                    this.setup_sale();
                                    this.doReady();
                                }
                            }, error => {
                                //
                            });
                    }
                });
        }, 0);
    }

    ngOnInit() {
        this.subscriptions.push(
            this.saveSubject.pipe(
                debounceTime(3000),
            ).subscribe(async result => {
                if (this.ready === true) {
                    await this.save()
                        .catch((error: any) => console.warn(error));
                }
            })
        );
    }

    async ngOnDestroy() {
        if (this.autoSave && this.autoSave.canUpdate()) {
            await this.save()
                .catch((error: any) => console.warn(error));
        }
        this.subscriptions.forEach(i => i.unsubscribe());
    }

    public onSaleChange(e: any): void {
        this.requirement.sale_id = (e && e.id) ? e.id : null;
    }

    public onAppointmentContactChange(appointment: AppointmentModel, e: any): void {
        appointment.contact_id = (e && e.id) ? e.id : null;
    }

    public onAppointmentSaleChange(appointment: AppointmentModel, e: any): void {
        appointment.sale_id = (e && e.id) ? e.id : null;
    }

    public onCustomerChange(e: any): void {
        this.requirement.customer_id = (e && e.id) ? e.id : null;
    }

    public onContactChange(e: any): void {
        this.requirement.contact_id = (e && e.id) ? e.id : null;
    }

    public onStartTimeChange(obj: any, e: any): void {
        setTimeout(() => {
            if (obj.start_time) {
                const start_time_moment = moment(obj.start_time, 'YYYY-MM-DD HH:mm:ss');
                if (obj.end_time) {
                    const end_time_moment = moment(obj.end_time, 'YYYY-MM-DD HH:mm:ss');
                    obj.end_time = start_time_moment.format('YYYY-MM-DD') + ' ' + end_time_moment.format('HH:mm:ss');
                } else {
                    obj.end_time = start_time_moment.format('YYYY-MM-DD') + ' 17:00:00';
                }
            }
        }, 0);
    }

    private setup_sale(): void {
        if (this.requirement && this.requirement.sale && !this.requirement.sale.id) {
            const user = this.auth.user;
            if (user && user.id) {
                this.requirement.sale = new UserModel();
                this.requirement.sale.clone(user.raw_data);
                this.requirement.sale_id = user.id;
            }
        }
    }

    public viewItemRequirementDocument(item_requirement_document: ItemRequirementDocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        if (item_requirement_document && item_requirement_document.document_id) {
            window.open(environment.api_host + 'view/' + item_requirement_document.document_id + '/document/', '_blank');
        }
    }

    public addDocument(item: ItemRequirementModel): void {
        this.uploader.trigger();
        this.current_item = item;
    }

    public deleteItemRequirement(item: ItemRequirementModel, index: number): void {
        if (item && index > -1) {
            this.swal.confirm('ยืนยันการยกเลิกรายการ ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.requirement.item_requirements.splice(index, 1);
                        if (item.id) {
                            this.api.request('item/requirements/delete', 'POST', {}, {
                                id: item.id
                            }).subscribe((response: any): void => {
                                if (response && response.data) {
                                    //
                                }
                            }, error => {
                                //
                            });
                        }
                    } else {
                        //
                    }
                });
        }
    }

    public onStartUploader(data: any): void {
        //
    }

    public onDocumentUploadedSuccess(event?: any): void {
        this.modal.show(DocumentTypeModal, {
            document            : event,
            item_requirement    : this.current_item
        }).then((content: any): void => {
            if (content && content.submit === true) {
                if (content.item_requirement_document) {
                    this.current_item.item_requirement_documents.push(content.item_requirement_document);
                }
            } else {
                //
            }
        });
    }

    public onDocumentUploadedError(event?: any): void {
        //
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public addMoreItemRequirement(): void {
        let item_requirement: ItemRequirementModel;
        item_requirement = new ItemRequirementModel();
        item_requirement.requirement_id = this.requirement.id;
        this.requirement.item_requirements.push(item_requirement);
        this.modelApi.create(item_requirement, ['requirement_id'])
            .subscribe((response: any): void => {
                //
            }, error => {
                //
            });
    }

    public getRequirement(id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('requirements/' + id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.requirement.clone(response.data);
                        resolve(this.requirement);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public viewDocumentDetail(item_requirement: ItemRequirementModel, document: ItemRequirementDocumentModel): void {
        this.modal.show(DocumentTypeModal, {
            item_requirement_document   : document,
            item_requirement            : item_requirement
        }).then((content: any): void => {
            if (content && content.submit === true) {
                document.clone(content.item_requirement_document);
            } else {
                //
            }
        });
    }

    public addAppointment(): void {
        let new_appointment: AppointmentModel;
        new_appointment = new AppointmentModel();
        this.requirement.appointments.push(new_appointment);
    }

    public removeAppointment(appointment: AppointmentModel, index?: number): void {
        if (appointment && index > -1) {
            this.swal.confirm('คุณต้องการลบการติดต่อนัดหมายครั้งที่ ' + (index + 1) + ' ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        if (appointment.id) {
                            this.modelApi.delete(appointment)
                                .subscribe((response: any): void => {
                                    this.requirement.appointments.splice(index, 1);
                                    // this.requirement.latest_appointment = new AppointmentModel();
                                    setTimeout(() => {
                                        this.swal.success('ลบการติดต่อนัดหมายครั้งที่ ' + (index + 1) + ' สำเร็จ');
                                    }, 500);
                                }, error => {
                                    //
                                });
                        } else {
                            this.requirement.appointments.splice(index, 1);
                            // this.requirement.latest_appointment = new AppointmentModel();
                            setTimeout(() => {
                                this.swal.success('ลบการติดต่อนัดหมายครั้งที่ ' + (index + 1) + ' สำเร็จ');
                            }, 500);
                        }
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    private save_appointment(appointment: AppointmentModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            appointment.requirement_id = this.requirement.id;
            this.modelApi.createOrUpdate(appointment, [
                'requirement_id',
                'place_name', 'sale_id', 'type', 'other', 'date', 'start_time',
                'end_time', 'description', 'summary', 'meeting_start_time', 'meeting_end_time',
                'sale_id', 'contact_id', 'man_id', 'progress_type', 'drawing_no', 'approve_dwg_date',
                'quotation_no', 'track_quotation_1_date', 'track_quotation_2_date', 'demo_job_no',
                'purchase_order_no', 'load_job_no', 'todo', 'distance', 'todo_other'
            ], 'requirements/appointment', null, null, false, true)
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        appointment.clone(response.data);
                    }
                    resolve();
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public save_appointments(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let _promise_list: Promise<Object>[];
            _promise_list = [];
            if (this.requirement.appointments && this.requirement.appointments.length) {
                for (let i = 0; i < this.requirement.appointments.length; i++) {
                    const appointment: AppointmentModel = this.requirement.appointments[i];
                    if (appointment) {
                        _promise_list.push(this.save_appointment(appointment));
                    }
                }
                Promise.all(_promise_list)
                    .then(() => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public createDocument(data: any): void {
        this.api.request('requirements/document', 'PUT', {}, {
            document: data,
            id: this.requirement.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.requirement.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onUploadedSuccess(data: any): void {
        if (this.requirement && this.requirement.id) {
            this.createDocument(data);
        } else {
            this.save()
                .then(() => {
                    this.createDocument(data);
                });
        }
    }

    public onUploadError(data: any): void {
        //
    }

    private get_drawing_no(item_requirement: ItemRequirementModel): string {
        let _: string[];
        _ = [];
        for (let i = 0; i < item_requirement.item_requirement_documents.length; i++) {
            if (item_requirement.item_requirement_documents[i] && item_requirement.item_requirement_documents[i].modelable_type === 'App\\Drawing') {
                _.push(item_requirement.item_requirement_documents[i].name);
            }
        }
        return _.join(', ');
    }

    public createProduct(): void {
        this.swal.confirm('ต้องการดำนเนินการต่อไปใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.save()
                        .then(() => {
                            this.loader.show();
                            this.api.request('requirements/start', 'POST', {}, {
                                id      : this.requirement.id,
                                task_id : this.task.id
                            }).subscribe((response: any): void => {
                                if (response && response.success === true) {
                                    let promise_list: Promise<Object>[];
                                    promise_list = [];
                                    for (let i = 0; i < this.requirement.item_requirements.length; i++) {
                                        const item_requirement: ItemRequirementModel = this.requirement.item_requirements[i];
                                        if (item_requirement
                                            && item_requirement.todo
                                            && item_requirement.todo.indexOf('CREATE_NEW_SN') > -1) {
                                            promise_list.push(this.taskService.createNewTask({
                                                product_name            : item_requirement.product_name,
                                                quantity                : item_requirement.product_amount,
                                                drawing_no              : this.get_drawing_no(item_requirement),
                                                //
                                                process_slug            : 'estimate',
                                                current_role            : 'estimate',
                                                action                  : 'create_product',
                                                parent_id               : this.task.id,
                                                item_requirement_id     : item_requirement.id,
                                                todo                    : item_requirement.todo,
                                                customer_product_amount : item_requirement.product_amount
                                            }));
                                        } else {
                                            //
                                        }
                                    }
                                    Promise.all(promise_list)
                                        .then((product_task_responses: any): void => {
                                            let product_ids: any[];
                                            let quotation_promise_list: Promise<Object>[];
                                            quotation_promise_list = [];
                                            product_ids = [];
                                            if (product_task_responses) {
                                                for (let i = 0; i < product_task_responses.length; i++) {
                                                    const product_task_response: any = product_task_responses[i];
                                                    if (product_task_response &&
                                                        product_task_response.todo &&
                                                        product_task_response.todo.indexOf('QUOTATION') > -1 &&
                                                        product_task_response.modelable_type &&
                                                        product_task_response.modelable_id) {
                                                        //
                                                        if (this.merge_quotation === true) {
                                                            product_ids.push({
                                                                id: product_task_response.modelable_id,
                                                                customer_product_amount: product_task_response.contact_product_amount
                                                            });
                                                        } else {
                                                            quotation_promise_list.push(
                                                                this.createQuotation([{
                                                                    id: product_task_response.modelable_id,
                                                                    customer_product_amount: product_task_response.contact_product_amount
                                                                }])
                                                            );
                                                        }
                                                    }
                                                }
                                                if (this.merge_quotation === true) {
                                                    if (product_ids && product_ids.length > 0) {
                                                        this.createQuotation(product_ids)
                                                            .then(quotation_response => {
                                                                this.task_check_next_step();
                                                                this.loader.hide();
                                                            }, error => {
                                                                this.swal.danger(error);
                                                                this.loader.hide();
                                                            });
                                                    } else {
                                                        this.task_check_next_step();
                                                        this.loader.hide();
                                                    }
                                                } else if (quotation_promise_list.length > 0) {
                                                    Promise.all(quotation_promise_list)
                                                        .then(() => {
                                                            this.task_check_next_step();
                                                            this.loader.hide();
                                                        }, error => {
                                                            this.swal.danger(error);
                                                            this.loader.hide();
                                                        });
                                                } else {
                                                    this.task_check_next_step();
                                                    this.loader.hide();
                                                }
                                            } else {
                                                this.swal.danger(product_task_responses);
                                                this.loader.hide();
                                            }
                                        }, error => {
                                            this.swal.danger(error);
                                            this.loader.hide();
                                        });
                                } else {
                                    this.swal.danger(response.message);
                                    this.loader.hide();
                                }
                            }, error => {
                                this.swal.danger(error);
                                this.loader.hide();
                            });
                        }, error => {
                            this.swal.danger(error);
                            this.loader.hide();
                        });
                } else {
                    //
                }
            });
    }

    private task_check_next_step(): void {
        let action_list: string[];
        action_list = [];
        for (let i = 0; i < this.requirement.item_requirements.length; i++) {
            const item_requirement: ItemRequirementModel = this.requirement.item_requirements[i];
            if (item_requirement
                && item_requirement.todo) {
                for (let j = 0; j < item_requirement.todo.length; j++) {
                    action_list.push(item_requirement.todo[j]);
                }
            }
        }
        // CREATE_DRAWING, APPOINTMENT_DRAWING, FOLLOW_UP_QUOTATION, CREATE_DEMO, TAKE_PO, LOAD_JOB
        if (action_list.indexOf('CREATE_DRAWING') > -1
            || action_list.indexOf('APPOINTMENT_DRAWING') > -1
            || action_list.indexOf('FOLLOW_UP_QUOTATION') > -1
            || action_list.indexOf('CREATE_DEMO') > -1
            || action_list.indexOf('TAKE_PO') > -1
            || action_list.indexOf('LOAD_JOB') > -1) {
            // do nothing
            this.taskService.leave(this.task, true);
            this.swal.success('ดำเนินการสำเร็จ');
        } else {
            this.taskService.complete(this.task)
                .then(() => {
                    if (this.requirement.item_requirements && this.requirement.item_requirements.length > 0) {
                        this.taskService.leave(this.task, true);
                        this.swal.success('ดำเนินการสำเร็จ');
                    }
                });
        }
    }

    public createQuotation(product_ids: any[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let _product_ids: any[];
            _product_ids = [];
            let amount: number;
            amount = 1;
            for (let i = 0; i < product_ids.length; i++) {
                const product_id: any = product_ids[i];
                _product_ids.push({
                    product_id              : product_id.id,
                    price                   : 0,
                    amount                  : product_id.customer_product_amount,
                    customer_product_amount : product_id.customer_product_amount
                });
            }
            this.taskService.createNewTask({
                customer_id     : this.task.customer_id,
                parent_id       : this.task.id,
                process_slug    : 'quotation',
                current_role    : 'quotation',
                action          : 'create_quotation',
                modelable_type  : 'App\\Quotation',
                product_ids     : _product_ids
            }).then(() => {
                resolve();
            }, error => {
                reject();
            });
        });
        return promise;
    }

    public sendContact(): void {
        if (this.requirement && this.requirement.contact && this.requirement.contact.email) {
            this.swal.confirm('คุณต้องการส่งบันทึกการติดต่อนี้ <br/>' +
                ' กับลูกค้า <i>' + this.requirement.contact.full_name + '</i><br/>' +
                ' ผ่านทางอีเมล <strong class="text-primary">"' + this.requirement.contact.email + '"</strong>' +
                ' ใช่หรือไม่?', true)
                .then((result: boolean): void => {
                    if (result === true) {
                        this.swal.success('ส่งบันทึกการติดต่อผ่านทางอีเมลลูกค้าสำเร็จ')
                            .then(() => {
                                this.loader.show();
                                this.api.request('mail/contact', 'POST', null, {
                                    task_id         : this.task.id,
                                    requirement_id  : this.requirement.id
                                }).subscribe((response: any): void => {
                                    this.loader.hide();
                                    this.leave(true);
                                }, error => {
                                    //
                                });
                            });
                    } else {
                        //
                    }
                });
        } else if (this.requirement && this.requirement.contact && !this.requirement.contact.email) {
            this.swal.input('กรุณากรอกอีเมลลูกค้าสำหรับการส่งใบบันทึกการติดต่อ', 'ข้อมูลอีเมล', 'อีเมล', 'ส่งใบบันทึกการติดต่อ')
                .then((result: any): void => {
                    if (result) {
                        this.api.request('contacts/email', 'POST', {}, {
                            id      : this.requirement.contact_id,
                            email   : result
                        }).subscribe((contact_response: any): void => {
                            if (contact_response && contact_response.success === false) {
                                this.swal.danger(contact_response.message);
                            } else {
                                this.loader.show();
                                this.api.request('mail/contact', 'POST', null, {
                                    task_id         : this.task.id,
                                    requirement_id  : this.requirement.id
                                }).subscribe((response: any): void => {
                                    this.loader.hide();
                                    this.leave(true);
                                }, error => {
                                    //
                                });
                            }
                        }, error => {
                            this.swal.danger(error);
                        });
                    } else {
                        //
                    }
                }, error => {
                    //
                });
        }
    }

    public printAppointment(): void {
        this.save()
            .then(() => {
                this.viewer.contactLog(this.requirement)
                    .then(() => {
                        //
                    });
            });
    }

    public startAppointment(): void {
        if (!this.check_appointments(true)) {
            //
        } else {
            let sale_name: string;
            if (this.requirement.latest_appointment && this.requirement.latest_appointment.sale) {
                sale_name = this.requirement.latest_appointment.sale.full_name;
            } else if (this.requirement.appointments[0] && this.requirement.appointments[0].sale) {
                sale_name = this.requirement.appointments[0].sale.full_name;
            }
            this.swal.confirm('ยืนยันการนัดหมายให้กับ ' + sale_name + ' ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.save()
                            .then(() => {
                                this.api.request('requirements/start', 'POST', {}, {
                                    id      : this.requirement.id,
                                    task_id : this.task.id
                                }).subscribe((response: any): void => {
                                    if (response && response.success === true) {
                                        this.swal.success('เริ่มการนัดหมายให้กับ ' + sale_name + ' สำเร็จ');
                                        this.leave(true);
                                    } else {
                                        this.swal.danger(response.message);
                                    }
                                }, error => {
                                    this.swal.danger(error);
                                });
                            }, error => {
                                this.swal.danger(error);
                            });
                    } else {
                        //
                    }
                });
        }
    }

    public check_appointments(is_alert?: boolean): boolean {
        if (!this.requirement.customer_id) {
            if (is_alert === true) {
                this.swal.danger('กรุณาเลือกลูกค้า หรือบริษัทที่ต้องการติดต่อ');
            }
            return false;
        }
        if (!this.requirement.name) {
            if (is_alert === true) {
                this.swal.danger('กรุณาระบุชื่องาน หรือชื่อสินค้า');
            }
            return false;
        }
        if (!this.requirement.type) {
            if (is_alert === true) {
                this.swal.danger('กรุณาระบุลักษณะของงาน');
            }
            return false;
        }
        for (let i = 0; i < this.requirement.appointments.length; i++) {
            const appointment: AppointmentModel = this.requirement.appointments[i];
            if (appointment) {
                if (!appointment.date || !appointment.start_time) {
                    if (is_alert === true) {
                        this.swal.danger('กรุณาระบุวันที่หรือเวลาของการนัดหมายครั้งที่ #' + (i + 1));
                    }
                    return false;
                } else if (!appointment.place_name) {
                    if (is_alert === true) {
                        this.swal.danger('กรุณาระบุสถานที่ของการนัดหมายครั้งที่ #' + (i + 1));
                    }
                    return false;
                } else if (!appointment.sale || !appointment.sale.id) {
                    if (is_alert === true) {
                        this.swal.danger('กรุณาระบุผู้เข้าไปติดต่อของการนัดหมายครั้งที่ #' + (i + 1));
                    }
                    return false;
                }
            }
        }
        return true;
    }

    public getItemRequriementIds(): any[] {
        let item_requirement_ids: any[];
        item_requirement_ids = [];
        for (let i = 0; i < this.requirement.item_requirements.length; i++) {
            const item_requirement: ItemRequirementModel = this.requirement.item_requirements[i];
            if (item_requirement) {
                item_requirement_ids.push({
                    id              : item_requirement.id,
                    product_name    : item_requirement.product_name,
                    product_amount  : item_requirement.product_amount,
                    description     : item_requirement.description,
                    todo            : item_requirement.todo,
                    guid            : item_requirement.guid,
                    todo_other      : item_requirement.todo_other
                });
            }
        }
        return item_requirement_ids;
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject): void => {
            this.requirement.item_requirement_ids = this.getItemRequriementIds();
            this.requirement.task_id = this.task.id;
            this.modelApi.createOrUpdate(this.requirement, [
                'type', 'name', 'drawing_no', 'remark', 'material_name', 'minutes', 'ref_no', 'task_id',
                'other', 'sale_id', 'customer_id', 'contact_id', 'task_id', 'quotation_no', 'purchase_order_no', 'other_document',
                'summary_drawing_no', 'summary_drawing_no_date', 'summary_quotation_no', 'summary_quotation_date', 'summary_quotation_1',
                'summary_quotation_2', 'summary_job_no', 'summary_job_no_date', 'summary_purchase_order_no',
                'summary_purchase_order_no_date', 'summary_load_job_no', 'summary_load_job_no_date', 'description',
                'todo', 'customer_status', 'item_requirement_ids', 'date', 'start_time', 'end_time', 'place_name', 'todo_other'
            ], null, null, null, false)
                .subscribe((response: any): void => {
                    if (response && response.data && !this.requirement.id) {
                        this.requirement.id = response.data.id;
                    }
                    if (response && response.data && response.data.item_requirements) {
                        for (let i = 0; i < response.data.item_requirements.length; i++) {
                            const dat_item_requirement: any = response.data.item_requirements[i];
                            if (dat_item_requirement) {
                                const item_requirement: ItemRequirementModel = this.requirement.item_requirements
                                    .find(item => item.guid === dat_item_requirement);
                                if (item_requirement) {
                                    item_requirement.clone(dat_item_requirement);
                                }
                            }
                        }
                    }
                    this.save_appointments()
                        .then(() => {
                            resolve();
                        }, error => {
                            reject(error);
                        });
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onSaveApiCallingBack(e: any): void {
        this.saveSubject.next(true);
    }

}
