import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskModel} from '../../../models/task.model';
import {TaskService} from '../../../services/task.service';
import {PusherService} from '../../../services/pusher.service';
import {DivApiDirective} from '../../../now/divApi';
import {UserService} from '../../../services/user.service';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {ModalService} from '../../../services/modal.service';
import {FindProductComponent} from '../../../modals/findProduct/findProduct.component';
import {FindQuotationComponent} from '../../../modals/findQuotation/findQuotation.component';
import {FindRequirementComponent} from '../../../modals/findRequirement/findRequirement.component';
import {Api} from '../../../now/api/api';
import {AppService} from '../../../app/app.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ViewRemarkComponent} from '../view/viewRemark/viewRemark.component';
import {FindPOComponent} from '../../../modals/findPO/findPO.component';
import {LoaderService} from '../../../components/loader/loader.service';
import {Viewer} from '../../../services/viewer';
import {CARModalComponent} from '../car/carModal/carModal.component';
import {Subject} from 'rxjs';
import {Subscription} from 'rxjs/internal/Subscription';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-task-list-component',
    templateUrl: 'taskList.component.html',
    styleUrls: ['taskList.component.scss']
})
export class TaskListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChildren(DivApiDirective) divApi: QueryList<DivApiDirective>;

    public total_items: number = 1;
    public current_page: number = 1;
    public item_per_page: number = 100;
    public isPagination: boolean;
    public isLoading: boolean;

    private searchSubject: Subject<string> = new Subject<string>();
    private subscriptions: Subscription[] = [];

    public taskList: TaskListComponent;
    public keyword: string;
    public all_tasks: TaskModel[];
    public room_slug: string;
    public current_job_document_path: string;
    public current_task: TaskModel;
    public timeout: any;
    public customers: any;
    public assemblies: any;
    public pdf_viewer_path: string;
    public pdf_viewer_title: string;
    public role: string;
    public channel_name: string;
    public pageWidth: string;
    public pageHeight: string;
    public config: PerfectScrollbarConfigInterface;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modal: ModalService,
        private taskService: TaskService,
        private userService: UserService,
        private modelApi: ModelApi,
        private swal: SwalService,
        private ngZone: NgZone,
        private pusherService: PusherService,
        private api: Api,
        private viewer: Viewer,
        private appService: AppService,
        private loader: LoaderService
    ) {
        //
        this.keyword = '';
        this.isLoading = true;
        this.isPagination = false;
        this.assemblies = [];
        this.initRoom();
        this.pageWidth = 'calc(100vw - 16px)';
        this.config = {
            useBothWheelAxes: false,
            suppressScrollX: false,
            suppressScrollY: true
        };
        this.taskList = this;
    }

    private initRoom(): void {
        this.all_tasks = [];
        this.route
            .params
            .subscribe(params => {
                this.room_slug = params.role;
                const channel_name: string = 'erpst.task.' + this.room_slug;
                this.channel_name = channel_name;
            });
    }

    ngAfterViewInit(): void {
        this.appService.isTaskListPage = true;
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.searchSubject.pipe(
                debounceTime(750)
            ).subscribe(searchKeyword => {
                this.refresh();
            })
        )
    }

    ngOnDestroy(): void {
    }

    viewHR() {
        //
    }

    public onAssemblyCheck(tasks: any[], e?: any): void {
        //
    }

    public onShippingDocumentSubmit(e: any): void {
        if (e && e.tasks && e.tasks.length) {
            let task_ids: any[];
            task_ids = [];
            for (const child of e.tasks) {
                if (child && child.id) {
                    task_ids.push(child.id);
                }
            }
            if (task_ids.length) {
                this.loader.show();
                this.api.request('shipping/documents', 'PUT', {}, {
                    task_ids : task_ids
                }).subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.loader.hide();
                        this.swal.success('ออกเอกสารแผนก Shipping สำเร็จ');
                        this.refresh();
                    } else {
                        this.loader.hide();
                    }
                }, error => {
                    this.loader.hide();
                });
            } else {
                this.swal.danger('ไม่พบรายการงานของลูกค้า "' + e.customer.customer_name + '"');
            }
        } else {
            //
        }
    }

    public onCustomerCheck(customer: any, e?: any): void {
        if (customer && customer.children) {
            for (let i = 0; i < customer.children.length; i++) {
                const task: any = customer.children[i];
                if (task && task.id) {
                    task._checked = e;
                }
            }
        }
    }

    public createPackingList(): void {
        //
    }

    public refresh(): void {
        this.timeout = setTimeout(() => {
            this.clear_timeout();
            this.isLoading = true;
            this.divApi.forEach((divApi: DivApiDirective) => {
                if (divApi && divApi.refresh) {
                    divApi.refresh({
                        limit: this.item_per_page,
                        page: this.current_page,
                        find: (this.keyword) ? this.keyword : '',
                    }).subscribe(() => {
                        //
                    });
                } else {
                    console.warn('divApi is undefinfed', divApi);
                }
            });
        }, 300);
    }

    public clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSuccess(response: any): void {
        this.isPagination = false;
        if (response && response.data) {
            this.render(response.data);
            if (response.meta && response.meta.total_pages > 1 && response.meta.total_items) {
                this.total_items = response.meta.total_items;
                this.isPagination = true;
            }
        }
    }

    public pageChange(page: number): void {
        this.current_page = page;
        console.log('this.current_page', this.current_page);
        this.refresh();
    }

    public onError(e: any): void {
        //
    }

    gotoTaskView(task: TaskModel | any): void {
        console.log(task);
        this.taskService.gotoTask(task);
    }

    private render(data: any): void {
        if (this.room_slug !== 'shipping' && this.room_slug !== 'assembly' && this.room_slug !== 'shipping-document' && this.room_slug !== 'finish' && this.room_slug !== 'po-store') {
            this.clear_tasks();
            for (let i = 0; i < data.length; i++) {
                let dat: any;
                dat = data[i];
                if (dat) {
                    let task: TaskModel;
                    task = new TaskModel();
                    task.clone(dat);
                    this.all_tasks.push(task);
                }
            }
        } else if (this.room_slug === 'po-store') {
            this.clear_tasks();
            for (let i = 0; i < data.length; i++) {
                let dat: any;
                dat = data[i];
                if (dat) {
                    let task: TaskModel;
                    task = new TaskModel();
                    task.clone(dat);
                    this.all_tasks.push(task);
                }
            }
            this.all_tasks = this.all_tasks.sort((a, b) => {
                if (a && a.modelable && a.modelable.inventory_date && b && b.modelable && b.modelable.inventory_date) {
                    return a.modelable.inventory_date.localeCompare(b.modelable.inventory_date);
                } else {
                    return 0;
                }
            });
        } else if (this.room_slug === 'shipping') {
            this.clear_tasks();
            for (const key in data) {
                if (key && data[key]) {
                    let children: any[];
                    children = [];
                    for (const job of data[key]) {
                        if (job && job.id) {
                            this.all_tasks.push(job.task);
                            children.push(job.task);
                        }
                    }
                    this.customers.push({
                        customer_name: key,
                        children: children
                    });
                }
            }
        } else if (this.room_slug === 'shipping-document' || this.room_slug === 'finish') {
            this.clear_tasks();
            for (const dat of data) {
                if (dat && dat.id) { // dat is task model
                    this.all_tasks.push(dat);
                    this.customers.push(dat);
                }
            }
            console.log(this.all_tasks);
            console.log(this.customers);
        } else if (this.room_slug === 'assembly') {
            this.clear_tasks();
            for (const dat of data) {
                if (dat && dat.id) { // dat is job model
                    this.all_tasks.push(dat.task);
                    const index = this.assemblies.push(dat.task) - 1;
                    const assembly = this.assemblies[index];
                    assembly.children = (dat.children && dat.children.length) ? dat.children : [];
                }
            }
        }

        this.isLoading = false;
    }

    public onClickHireHandler(e: any, task: TaskModel): void {
        e.stopPropagation();
        this.taskService.hire(task)
            .then(() => {
                // Go Go!
            });
    }

    public viewRemark(task: TaskModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        this.current_task = task;
        if (task) {
            this.modal.show(ViewRemarkComponent, {
                task: task
            }, { class: 'modal-lg' }).then(() => {
                //
            });
        } else {
            //
        }
    }

    public viewQuotation(task: TaskModel, quotation_no?: string, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.quotation(quotation_no)
            .then(() => {
                //
            });
    }

    public viewJob(task: TaskModel, job_no?: string, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        if (job_no && task.modelable_type === 'App\\Job') {
            this.viewer.manufacture(job_no, true, true)
                .then(path => {
                    //
                });
        }
    }

    public viewPayment(purchase_order_no: string, task?): void {
        console.log(task);
        if (task && (task.process_slug === 'po-store' || task.process_slug === 'po-store')) {
            this.viewer.purchaseOrder(purchase_order_no)
                .then(() => {
                    //
                });
        } else {
            this.viewer.payment(purchase_order_no)
                .then(() => {
                    //
                });
        }
    }

    public viewTaxInvoicePreview(tax_invoice_no: string): void {
        this.viewer.taxInvoice(tax_invoice_no)
            .then(() => {
                //
            });
    }

    public viewInvoicePreview(invoice_no: string): void {
        this.viewer.invoice(invoice_no)
            .then(() => {
                //
            });
    }

    public viewReceiptPreview(receipt_no: string): void {
        this.viewer.receipt(receipt_no)
            .then(() => {
                //
            });
    }

    public viewDepositPreview(deposit_no: string): void {
        this.viewer.deposit(deposit_no)
            .then(() => {
                //
            });
    }

    public viewPackingSlipPreview(packing_slip_no: string): void {
        this.viewer.packingSlip(packing_slip_no)
            .then(() => {
                //
            });
    }

    public viewDrawingPreview(drawing_no: string, task: TaskModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        if (drawing_no && task.product_no) {
            this.viewer.drawingWithProductNO(drawing_no, task.product_no);
        } else {
            this.viewer.drawing(drawing_no);
        }
    }

    private clear_tasks(): void {
        if (this.all_tasks) {
            this.all_tasks.splice(0, this.all_tasks.length);
        } else {
            this.all_tasks = [];
        }
        if (this.customers) {
            this.customers.splice(0, this.customers.length);
        } else {
            this.customers = [];
        }
        if (this.assemblies) {
            this.assemblies.splice(0, this.assemblies.length);
        } else {
            this.assemblies = [];
        }
    }

    public createItem(): void {
        this.router.navigateByUrl('/store');
    }

    public gotoCustomerList(): void {
        this.router.navigateByUrl('/database/customer');
    }

    public gotoQuotationList(): void {
        this.router.navigateByUrl('/database/quotation');
    }

    public findProduct(): void {
        this.modal.show(FindProductComponent)
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.task && content.task.id) {
                        this.taskService.gotoTask(content.task);
                    } else if (content.product_id) {
                        this.createProduct(content.product_id);
                    }
                } else {
                    //
                }
            });
    }

    public findQuotation(): void {
        this.modal.show(FindQuotationComponent)
            .then((content: any): void => {
                if (content && content.submit === true) {
                    this.taskService.gotoTask(content.task);
                } else {
                    //
                }
            });
    }

    public findPO(): void {
        this.modal.show(FindPOComponent)
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.task && content.task.id) {
                        this.taskService.gotoTask(content.task);
                    }
                } else {
                    //
                }
            });
    }

    public createPurchase(): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'purchase';
        task.current_role = 'purchase';
        task.action = 'create_purchase';
        this.taskService.hire(task, null, false, task.action)
            .then(() => {
                //
            });
    }

    public findRequirement(): void {
        this.modal.show(FindRequirementComponent)
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.task && content.task.id) {
                        this.taskService.gotoTask(content.task);
                    } else {
                        //
                    }
                } else {
                    //
                }
            });
    }

    public createRequirement(): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'requirement';
        task.current_role = 'contact';
        task.action = 'create_requirement';
        task.modelable_type = 'App\\Requirement';
        this.taskService.hire(task, null, false, 'create_requirement')
            .then(() => {
                //
            });
    }

    public openCARModal(): void {
        this.modal.show(CARModalComponent, {
            //
        }, { class: 'modal-lg' }).then(() => {
            //
        });
    }

    public createProduct(product_id?: string, force?: boolean): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'estimate';
        task.current_role = 'estimate';
        task.action = 'create_product';
        task.modelable_type = 'App\\Product';
        task.next_role = 'pd-planning';
        if (product_id) {
            task.modelable_id = product_id;
        } else {
            //
        }
        this.taskService.hire(task, null, ((!force) ? false : true), task.action, task.action)
            .then(() => {
                // Go Go!
            });
    }

    public createQuotation(): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'quotation';
        task.current_role = 'quotation';
        task.action = 'create_quotation';
        task.modelable_type = 'App\\Quotation';
        this.taskService.hire(task, null, false, 'create_quotation')
            .then(() => {
                // Go Go!
            });
    }

    public onKeywordInput(e: any): void {
        this.searchSubject.next(e);
    }

}
