import {AfterViewInit, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../app/app.service';
import {Api} from '../../now/api/api';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {UserTaskServices} from '../../services/userTask.services';

export const MAX_MAN = 5;

@Component({
    selector: 'app-home-component',
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

    private apiSubscription: Subscription;
    private subscriptions: Subscription[] = [];

    public parent_role: any;
    public user_roles: any[];
    public max_man: number;
    public slug: string;

    public init: boolean;

    constructor(
        private userTask: UserTaskServices,
        private appService: AppService,
        private api: Api,
        private route: ActivatedRoute
    ) {
        //
        this.init = true;
        this.max_man = MAX_MAN;
        this.user_roles = [];
        this.parent_role = {};
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.params
                .subscribe(params => {
                    this.slug = params['slug'];
                    console.log('this.slug', this.slug);
                    const promise_list: Promise<Object>[] = [
                        this.userTask.getUserRoles(this.slug),
                        this.userTask.getUserTasks()
                    ];
                    Promise.all(promise_list)
                        .then((response: any[]): void => {
                            console.log(response);
                            if (response && response[0]) {
                                const data = (response[0] && response[0].length) ? response[0] : [];
                                if (data && data.length) {
                                    if (this.slug) {
                                        const role: any = this.getRole(this.slug, response[0]);
                                        if (role) {
                                            this.parent_role = role;
                                            this.user_roles = role.children;
                                        } else {
                                            this.parent_role = {
                                                display_name: 'หน้าหลัก'
                                            };
                                            this.user_roles = response[0];
                                        }
                                    } else {
                                        this.parent_role = {
                                            display_name: 'หน้าหลัก'
                                        };
                                        this.user_roles = response[0];
                                    }
                                    console.log(this.user_roles);
                                }
                            }
                            if (response && response[1]) {
                                console.log(response[1]);
                                this.render(response[1]);
                            }
                        }, error => {
                            //
                        });
                })
        );
    }

    ngAfterViewInit(): void {
        //
    }

    public getRole(slug: string, data: any[]): any {
        let find_role: any;
        find_role = data.find(i => i.name === slug);
        console.log(find_role);
        if (find_role) {
            return find_role;
        } else if (data && data.length) {
            for (let i = 0; i < data.length; i++) {
                const dat: any = data[i];
                if (dat && dat.children && dat.children.length > 0) {
                    return this.getRole(slug, dat.children);
                }
            }
        }
        return null;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(i => i.unsubscribe());
        this.unsubscribe();
    }

    private unsubscribe(): void {
        if (this.apiSubscription) {
            this.apiSubscription.unsubscribe();
            this.apiSubscription = null;
        }
    }

    public setupNewest(roles: any[]): void {
        const datetime_now: any = moment(new Date());
        for (let i = 0; i < roles.length; i++) {
            const role: any = roles[i];
            const tasks: any[] = role.tasks;
            console.log(tasks);
            if (tasks) {
                for (let k = 0; k < tasks.length; k++) {
                    if (tasks[k] && tasks[k].status_date) {
                        const status_date: any = moment(tasks[k].status_date);
                        const asMinutes: number = moment.duration(datetime_now.diff(status_date)).asMinutes();
                        if (role && !role.newest) {
                            role.newest = (asMinutes > 0 && asMinutes < 8) ? true : false;
                            if (role.newest === true) {
                                break;
                            }
                        }
                    } else if (role && !role.newest) {
                        role.newest = false;
                    }
                }
            }
        }
    }

    public setupRooms(roles: any[], data: any, parent?: any): void {
        const { tasks, task_handles } = data;
        for (let i = 0; i < roles.length; i++) {
            const user_role: any = roles[i];
            if (user_role && user_role.name) {
                let dat: any;
                let dat_handles: any;
                dat = (tasks && tasks[user_role.name]) ? tasks[user_role.name] : [];
                dat_handles = (task_handles && task_handles[user_role.name]) ? task_handles[user_role.name] : [];
                // user_role.tasks = (user_role.tasks && user_role.tasks.length > 0)
                //     ? user_role.tasks.concat((dat) ? dat : []) : (dat) ? dat : [];
                if (user_role.tasks && user_role.tasks.length) {
                    for (const task of user_role.tasks) {
                        const exist = user_role.tasks.find(j => j.id === task.id);
                        if (!exist) {
                            user_role.tasks.push(task);
                        }
                    }
                } else {
                    user_role.tasks = [];
                    if (dat && dat.length) {
                        for (const task of dat) {
                            const exist = user_role.tasks.find(j => j.id === task.id);
                            if (!exist) {
                                user_role.tasks.push(task);
                            }
                        }
                    }
                }
                user_role.num_tasks = user_role.tasks.length;

                // user_role.task_handles = (user_role.task_handles && user_role.task_handles.length > 0)
                //     ? user_role.task_handles.concat((dat_handles) ? dat_handles : []) : (dat_handles) ? dat_handles : [];
                if (user_role.task_handles && user_role.task_handles.length) {
                    for (const task of user_role.task_handles) {
                        const exist = user_role.task_handles.find(j => j.id === task.id);
                        if (!exist) {
                            user_role.task_handles.push(task);
                        }
                    }
                } else {
                    user_role.task_handles = [];
                    if (dat_handles && dat_handles.length) {
                        for (const task of dat_handles) {
                            const exist = user_role.task_handles.find(j => j.id === task.id);
                            if (!exist) {
                                user_role.task_handles.push(task);
                            }
                        }
                    }
                }

                if (parent) {
                    for (const task of user_role.tasks) {
                        const exist = parent.tasks.find(j => j.id === task.id);
                        if (!exist) {
                            console.log(task);
                            parent.tasks.push(task);
                        }
                    }
                    parent.num_tasks = parent.tasks.length;
                    // parent.tasks = parent.tasks.concat(user_role.tasks);
                }
                if (user_role.children && user_role.children.length > 0) {
                    this.setupRooms(user_role.children, {
                        tasks, task_handles
                    }, (parent) ? parent : user_role);
                }
            }
        }
    }

    private render(data: any): void {
        console.log(data);
        const tasks = {};
        const task_handles = {};

        if (data) {
            if (data.tasks) {
                for (const dat of data.tasks) {
                    if (dat && dat.current_role) {
                        const role = dat.current_role;
                        if (!tasks[role]) {
                            tasks[role] = [];
                        }
                        const exist = tasks[role].find(i => i.id === dat.id);
                        if (!exist) {
                            tasks[role].push(dat);
                        }
                    }
                }
            }
            if (data.task_handles) {
                for (const dat of data.task_handles) {
                    if (dat && dat.current_role) {
                        const role = dat.current_role;
                        if (!task_handles[role]) {
                            task_handles[role] = [];
                        }
                        const exist = task_handles[role].find(i => i.id === dat.id);
                        if (!exist) {
                            task_handles[role].push(dat);
                        }
                    }
                }
            }
        }

        console.log(tasks);
        console.log(task_handles);

        this.setupRooms(this.user_roles, { tasks, task_handles });
        this.setupNewest(this.user_roles);

        console.log(this.user_roles.length);
    }

}
