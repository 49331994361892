import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {DepositModel} from '../../models/deposit.model';
import {DepositDetailComponent} from '../../pages/task/view/depositDetail/depositDetail.component';
import {ModalService} from '../../services/modal.service';
import {TaskModel} from '../../models/task.model';
import {DocumentModel} from '../../models/document.model';
import {Api} from '../../now/api/api';
import {UploaderComponent} from '../uploader/uploader.component';
import {SwalService} from '../../services/swal.service';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'deposit-table-component',
    templateUrl: 'depositTable.component.html',
    styleUrls: ['depositTable.component.scss']
})
export class DepositTableComponent implements AfterViewInit {

    @ViewChild(UploaderComponent, { static: true }) uploader: UploaderComponent;
    @Input() deposits: DepositModel[];
    @Input() model: any;
    @Input() task: TaskModel;

    public current_deposit: DepositModel;

    constructor(
        public viewer: Viewer,
        private modal: ModalService,
        private swal: SwalService,
        private api: Api
    ) {
        this.deposits = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewDepositDetail(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.deposit) {
                    deposit.status = content.deposit.status;
                }
            });
    }

    public viewDepositPreview(deposit: DepositModel): void {
        this.viewer.deposit(deposit);
    }

    public createDeposit(): void {
        const deposit: DepositModel = new DepositModel();
        deposit.grand_total = this.model.grand_total;
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.submit === true) {
                    // this.quotation.deposits.push(deposit);
                    if (content.is_create === true) {
                        deposit.clone(content.deposit);
                        // this.deposits.push(deposit);
                        this.model.deposits.push(deposit);
                        this.viewDepositPreview(deposit);
                    }
                } else {
                    //
                }
            });
    }

    public attachFile(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.current_deposit = deposit;
        this.uploader.trigger();
    }

    public viewFile(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(deposit.document_id);
    }

    public removeFile(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.swal.confirm('คุณต้องการลบเอกสารแนบใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('deposits/document/delete', 'POST', {}, {
                        id: deposit.id
                    }).subscribe((response: any): void => {
                        this.swal.success('ลบเอกสารแนบสำเร็จ');
                        deposit.document_id = null;
                        deposit.document = null;
                    });
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.current_deposit && this.current_deposit.id) {
            this.api.request('deposits/document', 'PUT', {}, {
                document: data,
                id: this.current_deposit.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.current_deposit.document_id = new_document.id;
                    this.current_deposit.document = new_document;
                    this.viewFile(this.current_deposit);
                }
                this.current_deposit = null;
            }, error => {
                this.current_deposit = null;
            });
        }
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
