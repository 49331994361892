import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import * as moment from 'moment';
import {DepositModel} from '../../../../models/deposit.model';
import {CustomerModel} from '../../../../models/customer.model';
import {JobModel} from '../../../../models/job.model';
import {ProductModel} from '../../../../models/product.model';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'app-deposit-detail-component',
    templateUrl: 'depositDetail.component.html',
    styleUrls: ['depositDetail.component.scss']
})
export class DepositDetailComponent implements OnInit {

    public deposit: DepositModel;
    public tmp_deposit: DepositModel;
    public credit_terms: number;
    public percent: number;
    public task: TaskModel;
    public model: any;
    public products: ProductModel[];
    public customer: CustomerModel;
    public job: JobModel;
    public is_new: boolean;
    public type: number;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService,
        public viewer: Viewer,
    ) {
        //
        this.deposit = new DepositModel();
        this.tmp_deposit = new DepositModel();
        // this.quotation = new QuotationModel();
        this.products = [];
        // if (this.model && this.model.customer) {
        //     this.customer = this.model.customer;
        // }
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.model) {
                this.model = this.bsModalRef.content.model;
                if (this.model && this.model.jobs) {
                    for (const job of this.model.jobs) {
                        if (job && job.id) {
                            job._checked = true;
                        }
                    }
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.type) {
                this.type = this.bsModalRef.content.type;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.products) {
                this.products = this.bsModalRef.content.products;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.deposit) {
                // if (!this.bsModalRef.content.deposit.started_at) {
                //     this.bsModalRef.content.deposit.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                // }
                this.deposit = this.bsModalRef.content.deposit;
                // this.tmp_deposit.clone(this.deposit);
                this.tmp_deposit.started_at = this.deposit.started_at;
                this.tmp_deposit.ended_at = this.deposit.ended_at;
                this.tmp_deposit.id = this.deposit.id;
                this.tmp_deposit.deposit_no = this.deposit.deposit_no;
                this.tmp_deposit.required_at = this.deposit.required_at;
                this.tmp_deposit.remark = this.deposit.remark;
                this.tmp_deposit.status = (this.deposit.status) ? this.deposit.status : 2;
                this.tmp_deposit.grand_total = this.deposit.grand_total;
                this.tmp_deposit.total_price = this.deposit.total_price;

                if (this.deposit.customer && this.deposit.customer.credit_terms > 0) {
                    this.credit_terms = this.deposit.customer.credit_terms;
                } else {
                    this.credit_terms = 0;
                }

                if (!this.tmp_deposit.started_at) {
                    this.tmp_deposit.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                // if (!this.tmp_deposit.ended_at && this.tmp_deposit.started_at && this.credit_terms > 0) {
                //     this.tmp_deposit.ended_at = moment(this.tmp_deposit.started_at, 'YYYY-MM-DD HH:mm:ss')
                //         .add(this.credit_terms, 'days')
                //         .format('YYYY-MM-DD HH:mm:ss');
                // }

                this.is_new = this.deposit.isNew;
                // this.percent = 50;
                // this.onPercentChanged();
                if (!this.tmp_deposit.total_price) {
                    // 50 percents
                    this.tmp_deposit.total_price = this.deposit.grand_total * .5;
                }
                this.onTotalPriceChanged();
                this.onPercentChanged();
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
            }
        }, 0);
    }

    public onStartedChanged(e: any): void {
        if (e) {
            const started_moment_object: any = moment(e, 'YYYY-MM-DD HH:mm:ss');
            const ended_moment_object: any = moment(this.tmp_deposit.ended_at, 'YYYY-MM-DD HH:mm:ss');
            this.credit_terms = moment.duration(ended_moment_object.diff(started_moment_object)).asDays();
        }
    }

    public onTotalPriceChanged(e?: any): void {
        setTimeout(() => {
            const grand_total: number = this.deposit.grand_total;
            if (grand_total > 0) {
                this.percent = (this.tmp_deposit.total_price * 100) / grand_total;
            }
        }, 100);
    }

    public onPercentChanged(e?: any): void {
        setTimeout(() => {
            const grand_total: number = this.deposit.grand_total;
            this.tmp_deposit.total_price = (grand_total * this.percent) / 100;
        }, 100);
    }

    public delete(): void {
        if (this.deposit.status === 1) {
            return;
        }
        const name: string = (this.tmp_deposit.deposit_no)
            ? this.tmp_deposit.deposit_no : 'ใบวางเงินมัดจำ';
        this.swal.confirm('คุณต้องการลบเอกสารใบสั่งซื้อ "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.modelApi.delete(this.tmp_deposit)
                        .subscribe((response: any): void => {
                            const index: number = this.model.deposits.findIndex(i => i.id === this.tmp_deposit.id);
                            if (index > -1) {
                                this.model.deposits.splice(index, 1);
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public viewDeposit(): void {
        this.viewer.deposit(this.tmp_deposit);
    }

    public update(): void {
        if (this.tmp_deposit && !this.tmp_deposit.id) {
            this.swal.confirm('ยืนยันการออกใบวางเงินมัดจำใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('deposits', 'PUT', {}, {
                            customer_id: this.model.customer_id,
                            model_id: this.model.id,
                            type: this.type,
                            // tax_invoice_id: this.model.id,
                            bill_id: this.model.bill_id,
                            customer_address_id: this.model.customer_address_id,
                            contact_id: this.model.contact_id,
                            incoterm_code: this.model.incoterm_code,
                            status: this.tmp_deposit.status,
                            started_at: this.tmp_deposit.started_at,
                            total_price: this.tmp_deposit.total_price,
                            remark: this.tmp_deposit.remark,
                            jobs: this.get_jobs()
                        }).subscribe((response: any): void => {
                            this.swal.success('ออกใบวางเงินมัดจำสำเร็จ');
                            this.deposit.clone(response.data);
                            this.bsModalRef.content.deposit = this.deposit;
                            this.bsModalRef.content.is_submit = this.is_new;
                            this.bsModalRef.content.submit = false;
                            this.tmp_deposit = this.deposit;
                            this.viewDeposit();
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        } else {
            this.swal.confirm('ยืนยันการแก้ไขเอกสารใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('deposits/status', 'POST', {}, {
                            status: this.tmp_deposit.status,
                            id: this.tmp_deposit.id
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.bsModalRef.content.deposit.status = this.tmp_deposit.status;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                        /*this.api.request('deposits', 'POST', {}, {
                            id: this.tmp_deposit.id,
                            // customer_id: this.model.customer_id,
                            // model_id: this.model.id,
                            // bill_id: this.model.bill_id,
                            // customer_address_id: this.model.customer_address_id,
                            // contact_id: this.model.contact_id,
                            // incoterm_code: this.model.incoterm_code,
                            status: this.tmp_deposit.status,
                            // started_at: this.tmp_deposit.started_at,
                            // total_price: this.tmp_deposit.total_price,
                            // remark: this.tmp_deposit.remark,
                            // jobs: this.get_jobs()
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.bsModalRef.content.deposit.status = this.tmp_deposit.status;
                            // this.bsModalRef.content.deposit.started_at = this.tmp_deposit.started_at;
                            // this.bsModalRef.content.deposit.total_price = this.tmp_deposit.total_price;
                            // this.bsModalRef.content.deposit.grand_total = this.tmp_deposit.grand_total;
                            // this.bsModalRef.content.deposit.remark = this.tmp_deposit.remark;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });*/
                    }
                });
        }
    }

    private get_jobs(): any {
        let job_ids: any;
        job_ids = {};
        if (this.model && this.model.jobs) {
            for (const job of this.model.jobs) {
                if (job && job._checked) {
                    job_ids[job.id] = {
                        amount: job.pivot.amount,
                        price_per_unit: job.pivot.price_per_unit
                    };
                }
            }
        }
        return job_ids;
    }

    public complete(): void {
        if (this.tmp_deposit.total_price <= 0 || !this.tmp_deposit.total_price) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากไม่มียอดเงินวางมัดจำ');
        } else {
            this.swal.confirm('ยืนยันการออกใบวางเงินมัดจำเป็นจำนวนเงิน "' + this.tmp_deposit.total_price + '" บาท ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.save()
                            .then(() => {
                                this.viewDeposit();
                                this.bsModalRef.content.deposit = this.deposit;
                                this.bsModalRef.content.is_submit = this.is_new;
                                this.bsModalRef.content.submit = false;
                                this.bsModalRef.hide();
                            });
                    } else {
                        //
                    }
                });
        }
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const product_ids: any[] = this.get_product_ids();
            let request_data: any;
            request_data = {
                type            : this.type,
                product_ids     : product_ids,
                customer_id     : this.model.customer_id,
                credit_terms    : this.tmp_deposit.credit_terms,
                grand_total     : this.tmp_deposit.grand_total,
                total_price     : this.tmp_deposit.total_price,
                started_at      : this.tmp_deposit.started_at,
                remark          : this.tmp_deposit.remark,
                status          : this.tmp_deposit.status
            };
            if (this.model && this.model.model_name === 'App\\Quotation') {
                request_data['quotation_id'] = this.model.id;
            } else if (this.model && this.model.model_name === 'App\\TaxInvoice') {
                request_data['tax_invoice_id'] = this.model.id;
            }
            this.api.request('deposits', 'PUT', {}, request_data)
                .subscribe((response: any): void => {
                    this.deposit.clone(response.data);
                    resolve();
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private get_product_ids(): any[] {
        let product_ids: any[];
        product_ids = [];
        for (let i = 0; i < this.products.length; i++) {
            product_ids.push(this.products[i].id);
        }
        return product_ids;
    }

    /*public onSubmit(form: NgForm): void {
        this.save()
            .then(() => {
                this.swal.success('บันทักข้อมูลใบวางเงินมัดจำสำเร็จ');
                this.bsModalRef.content.is_submit = this.is_new;
                this.bsModalRef.hide();
            });
    }*/

}
