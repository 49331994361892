import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {ModalService} from '../../services/modal.service';
import {TaskModel} from '../../models/task.model';
import {DeliveryNoteModel} from '../../models/deliveryNote.model';
import {SwalService} from '../../services/swal.service';
import {Api} from '../../now/api/api';
import {DeliveryNoteDetailComponent} from '../../pages/task/view/deliveryNoteDetail/deliveryNoteDetail.component';
import {DocumentModel} from '../../models/document.model';
import {UploaderComponent} from '../uploader/uploader.component';
import {CustomerModel} from '../../models/customer.model';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'delivery-note-table-component',
    templateUrl: 'deliveryNoteTable.component.html',
    styleUrls: ['deliveryNoteTable.component.scss']
})
export class DeliveryNoteTableComponent implements AfterViewInit {

    @ViewChild(UploaderComponent, { static: true }) uploader: UploaderComponent;
    @Input() deliveryNotes: DeliveryNoteModel[];
    @Input() model: any;
    @Input() task: TaskModel;
    @Input() customer: CustomerModel;

    public current_delivery_note: DeliveryNoteModel;

    constructor(
        private modal: ModalService,
        private swal: SwalService,
        private api: Api,
        public viewer: Viewer
    ) {
        this.deliveryNotes = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewDeliveryNoteDetail(deliveryNote: DeliveryNoteModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        console.log(this.customer);
        this.modal.show(DeliveryNoteDetailComponent, {
            deliveryNote    : deliveryNote,
            model           : this.model,
            task            : this.task,
            customer        : this.customer
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.deliveryNote) {
                    deliveryNote.status = content.deliveryNote.status;
                }
            });
    }

    public viewDeliveryNotePreview(deliveryNote: DeliveryNoteModel, e?: any): void {
        this.viewer.deliveryNote(deliveryNote);
    }

    public createDeliveryNote(customer?): void {
        if (this.model && this.model.customer_address_id && this.model.bill_id) {
            console.log(this.customer);
            const deliveryNote: DeliveryNoteModel = new DeliveryNoteModel();
            deliveryNote.customer = (customer) ? customer : this.customer;
            this.modal.show(DeliveryNoteDetailComponent, {
                deliveryNote: deliveryNote,
                model: this.model,
                task: this.task,
                customer: (customer) ? customer : this.customer,
            }, {backdrop: true, ignoreBackdropClick: true, class: 'modal-lg'})
                .then((content: any): void => {
                    if (content && content.submit === true) {
                        if (content.is_create === true) {
                            deliveryNote.clone(content.deliveryNote);
                            // this.deliveryNotes.push(deliveryNote);
                            this.model.delivery_notes.push(deliveryNote);
                            this.viewDeliveryNotePreview(deliveryNote);
                        }
                    } else {
                        //
                    }
                });
        } else if (this.model && !this.model.customer_address_id) {
            this.swal.danger('โปรดระบุที่อยู่สำหรับจัดส่ง');
        }
    }

    public attachFile(delivery_note: DeliveryNoteModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.current_delivery_note = delivery_note;
        this.uploader.trigger();
    }

    public viewFile(delivery_note: DeliveryNoteModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(delivery_note.document_id);
    }

    public removeFile(delivery_note: DeliveryNoteModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.swal.confirm('คุณต้องการลบเอกสารแนบใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('delivery/notes/document/delete', 'POST', {}, {
                        id: delivery_note.id
                    }).subscribe((response: any): void => {
                        this.swal.success('ลบเอกสารแนบสำเร็จ');
                        delivery_note.document_id = null;
                        delivery_note.document = null;
                    });
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.current_delivery_note && this.current_delivery_note.id) {
            this.api.request('delivery/notes/document', 'PUT', {}, {
                document: data,
                id: this.current_delivery_note.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.current_delivery_note.document_id = new_document.id;
                    this.current_delivery_note.document = new_document;
                    this.viewFile(this.current_delivery_note);
                }
                this.current_delivery_note = null;
            }, error => {
                this.current_delivery_note = null;
            });
        }
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
