import {UserModel} from '../now/user/user.model';
import {MachineModel} from './machine.model';
import {ProcessModel} from './process.model';
import {SchedulerModel} from './scheduler.model';
import {FormModel} from '../now/formModel';
import * as uuid4 from 'uuid/v4';
import {ShippingModel} from './shipping.model';
import {PackagingModel} from './packaging.model';
import {CosmeticModel} from './cosmetic.model';
import {HardeningModel} from './hardening.model';
import {Model} from '../now/model';
import {JobModel} from './job.model';
import {SupplierModel} from './supplier.model';
import {InstrumentModel} from './instrument.model';
import {FVD} from '../app/api/fvd';

export class ProductProcessModel extends FormModel {

    public instrument: InstrumentModel;
    public instrument_id: string;
    public instrument_price: number;
    public adding: boolean;

    public revision: number;
    public completed_at: string;
    public order_index: number;
    public job: JobModel;
    public index: number;
    public parent_id: string;
    public product_id: string;
    public process_id: string;
    public model_name: string;
    public model_id: string;
    public model_price: number;
    public model_time: number;
    public total_price: number;
    public parent: MachineModel;
    public process_slug: string;
    // public latest_scheduler: SchedulerModel;
    public scheduler: SchedulerModel;
    public amount: number;
    public description: string;
    public fvd: FVD;
    public fvd_id: string;
    public is_fvd: boolean;
    public job_id: string;
    public children: ProductProcessModel[];
    public order: number;
    public name: string;
    public machine_name: string;
    public full_name: string;
    public user: UserModel;
    public user_id: any;
    public user_price: number;
    public user_time: number;

    public machine: MachineModel;
    public machine_id: any;
    public machine_price: number;
    public machine_time: number;

    public ranking: number;

    public model: any;
    public process: ProcessModel;

    public started_at: string;
    public ended_at: string;

    public color: string;

    public guid: string;
    public schedulers: SchedulerModel[];

    public supplier_id: string;
    public supplier: SupplierModel;
    public price: number;
    public fvd_price: number;
    public estimated_price: number;

    public modelable: any;
    public modelable_id: string;
    public modelable_type: string;
    public modelable_data: any;

    public parent_guid: string;
    public scheduler_price: number;

    public editable: boolean;

    constructor() {
        super('product_processes');
        this.schedulers = [];

        this.instrument         = new InstrumentModel();
        this.machine            = new MachineModel();
        this.user               = new UserModel();
        this.process            = new ProcessModel();
        // this.latest_scheduler   = new SchedulerModel();
        // this.scheduler = new SchedulerModel();
        this.children = [];
        this.modelable_data = {};

        this.user_time = 0;
        this.machine_time = 0;
        this.user_price = 0;
        this.machine_price = 0;
        this.instrument_price = 0;

        this.is_fvd = false;
        this.supplier = new SupplierModel();

        this.guid = uuid4();
        this.modelable = {};
    }

    public addScheduler(scheduler: SchedulerModel): void {
        if (!this.schedulers) {
            this.schedulers = [];
        }
        scheduler.product_process_id = this.id;
        scheduler.product_process_guid = this.guid;
        scheduler.checked = true;
        scheduler.current_role = (this.job && this.job.current_role) ? this.job.current_role : (this.current_role) ? this.current_role : null;
        console.log(scheduler);
        this.schedulers.push(scheduler);
    }

    public clone(data: any): Model {
        if (data) {
            /*if (data.modelable_type === 'App\\Hardening' || (data.process && data.process.slug === 'hardening')) {
                this.modelable = new HardeningModel();
            } else if (data.modelable_type === 'App\\Cosmetic' || (data.process && data.process.slug === 'cosmetic')) {
                this.modelable = new CosmeticModel();
            } else if (data.modelable_type === 'App\\Packaging' || (data.process && data.process.slug === 'packing')) {
                this.modelable = new PackagingModel();
            } else if (data.modelable_type === 'App\\Shipping' || (data.process && data.process.slug === 'shipping')) {
                this.modelable = new ShippingModel();
            }
            if (this.modelable && this.modelable.model_name) {
                this.modelable_type = this.modelable.model_name;
                delete data.modelable_type;
            }*/
            this.modelable = null;
            if (data.modelable_type === 'App\\Hardening') {
                this.modelable = new HardeningModel();
            } else if (data.modelable_type === 'App\\Cosmetic') {
                this.modelable = new CosmeticModel();
            } else if (data.modelable_type === 'App\\Packaging') {
                this.modelable = new PackagingModel();
            } else if (data.modelable_type === 'App\\Shipping') {
                this.modelable = new ShippingModel();
            }
            if (this.modelable) {
                this.modelable.clone(data.modelable);
                delete data.modelable;
            }
            if (!data.checked || data.checked === 0 || data.checked === '0' || data.checked === false) {
                this.checked = false;
            } else {
                this.checked = true;
            }
            if (data.children && data.children.length > 0) {
                for (let i = 0; i < data.children.length; i++) {
                    const child: any = data.children[i];
                    if (child) {
                        let _child: ProductProcessModel;
                        _child = new ProductProcessModel();
                        _child.clone(child);
                        if (!_child.guid) {
                            _child.guid = uuid4();
                        }
                        this.children.push(_child);
                    }
                }
                delete data.children;
            }
            if (data.schedulers) {
                for (let i = 0; i < data.schedulers.length; i++) {
                    const child: any = data.schedulers[i];
                    if (child) {
                        let _child: SchedulerModel;
                        _child = new SchedulerModel();
                        _child.clone(child);
                        _child.machine = this.machine;
                        _child.user = this.user;
                        if (!_child.guid) {
                            _child.guid = uuid4();
                            console.log('guid', _child.guid);
                        }
                        this.schedulers.push(_child);
                    }
                }
                delete data.schedulers;
            }
        }

        if (data && data.process) {
            this.process.clone(data.process);
            delete data.process;
        }

        if (data && data.machine) {
            this.machine.clone(data.machine);
            delete data.machine;
        }

        if (data && data.instrument) {
            this.instrument.clone(data.instrument);
            delete data.instrument;
        }

        if (data && data.user) {
            this.user.clone(data.user);
            delete data.user;
        }

        super.clone(data);

        if (this.schedulers.length === 0) {
            this.addScheduler(new SchedulerModel());
        }

        return this;
    }

    public update(show_job_no?: boolean): void {
        /*if (this.scheduler) {
            this.scheduler.update(this, show_job_no);
        }*/
        for (let i = 0; i < this.schedulers.length; i++) {
            this.schedulers[i].user = this.user;
            this.schedulers[i].machine = this.machine;
            this.schedulers[i].process = this.process;
        }
        if (this.children && this.children.length > 0) {
            for (let i = 0; i < this.children.length; i++) {
                if (this.children[i]) {
                    this.children[i].update();
                } else {
                    //
                }
            }
        }

        if (this.modelable_type === 'App\\Packaging') {
            //
        }

        // this.cal_price();
        this.cal_scheduler_price();
    }

    /*public clone(data: any): Model {
        if (data && data.model && data.model_name && data.model_name === 'App\\Machine') {
            let machine: MachineModel;
            machine = new MachineModel();
            machine.clone(data.model);
            machine.checked = true;
            machine.time = (data.model_time) ? data.model_time : 0;
            this.model = machine;
            delete data.model;
        }
        if (data && data.model && data.model_name && data.model_name === 'App\\User') {
            let user: UserModel;
            user = new UserModel();
            user.clone(data.model);
            user.checked = true;
            user.time = (data.model_time) ? data.model_time : 0;
            this.model = user;
            delete data.model;
        }
        if (data && data.parent) {
            let machine: MachineModel;
            machine = new MachineModel();
            machine.clone(data.child);
            machine.checked = true;
            machine.time = (data.time) ? data.time : 0;
            this.parent = machine;
            delete data.child;
        }
        super.clone(data);
        return this;
    }*/

    public setProcess(process: ProcessModel): void {
        this.process_id = process.id;
        this.process = process;
        this.process_slug = process.slug;
    }

    public setModel(model: any, parent?: any): void {
        this.model_id       = model.id;
        this.model_time     = model.time;
        this.model_name     = model.model_name;
        this.model_price    = model.price;
        this.model          = model;
        if (parent) {
            this.parent_id = parent.id;
        }
    }

    public get latest_child(): ProductProcessModel {
        if (this.children && this.children.length > 0) {
            return this.children[this.children.length - 1];
        }
        return null;
    }

    public get latest_scheduler(): SchedulerModel {
        if (this.schedulers && this.schedulers.length > 0) {
            return this.schedulers[this.schedulers.length - 1];
        }
        return null;
    }

    public cal_scheduler_price(): number {
        this.scheduler_price = 0;
        if (this.process && this.process.id) {
            for (let i = 0; i < this.schedulers.length; i++) {
                const scheduler: SchedulerModel = this.schedulers[i];
                if (scheduler && scheduler.checked) {
                    const user_price: number = ((this.user && this.user.price) ? this.user.price : this.user_price);
                    const machine_price: number = ((this.machine && this.machine.price) ? this.machine.price : this.machine_price);
                    const instrument_price: number = ((this.instrument && this.instrument.price) ? this.instrument.price : this.instrument_price);
                    this.scheduler_price += (scheduler.minutes * (user_price + machine_price + instrument_price));
                }
            }
        }
        return this.scheduler_price;
    }

    public cal_price(): number {
        this.total_price = 0;
        if (this.process) {
            if (this.process.slug === 'hardening') {
                this.total_price = (this.modelable && this.modelable.price > 0) ? this.modelable.price : 0;
                this.scheduler_price = this.total_price;
            } else if (this.process.slug === 'cosmetic') {
                this.total_price = (this.modelable && this.modelable.price > 0) ? this.modelable.price : 0;
                this.scheduler_price = this.total_price;
            } else if (this.process.slug === 'shipping') {
                this.total_price = (this.modelable && this.modelable.price > 0) ? this.modelable.price : 0;
                this.scheduler_price = this.total_price;
            } else if (this.process.slug === 'packing') {
                if (this.modelable && this.modelable.price > 0 && this.modelable_data && this.modelable_data.amount > 0) {
                    this.total_price = this.modelable.price * this.modelable_data.amount;
                    this.scheduler_price = this.total_price;
                } else {
                    //
                }
            } else {
                if ((this.user && this.user.price > 0) || this.user_price > 0) {
                    this.total_price += this.user_time * ((this.user && this.user.price) ? this.user.price : this.user_price);
                }
                if ((this.machine && this.machine.price > 0) || this.machine_price > 0) {
                    this.total_price += this.user_time * ((this.machine && this.machine.price) ? this.machine.price : this.machine_price);
                }
                if ((this.instrument && this.instrument.price > 0) || this.instrument_price > 0) {
                    this.total_price += this.user_time * ((this.instrument && this.instrument.price) ? this.instrument.price : this.instrument_price);
                }
            }
        }
        return this.total_price;
    }

    public get busy_date_error(): boolean {
        if (this.children && this.children.length > 0) {
            for (let i = 0; i < this.children.length; i++) {
                const child: any = this.children[i];
                if (child.checked) {
                    if (child.schedulers && child.schedulers.length) {
                        for (let i = 0; i < child.schedulers.length; i++) {
                            const scheduler: SchedulerModel = child.schedulers[i];
                            if (scheduler.checked && scheduler.busy_date_error) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else if (this.schedulers && this.schedulers.length) {
            for (let i = 0; i < this.schedulers.length; i++) {
                const scheduler: SchedulerModel = this.schedulers[i];
                if (scheduler.checked && scheduler.busy_date_error) {
                    return true;
                }
            }
        }
        return false;
    }

    public get date_error(): boolean {
        if (this.children && this.children.length > 0) {
            for (let i = 0; i < this.children.length; i++) {
                const child: any = this.children[i];
                if (child.checked) {
                    if (child.schedulers && child.schedulers.length) {
                        for (let j = 0; j < child.schedulers.length; j++) {
                            const scheduler: SchedulerModel = child.schedulers[j];
                            if (scheduler.checked && scheduler.date_error) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else if (this.schedulers && this.schedulers.length) {
            for (let i = 0; i < this.schedulers.length; i++) {
                const scheduler: SchedulerModel = this.schedulers[i];
                if (scheduler.checked && scheduler.date_error) {
                    return true;
                }
            }
        }
        return false;
    }

}
