import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
// import {BsDatepickerDirective, BsLocaleService} from 'ngx-bootstrap';
import {BsDaterangepickerDirective, BsLocaleService} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
    selector: 'daterangepicker-component',
    templateUrl: 'daterangepicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DaterangepickerComponent),
        }
    ],
    styleUrls: ['daterangepicker.component.scss']
})
export class DaterangepickerComponent implements ControlValueAccessor, AfterViewInit {

    @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(BsDaterangepickerDirective, { static: false }) daterangepicker: BsDaterangepickerDirective;

    @Input() startDate;
    @Input() minDate;
    @Input() maxDate;

    @Input('left') left: boolean;
    @Input('icon') icon_text: string;
    @Input('display') display_text: string;
    @Input() dateCustomClasses: any;
    @Input() config: any;

    @Input('highLightDates')
    highLightDates: any[];

    public time_HH_mm_ss: string;
    public the_model: any;

    public mask: any;
    public tmp_daterangepicker: any;
    public timeout: any;

    @Input()
    public set disabled(value: boolean) {
        this._disabled = value;
    }

    @Input('placeholder') placeholder: string;
    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

    public _disabled: boolean;
    public ready: boolean;
    public date_time_string: string;
    public propagateChange: any = (_: any): void => {};

    constructor(
        private localeService: BsLocaleService,
        private el: ElementRef
    ) {
        //
        this._disabled = false;
        this.ready = false;
        this.localeService.use('th');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.placeholder = (this.placeholder || this.placeholder === '') ? this.placeholder : 'เลือกวัน';
            this.config = Object.assign({
                // dateInputFormat: 'YYYY-MM-DD HH:mm:ss'
            }, this.config);
        }, 0);
    }

    public onOpenCalendar(container: any): void {
        // if (this.config && this.config.dateInputFormat) {
        //     if (this.config.dateInputFormat === 'YYYY') {
        //         if (container) {
        //             container.yearSelectHandler = (e: any): void => {
        //                 container._store.dispatch(container._actions.select(e.date));
        //             };
        //             container.setViewMode('year');
        //         }
        //     } else if (this.config.dateInputFormat === 'MM/YYYY') {
        //         if (container) {
        //             container.monthSelectHandler = (e: any): void => {
        //                 container._store.dispatch(container._actions.select(e.date));
        //             };
        //             container.setViewMode('month');
        //         }
        //     } else if (this.config.dateInputFormat === 'DD/MM/YYYY') {
        //         //
        //     }
        // }
    }

    public trigger(e: any): void {
        if (e) {
            e.stopPropagation();
        }
        if (this._disabled) {
            //
        } else {
            this.daterangepicker.show();
        }
    }

    writeValue(obj: any): void {
        if (obj) {
            const startDate = obj.startDate.toDate();
            const endDate = obj.endDate.toDate();
            this.the_model = [startDate, endDate];
        }
        // this.checkObject(startDate);
        // this.checkObject(endDate);
    }

    public onValueChange(e: any): void {
        this.onUpdate.emit(e);
        // this.propagateChange(e);
    }

    public checkObject(obj) {
        // if (moment.isDate(obj)) {
        //     const clone = moment(obj).locale('th').clone();
        //     this.the_model = clone.toDate();
        //     this.date_time_string = clone.format('DD/MM/YYYY');
        //     this.time_HH_mm_ss = clone.format('HH:mm:ss');
        // } else if (moment.isMoment(obj)) {
        //     const clone = obj.locale('th').clone();
        //     this.the_model = clone.toDate();
        //     this.date_time_string = clone.format('DD/MM/YYYY');
        //     this.time_HH_mm_ss = clone.format('HH:mm:ss');
        // } else if (obj) {
        //     const clone = moment(obj, 'YYYY-MM-DD HH:mm:ss', 'th').locale('th').clone();
        //     this.the_model = clone.toDate();
        //     this.date_time_string = clone.format('DD/MM/YYYY');
        //     this.time_HH_mm_ss = clone.format('HH:mm:ss');
        // }
        // if (!this.time_HH_mm_ss) {
        //     this.time_HH_mm_ss = '08:00:00';
        // }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        //
    }

}
