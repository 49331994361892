import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {TaskModel} from '../../../models/task.model';
import {Api} from '../../../now/api/api';
import {ActivatedRoute} from '@angular/router';
import {LoaderService} from '../../../components/loader/loader.service';
import {ViewTask} from '../view/viewTask';
import {TaskService} from '../../../services/task.service';
import {ModalService} from '../../../services/modal.service';
import {Viewer} from '../../../services/viewer';
import {Location} from '@angular/common';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../now/user/auth.service';
import {SwalService} from '../../../services/swal.service';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {DocumentModel} from '../../../models/document.model';

@Component({
    selector: 'preventive-ma-task-component',
    templateUrl: 'preventiveMATask.component.html',
    styleUrls: ['preventiveMATask.component.scss']
})
export class PreventiveMATaskComponent extends ViewTask implements AfterViewInit {

    @ViewChild('remarkModal', { static: true }) remarkModal: RemarkModal;

    public content: string;
    public ready: boolean;
    public task: TaskModel;
    public maintainPlan: any;
    public preventiveMA;
    public task_id: string;
    public maintain_plan_id: string;

    constructor(
        public userService: UserService,
        public taskService: TaskService,
        public modal: ModalService,
        public location: Location,
        public viewer: Viewer,
        public api: Api,
        public swal: SwalService,
        public auth: AuthService,
        public route: ActivatedRoute,
        public loader: LoaderService,
    ) {
        //
        super({ taskService, api, modal, location, viewer });
        this.content = '';
        this.task = new TaskModel();
        this.ready = false;
    }

    ngAfterViewInit(): void {
        this.route.params
            .subscribe(params => {
                this.loader.show();
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            this.maintain_plan_id = this.task.modelable_id;
                            this.getMachinePlan()
                                .then(() => {
                                    this.ready = true;
                                    this.loader.hide();
                                }, error => {
                                    console.error(error);
                                    this.loader.hide();
                                });
                        }, error => {
                            console.error(error);
                            this.loader.hide();
                        });
                } else {
                    this.ready = true;
                    this.loader.hide();
                }
            });
    }

    public getMachinePlan(): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            this.api.request('machines/' + this.maintain_plan_id + '/maintain_plan', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.maintainPlan = Object.assign({}, response.data);
                        this.content = (response.data.content) ? response.data.content : '';
                        resolve(this.maintainPlan);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getTask(): Promise<TaskModel> {
        const promise = new Promise<TaskModel>((resolve, reject) => {
            this.api.request('tasks/' + this.task_id + '/detail', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.task.clone(response.data);
                        resolve(this.task);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onDocumentUploadedSuccess(data) {
        this.api.request('machines/maintain_plan/document', 'PUT', {}, {
            document    : data,
            id          : this.maintainPlan.id,
            // role        : this.task.current_role
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                if (!this.maintainPlan.documents) {
                    this.maintainPlan.documents = [];
                }
                this.maintainPlan.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(event) {
        //
    }

    public viewPreventiveMA() {
        this.viewer.preventiveMA(this.maintain_plan_id);
    }

    public archiveTask() {
        if (this.maintainPlan && this.content) {
            this.swal.confirm('ยืนยันการจัดเก็บเอกสาร ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('machines/maintain_plan/archive', 'POST', {}, {
                            id: this.maintain_plan_id,
                            task_id: this.task_id
                        }).subscribe((response: any): void => {
                            if (response && response.success === true) {
                                this.taskService.complete(this.task)
                                    .then(() => {
                                        this.swal.success('จัดเก็บเอกสารสำเร็จ');
                                        super.leave(true);
                                    });
                            }
                        });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('โปรดระบุรายละเอียดการซ่อมบำรุง');
        }
    }

    public save(force?: boolean) {
        const promise = new Promise(resolve => {
            this.api.request('machines/maintain_plan', 'POST', {}, {
                id: this.maintain_plan_id,
                content: this.content
            }).subscribe(result => {
                resolve(result);
            });
        });
        return promise;
    }

    public onSaveApiCallingBack(e: any): void {
        this.save(true);
    }

}
