import {Component, Input} from '@angular/core';
import {TaskModel} from '../../models/task.model';
import {Router} from '@angular/router';

@Component({
    selector: 'support-bar-component',
    templateUrl: 'supportBar.component.html',
    styleUrls: ['supportBar.component.scss']
})
export class SupportBarComponent {

    public _task: TaskModel;

    @Input()
    set task(val: TaskModel) {
        this._task = val;
    }

    constructor(private router: Router) {
        //
    }

    public purchaseTask(): void {
        this.router.navigateByUrl('/request/purchase');
    }

    public purchaseFVDTask(): void {
        this.router.navigateByUrl('/request/fvd');
    }

    public fvdTask(): void {
        this.router.navigateByUrl('/request/fvd');
    }

    public engTask(): void {
        this.router.navigateByUrl('/request/eng');
    }

    public qaTask(): void {
        this.router.navigateByUrl('/request/qa');
    }

    public fmtTask(): void {
        this.router.navigateByUrl('/request/fmt');
    }

    public storeTask(): void {
        this.router.navigateByUrl('/request/store');
    }

    public crdTask(): void {
        this.router.navigateByUrl('/request/crd');
    }

    public gotoStore(): void {
        this.router.navigateByUrl('/store');
    }

    public viewCalendar(): void {
        this.router.navigateByUrl('/calendar');
    }

    public gotoEngineering(): void {
        this.router.navigateByUrl('/engineering');
    }

    public gotoFMT(): void {
        this.router.navigateByUrl('/fmt');
    }

    public gotoCAR(): void {
        this.router.navigateByUrl('/car');
    }

}
