import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {Api} from '../../now/api/api';
import {ActivatedRoute} from '@angular/router';
import {FullCalendarComponent} from '../../components/fullCalendar/fullCalendar.component';
import {SchedulerModel} from '../../models/scheduler.model';
import {ProductProcessModel} from '../../models/productProcess.model';
import {AppService} from '../../app/app.service';
import * as moment from 'moment';
import {TaskService} from '../../services/task.service';
import {MachineService} from '../../services/machine.service';
import {UserService} from '../../services/user.service';
import {ProcessService} from '../../services/process.service';
import {MachineModel} from '../../models/machine.model';
import {UserModel} from '../../now/user/user.model';
import {ProcessModel} from '../../models/process.model';
import {PusherService} from '../../services/pusher.service';
import {AuthService} from '../../now/user/auth.service';
import {Viewer} from '../../services/viewer';
import {JobModel} from '../../models/job.model';
import {SwalService} from '../../services/swal.service';
import {PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {LoaderService} from '../../components/loader/loader.service';
import {TypeaheadComponent} from '../../components/typeahead/typeahead.component';

@Component({
    selector: 'calendar-model-view-component',
    templateUrl: 'calendarModelView.component.html',
    styleUrls: ['calendarModelView.component.scss']
})
export class CalendarModelViewComponent implements AfterViewInit, OnDestroy {

    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScroll: PerfectScrollbarDirective;
    @ViewChild('searchCalendarJob', { static: false }) searchCalendarJob: TypeaheadComponent;

    public keyword: string;
    public current_task: any;
    public tmp_job: string;
    public resources: any[];
    public machine_resources: any[];
    public man_resources: any[];
    public process_resources: any[];
    public tmp_model: any;
    public ready: boolean;
    public role: string;
    public model: string;
    public title: string;
    public data: any;
    public job_list: any[];
    public machine_list: any[];
    public man_list: any[];
    public process_list: any[];
    public schedulers: SchedulerModel[];
    public product_processes: ProductProcessModel[];
    public current_date: any;
    private filter: string[];
    public options: any;
    public current_filter: any;
    public events: any[];
    public current_show: string;
    public current_view_name: string;
    public is_shipping: boolean;
    public confirmed_added: boolean;

    constructor(
        private api: Api,
        private route: ActivatedRoute,
        private location: Location,
        private appService: AppService,
        private taskService: TaskService,
        private machineService: MachineService,
        private userService: UserService,
        private processService: ProcessService,
        private pusherService: PusherService,
        private authService: AuthService,
        private swal: SwalService,
        private viewer: Viewer,
        private loader: LoaderService,
    ) {
        //
        this.is_shipping = false;
        this.tmp_job = '';
        this.current_view_name = 'month';
        this.current_show = 'job';
        this.ready = false;
        this.title = 'ตารางงาน';
        this.schedulers = [];
        this.resources = [];
        this.machine_resources = [];
        this.man_resources = [];
        this.process_resources = [];
        this.data = {};
        this.job_list = [];
        this.machine_list = [];
        this.man_list = [];
        this.process_list = [];
        this.tmp_model = {};
        this.product_processes = [];
        this.current_filter = null;
        this.events = [];
        this.options = {
            height: 'parent',
            // contentHeight: 500,
            aspectRatio: 1,
            editable: false,
            resources: [],
            resourceAreaWidth: '12%',
            resourceLabelText: null,
        };
        this.filter = [];
        const moment_now = moment(new Date());
        this.current_date = moment_now.format('YYYY-MM-DD HH:mm:ss');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.appService.fixPage();
            this.appService.full_page_screen = true;

            const _user: UserModel = this.authService.user;
            const estimate_role_index: number = _user.roles.findIndex(i => i === 'estimate');
            const shipping_role_index: number = _user.roles.findIndex(i => i === 'shipping');
            if (shipping_role_index > -1 || estimate_role_index > -1) {
                this.is_shipping = true;
            } else {
                //
            }

            this.route.params
                .subscribe(params => {
                    this.model = params['model'];
                    if (this.model === 'man') {
                        this.title = 'ตารางงานคนทำงาน';
                    } else if (this.model === 'machine') {
                        this.title = 'ตารางงานเครื่องจักร';
                    } else if (this.model === 'process') {
                        this.title = 'ตารางกระบวนการ';
                    }
                    this.role = params['role'];
                });

            this.machineService.getMachines()
                .then((machines: MachineModel[]): void => {
                    if (this.machine_list) {
                        this.machine_list.splice(0, this.machine_list.length);
                    } else {
                        this.machine_list = [];
                    }
                    if (machines && machines.length) {
                        for (let i = 0; i < machines.length; i++) {
                            const machine: MachineModel = machines[i];
                            const machine_value: string = 'machine_' + machine.id;
                            this.machine_list.push({
                                machine_id      : machine.id,
                                machine_name    : machine.machine_name,
                                title           : machine.machine_name,
                                start_date      : null,
                                value           : machine_value,
                                resources       : []
                            });
                        }
                    }
                });

            this.userService.getWorkers()
                .then((users: UserModel[]): void => {
                    if (this.man_list) {
                        this.man_list.splice(0, this.man_list.length);
                    } else {
                        this.man_list = [];
                    }
                    if (users && users.length) {
                        for (let i = 0; i < users.length; i++) {
                            const user: UserModel = users[i];
                            const man_value: string = 'man_' + user.id;
                            this.man_list.push({
                                user_id     : user.id,
                                full_name   : user.full_name,
                                title       : user.full_name,
                                user        : user,
                                start_date  : null,
                                value       : man_value,
                                resources   : []
                            });
                        }
                    }
                });

            this.processService.getProcesses()
                .then((processes: ProcessModel[]): void => {
                    if (processes && processes.length) {
                        if (this.process_list) {
                            this.process_list.splice(0, this.process_list.length);
                        } else {
                            this.process_list = [];
                        }
                        this.process_list.push(this.create_fvd_process());
                        for (let i = 0; i < processes.length; i++) {
                            const process: ProcessModel = processes[i];
                            const process_value: string = 'process_' + process.slug;
                            this.process_list.push({
                                process_id      : process.id,
                                process_slug    : process.slug,
                                process         : process,
                                user            : null,
                                title           : process.name,
                                start_date      : null,
                                value           : process_value,
                                resources       : []
                            });
                        }
                    }
                });

            // this.pusherService.subscribe('erpst.calendar');
            // this.pusherService.bind('erpst.calendar', 'tasks.update', data => {
            //     console.log(data);
            // });

            this.getJobs()
                .then(() => {
                    this.ready = true;
                    this.events = [];
                    this.options.resources = [];
                    setTimeout(() => {
                        this.onViewChange('job');
                    }, 500);
                }, error => {
                    this.swal.danger(JSON.stringify(error));
                });
        }, 0);
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
        // this.pusherService.unsubscribe('erpst.calendar');
    }

    public refreshEventSources(events: any): void {
        if (this.fullCalendarComponent) {
            this.fullCalendarComponent.removeEvents();
            this.fullCalendarComponent.addEventSource(events);
            this.fullCalendarComponent.rerenderEvents();
        }
        if (events && events[0] && events[0].start) {
            this.current_date = events[0].start;
        }
    }

    private add_event(scheduler: any, title: string, slug?: string): void {
        if (scheduler && !scheduler.completed_at) {
            const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
            const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
            // const title = process.name;
            const find_job_no = this.find_job_no(scheduler.job_id);
            const find_job_revision = this.find_job_revision(scheduler.job_id);
            const event_scheduler = {
                job_id          : scheduler.job_id,
                job_no          : find_job_no,
                job_revision    : find_job_revision,
                title           : title,
                start           : start,
                end             : end,
                calendar_title  : title,
                timeline_title  : title,
                className       : 'event-process-' + slug
            };
            this.events.push(event_scheduler);
        }
    }

    public clear_events(): void {
        this.confirmed_added = false;
        if (this.events) {
            this.events.splice(0, this.events.length);
        } else {
            this.events = [];
        }
    }

    private find_job_no(job_id: string): string {
        if (this.job_list) {
            const find = this.job_list.find(i => i.job_id === job_id);
            if (find && find.job_no) {
                return find.job_no;
            }
        }
        return 'UNKNOW';
    }

    private find_job_revision(job_id: string): any {
        const find = this.job_list.find(i => i.job_id === job_id);
        if (find && find.job_revision) {
            return find.job_revision;
        }
        return 0;
    }

    public getProcessJobs(process_id: any, slug: string): Promise<any> {
        const promise = new Promise<any[]>((resolve, reject) => {
            this.api.request('calendar/process', 'POST', {}, {
                id: process_id,
                slug: slug
            }).subscribe((response: any): void => {
                this.clear_events();
                if (response && response.success === true) {
                    for (const dat of response.data) {
                        if (dat) {
                            const revision = (dat.revision > 0) ? ' R' + dat.revision : '';
                            const job_no = this.find_job_no(dat.job_id);
                            if (slug !== 'fvd') {
                                const fvd = (dat.is_fvd) ? ' (FVD)' : '';
                                this.add_event(dat, job_no + '' + revision + '' + fvd, slug);
                            } else {
                                const process_name = (dat.name) ? ' ' + dat.name : 'UNKNOW';
                                this.add_event(dat, job_no + '' + revision + '' + process_name, slug);
                            }
                            if (this.is_shipping === true && dat.confirmed_delivery_date && slug === 'shipping') {
                                this.addConfirmedDeliveryDate(dat.confirmed_delivery_date, dat.job_id, dat.revision, job_no, job_no + '' + revision);
                            }
                        }
                    }
                    resolve([]);
                } else {
                    resolve([]);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public getManJobs(user_id: any): Promise<any> {
        const promise = new Promise<any[]>((resolve, reject) => {
            this.api.request('calendar/man', 'POST', {}, {
                id: user_id,
            }).subscribe((response: any): void => {
                console.log(response);
                this.clear_events();
                if (response && response.success === true) {
                    for (const dat of response.data) {
                        if (dat) {
                            const revision = (dat.revision > 0) ? ' R' + dat.revision : '';
                            this.add_event(dat, this.find_job_no(dat.job_id) + '' + revision);
                        }
                    }
                    resolve([]);
                } else {
                    reject({});
                }
            }, error => {
                console.log(error);
                reject(error);
            });
        });
        return promise;
    }

    public getMachineJobs(machine_id: any): Promise<any> {
        const promise = new Promise<any[]>((resolve, reject) => {
            this.api.request('calendar/machine', 'POST', {}, {
                id: machine_id,
            }).subscribe((response: any): void => {
                this.clear_events();
                if (response && response.success === true) {
                    for (const dat of response.data) {
                        if (dat) {
                            const revision = (dat.revision > 0) ? ' R' + dat.revision : '';
                            this.add_event(dat, this.find_job_no(dat.job_id) + '' + revision);
                        }
                    }
                    resolve([]);
                } else {
                    reject({});
                }
            }, error => {
                console.log(error);
                reject(error);
            });
        });
        return promise;
    }

    public getCalendarJobs(job_id: any, revision?: number): Promise<any> {
        const promise = new Promise<any[]>((resolve, reject) => {
            this.api.request('calendar/job', 'POST', {}, {
                id: job_id,
                revision: revision
            }).subscribe((response: any): void => {
                this.clear_events();
                if (response && response.success === true) {
                    for (const dat of response.data) {
                        if (dat) {
                            const process_name = this.find_process_name(dat);
                            const fvd = (dat.is_fvd) ? ' (FVD)' : '';
                            this.add_event(dat, process_name + fvd);
                            if (this.is_shipping === true && dat.confirmed_delivery_date && this.confirmed_added === false) {
                                this.addConfirmedDeliveryDate(dat.confirmed_delivery_date, dat.job_id, dat.revision, dat.job_no);
                            }
                        }
                    }
                    resolve([]);
                } else {
                    resolve([]);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public getJobs(): Promise<JobModel[]> {
        const promise = new Promise<JobModel[]>((resolve, reject) => {
            this.api.request('calendar/jobs', 'POST', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    for (const dat of response.data) {
                        if (dat) {
                            this.job_list.push({
                                title           : dat.job_no + ' ' + ((dat.revision > 0) ? 'R' + dat.revision : ''),
                                start_date      : null,
                                value           : dat.job_no,
                                job_id          : dat.id,
                                job_revision    : dat.revision,
                                job_no          : dat.job_no,
                                confirmed_delivery_date : dat.confirmed_delivery_date
                            });
                        }
                    }
                    if (this.job_list && this.job_list[0]) {
                        this.current_filter = this.job_list[0];
                    }
                    resolve(this.job_list);
                } else {
                    reject({});
                }
            }, error => {
                console.log(error);
                reject(error);
            });
        });
        return promise;
    }

    private create_fvd_process(): any {
        return {
            process_id      : null,
            process_slug    : 'fvd',
            process         : 'fvd',
            user            : null,
            title           : 'FVD',
            start_date      : null,
            value           : 'process_fvd',
            resources       : []
        };
    }

    public addConfirmedDeliveryDate(date: string, job_id?: any, job_revision?: any, job_no?: string, title?: string): any {
        const date_moment_obj: any = moment(date, 'YYYY-MM-DD HH:mm:ss');
        const scheduler_obj = {
            title           : (title) ? title : 'ส่งมอบสินค้า',
            job_id          : job_id,
            job_no          : job_no,
            job_revision    : job_revision,
            start           : date_moment_obj,
            end             : date_moment_obj,
            className       : 'event-solid'
        };
        this.events.push(scheduler_obj);
        this.confirmed_added = true;
        return scheduler_obj;
    }

    public selectMan(dat: any): void {
        if (this.current_filter === dat) {
            //
        } else {
            this.current_filter = dat;
            this.loader.show();
            this.getManJobs(this.current_filter.user_id)
                .then(() => {
                    console.log('user id : ' + this.current_filter.user_id);
                    this.getLeaveHistories(this.current_filter.user_id)
                        .then(() => {
                            if (!this.events || !this.events.length) {
                                // this.swal.danger(this.current_filter.full_name + ' ไม่พบรายการทำงาน');
                            }
                            this.loader.hide();
                            this.refreshEventSources(this.events);
                        });
                }, error => {
                    this.loader.hide();
                    this.swal.danger(JSON.stringify(error));
                });
        }
    }

    public selectProcess(dat: any): void {
        if (this.current_filter === dat) {
            //
        } else {
            this.current_filter = dat;
            this.loader.show();
            this.getProcessJobs(this.current_filter.process_id, this.current_filter.process_slug)
                .then(() => {
                    if (!this.events || !this.events.length) {
                        // this.swal.danger(this.current_filter.full_name + ' ไม่พบรายการทำงาน');
                    }
                    this.loader.hide();
                    this.refreshEventSources(this.events);
                }, error => {
                    this.loader.hide();
                    this.swal.danger(JSON.stringify(error));
                });
        }
    }

    public selectMachine(dat: any): void {
        if (this.current_filter === dat) {
            //
        } else {
            this.current_filter = dat;
            this.loader.show();
            this.getMachineJobs(this.current_filter.machine_id)
                .then(() => {
                    this.getMaintainPlans()
                        .then(() => {
                            this.getMachineFMTs()
                                .then(() => {
                                    if (!this.events || !this.events.length) {
                                        // this.swal.danger(this.current_filter.machine_name + ' ไม่พบรายการทำงาน');
                                    }
                                    this.loader.hide();
                                    this.refreshEventSources(this.events);
                                });
                        });
                }, error => {
                    this.loader.hide();
                    this.swal.danger(JSON.stringify(error));
                });
        }
    }

    public getLeaveHistories(user_id): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('users/' + user_id + '/leaves', 'GET')
                .subscribe((res: any): void => {
                    const leaveHistories = [];
                    if (res && res.data) {
                        for (const dat of res.data) {
                            leaveHistories.push(dat);
                        }
                    }
                    if (leaveHistories && leaveHistories.length) {
                        for (const activity of leaveHistories) {
                            if (activity && activity.approved_3_user_id && !activity.canceled_at) {
                                const start = activity.start;
                                const end = activity.end;
                                let title = 'ลา';
                                const subject = activity.subject;
                                if (subject === 'VACATION') {
                                    title = 'ลาพักร้อน';
                                } else if (subject === 'PERSONAL') {
                                    title = 'ลากิจ';
                                } else if (subject === 'SICK') {
                                    title = 'ลาป่วย';
                                } else if (subject === 'MATERNITY') {
                                    title = 'ลาคลอด';
                                } else if (subject === 'FORCE') {
                                    title = 'ลาขาด';
                                } else if (subject === 'OTHER') {
                                    title = 'ลา (อื่นๆ)';
                                }
                                const event_scheduler = {
                                    isMaintain      : true,
                                    title,
                                    start           : start,
                                    end             : end,
                                    calendar_title  : title,
                                    timeline_title  : title,
                                    className       : 'event-process-man'
                                };
                                this.events.push(event_scheduler);
                            }
                        }
                    }
                    resolve([]);
                });
        });
        return promise;
    }

    public getMachineFMTs() {
        const promise = new Promise(resolve => {
            this.api.request('machines/fmts', 'GET', {
                id: this.current_filter.machine_id
            }).subscribe((res: any) => {
                if (res && res.data) {
                    for (const dat of res.data) {
                        if (dat && dat.issued_date) {
                            const start = dat.issued_date;
                            const end = dat.estimated_repaired_at;
                            const title = 'แจ้งซ่อม ' + dat.document_no;
                            const event_scheduler = {
                                isMaintain      : true,
                                title,
                                start           : start,
                                end             : end,
                                calendar_title  : title,
                                timeline_title  : title,
                                className       : 'event-process-maintain'
                            };
                            this.events.push(event_scheduler);
                        }
                    }
                }
                resolve();
            }, error => {
                console.error(error);
                resolve();
            });
        });
        return promise;
    }

    public getMaintainPlans() {
        const promise = new Promise(resolve => {
            this.api.request('machines/maintain_plans', 'GET', {
                id: this.current_filter.machine_id
            }).subscribe((res: any) => {
                if (res && res.data) {
                    for (const dat of res.data) {
                        if (dat && dat.start) {
                            const start = dat.start;
                            const end = dat.end;
                            const title = 'ซ่อมบำรุง ' + ((dat.user && dat.user.id) ? '(' + dat.user.full_name + ')' : '');
                            const event_scheduler = {
                                isMaintain      : true,
                                title,
                                start           : start,
                                end             : end,
                                calendar_title  : title,
                                timeline_title  : title,
                                maintain_id     : dat.id,
                                className       : 'event-process-maintain'
                            };
                            this.events.push(event_scheduler);
                        }
                    }
                }
                resolve();
            }, error => {
                console.error(error);
                resolve();
            });
        });
        return promise;
    }

    public selectJob(dat: any): void {
        if (this.current_filter === dat) {
            //
        } else {
            this.current_filter = dat;
            // this.loader.show();
            this.getCalendarJobs(this.current_filter.job_id, this.current_filter.job_revision)
                .then(() => {
                    if (!this.events || !this.events.length) {
                        // this.swal.danger(this.current_filter.job_no + ' ไม่พบรายการทำงาน');
                    }
                    // this.loader.hide();
                    this.refreshEventSources(this.events);
                }, error => {
                    // this.loader.hide();
                    this.swal.danger(JSON.stringify(error));
                });
        }
    }

    public onChangedDate(date_time: any): void {
        this.current_date = date_time;
    }

    public eventClickHandler(e?: any): void {
        const calEvent: any = e.calEvent;
        if (calEvent && (calEvent.job_id || calEvent.job_revision)) {
            const job_id = calEvent.job_id;
            const job_revision = calEvent.job_revision;
            this.viewer.manufacture(job_id, true, job_revision)
                .then(() => {});
        } else if (calEvent && calEvent.maintain_id) {
            this.viewer.preventiveMA(calEvent.maintain_id);
        }
    }

    public dayClickHandler(e?: any): void {
        //
    }

    public onChangeView(e: any): void {
        /*if (e && e.name === 'timelineDay') {
            this.current_view_name = 'timelineDay';
        } else if (e && e.name === 'month') {
            this.current_view_name = 'month';
        }*/
    }

    public clear_product_processes(): void {
        if (this.product_processes) {
            this.product_processes.splice(0, this.product_processes.length);
        } else {
            this.product_processes = [];
        }
        if (this.schedulers) {
            this.schedulers.splice(0, this.schedulers.length);
        } else {
            this.schedulers = [];
        }
    }

    public onViewChange(e?: any): void {
        if (e === 'job') {
            this.perfectScroll.scrollToTop();
            if (this.job_list && this.job_list[0]) {
                this.loader.show();
                this.getCalendarJobs(this.current_filter.job_id, this.current_filter.job_revision)
                    .then(() => {
                        if (!this.events || !this.events.length) {
                            // this.swal.danger(this.current_filter.job_no + ' ไม่พบรายการทำงาน');
                        }
                        this.loader.hide();
                        this.refreshEventSources(this.events);
                    }, error => {
                        this.loader.hide();
                        this.swal.danger(JSON.stringify(error));
                    });
            }
        } else if (e === 'machine') {
            this.perfectScroll.scrollToTop();
            if (this.machine_list && this.machine_list[0]) {
                this.current_filter = this.machine_list[0];
                this.loader.show();
                this.getMachineJobs(this.current_filter.machine_id)
                    .then(() => {
                        this.getMaintainPlans()
                            .then(() => {
                                this.getMachineFMTs()
                                    .then(() => {
                                        if (!this.events || !this.events.length) {
                                            // this.swal.danger(this.current_filter.machine_name + ' ไม่พบรายการทำงาน');
                                        }
                                        this.loader.hide();
                                        this.refreshEventSources(this.events);
                                    });
                            });
                    }, error => {
                        this.loader.hide();
                        this.swal.danger(JSON.stringify(error));
                    });
            }
        } else if (e === 'man') {
            this.perfectScroll.scrollToTop();
            if (this.man_list && this.man_list[0]) {
                this.current_filter = this.man_list[0];
                this.loader.show();
                this.getManJobs(this.current_filter.user_id)
                    .then(() => {
                        this.getLeaveHistories(this.current_filter.user_id)
                            .then(() => {
                                if (!this.events || !this.events.length) {
                                    // this.swal.danger(this.current_filter.full_name + ' ไม่พบรายการทำงาน');
                                }
                                this.loader.hide();
                                this.refreshEventSources(this.events);
                            });
                    }, error => {
                        this.loader.hide();
                        this.swal.danger(JSON.stringify(error));
                    });
            }
        } else if (e === 'process') {
            this.perfectScroll.scrollToTop();
            if (this.process_list && this.process_list[0]) {
                this.current_filter = this.process_list[0];
                this.loader.show();
                this.getProcessJobs(this.current_filter.process_id, this.current_filter.process_slug)
                    .then(() => {
                        if (!this.events || !this.events.length) {
                            // this.swal.danger(this.current_filter.full_name + ' ไม่พบรายการทำงาน');
                        }
                        this.loader.hide();
                        this.refreshEventSources(this.events);
                    }, error => {
                        this.loader.hide();
                        this.swal.danger(JSON.stringify(error));
                    });
            }
        }
    }

    public createEventConfirmedDeliveryDate(dat: any, type: string): any {
        const confirmed_delivery_date: string = dat.confirmed_delivery_date;
        const event_scheduler: any = {
            title           : dat.job_no,
            start           : confirmed_delivery_date,
            end             : confirmed_delivery_date,
            filter_value    : [dat.job_no, 'process_shipping'],
            type            : type,
            filter_title    : 'ส่งมอบ ' + dat.job_no,
            calendar_title  : 'ส่งมอบ ' + dat.job_no,
            timeline_title  : 'ส่งมอบ ' + dat.job_no,
            job_id          : dat.id,
            job_no          : 'ส่งมอบ ' + dat.job_no,
            job_revision    : dat.revision,
            className       : 'event-solid'
        };
        return event_scheduler;
    }

    public onChange(dat: any, e: any): void {
        if (dat) {
            const index: number = this.filter.indexOf(dat.id);
            if (dat.checked) {
                if (index === -1) {
                    this.filter.push(dat.id);
                } else {
                    //
                }
            } else if (index > -1) {
                this.filter.splice(index, 1);
            }
            this.fullCalendarComponent.rerenderEvents();
        }
    }

    private find_process_name(dat: any): string {
        if (dat && dat.name) {
            return dat.name;
        } else if (dat.process_id && this.process_list) {
            const process = this.process_list.find(i => i.process_id === dat.process_id);
            if (process) {
                return process.title;
            }
        }
        if (dat && dat.process_slug) {
            return dat.process_slug.toUpperCase();
        }
        return 'UNKNOW';
    }

    public printCalendar(): void {
        window.print();
    }

    public onKeywordInput(e: any): void {
        //
    }

}
