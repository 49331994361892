import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProcessService} from '../../../../services/process.service';
import {NgForm} from '@angular/forms';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {JobModel} from '../../../../models/job.model';
import {DocumentModel} from '../../../../models/document.model';
import {DocumentDetailComponent} from '../../view/documentDetail/documentDetail.component';
import {ModalService} from '../../../../services/modal.service';
import {Viewer} from '../../../../services/viewer';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
    selector: 'car-modal-component',
    templateUrl: 'carModal.component.html',
    styleUrls: ['carModal.component.scss']
})
export class CARModalComponent implements AfterViewInit {

    public tmp_job;
    public job;
    public amount;
    public started_at;

    public pdfInfo: any;
    public numPages: number[];
    public current_job_document_path: string;
    public is_ready: boolean;

    constructor(
        private router: Router,
        private viewer: Viewer,
        public bsModalRef: BsModalRef,
        private processService: ProcessService,
        private api: Api,
        private modal: ModalService,
        private swal: SwalService
    ) {
        //
        this.is_ready = false;
    }

    ngAfterViewInit(): void {
        this.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.amount = '';
    }

    public onJobChange(event) {
        // this.requisition.job_id = (e && e.id) ? e.id : null;
        if (event && event.id) {
            this.job = new JobModel();
            this.is_ready = false;
            this.current_job_document_path = '';
            this.getJob(event.id)
                .then(() => {
                    console.log(this.job);
                    this.viewer.manufacture(this.job, false, this.job.revision)
                        .then(path => {
                            this.current_job_document_path = path;
                            this.is_ready = true;
                        });
                });
        }
    }

    public viewDocument(doc, e) {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(doc, true).then(() => {});
    }

    public viewDocumentDetail(doc: DocumentModel, documents?: any[], index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc,
            job: this.job,
            deletable: false,
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((content: any) => {
                if (content && content.submit === true) {
                    if (documents && documents[index]) {
                        documents.splice(index, 1);
                    }
                } else {
                    //
                }
            });
    }

    public onSubmit(form: NgForm): void {
        if (this.job && this.job.id && this.amount > 0 && this.started_at) {
            this.swal.confirm('ยืนยันการออก CAR กับ "' + this.job.job_no + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('cars', 'PUT', {}, {
                            job_id: this.job.id,
                            amount: this.amount,
                            started_at: this.started_at,
                        }).subscribe((response: any): void => {
                            if (response && response.data) {
                                const task = response.data.task;
                                if (task && task.id) {
                                    const taskId = task.id;
                                    this.router.navigateByUrl('/task/create/car/' + taskId);
                                }
                            }
                            this.swal.success('สร้างงาน CAR สำเร็จ');
                            this.bsModalRef.hide();
                        }, error => {
                            this.swal.danger(error);
                        });
                    }
                });
        } else if (!this.job || !this.job.id) {
            this.swal.danger('โปรดระบุ JOB');
        } else if (!this.amount) {
            this.swal.danger('โปรดระบุจำนวนงานที่เกิด CAR');
        } else if (!this.started_at) {
            this.swal.danger('โปรดระบุวันที่เกิด CAR');
        }
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        if (this.job.ncr_equipments) {
                            //
                        } else {
                            this.job.ncr_equipments = [
                                '', '', '', '', ''
                            ];
                        }
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onPdfLoadCompleted(e: any): void {
        //
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

}
