import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {DivApiDirective} from '../../../now/divApi';
import {UserModel} from '../../../now/user/user.model';
import {ProductModel} from '../../../models/product.model';
import {JobModel} from '../../../models/job.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {AuthService} from '../../../now/user/auth.service';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {ModalService} from '../../../services/modal.service';
import {CarrierService} from '../../../services/carrier.service';
import {PusherService} from '../../../services/pusher.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {UserService} from '../../../services/user.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {SwalService} from '../../../services/swal.service';
import {DOCUMENT, Location} from '@angular/common';
import {TaskModel} from '../../../models/task.model';
import {ViewTask} from '../view/viewTask';
import {RequisitionModel} from '../../../models/requisition.model';
import {StoreService} from '../../../services/store.service';
import {MaterialCreateModal} from '../view/product/materialCreate/materialCreate.modal';
import {JobService} from '../../../services/job.service';
import {FVD} from '../../../app/api/fvd';
import {ProductProcessModel} from '../../../models/productProcess.model';
import {FVDService} from '../../../services/fvd.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {PurchaseService} from '../../../services/purchase.service';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'po-fvd-task-component',
    templateUrl: 'poFVDTask.component.html',
    styleUrls: ['poFVDTask.component.scss']
})
export class POFVDTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;

    public is_fvd: boolean;

    public current_job_document_path: string;
    public pdfInfo: any;
    public numPages: number[];
    public job_id: string;
    public current_product_process: ProductProcessModel;
    public product: ProductModel;
    public product_id: string;
    public task_id: string;
    public current_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public job: JobModel;
    public fvd: FVD;
    public requisition: RequisitionModel;
    public job_no: string;
    public materials: any[];
    public prepared_status: number;
    public purchase_order: PurchaseOrderModel;

    constructor(
        public taskService: TaskService,
        public api: Api,
        public modal: ModalService,
        public location: Location,
        public router: Router,
        public viewer: Viewer,
        private route: ActivatedRoute,
        private storeService: StoreService,
        private modelApi: ModelApi,
        private authService: AuthService,
        private jobService: JobService,
        private ngZone: NgZone,
        private fvdService: FVDService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private purchaseService: PurchaseService,
        private incotermService: IncotermService,
        private swal: SwalService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.materials = [];
        this.task = new TaskModel();
        this.job = new JobModel();
        this.product = new ProductModel();
        this.prepared_status = 0;
        this.purchase_order = new PurchaseOrderModel();
        this.is_fvd = false;
    }

    private init(): void {
        this.task = new TaskModel();
        this.job = new JobModel();
        this.product = new ProductModel();
    }

    public showMaterialDetail(material: any): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : true,
            editable        : false,
            type            : 'frw',
            item_id         : material.item.id,
            item_name       : material.item.search_value,
            material_model  : material.item,
            current_item    : material.item
        }, {
            //
        }).then((data: any): void => {
            //
        });
    }

    public save(skip?: boolean): void {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.getTask()
                            .then(() => {
                                this.onSuccess(this.task);
                            });
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public getPurchaseOrder(id: string): Promise<PurchaseOrderModel> {
        let promise: Promise<PurchaseOrderModel>;
        promise = new Promise<PurchaseOrderModel>((resolve, reject) => {
            this.api.request('purchase/orders/' + id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.purchase_order.clone(response.data);
                        resolve(this.purchase_order);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onSuccess(data: any): void {
        this.current_tab = '#information';
        this.job_id = data.job_id;

        this.getPurchaseOrder(data.modelable_id)
            .then(() => {
                if (this.purchase_order.fvd_id) {
                    this.is_fvd = true;
                    this.getFVD(this.purchase_order.fvd_id)
                        .then(() => {
                            this.doReady();
                            this.prepared_status = this.fvd.prepared_status;
                        });
                } else {
                    this.doReady();
                    this.is_fvd = false;
                }
            }, error => {
                this.swal.danger(error);
            });

        if (this.job_id) {
            this.getJob(this.job_id)
                .then(() => {
                    this.product_id = this.job.product_id;
                    this.current_product_process = this.job.current_product_process;
                    this.getProductDetail()
                        .then(() => {
                            this.viewer.manufacture(this.job, false, this.job.revision)
                                .then(path => {
                                    this.current_job_document_path = path;
                                });
                        });

                });
        }
    }

    public onError(e: any): void {
        //
    }

    public onRemarkModalSubmit(e: any): void {
        this.jobService.reportJob(this.job, e.remark_message)
            .then(() => {
                this.api.request('tasks/archive', 'POST', null, {
                    id: this.task.id
                }).subscribe((response: any): void => {
                    this.taskService.setStatus(this.job.task_id, null, 'creating_ncr', 'dummy', 'dummy')
                        .then((task: TaskModel): void => {
                            this.swal.success('แจ้งปัญหากับแผนกสำเร็จ')
                                .then(() => {
                                    super.leave(true);
                                });
                        }, error => {
                            this.swal.danger(error);
                        });
                }, error => {
                    this.swal.danger(error);
                });
            });
    }

    public getFVD(fvd_id: any): Promise<FVD> {
        let promise: Promise<FVD>;
        promise = new Promise<FVD>((resolve, reject) => {
            this.api.request('fvds/' + fvd_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.fvd = response.data;
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public onReportRemarkModalSubmit(remark_message?: string): void {
        this.jobService.reportJob(this.job, remark_message)
            .then(() => {
                this.taskService.cancel(this.task)
                    .then(() => {
                        this.taskService.setStatus(this.job.task_id, null, 'create_ncr', 'dummy', 'dummy', false)
                            .then((task: TaskModel): void => {
                                this.swal.success('แจ้งปัญหากับแผนกสำเร็จ')
                                    .then(() => {
                                        super.leave(true);
                                    });
                            }, error => {
                                //
                            });
                    });
            });
    }

    public reject_to_pr(): void {
        this.swal.confirm('แจ้งคืนรายการจัดซื้อจัดจ้าง "' + this.purchase_order.purchase_order_no + '" ให้กับ Purchase ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.taskService.setStatus(this.task_id, null, 'return_to_pr', 'po-purchase', 'po-purchase')
                        .then((task: TaskModel): void => {
                            this.swal.success('แจ้งคืนรายการจัดซื้อจัดจ้างสำเร็จ')
                                .then(() => {
                                    super.leave(true);
                                });
                        }, error => {
                            this.swal.danger(error);
                        });
                }
            });
    }

    public complete(): void {
        /*if (this.job.current_product_process && this.job.current_product_process.process) {
            const process_name: string = this.job.current_product_process.process.name;
            this.swal.danger('สินค้ายังไม่ได้ถูกส่งให้ผู้รับจ้าง, กำลังผลิตอยู่ในกระบวนการ "' + process_name + '"');
            return;
        }*/
        let items: string[];
        items = [];
        let prepare_items: any[];
        prepare_items = [];
        for (const purchase of this.purchase_order.purchases) {
            if (purchase && purchase.prepared_status === 1) {
                // if (!purchase.inventory_date) {
                //     this.swal.danger('โปรดระบุวันที่เข้าคลังสินค้า');
                //     return;
                // }
                prepare_items.push({
                    purchase_id: purchase.id,
                    prepared_status: purchase.prepared_status,
                    // inventory_date: purchase.inventory_date,
                });
                if (purchase.modelable && purchase.modelable.search_value) {
                    items.push(purchase.modelable.search_value);
                } else if (purchase.is_modelable) {
                    items.push(purchase.item_name);
                } else {
                    items.push(purchase.item_description);
                }
            }
        }
        if (items.length > 0) {
            if (!this.purchase_order.inventory_date) {
                this.swal.danger('โปรดระบุวันที่เข้าคลังสินค้า');
                return;
            }
            this.swal.confirm('ยืนยันการได้รับสินค้า "' + items.join(',\n') + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.purchaseService.prepare(this.purchase_order, prepare_items, this.task_id)
                            .then((response: any): void => {
                                if (this.fvd && this.fvd.id) {
                                    this.fvdService.prepare(this.fvd)
                                        .then(() => {
                                            this.taskService.complete(this.task)
                                                .then(() => {
                                                    this.swal.success('ดำเนินการสำเร็จ');
                                                    this.leave(true);
                                                });
                                        });
                                } else {
                                    if (response.status === 2) { // completed all
                                        this.swal.success('ดำเนินการสำเร็จ พร้อมจัดเก็บงาน');
                                    } else if (response.status === 1) { // completed some purchase
                                        this.swal.success('ดำเนินการสำเร็จ');
                                    }
                                    this.leave(true);
                                }
                            }, error => {
                                this.swal.danger(error);
                            });
                    } else {
                        //
                    }
                });
        } else if (!this.purchase_order.purchases.length) {
            this.swal.confirm('ไม่พบรายการที่ต้องรับสินค้าจาก PO ยืนยันการส่งต่อเพื่อดำเนินการใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.purchaseService.prepare(this.purchase_order, prepare_items, this.task_id)
                            .then((response: any): void => {
                                if (this.fvd && this.fvd.id) {
                                    this.fvdService.prepare(this.fvd)
                                        .then(() => {
                                            this.taskService.complete(this.task)
                                                .then(() => {
                                                    this.swal.success('ดำเนินการสำเร็จ');
                                                    this.leave(true);
                                                });
                                        });
                                } else {
                                    if (response.status === 2) { // completed all
                                        this.swal.success('ดำเนินการสำเร็จ พร้อมจัดเก็บงาน');
                                    } else if (response.status === 1) { // completed some purchase
                                        this.swal.success('ดำเนินการสำเร็จ');
                                    }
                                    this.leave(true);
                                }
                            }, error => {
                                this.swal.danger(error);
                            });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('กรุณาเลือกการเตรียมรายการ');
        }
    }

    public onPdfLoadCompleted(e: any): void {
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

    public onSaveApiCallingBack(e: any): void {
        // this.save(true);
    }

}
