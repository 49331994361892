import {Pipe, PipeTransform} from '@angular/core';
import {TaskModel} from '../models/task.model';

@Pipe({
    name: 'processName'
})
export class ProcessPipe implements PipeTransform {

    transform(process_slug: string): string {
        if (process_slug === 'requirement') {
            return 'Contact Log';
        } else if (process_slug === 'create_pricing_inquiry') {
            return 'Pricing Inquiry';
        } else if (process_slug === 'check-store') {
            return 'Check Store';
        } else if (process_slug === 'notice') {
            return 'Notice';
        } else if (process_slug === 'design') {
            return 'Design';
        } else if (process_slug === 'requisition') {
            return 'Requisition';
        } else if (process_slug === 'control') {
            return 'Control';
        } else if (process_slug === 'qc-process') {
            return 'QC Process';
        } else if (process_slug === 'cutting') {
            return 'Cutting';
        } else if (process_slug === 'water-jet') {
            return 'Water Jet';
        } else if (process_slug === 'press') {
            return 'Press';
        } else if (process_slug === 'milling') {
            return 'Milling';
        } else if (process_slug === 'cnc-milling') {
            return 'CNC Milling';
        } else if (process_slug === 'cnc-lathe') {
            return 'CNC Lathe';
        } else if (process_slug === 'wire-cut') {
            return 'Wire Cut';
        } else if (process_slug === 'grinding') {
            return 'Grinding';
        } else if (process_slug === 'worlding') {
            return 'Worlding';
        } else if (process_slug === 'cosmetic') {
            return 'Cosmetic';
        } else if (process_slug === 'assambly') {
            return 'Assambly';
        } else if (process_slug === 'shipping') {
            return 'Shipping';
        } else if (process_slug === 'qc-final') {
            return 'QC Final';
        } else if (process_slug === 'auto-lathe') {
            return 'Auto Lathe';
        } else if (process_slug === 'finish-good') {
            return 'Finish Good';
        } else if (process_slug === 'finish') {
            return 'Receive';
        } else if (process_slug === 'packing') {
            return 'Packing';
        } else if (process_slug === 'hardening') {
            return 'Hardening';
        } else if (process_slug === 'costing') {
            return 'Costing';
        } else if (process_slug === 'polishing') {
            return 'Polishing';
        } else if (process_slug === 'delivery') {
            return 'Delivery';
        } else if (process_slug === 'dummy') {
            return 'Dummy';
        } else if (process_slug === 'ncr') {
            return 'NCR';
        } else if (process_slug === 'planning') {
            return 'Planning';
        } else if (process_slug === 'estimate') {
            return 'Estimate';
        } else if (process_slug === 'sale-admin') {
            return 'Sale Admin';
        } else if (process_slug === 'pd-planning') {
            return 'PD Planning';
        } else if (process_slug === 'pd-planning-2') {
            return 'PD Planning';
        } else if (process_slug === 'pd-control') {
            return 'PD Control';
        } else if (process_slug === 'store') {
            return 'Store';
        } else if (process_slug === 'cutting') {
            return 'Cutting';
        } else if (process_slug === 'programmer') {
            return 'Programmer';
        } else if (process_slug === 'cnc-milling') {
            return 'CNC Milling';
        } else if (process_slug === 'cnc-lathe') {
            return 'CNC Lathe';
        } else if (process_slug === 'water-jet') {
            return 'Water Jet';
        } else if (process_slug === 'wire-cut') {
            return 'Wire Cut';
        } else if (process_slug === 'laser-mark') {
            return 'Laser Mark';
        } else if (process_slug === 'laser-cut') {
            return 'Laser Cut';
        } else if (process_slug === 'milling') {
            return 'Milling';
        } else if (process_slug === 'lathe') {
            return 'Lathe';
        } else if (process_slug === 'grinding') {
            return 'Grinding';
        } else if (process_slug === 'press') {
            return 'Press';
        } else if (process_slug === 'fab') {
            return 'Fab';
        } else if (process_slug === 'assembly') {
            return 'Assembly';
        } else if (process_slug === 'cosmetic') {
            return 'Cosmetic';
        } else if (process_slug === 'qa') {
            return 'QA';
        } else if (process_slug === 'prod-1') {
            return 'PROD 1';
        } else if (process_slug === 'prod-2') {
            return 'PROD 2';
        } else if (process_slug === 'prod-3') {
            return 'PROD 3';
        } else if (process_slug === 'hr') {
            return 'HR';
        } else if (process_slug === 'accounting') {
            return 'Accounting';
        } else if (process_slug === 'fmt') {
            return 'FMT';
        } else if (process_slug === 'sale-manager') {
            return 'Sale Manager';
        } else if (process_slug === 'eng') {
            return 'ENG';
        } else if (process_slug === 'fvd' ) {
            return 'FVD';
        } else if (process_slug === 'crd') {
            return 'CRD';
        } else if (process_slug === 'approved') {
            return 'Approved';
        } else if (process_slug === 'sale-room') {
            return 'Sale';
        } else if (process_slug === 'quotation') {
            return 'Quotation';
        } else if (process_slug === 'fvd') {
            return 'FVD';
        } else if (process_slug === 'fvd-room') {
            return 'FVD';
        } else if (process_slug === 'prepare-store') {
            return 'Prepare Store';
        } else if (process_slug === 'payment-proforma') {
            return 'Proforma';
        } else if (process_slug === 'payment') {
            return 'Payment';
        }
        return process_slug;
    }

}
