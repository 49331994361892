import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {ProductModel} from '../../../models/product.model';
import {TaskModel} from '../../../models/task.model';
import {UserService} from '../../../services/user.service';
import {CustomerModel} from '../../../models/customer.model';
import {Api} from '../../../now/api/api';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {environment} from '../../../environments/environment';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {IncotermService} from '../../../services/incoterm.service';
import {CarrierService} from '../../../services/carrier.service';
import {TaskService} from '../../../services/task.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ModalService} from '../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../now/user/auth.service';
import {ViewTask} from '../view/viewTask';
import {VendorModel} from '../../../models/vendor.model';
import {PurchaseModel} from '../../../models/purchase.model';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {JobModel} from '../../../models/job.model';
import {StoreService} from '../../../services/store.service';
import {PricingInquiryModel} from '../../../models/pricingInquiry.model';
import {PricingInquiryService} from '../../../services/pricingInquiry.service';
import {DocumentModel} from '../../../models/document.model';
import {PricingInquiryDocument} from '../../../app/api/pricingInquiryDocument';
import {PricingInquiryDocumentDetailComponent} from './pricingInquiryDocumentDetail/pricingInquiryDocumentDetail.component';
import {ProductMaterial} from '../../../models/productMaterial';
import {MaterialCreateModal} from '../view/product/materialCreate/materialCreate.modal';
import * as moment from 'moment';
import {SchedulerModel} from '../../../models/scheduler.model';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {Viewer} from '../../../services/viewer';
import {LoaderService} from '../../../components/loader/loader.service';

@Component({
    selector: 'purchase-task-component',
    templateUrl: 'purchaseTask.component.html',
    styleUrls: ['purchaseTask.component.scss'],
    providers: [DecimalPipe]
})
export class PurchaseTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;

    public pricing_inquiry: PricingInquiryModel;

    public job: JobModel;
    public purchase_order: PurchaseOrderModel;
    public sale: UserModel;
    public customer: CustomerModel;
    public current_tab: string;
    public incoterms: IncotermModel[];
    public carriers: CarrierModel[];
    public vendor: VendorModel;
    public new_tasks: TaskModel[];
    public items: ProductModel[];
    public job_no: string;
    public product_no: string;
    public product_id: string;
    public request_man: UserModel;
    public job_id: string;
    public schedulers: SchedulerModel[];
    public materials: ProductMaterial[];
    public children: any[];
    public product: ProductModel;
    public options: any;

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private swal: SwalService,
        private pricingInquiryService: PricingInquiryService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        private storeService: StoreService,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        private route: ActivatedRoute,
        public location: Location,
        private loader: LoaderService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.materials = [];

        this.current_tab = '#information';
        this.vendor = new VendorModel();
        this.sale = new UserModel();
        this.items = [];
        this.job = new JobModel();
        this.pricing_inquiry = new PricingInquiryModel();

        this.new_tasks = [];
        this.job_no = '';
        this.product_no = '';

        this.options = {
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'month'
            },
            events: []
        };

        this.route.params
            .subscribe(params => {
                this.viewTaskInit();
                this.task = new TaskModel();
                this.task_id = params['id'];
                this.job_id = params['job_id'];
                if (this.task_id) {
                    this.task.id = this.task_id;
                    this.getTaskDetail();
                } else {
                    //
                }
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            // console.log(this.task);
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public showMaterialDetail(product_material: any): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : true,
            editable        : false,
            type            : 'frw',
            // submit_text     : this.submit_text,
            item_id         : product_material.item.id,
            item_name       : product_material.item.search_value,
            material_model  : product_material.item,
            current_item    : product_material.item
        }, {
            //
        }).then((data: any): void => {
            //
        });
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                if (response && response.data) {
                    this.task.clone(response.data);
                    if (response.data.modelable_type === 'App\\Job') {
                        this.job_id = (this.job_id) ? this.job_id : response.data.modelable_id;
                        this.getJob(this.job_id)
                            .then(() => {
                                /*this.getJobChildren(this.task.modelable_id)
                                    .then((children_response: any): void => {
                                        //
                                    }, error => {
                                        //
                                    });*/

                                this.product_id = this.job.product_id;
                                this.getProductDetail()
                                    .then(() => {
                                        for (let i = 0; i < this.product.materials.length; i++) {
                                            if (this.product.materials[i] && this.product.materials[i].checked) {
                                                this.materials.push(this.product.materials[i]);
                                            }
                                        }
                                        this.doReady();
                                        setTimeout(() => {
                                            this.refreshCalendar();
                                        });
                                    });

                                if (this.job.current_pricing_inquiry_id) {
                                    this.getPricingInquiry(this.job.current_pricing_inquiry_id)
                                        .then(() => {
                                            this.initPricingInquiry();
                                        });
                                } else {
                                    //
                                }
                            });
                    } else if (response.data.modelable_type === 'App\\PricingInquiry') {
                        this.getPricingInquiry(response.data.modelable_id)
                            .then(() => {
                                this.initPricingInquiry();
                            });
                    }
                }
            }, error => {
                //
            });
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.product = new ProductModel();
            // this.job_id = this.job.id;
            this.modelApi.sync(this.product, this.product_id, null, {
                job_id: this.job_id
            }).subscribe((response: any): void => {
                resolve(this.product);
            }, error => {
                reject();
            });
        });
        return promise;
    }

    public refreshCalendar(): void {
        if (this.schedulers) {
            this.schedulers.splice(0, this.schedulers.length);
        } else {
            this.schedulers = [];
        }
        if (this.options.events) {
            this.options.events.splice(0, this.options.events.length);
        } else {
            this.options.events = [];
        }
        for (let i = 0; i < this.product.product_processes.length; i++) {
            const product_process = this.product.product_processes[i];
            if (product_process && product_process.checked && product_process.revision === this.job.revision) {
                for (let j = 0; j < product_process.schedulers.length; j++) {
                    const scheduler = product_process.schedulers[j];
                    if (scheduler && scheduler.checked && scheduler.revision === this.job.revision) {
                        const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                        const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                        const event_scheduler: any = {
                            title: ((product_process.is_fvd) ? '(FVD)' : '') + product_process.process.name,
                            start: start,
                            end: end,
                        };
                        this.schedulers.push(event_scheduler);
                        this.options.events.push(event_scheduler);
                    }
                }
            }
            if (product_process && product_process.children && product_process.children.length) {
                for (let j = 0; j < product_process.children.length; j++) {
                    const child: any = product_process.children[j];
                    if (child && child.checked && child.schedulers && child.revision === this.job.revision) {
                        for (let n = 0; n < child.schedulers.length; n++) {
                            const scheduler = child.schedulers[n];
                            if (scheduler && scheduler.checked && scheduler.revision === this.job.revision) {
                                const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                                const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                                const event_scheduler: any = {
                                    title: child.process.name + ((child.is_fvd) ? '(FVD)' : ''),
                                    start: start,
                                    end: end,
                                };
                                this.schedulers.push(event_scheduler);
                                this.options.events.push(event_scheduler);
                            }
                        }
                    }
                }
            }
        }
        this.options.events.push(this.addConfirmedDeliveryDate(this.job.confirmed_delivery_date));
        if (this.ready) {
            this.fullCalendarComponent.removeEvents();
            this.fullCalendarComponent.addEventSource(this.options.events);
            this.fullCalendarComponent.rerenderEvents();
        }
    }

    public addConfirmedDeliveryDate(date: string): any {
        const date_moment_obj: any = moment(date, 'YYYY-MM-DD HH:mm:ss');
        return {
            title: 'ส่งมอบสินค้า',
            start: date_moment_obj,
            end: date_moment_obj,
            className: 'event-solid'
        };
    }

    /*public getJobChildren(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id + '/children')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        if (this.children) {
                            this.children.splice(0, this.children.length);
                        } else {
                            this.children = [];
                        }
                        if (response.data.parent) {
                            const parent_job: any = response.data.parent;
                            this.children.push({
                                task: parent_job.task,
                                task_id: (parent_job.task) ? parent_job.task.id : null,
                                job_id: parent_job.id,
                                product_name: (parent_job.product) ? parent_job.product.name : null
                            });
                            this.parent_job = parent_job;
                        }
                        if (response.data.children) {
                            for (let i = 0; i < response.data.children.length; i++) {
                                const dat: any = response.data.children[i];
                                if (dat) {
                                    this.children.push({
                                        task: dat.task,
                                        task_id: (dat.task) ? dat.task.id : null,
                                        job_id: dat.id,
                                        product_name: (dat.product) ? dat.product.name : null
                                    });
                                }
                            }
                        }
                        resolve(this.children);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }*/

    public onDocumentVendorChange(doc: PricingInquiryDocument, e: any): void {
        setTimeout(() => {
            this.api.request('pricing/inquiries/document', 'POST', {}, {
                id: doc.id,
                vendor_id: doc.vendor_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    //
                } else {
                    //
                }
            });
        }, 300);
    }

    public viewDocumentDetail(doc: PricingInquiryDocument, index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PricingInquiryDocumentDetailComponent, {
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((data: any): void => {
                if (data && data.deleted === true) {
                    this.pricing_inquiry.documents.splice(index, 1);
                    this.swal.success('ลบเอกสารสำเร็จ');
                } else if (data && data.submit === true) {
                    doc.name = data.name;
                    this.swal.success('แก้ไขเอกสารสำเร็จ');
                }
            });
    }

    public initPricingInquiry(): void {
        if (this.pricing_inquiry && this.pricing_inquiry.items) {
            for (let i = 0; i < this.pricing_inquiry.items.length; i++) {
                this.items.push(this.pricing_inquiry.items[i]);
            }
        } else {
            //
        }
        if (this.pricing_inquiry && this.pricing_inquiry.job) {
            this.job_no = this.pricing_inquiry.job.job_no;
        }
        if (this.pricing_inquiry && this.pricing_inquiry.product) {
            this.product_no = this.pricing_inquiry.product.product_no;
        }
        this.request_man = this.pricing_inquiry.request_man;
    }

    public getJob(job_id: string): Promise<JobModel> {
        let promise: Promise<JobModel>;
        promise = new Promise<JobModel>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        if (this.job && this.job.job_no) {
                            this.job_no = this.job.job_no;
                        }
                        if (this.job && this.job.product && this.job.product.product_no) {
                            this.product_no = this.job.product.product_no;
                        }
                        resolve(this.job);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getPricingInquiry(id: string): Promise<PricingInquiryModel> {
        let promise: Promise<PricingInquiryModel>;
        promise = new Promise<PricingInquiryModel>((resolve, reject) => {
            this.pricingInquiryService.get(id)
                .then(pricing_inquiry => {
                    this.pricing_inquiry = pricing_inquiry;
                    resolve(this.pricing_inquiry);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private task_purchase_order_clone(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/clone', 'POST', {
                action: 'clone_purchase_order',
                id: this.purchase_order.task_id
            }).subscribe((response: any): void => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onCustomerSelect(e: any): void {
        this.pricing_inquiry.customer_id = (e && e.id) ? e.id : null;
    }

    public onVendorRadioChange(event: any, item: any, num: number): void {
        if (num === 1) {
            item.price = item.price1;
        } else if (num === 2) {
            item.price = item.price2;
        } else if (num === 3) {
            item.price = item.price3;
        }
    }

    public onStartUploader(data: any): void {
        //
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public onUploadedSuccess(data: any): void {
        this.api.request('pricing/inquiries/document', 'PUT', {}, {
            document: data,
            id: this.pricing_inquiry.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                if (!this.pricing_inquiry.documents) {
                    this.pricing_inquiry.documents = [];
                }
                this.pricing_inquiry.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onUploadError(data: any): void {
        //
    }

    public onRemarkModalSubmit(e: any): void {
        this.loader.show();
        this.api.request('job/remarks', 'PUT', {}, {
            message: e.remark_message,
            tag: 'purchase_notice',
            job_id: this.job.id,
        }).subscribe((response: any): void => {
            this._save()
                .then(() => {
                    let role: string;
                    role = (this.job.current_role === 'pd-planning') ? 'pd-planning-2' : this.job.current_role;
                    this.taskService.setStatus(this.task, this.task.status, 'purchase_notice', role, this.job.process_slug)
                        .then(() => {
                            this.swal.success('แจ้งข้อมูลต่อผู้ร้องขอสำเร็จ');
                            this.leave(true);
                            this.loader.hide();
                        });
                }, error => {
                    this.swal.danger(error);
                    this.loader.hide();
                });
        });
    }

    /*public createPR(force: boolean): void {
      if (force === true) {
        this.viewer.purchaseRequest(this.pricing_inquiry.id);
      } else {
        this.swal.confirm('ยืนยันการออกเอกสาร PR ใช่หรือไม่?')
          .then(result => {
            if (result) {
              this.createPR(true);
            }
          });
      }
    }*/

    public noticePurchaseDate(): void {
        for (let i = 0; i < this.items.length; i++) {
            const item: any = this.items[i];
            if (item && item.id && !item.dateTime) {
                if (item.modelable && item.modelable.mat_name) {
                    this.swal.danger('รายการ "' + item.modelable.mat_name + '" ไม่ได้ระบุวันที่จะได้รับวัสดุ');
                } else {
                    this.swal.danger('รายการจัดซื้อไม่ได้ระบุวันที่จะได้รับวัสดุ');
                }
                return;
            }
        }
        this.swal.confirm('ยืนยันการแจ้งวันที่จะได้รับวัสดุต่อผู้ร้องขอใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.loader.show();
                    this._save()
                        .then(() => {
                            this.storeService.noticePurchaseDate(this.pricing_inquiry.id, this.pricing_inquiry.items)
                                .then(response => {
                                    let role: string;
                                    role = (this.job.current_role === 'pd-planning') ? 'pd-planning-2' : this.job.current_role;
                                    this.taskService.setStatus(this.task, this.task.status, 'purchase_date_time', role, this.job.process_slug)
                                        .then(() => {
                                            const items: any[] = this.get_items();
                                            this.storeService.purchase(
                                                null,
                                                this.job.id,
                                                this.job.product_id,
                                                null,
                                                items,
                                                this.job.customer_id,
                                                null,
                                                this.pricing_inquiry.objective,
                                                true,
                                                null,
                                                this.task.id,
                                                null,
                                                this.pricing_inquiry.id,
                                                'po-purchase',
                                                'create_po_purchase'
                                            ).then(purchase_response => {
                                                this.swal.success('ออกใบจัดซื้อจัดจ้างสำเร็จ');
                                                this.leave(true);
                                                this.loader.hide();
                                            }, error => {
                                                this.swal.danger(error);
                                                this.loader.hide();
                                            });
                                        });
                                });
                        }, error => {
                            this.swal.danger(error);
                            this.loader.hide();
                        });
                } else {
                    //
                }
            });
    }

    private get_items(): any[] {
        let items: any[];
        items = [];
        for (let i = 0; i < this.items.length; i++) {
            const material: any = this.items[i];
            if (material && material.id/* && material.checked && material.job_id === this.job.id*/) {
                items.push({
                    // modelable_type: material.item.modelable.modelable_type,
                    // modelable_id: material.item.modelable.id,
                    modelable_type: 'App\\Item',
                    modelable_id: material.item.id,
                    amount: material.amount,
                    dateTime: material.dateTime,
                    prepared_status: material.prepared_status,
                    price: material.price,
                    remark: material.remark,
                    is_modelable: true
                });
            }
            if (material.children) {
                for (let j = 0; j < material.children.length; j++) {
                    const mat_child: any = material.children[j];
                    if (mat_child && mat_child.id/* && mat_child.checked && material.job_id === this.job.id*/) {
                        items.push({
                            // modelable_type: mat_child.item.modelable.modelable_type,
                            // modelable_id: mat_child.item.modelable.id,
                            modelable_type: 'App\\Item',
                            modelable_id: mat_child.item.id,
                            amount: mat_child.amount,
                            dateTime: mat_child.dateTime,
                            prepared_status: mat_child.prepared_status,
                            price: mat_child.price,
                            remark: mat_child.remark,
                            is_modelable: true
                        });
                    }
                }
            }
        }
        return items;
    }

    public addRemark(purchase: PurchaseModel): void {
        this.modal.show(RemarkModalComponent, {
            description: purchase.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                purchase.remark = content.description;
                this.save(true);
            } else {
                //
            }
        });
    }

    public save(skip?: boolean): void {
        if (this.task) {
            this._save(skip)
                .then(() => {
                    if (skip === true) {
                        //
                    } else {
                        this.swal.success('บันทึกข้อมูลสำเร็จ');
                    }
                });
        }
    }

    private get_item_ids(): any[] {
        let item_ids: any[];
        item_ids = [];
        for (let i = 0; i < this.items.length; i++) {
            const item: any = this.items[i];
            if (item && item.modelable && item.modelable_id) {
                item_ids.push({
                    // purchase_id     : (item.id) ? item.id : '',
                    id              : item.id,
                    modelable_id    : item.modelable_id,
                    modelable_type  : item.modelable_type,
                    dateTime        : item.dateTime,
                    amount          : item.amount,
                    remark          : item.remark,
                    price1          : item.price1,
                    price2          : item.price2,
                    price3          : item.price3,
                    prepared_status : item.prepared_status
                });
            }
        }
        return item_ids;
    }

    private _save(skip?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.pricing_inquiry.item_ids = this.get_item_ids();
            this.modelApi.update(this.pricing_inquiry, [
                'vendor1_id', 'vendor2_id', 'vendor3_id', 'item_ids', 'request_man_id',
                'place_name', 'customer_id', 'objective', 'purchase_user_id', 'quotation_ref'
            ], null, null, null, false)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public approveTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.approveTask()
                    .then(() => {
                        this.swal.success('ผ่านการอนุมัติสำเร็จ');
                        this.leave(true, true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

    public rejectTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ไม่ผ่านการอนุมัติสำเร็จ');
                        this.leave(true, true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

    public onSaveApiCallingBack(e: any): void {
        this.save(true);
    }

}
