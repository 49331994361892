import {Component, NgZone, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {Api} from '../../../now/api/api';
import {ViewList} from '../../../pages/database/list/viewList';

const DISPLAY_FIELDS = {
    id          : 'ID',
    name        : 'ชื่อ',
    tax_no      : 'เลขประจำตัวผู้เสียภาษี',
    branch      : 'สาขา',
    telephone   : 'โทรศัพท์',
    telephone2  : 'โทรศัพท์(สำรอง)',
    fax         : 'แฟกซ์',
    email       : 'อีเมล',
    full_name   : 'ชื่อ',
    position    : 'ตำแหน่ง',
    nick_name   : 'ชื่อเรียก',
    ranking     : 'RANK',
    item_no     : 'รหัสสินค้า',
    search_value    : 'ชื่อเรียก',
    ref_no          : 'อ้างอิง',
    amount          : 'จำนวน',
    modelable       : -1,
    contact_ref_no  : 'RFQ',
    contact_ref     : 'RFQ',
    boi             : 'BOI',
    material_type   : 'ประเภทวัสดุ',
    customer_name   : 'ลูกค้า/บริษัท',
    avatar          : -1,
    slug            : -1,
    machine_no      : 'รหัสเครื่องจักร',
    machine_name    : 'เครื่องจักร',
    model_no        : 'Model NO',
    serial_no       : 'Serial NO',
    price           : 'ต้นทุน',
    brand           : 'ยี่ห้อ',
    display_name    : 'ชื่อ',
    special_tooling_no  : 'รหัสเครื่องมือ',
    description         : 'คำอธิบาย',
    product_no          : 'รหัสสินค้า',
    unit                : 'หน่วย',
    credit_terms        : 'เครดิต',
    product_name        : 'สินค้า',
    process_name        : 'กระบวนการ',
    job_no              : 'JOB NO',
    reserved_amount     : 'จำนวนจอง',
    sale_full_name      : 'ผู้ติดต่อ',
    contact_full_name   : 'ผู้ประสานงาน',
    created_at          : 'สร้าเมื่อ',
    purchase_order_no   : 'P/O NO',
    sale_estimated_price    : 'ราคาประเมิน'
};

@Component({
    selector: 'typeahead-modal-component',
    templateUrl: 'typeaheadModal.component.html',
    styleUrls: ['typeaheadModal.component.scss']
})
export class TypeaheadModalComponent extends ViewList implements OnInit {

    public none: boolean;
    public fields: any;
    public search_filter: string;
    public items: any[];
    public servicePath: string;
    public current_page: number;
    public limit: number;
    public total_pages: number;
    public loop_pages: number[];
    public params: any;
    public existList: any[];
    public existField: string;
    public first_field: string;
    public loop_fields: any[];
    public is_loading: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private ngZone: NgZone
    ) {
        //
        super();
        //
        this.params = {};
        this.loop_pages = [];
        this.loop_fields = [];
        this.fields = {};
        this.items = [];
        this.current_page = 1;
        this.limit = 100;
        this.is_loading = false;
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content) {
                const params: any = this.bsModalRef.content.params;
                if (params) {
                    this.params = params;
                }
                if (this.params && this.params.find) {
                    delete this.params.find;
                }
                const servicePath: any = this.bsModalRef.content.servicePath;
                const dataSource: any = this.bsModalRef.content.dataSource;
                this.none = this.bsModalRef.content.none;
                if (servicePath) {
                    this.servicePath = servicePath;
                    this.getItems(1)
                        .then((items: any[]): void => {
                            this.items = items;
                            // this.setupItems();
                        });
                } else if (dataSource) {
                    this.items = dataSource;
                    this.initTable(dataSource);
                    // this.setupItems();
                }
            } else {
                //
            }
        }, 0);
    }

    public next(): void {
        let next_page: number;
        next_page = this.current_page + 1;
        if (next_page > this.total_pages) {
            next_page = this.total_pages;
        } else {
            //
        }
        this.current_page = next_page;
        this.refresh();
    }

    public select(item: any): void {
        this.bsModalRef.content.submit = true;
        this.bsModalRef.content.data = (item === -1) ? this.none_object() : item;
        this.bsModalRef.hide();
    }

    private none_object(): any {
        return {
            id: -1,
        };
    }

    private refresh(): void {
        this.ngZone.run(() => {
            // this.items.splice(0, this.items.length);
            this.getItems(this.current_page)
                .then(() => {
                    //
                });
        });
    }

    private getItems(page: number): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.is_loading = true;
            this.api.request(this.servicePath, 'GET', Object.assign({
                page: page,
                limit: this.item_per_page
            }, this.params))
                .subscribe((response: any): void => {
                    if (response && response.meta) {
                        this.total_pages = (response.meta.total_pages) ? response.meta.total_pages : 0;
                        this.total_items = (response.meta.total_items) ? response.meta.total_items : 1;
                    } else {
                        // old version
                        this.total_pages = (response.total_pages) ? response.total_pages : 0;
                        this.total_items = (response.total_items) ? response.total_items : 1;
                    }
                    this.items.splice(0, this.items.length);
                    this.initTable(response.data);
                    this.items = this.items.concat(response.data);
                    this.is_loading = false;
                    resolve(this.items);
                }, error => {
                    this.is_loading = false;
                    reject(error);
                });
        });
        return promise;
    }

    public initTable(data: any[]): void {
        if (this.loop_fields) {
            this.loop_fields.splice(0, this.loop_fields.length);
        } else {
            this.loop_fields = [];
        }
        if (data && data.length) {
            const dat = data[0];
            if (typeof dat !== 'string' && dat) {
                for (const [key, value] of Object.entries(dat)) {
                    if (DISPLAY_FIELDS[key] === -1) {
                        //
                    } else {
                        this.loop_fields.push({
                            display: (DISPLAY_FIELDS[key]) ? DISPLAY_FIELDS[key] : key,
                            key
                        });
                    }
                }
            } else if (dat) {
                this.loop_fields.push({
                    display: '',
                    key: null
                });
            }
        }
        // this.initData();
    }

    public initData(): void {
        if (this.none === true) {
            let dat: any;
            dat = { id: -1 };
            if (this.first_field) {
                dat[this.first_field] = 'N/A';
            }
            // this.items.push(dat);
            this.items = [dat].concat(this.items);
        } else {
            // this.items = [];
        }
    }

    private check(item: any): void {
        item.checked = (item.checked === true) ? false : true;
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            //
        } else {
            //
        }
    }

    public pageChange(page: number): void {
        this.current_page = page;
        this.getItems(this.current_page);
    }

}
