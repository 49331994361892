import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MediaComponent} from './media/media.component';
import {DocumentTableComponent} from './documentTable/documentTable.component';
import {UploaderComponent} from './uploader/uploader.component';
import {ERPPipeModule} from '../pipes/erpPipe.module';
import {FileUploadModule} from 'ng2-file-upload';
import {RemarkViewerComponent} from './remarkViewer/remarkViewer.component';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {RemarkTableComponent} from './remarkViewer/table/remarkTable.component';
import {LoaderComponent} from './loader/loader.component';
import {LoaderService} from './loader/loader.service';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {DaterangepickerComponent} from './daterangepicker/daterangepicker.component';
import {TimepickerComponent} from './timepicker/timepicker.component';
import {TypeaheadComponent} from './typeahead/typeahead.component';
// import {BsDatepickerModule, TypeaheadModule} from 'ngx-bootstrap';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {DragulaModule} from 'ng2-dragula';
import {TextMaskModule} from 'angular2-text-mask';
import {DeliveryNoteTableComponent} from './deliveryNoteTable/deliveryNoteTable.component';
import {DepositTableComponent} from './depositTable/depositTable.component';
import {InvoiceTableComponent} from './invoiceTable/invoiceTable.component';
import {PackingListTableComponent} from './packingListTable/packingListTable.component';
import {ReceiptTableComponent} from './receiptTable/receiptTable.component';
import {TaxInvoiceTableComponent} from './taxInvoiceTable/taxInvoiceTable.component';
import {TaskHistoryComponent} from './taskHistory/taskHistory.component';
import {NzTimePickerModule} from 'ng-zorro-antd';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TypeaheadInputComponent} from './typeahead/input/typeaheadInput.component';
import {TypeaheadBasicComponent} from './typeahead/basic/typeaheadBasic.component';
import {DebitNoteTableComponent} from './debitNoteTable/debitNoteTable.component';
import {CreditNoteTableComponent} from './creditNoteTable/creditNoteTable.component';
import {CommercialInvoiceTableComponent} from './commercialInvoiceTable/commercialInvoiceTable.component';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        TextMaskModule,
        FormsModule,
        ERPPipeModule,
        FileUploadModule,
        NgxSmartModalModule,
        PdfViewerModule,
        TypeaheadModule.forRoot(),
        DragulaModule.forRoot(),
        BsDatepickerModule,
        NzTimePickerModule
    ],
    entryComponents: [
        RemarkTableComponent,
        LoaderComponent,
        UploaderComponent,
        DatepickerComponent,
        DaterangepickerComponent,
        TimepickerComponent,
        TypeaheadComponent,
        TypeaheadBasicComponent,
        TaskHistoryComponent
    ],
    exports: [
        MediaComponent,
        DocumentTableComponent,
        UploaderComponent,
        LoaderComponent,
        UploaderComponent,
        RemarkViewerComponent,
        RemarkTableComponent,
        DatepickerComponent,
        DaterangepickerComponent,
        TimepickerComponent,
        TypeaheadComponent,
        TypeaheadBasicComponent,
        TaskHistoryComponent,

        DebitNoteTableComponent,
        CreditNoteTableComponent,
        DeliveryNoteTableComponent,
        DepositTableComponent,
        InvoiceTableComponent,
        PackingListTableComponent,
        ReceiptTableComponent,
        TaxInvoiceTableComponent,
        CommercialInvoiceTableComponent,

        TypeaheadInputComponent
    ],
    declarations: [
        MediaComponent,
        DocumentTableComponent,
        UploaderComponent,
        LoaderComponent,
        UploaderComponent,
        RemarkViewerComponent,
        RemarkTableComponent,
        DatepickerComponent,
        DaterangepickerComponent,
        TimepickerComponent,
        TypeaheadComponent,
        TypeaheadBasicComponent,
        TaskHistoryComponent,

        DebitNoteTableComponent,
        CreditNoteTableComponent,
        DeliveryNoteTableComponent,
        DepositTableComponent,
        InvoiceTableComponent,
        PackingListTableComponent,
        ReceiptTableComponent,
        TaxInvoiceTableComponent,
        CommercialInvoiceTableComponent,

        TypeaheadInputComponent
    ],
    providers: [
        LoaderService,
    ]
})
export class ERPComponentModule {

    public static forRoot(config?: any): ModuleWithProviders {
        return {
            ngModule: ERPComponentModule,
            providers: [
                //
            ]
        };
    }

}
