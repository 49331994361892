import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TaskModel} from '../../../models/task.model';
import {CustomerModel} from '../../../models/customer.model';
import {ContactModel} from '../../../models/contact.model';
import {ProductModel} from '../../../models/product.model';
import {DocumentModel} from '../../../models/document.model';
import {DivApiDirective} from '../../../now/divApi';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {UserModel} from '../../../now/user/user.model';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../../now/user/auth.service';
import {QuotationModel} from '../../../models/quotation.model';
import {SwalService} from '../../../services/swal.service';
import {UserService} from '../../../services/user.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {PusherService} from '../../../services/pusher.service';
import {ModalService} from '../../../services/modal.service';
import {CarrierModel} from '../../../models/carrier.model';
import {CarrierService} from '../../../services/carrier.service';
import {JobModel} from '../../../models/job.model';
import {SchedulerModel} from '../../../models/scheduler.model';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {SchedulerHistoryModel} from '../../../models/schedulerHistory.model';
import {ViewTask} from '../view/viewTask';
import {DOCUMENT, Location} from '@angular/common';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DrawingModel} from '../../../models/drawing.model';
import {DrawingDetailComponent} from '../view/drawingDetail/drawingDetail.component';
import {CADModel} from '../../../models/cad.model';
import {CadDetailComponent} from '../view/cadDetail/cadDetail.component';
import {DocumentDetailComponent} from '../view/documentDetail/documentDetail.component';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {JobRemark} from '../../../app/api/job_remark';
import {PaymentModel} from '../../../models/payment.model';
import {PaymentDetailComponent} from '../view/paymentDetail/paymentDetail.component';
import {JobService} from '../../../services/job.service';
import {Viewer} from '../../../services/viewer';

declare var window: any;

@Component({
    selector: 'rejected-task-component',
    templateUrl: 'rejectedTask.component.html',
    styleUrls: ['rejectedTask.component.scss']
})
export class RejectedTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild('continueRemarkModal', { static: true }) continueRemarkModal: RemarkModal;

    public schedulers: SchedulerModel[];
    public job_id: string;
    public product_id: string;
    public product: ProductModel;
    public task_id: string;
    public customer: CustomerModel;
    public contact: ContactModel;
    public current_user: UserModel;
    public from_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public documents: DocumentModel[];
    public quotations: QuotationModel[];
    public purchase_orders: PurchaseOrderModel[];
    public tax_invoices: any[];
    public carriers: CarrierModel[];
    public current_role: string;
    public payment: PaymentModel;

    public pdfInfo: any;
    public numPages: number[];

    public scheduler_histories: SchedulerHistoryModel[];
    public current_job_document_path: string;

    public job: JobModel;
    public planning_events: any[];

    public current_tab: string;
    public product_index: number;
    public current_part_product_index: number;
    public is_ready: boolean;

    public ready: boolean;
    public options: any;

    public job_remark: JobRemark;
    public job_remark_id: string;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private ngZone: NgZone,
        public api: Api,
        public modal: ModalService,
        public pageScrollService: PageScrollService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        private jobService: JobService,
        public location: Location,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.schedulers = [];
        this.is_ready = false;
        // this.payment = [];
    }

    public viewRemarkDocument(document: DocumentModel): void {
        //
    }

    private init(): void {
        this.task = new TaskModel();
        this.contact = new ContactModel();
        this.product = new ProductModel();
        this.product_index = -1;
        this.current_part_product_index = -1;

        this.job = new JobModel();
        this.ready = false;
        this.planning_events = [];
        this.documents = [];
        this.options = {
            events: []
        };

        this.numPages = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTaskDetail();
                    }
                });

            // this.setupScroll();
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public getPayment(): Promise<PaymentModel> {
        let promise: Promise<PaymentModel>;
        promise = new Promise<PaymentModel>((resolve, reject) => {
            this.api.request('jobs/payment', 'POST', {}, {
                id: this.job_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    let payment: PaymentModel;
                    payment = new PaymentModel();
                    payment.clone(response.data);
                    resolve(payment);
                } else {
                    reject(response);
                }
            }, error => {
                resolve(error);
            });
        });
        return promise;
    }

    public cancelPayment(payment?: PaymentModel): void {
        this.swal.confirm('คุณต้องการยกเลิกหลักฐานการสั่งซื้อนี้ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('payments/delete', 'POST', {}, {
                        id: this.payment.id,
                        task_id: this.task_id
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.swal.success('ยกเลิกหลักฐานการสั่งซื้อนี้สำเร็จ');
                            this.payment = null;
                            // payment.status = 2;
                        } else {
                            //
                        }
                    });
                }
            });
    }

    public viewPaymentDetail(payment: PaymentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PaymentDetailComponent, {
            payment     : payment,
            task        : this.task,
            task_id     : this.task_id,
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    this.payment.clone(content.updated_payment);
                } else if (content && content.deleted === true) {
                    // if (this.taxInvoice.payment_documents) {
                    //     this.taxInvoice.payment_documents.splice(index, 1);
                    // }
                }
            });
    }

    private get_product_ids(): any {
        return {
            product_id              : this.job.product_id,
            customer_product_amount : this.job.customer_product_amount,
            amount                  : this.job.product_amount,
            tmp_job_no              : this.job.job_no,
            checked                 : true
        };
    }

    public onPaymentUploadedSuccess(data: any): void {
        this.api.request('jobs/payment', 'PUT', {}, {
            task_id: this.task.id,
            document: data,
            id: this.job_id,
            verified: true,
            product_ids: this.get_product_ids()
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_payment: PaymentModel;
                new_payment = new PaymentModel();
                new_payment.clone(response.data);
                this.payment = new_payment;
                this.viewPaymentDetail(new_payment);
            }
        }, error => {
            //
        });
    }

    public onPaymentUploadError(data: any): void {
        //
    }

    public onStartUploader(data: any): void {
        if (this.product && this.product.id) {
            //
        } else {
            if (data && data.uploader) {
                data.uploader.cancel();
            }
            this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการอัพโหลดเอกสาร');
        }
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public onUploadedSuccess(data: any): void {
        this.api.request('products/document', 'PUT', {}, {
            document: data,
            id: this.product.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.documents.push(new_document);
                this.product.documents.push(new_document);
                // this.task.addDocument(new_document);
            }
        }, error => {
            //
        });
    }

    public onUploadError(data: any): void {
        //
    }

    private onDrawingUploadedSuccess(data: any): void {
        if (this.product && data) {
            data.name = 'DRAWING ' + this.product.name;
            this.api.request('products/drawing', 'PUT', {}, {
                document: data,
                id: this.product.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let doc: DrawingModel;
                    doc = new DrawingModel();
                    doc.clone(response.data);
                    const index: number = this.product.drawings.push(doc) - 1;
                    this.viewDrawingDetail(doc, index);
                }
            }, error => {
                //
            });
        }
    }

    private onDrawingUploadedError(data: any): void {
        //
    }

    private onCADUploadedSuccess(data: any): void {
        if (this.product) {
            this.api.request('products/cad', 'PUT', {}, {
                document: data,
                id: this.product.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let doc: CADModel;
                    doc = new CADModel();
                    doc.clone(response.data);
                    const length: number = this.product.cads.push(doc);
                    this.viewCADDetail(doc, length - 1);
                }
            }, error => {
                //
            });
        }
    }

    private onCADUploadedError(data: any): void {
        //
    }

    public continuePlanning(): void {
        if (this.payment && this.payment.id) {
            this.continueRemarkModal.open();
        } else {
            this.swal.danger('ไม่สามารถส่งวางแผนได้เนื่องจากไม่พบหลักฐานการสั่งซื้อ');
        }
    }

    private save(): void {
        setTimeout(() => {
            this.swal.success('บันทึกข้อมูลสำเร็จ');
        }, 500);
    }

    public viewDrawingDetail(drawing: DrawingModel, index: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DrawingDetailComponent, {
            drawing: drawing,
            product: this.product,
            task: this.task,
            drawing_index: index
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewCADDetail(cad: CADModel, index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(CadDetailComponent, {
            cad: cad,
            cad_index: index,
            drawings: this.product.drawings,
            product: this.product,
            task: this.task
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewDocumentDetail(doc: DocumentModel, index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc,
            product: this.product,
            task: this.task,
            document_index: index
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.task.clone(response.data);
                    // this.disabledRoleService.set(this.task, ['pd-planning', 'sale-manager', 'qa'], ['estimate']);
                    this.onSuccess(response.data);
                } else {
                    //
                }
            }, error => {
                this.onError(error);
            });
    }

    public onSuccess(data: any): void {
        this.job_id = this.task.modelable_id;
        this.getJob(this.job_id)
            .then(() => {
                this.product_id = this.job.product_id;
                this.getProductDetail()
                    .then(() => {
                        setTimeout(() => {
                            this.is_ready = true;
                        }, 500);
                    });

                this.getPayment()
                    .then((payment: PaymentModel): void => {
                        if (payment && payment.id) {
                            this.payment = payment;
                        } else {
                            //
                        }
                    });
            });
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onError(e: any): void {
        //
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.task.modelable_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public onPdfLoadCompleted(e: any): void {
        //
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

    public onRemarkDocumentUploaded(e?: any): void {
        this.api.request('documents', 'PUT', {}, e)
            .subscribe((response: any): void => {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.documents.push(new_document);
            });
    }

    public onContinueRemarkModalSubmitting(e?: any): void {
        //
    }

    public onContinueRemarkModalSubmit(e: any): void {
        this.taskService.setStatus(this.task, this.task.status, 'create_planning', 'pd-planning', 'planning')
            .then(() => {
                this.swal.success('ดำเนินการสำเร็จ');
                this.leave(true);
            });
    }

    public onCanceledJobRemarkSubmit(e: any): void {
        this.jobService.cancel(this.job, this.task, (e.remark_message) ? e.remark_message : null)
            .then(() => {
                this.swal.success('ยกเลิกงานสำเร็จ');
                this.leave(true);
            });
    }

}
