import {Component, OnInit, ViewChild} from '@angular/core';
import {MachineModel} from '../../../../models/machine.model';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {AppService} from '../../../../app/app.service';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {ModalService} from '../../../../services/modal.service';
import {SwalService} from '../../../../services/swal.service';
import * as moment from 'moment';
import {NgForm} from '@angular/forms';
import {FullCalendarComponent} from '../../../../components/fullCalendar/fullCalendar.component';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {DocumentModel} from '../../../../models/document.model';
import {DocumentDetailComponent} from '../../../task/view/documentDetail/documentDetail.component';
import {environment} from '../../../../environments/environment';
import {UploaderComponent} from '../../../../components/uploader/uploader.component';
import {RepairModalComponent} from '../../../../modals/repair/repairModal.component';
import {Location} from '@angular/common';
import {AxisModalComponent} from '../axisModal/axisModal.component';
import {MethodModalComponent} from '../methodModal/methodModal.component';
import {ProcessModel} from '../../../../models/process.model';
import {ProcessService} from '../../../../services/process.service';
import {VendorModel} from '../../../../models/vendor.model';
import {SupplierModel} from '../../../../models/supplier.model';
import {Viewer} from '../../../../services/viewer';
import {UserModel} from '../../../../now/user/user.model';
import {LoaderService} from '../../../../components/loader/loader.service';

declare var window: any;

@Component({
    selector: 'machine-view-component',
    templateUrl: 'machineView.component.html',
    styleUrls: ['machineView.component.scss']
})
export class MachineViewComponent implements OnInit {

    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild('machinePhoto', { static: false }) machinePhoto: UploaderComponent;

    public machine: MachineModel;
    public machine_id: string;
    public current_tab: string;
    public today: string;
    public ready: boolean;
    public events: any[];
    public all_process: boolean;
    public product_processes: any[];
    public date;
    public processes: ProcessModel[];
    public tmp_supplier: any;
    public tmp_vendor: any;
    public photo_uploader_front: any;
    public photo_uploader_left: any;
    public photo_uploader_right: any;
    public photo_uploader_back: any;
    public current_date;
    public month_list: any[] = [];
    public maintain: any;
    public year;
    public options: any;
    public maintainPlans;

    constructor(
        private loader: LoaderService,
        private ngxSmartModal: NgxSmartModalService,
        private route: ActivatedRoute,
        private api: Api,
        public viewer: Viewer,
        private processService: ProcessService,
        private location: Location,
        private appService: AppService,
        private modelApi: ModelApi,
        private modal: ModalService,
        private swal: SwalService,
    ) {
        //
        this.machine = new MachineModel();
        this.date = moment(new Date());
        const moment_now = moment(new Date());
        this.current_date = moment_now.clone().format('YYYY-MM-DD HH:mm:ss');
        // this.scheduler_histories = [];
        this.today = moment(new Date()).format('MMMM YYYY');
        this.route.params
            .subscribe(params => {
                this.machine_id = params['id'];
                if (this.machine_id) {
                    this.getMachine()
                        .then(() => {
                            this.getMachineJobs(this.machine_id)
                                .then(() => {
                                    //
                                });
                        });
                } else {
                    //
                }
            });

        this.year = +(moment(new Date()).format('YYYY'));
        this.maintain = {};
        this.current_tab = 'profile';
        this.ready = false;
        this.options = {};
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.ready = true;
            this.getProcesses();
            setTimeout(() => {
                // this.fullCalendarComponent.addEventSource(this.user_events);
            }, 250);
        }, 0);
    }

    public viewPreventiveMA(maintain_plan) {
        this.viewer.preventiveMA(maintain_plan, this.machine_id);
    }

    public getMachineJobs(machine_id: any): Promise<any> {
        const promise = new Promise<any[]>((resolve, reject) => {
            this.api.request('calendar/machine', 'POST', {}, {
                id: machine_id,
            }).subscribe((response: any): void => {
                this.clear_events();
                if (response && response.success === true) {
                    for (const dat of response.data) {
                        if (dat) {
                            const revision = (dat.revision > 0) ? ' R' + dat.revision : '';
                            this.add_event(dat, dat.job_no + '' + revision);
                        }
                    }
                    this.refreshEventSources(this.events);
                    resolve([]);
                } else {
                    reject({});
                }
            }, error => {
                console.log(error);
                reject(error);
            });
        });
        return promise;
    }

    public refreshEventSources(events: any): void {
        if (this.fullCalendarComponent) {
            this.fullCalendarComponent.removeEvents();
            this.fullCalendarComponent.addEventSource(events);
            this.fullCalendarComponent.rerenderEvents();
        }
        if (events && events[0] && events[0].start) {
            this.current_date = events[0].start;
        }
    }

    public clear_events(): void {
        if (this.events) {
            this.events.splice(0, this.events.length);
        } else {
            this.events = [];
        }
    }

    public viewFMT(document_no): void {
        this.viewer.fmt(document_no, true)
            .then(path => {
                //
            });
    }

    private add_event(scheduler: any, title: string, slug?: string): void {
        if (scheduler && !scheduler.completed_at) {
            const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
            const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
            // const title = process.name;
            const find_job_no = scheduler.job_no;
            const find_job_revision = scheduler.revision;
            const event_scheduler = {
                job_id          : scheduler.job_id,
                job_no          : find_job_no,
                job_revision    : find_job_revision,
                title           : title,
                start           : start,
                end             : end,
                calendar_title  : title,
                timeline_title  : title,
                className       : 'event-process-' + slug
            };
            this.events.push(event_scheduler);
        }
    }

    private getProcesses(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.processService.getProcesses()
                .then((processes: ProcessModel[]): void => {
                    this.processes = processes;
                    this.setup_processes();
                    resolve(processes);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private onProcessValueChange(process: ProcessModel, e?: any): void {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (process.checked === true) {
            for (let i = 0; i < this.processes.length; i++) {
                if (!this.processes[i].checked) {
                    this.all_process = false;
                    return;
                }
            }
            this.all_process = true;
            // if (this.checkbox_directives.first) {
            //     this.checkbox_directives.first.check();
            // }
        } else if (!process.checked) {
            this.all_process = false;
            // if (this.checkbox_directives.first) {
            //     this.checkbox_directives.first.uncheck();
            // }
        }
    }

    public onVendorDelete(e?: any): void {
        this.machine.vendor = new VendorModel();
    }

    public onSupplierDelete(e?: any): void {
        this.machine.supplier = new SupplierModel();
    }

    public getMachineSchedulerHistories() {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('machines/' + this.machine_id + '/histories')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.product_processes = response.data;
                        for (const product_process of this.product_processes) {
                            product_process.sum_minutes = 0;
                            product_process.sum_gap_time_minutes = 0;
                            if (product_process.scheduler_histories) {
                                for (const scheduler_history of product_process.scheduler_histories) {
                                    product_process.sum_minutes += scheduler_history.minutes;
                                    product_process.sum_gap_time_minutes += scheduler_history.gap_time_minutes;
                                }
                            }
                        }
                    }
                    resolve();
                }, error => {
                    reject();
                });
        });
        return promise;
    }

    private setup_processes(): void {
        let is_all_process: boolean;
        is_all_process = true;
        if (this.processes && this.processes.length > 0 && this.machine && this.machine.processes && this.machine.processes.length > 0) {
            for (let i = 0; i < this.processes.length; i++) {
                if (this.machine.processes.indexOf(this.processes[i].slug) > -1) {
                    this.processes[i].checked = true;
                } else {
                    is_all_process = false;
                }
            }
        }
        if (is_all_process) {
            this.all_process = true;
        }
    }

    public addAxis(): void {
        this.modal.show(AxisModalComponent, {
            machine: this.machine
        }).then((content: any): void => {
            if (content) {
                //
            } else {
                //
            }
        });
    }

    public addMethod(): void {
        this.modal.show(MethodModalComponent, {
            machine: this.machine
        }).then((content: any): void => {
            if (content) {
                //
            } else {
                //
            }
        });
    }

    public getMachine(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('machines/' + this.machine_id + '/detail')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.machine.clone(response.data);
                        if (this.machine.vendor && this.machine.vendor.id) {
                            this.tmp_vendor = this.machine.vendor;
                        }
                    }
                    this.getMachineSchedulerHistories();
                    this.setup_processes();

                    let year;
                    if (moment.isMoment(this.date)) {
                        year = this.date.format('YYYY');
                    } else {
                        const momentObject = moment(this.date, 'YYYY-MM-DD HH:mm:ss');
                        year = momentObject.format('YYYY');
                    }
                    this.getMaintainPlans(year)
                        .then(() => {
                            //
                        });

                    resolve();
                }, error => {
                    reject();
                });
        });
        return promise;
    }

    public doMaintain(): void {
        this.modal.show(RepairModalComponent, {
            machine: this.machine
        }, { class: 'modal-lg' }).then((content: any): void => {
            if (content) {
                //
            }
        });
    }

    public doBroke(): void {
        //
    }

    public deleteMachine(): void {
        //
    }

    public onSelectTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (model && data) {
            model.clone(data);
        }
        if (model_parent && field_name && data && data.id) {
            model_parent[field_name] = data.id;
        }
    }

    public onWorkSubmit(form: NgForm): void {
        if (form.valid) {
            this.modelApi.update(this.machine, [
                'price', 'ranking', 'width', 'length', 'height', 'weight', 'voltage', 'electric_current', 'power', 'round',
                'axis_x', 'axis_y', 'axis_z', 'axis_a', 'axis_e', 'methods'
            ]).subscribe((response: any): void => {
                this.swal.success('บันทักข้อมูลเครื่องจักรสำเร็จ', null, 2000);
            }, error => {
                this.swal.danger(error);
            });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลให้ครบถ้วน');
        }
    }

    public onProfileSubmit(form: NgForm): void {
        if (form.valid) {
            this.modelApi.update(this.machine, [
                'machine_name', 'purchase_date', 'brand', 'model_no', 'serial_no', 'vendor_id', 'supplier_id'
            ]).subscribe((response: any): void => {
                this.swal.success('บันทักข้อมูลเครื่องจักรสำเร็จ', null, 2000);
            }, error => {
                this.swal.danger(error);
            });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลให้ครบถ้วน');
        }
    }

    public viewDocument(doc: DocumentModel): void {
        this.viewer.document(doc);
    }

    public viewDocumentDetail(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            user: this.machine,
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewDocumentPreview(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        window.open(environment.api_host + 'view/' + doc.id + '/machine/document/', '_blank');
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('machines/document', 'PUT', {}, {
            document: data,
            id: this.machine.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.machine.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public deletePhoto(document: DocumentModel, side_name: string): void {
        this.swal.confirm('คุณต้องการลบรูปภาพเครื่องจักรใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    if (this.machine.photos) {
                        this.api.request('machines/document/delete', 'POST', {}, {
                            id: document.id
                        }).subscribe((response: any): void => {
                            delete this.machine.photos[side_name];
                        }, error => {
                            //
                        });
                    } else {
                        //
                    }
                }
            });
    }

    public onDateChange(event) {
        const momentObject = moment(event, 'YYYY-MM-DD HH:mm:ss');
        const year = momentObject.format('YYYY');
        if (year) {
            this.year = year;
            this.getMaintainPlans(year)
                .then(() => {
                    //
                });
        }
    }

    public previewPhoto(doc: DocumentModel): void {
        window.open(environment.api_host + 'view/' + doc.id + '/machine/document/', '_blank');
    }

    public getMaintainPlans(year?: string): Promise<any> {
        this.month_list = [
            {id: '01', value: 'มกราคม'}, {id: '02', value: 'กุมภาพันธ์'}, {id: '03', value: 'มีนาคม'}, {id: '04', value: 'เมษายน'},
            {id: '05', value: 'พฤษภาคม'}, {id: '06', value: 'มิถุนายน'}, {id: '07', value: 'กรกฎาคม'}, {id: '08', value: 'สิงหาคม'},
            {id: '09', value: 'กันยายน'}, {id: '10', value: 'ตุลาคม'}, {id: '11', value: 'พฤศจิกายน'}, {id: '12', value: 'ธันวาคม'}
        ];
        const promise = new Promise((resolve) => {
            this.api.request('machines/maintain_plans', 'GET', {
                year, id: this.machine_id
            }).subscribe((res: any) => {
                this.maintainPlans = [];
                if (res && res.data) {
                    for (let dat of res.data) {
                        if (dat && dat.start) {
                            const momentObject = moment(dat.start, 'YYYY-MM-DD HH:mm:ss');
                            if (momentObject) {
                                const month = momentObject.clone().format('MM').toString();
                                if (month) {
                                    const findIndex = this.month_list.findIndex(i => i.id === month);
                                    if (findIndex > -1) {
                                        if (dat && dat.user && dat.user.id) {
                                            const user = new UserModel();
                                            user.clone(dat.user);
                                            this.month_list[findIndex].user = user;
                                        }
                                        this.month_list[findIndex].date = momentObject.clone().format('MMYYYY').toString();
                                        this.month_list[findIndex].start = dat.start;
                                        this.month_list[findIndex].end = dat.end;
                                        this.month_list[findIndex].remark = dat.remark;
                                        this.month_list[findIndex].completed_at = dat.completed_at;
                                    }
                                }
                            }
                        }
                    }
                }
                for (let i = 0; i < this.month_list.length; i++) {
                    const month = this.month_list[i];
                    const start = (month.start) ? moment(month.start, 'YYYY-MM-DD HH:mm:ss') : undefined;
                    const end = (month.end) ? moment(month.end, 'YYYY-MM-DD HH:mm:ss') : undefined;
                    // const user = new UserModel();
                    const remark = (month.remark) ? month.remark : '';
                    const momentObject = moment(this.year + '-' + (i + 1) + '-01', 'YYYY-MM-DD');
                    const minDate = momentObject.startOf('month').toDate();
                    const maxDate = momentObject.endOf('month').toDate();
                    const user = (month && month.user && month.user.id) ? month.user : undefined;
                    this.maintainPlans.push({
                        user,
                        minDate, maxDate,
                        month_index: i,
                        month_name: month.value,
                        start, end, remark
                    });
                }
                resolve(res);
            });
        });
        return promise;
    }

    public createNewMaintainPlan(): void {
        this.ngxSmartModal.open('maintainPlanModal');
        let year;
        if (moment.isMoment(this.date)) {
            year = this.date.format('YYYY');
        } else {
            const momentObject = moment(this.date, 'YYYY-MM-DD HH:mm:ss');
            year = momentObject.format('YYYY');
        }
        this.getMaintainPlans(year)
            .then(() => {
                //
            });
    }

    public saveMaintainPlan(): void {
        this.loader.show();
        let year;
        if (moment.isMoment(this.date)) {
            year = this.date.format('YYYY');
        } else {
            const momentObject = moment(this.date, 'YYYY-MM-DD HH:mm:ss');
            year = momentObject.format('YYYY');
        }
        this.api.request('machines/maintain_plans', 'POST', {}, {
            id: this.machine_id, year,
            plans: this.prepareMaintainPlans()
        }).subscribe(res => {
            this.loader.hide();
            this.swal.success('บันทึกแผนสำเร็จ');
            this.ngxSmartModal.close('maintainPlanModal');
            this.getMaintainPlans(year)
                .then(() => {
                    //
                }, error => {
                    console.error(error);
                    this.loader.hide();
                });
        }, error => {
            console.error(error);
            this.loader.hide();
        });
    }

    public prepareMaintainPlans() {
        let data = [];
        for (const plan of this.maintainPlans) {
            const start = (plan && plan.start) ? plan.start : undefined;
            let end;
            if (plan && plan.end) {
                end = moment(plan.end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD 17:00:00');
            }
            data.push({
                month: plan.month_index,
                user_id: (plan.user && plan.user.id) ? plan.user.id : undefined,
                start, end,
                remark: plan.remark
            });
        }
        console.log(data);
        return data;
    }

    public save(): void {
        this.modelApi.update(this.machine, [
            // Profile
            'machine_name', 'purchase_date', 'brand', 'model_no', 'serial_no', 'vendor_id', 'supplier_id', 'display_name',
            // Work
            'price', 'ranking', 'width', 'length', 'height', 'weight', 'voltage', 'electric_current', 'power', 'round',
            'methods', 'axis'
            // 'axis_x', 'axis_y', 'axis_z', 'axis_a', 'axis_e', 'methods'
        ]).subscribe((response: any): void => {
            let process_ids: string[];
            process_ids = this.get_checked_process_ids();
            this.api.request('machines/processes', 'POST', {}, {
                id: this.machine_id,
                process_ids: process_ids
            }).subscribe((): void => {
                this.swal.success('บันทึกข้อมูลเครื่องจักรสำเร็จ');
            });
        }, error => {
            this.swal.danger(error);
        });
    }

    private get_checked_process_ids(): string[] {
        let checked_process_ids: string[];
        checked_process_ids = [];
        for (let i = 0; i < this.processes.length; i++) {
            if (this.processes[i].checked) {
                checked_process_ids.push(this.processes[i].id);
            }
        }
        return checked_process_ids;
    }

    public createMachine(): void {
        this.swal.confirm('ยืนยันการเพิ่มเครื่องจักรใหม่ ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.modelApi.create(this.machine, [
                        // Profile
                        'machine_name', 'purchase_date', 'brand', 'model_no', 'serial_no', 'vendor_id', 'supplier_id',
                        // Work
                        'price', 'ranking', 'width', 'length', 'height', 'weight', 'voltage', 'electric_current', 'power', 'round',
                        'axis_x', 'axis_y', 'axis_z', 'axis_a', 'axis_e', 'methods'
                    ]).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.machine.clone(response.data);
                            this.swal.success('เพิ่มเครื่องจักรสำเร็จ');
                            this.location.replaceState('/database/machine/' + this.machine.id + '/detail');
                        } else {
                            this.swal.danger(response.message);
                        }
                    }, error => {
                        this.swal.danger(error);
                    });
                } else {
                    //
                }
            });
    }

    public onUploadSuccess(data: any, side_name: string): void {
        if (data && side_name) {
            data['name'] = side_name;
            this.api.request('machines/document', 'PUT', {}, {
                document: data,
                id: this.machine.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.machine.photos[side_name] = new_document;
                }
            }, error => {
                //
            });
        } else {
            //
        }
    }

    public method_keys(): string[] {
        return Object.keys(this.machine.methods);
    }

    public axis_keys(): string[] {
        return Object.keys(this.machine.axis);
    }

}
