import {FormModel} from '../formModel';
import {TaskModel} from '../../models/task.model';
import {DocumentModel} from '../../models/document.model';
import {Model} from '../model';

export class UserModel extends FormModel {

    public prefix: string;
    public full_name: string;
    public nick_name: string;
    public config: any;
    public databases: any[];

    public prefix_en: string;
    public full_name_en: string;
    public nick_name_en: string;

    public gender: string;
    public remark: string;

    public salary: number;

    public level: number;
    public avatar: string;
    public phone_number: string;
    public email: string;
    public roles: string[];
    public processes: string[];

    public telephone: string;
    public telephone2: string;
    public fax: string;

    public position: string;

    public current_task_id: string;
    public current_task: TaskModel;

    public current_role: string;

    public ranking: number;
    public price: number;
    public status: number;

    public time: number;

    public username: string;
    public password: string;

    public activities: any;
    public profile: any;
    public benefits: any;
    public leaves: any;

    public documents: DocumentModel[];

    constructor() {
        super('users', 'App\\User');
        this.roles = [];
        this.activities = {
            absence: {},
            leave: {},
            late: {}
        };
        this.documents = [];
        this.benefits = {
            social_security: false,
            night_shift: false,
            overtime: false,
            fund: false,
            diligence: false,
            position: false,
            insurance: false,
            feed: false,
            risk: false,
            health_insurance: false,
            gas: false,
            skill: false,
            other: false
        };
        // {"max_vacation": 6, "max_sick_leave": 30, "max_personal_leave": 3, "max_maternity_leave": 90}
        // this.configs = {
        //     vacation: true,
        //     max_vacation: 6,
        //     personal: true,
        //     max_personal_leave: 3,
        //     sick: true,
        //     max_sick_leave: 30,        // 30
        //     maternity: true,
        //     max_maternity_leave: 90,   // 90
        //     force: true,
        //     max_force_leave: 0
        // };
        this.profile = {};
    }

    public clone(data: any): Model {
        if (data && data.benefits) {
            this.benefits = Object.assign(this.benefits, data.benefits);
            delete data.benefits;
        }
        if (data && data.profile) {
            this.profile = Object.assign(this.profile, data.profile);
            delete data.profile;
        }
        super.clone(data);
        return this;
    }

    public deleteDocument(doc: DocumentModel): void {
        let index: number;
        index = this.documents.findIndex(i => i.id === doc.id);
        if (index > -1) {
            this.documents.splice(index, 1);
        } else {
            //
        }
    }

    public get total_price(): number {
        return this.time * this.price;
    }

}
