import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {ProductModel} from '../../../../models/product.model';
import {DocumentModel} from '../../../../models/document.model';
import {Api} from '../../../../now/api/api';
import {QuotationModel} from '../../../../models/quotation.model';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import * as moment from 'moment';
import {PaymentModel} from '../../../../models/payment.model';
import {JobService} from '../../../../services/job.service';
import {LoaderService} from '../../../../components/loader/loader.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {DecimalPipe} from '@angular/common';

@Component({
    selector: 'app-purchase-order-detail-component',
    templateUrl: 'purchaseOrderDetail.component.html',
    styleUrls: ['purchaseOrderDetail.component.scss'],
    providers: [
        DecimalPipe
    ]
})
export class PurchaseOrderDetailComponent implements OnInit {

    public payment: PaymentModel;
    public tmp_payment: PaymentModel;
    public product: ProductModel;
    public mask: string[];
    public task: TaskModel;
    public quotation: QuotationModel;
    public options: any[];

    public products: ProductModel[];
    public tmp_products: ProductModel[];

    constructor(
        private decimalPipe: DecimalPipe,
        public bsModalRef: BsModalRef,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService,
        private jobService: JobService,
        private loaderService: LoaderService,
    ) {
        //
        this.mask = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: true
        });

        this.task = new TaskModel();
        this.payment = new PaymentModel();
        this.tmp_payment = new PaymentModel();
        this.tmp_payment.document = new DocumentModel();
        this.quotation = new QuotationModel();
        this.options = [];

        this.products = [];
        this.tmp_products = [];
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.payment) {
                if (!this.bsModalRef.content.payment.started_at) {
                    this.bsModalRef.content.payment.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }
                this.payment = this.bsModalRef.content.payment;
                this.tmp_payment.id = this.payment.id;
                this.tmp_payment.started_at = this.payment.started_at;
                this.tmp_payment.purchase_order_no = this.payment.purchase_order_no;
                this.tmp_payment.required_at = this.payment.required_at;
                this.tmp_payment.customer_required_at = this.payment.customer_required_at;
                this.tmp_payment.verified = this.payment.verified;
                this.tmp_payment.remark = this.payment.remark;
                this.tmp_payment.grand_total = this.payment.grand_total;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.quotation) {
                this.quotation = this.bsModalRef.content.quotation;
                // this.initOptions();
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.products) {
                for (let i = 0; i < this.bsModalRef.content.products.length; i++) {
                    if (this.bsModalRef.content.products[i].id) {
                        this.tmp_products.push(Object.assign({}, this.bsModalRef.content.products[i]));
                    } else {
                        //
                    }
                }
                this.reset_products();
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
            this.setup_products();
            this.getProductStoreAmount()
                .then(() => {});
        }, 0);
    }

    public getProductStoreAmount(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            let product_ids: any[];
            product_ids = [];
            for (let i = 0; i < this.tmp_products.length; i++) {
                product_ids.push(this.tmp_products[i].id);
            }
            this.api.request('store/products', 'POST', {}, {
                product_ids: product_ids
            }).subscribe((response: any): void => {
                for (let i = 0; i < this.products.length; i++) {
                    const product_id = this.products[i].id;
                    const dat = response.data.find(_ => _.modelable_id === product_id);
                    if (dat) {
                        this.products[i].store_amount = dat.amount;
                    }
                }
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onProductCheckChange(product: ProductModel, e?: any): void {
        setTimeout(() => {
            if (product && product.checked) {
                let product_ids: any[];
                product_ids = [];
                for (let i = 0; i < this.products.length; i++) {
                    const p: any = this.products[i];
                    if (p && p.id) {
                        product_ids.push({
                            id: p.id,
                            checked: p.checked,
                            job_no: p.tmp_job_no
                        });
                    }
                }
                this.api.request('jobs/next/no', 'POST', {}, {
                    product_ids: product_ids
                }).subscribe((response: any): void => {
                    if (response && response.success === true) {
                        // product.tmp_job_no = response.data.job_no;
                        const no: number = response.data.no;
                        // const year: number = response.data.year;
                        // const month: number = response.data.month;
                        let j: number;
                        j = 0;
                        for (let i = 0; i < this.products.length; i++) {
                            if (this.products[i] && this.products[i].checked && !this.products[i].tmp_job_no) {
                                // const tmp_job_no_without_prefix: number = no + j;
                                // const tmp_job_no: string = 'JOB' + tmp_job_no_without_prefix;
                                let tmp_job_no_without_prefix: number;
                                let tmp_job_no: string;
                                let exist_index: number;
                                exist_index = -1;
                                do {
                                    tmp_job_no_without_prefix = no + j;
                                    tmp_job_no = 'JOB' + tmp_job_no_without_prefix;
                                    exist_index = this.products.findIndex(p => p.tmp_job_no === tmp_job_no);
                                    j++;
                                } while (exist_index > -1);
                                this.products[i].tmp_job_no = tmp_job_no;
                            } else if (this.products[i] && this.products[i].checked) {
                                j++;
                            }
                        }
                    }
                });
            } else if (product) {
                product.tmp_job_no = '';
            }
        }, 0);
    }

    public setup_products(): void {
        for (let i = 0; i < this.products.length; i++) {
            const _product: ProductModel = this.products[i];
            if (_product) {
                const index: number = this.payment.products.findIndex(p => p.id === _product.id);
                const payment_product: any = this.payment.products[index];
                if (index > -1 && payment_product) {
                    _product.customer_product_amount = (payment_product.pivot && payment_product.pivot.customer_product_amount > 0)
                        ? payment_product.pivot.customer_product_amount : 0;
                    _product.amount = (payment_product.pivot && payment_product.pivot.amount > 0)
                        ? payment_product.pivot.amount : 0;
                    _product.pivot.price_per_unit = (payment_product.pivot && payment_product.pivot.price_per_unit > 0)
                        ? payment_product.pivot.price_per_unit : 0;
                    _product.tmp_job_no = (payment_product.pivot && payment_product.pivot.tmp_job_no)
                        ? payment_product.pivot.tmp_job_no : '';
                    _product.checked = (payment_product.pivot.checked) ? true : false;
                } else {
                    //
                }
            } else {
                //
            }
        }
    }

    private reset_products(): void {
        if (this.tmp_products) {
            for (let i = 0; i < this.tmp_products.length; i++) {
                let product: ProductModel;
                product = new ProductModel();
                product.clone(this.tmp_products[i]);
                product.checked = false;
                // product.drawings = this.tmp_products[i].drawings;
                product.customer_product_amount = this.tmp_products[i].customer_product_amount;
                product.amount = this.tmp_products[i].amount;
                product.pivot.price_per_unit = this.tmp_products[i].pivot.price_per_unit;
                product.tmp_job_no = this.tmp_products[i].pivot.tmp_job_no;
                this.products.push(product);
            }
        }
    }

    private checked_products_amount(): boolean {
        for (let i = 0; i < this.products.length; i++) {
            if ((this.products[i].amount < this.products[i].customer_product_amount || !this.products[i].amount) && this.products[i].checked) {
                return false;
            }
        }
        return true;
    }

    private get_job_nos(): string[] {
        let job_nos: string[];
        job_nos = [];
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i] && this.products[i].tmp_job_no && this.products[i].checked) {
                job_nos.push(this.products[i].tmp_job_no);
            }
        }
        return job_nos;
    }


    public get_checked_products(): any {
        let checked_products: any;
        for (let i = 0; i < this.products.length; i++) {
            const product: ProductModel = this.products[i];
            if (product && product.id && product.checked === true) {
                if (!checked_products) {
                    checked_products = {};
                }
                if (checked_products[product.id]) {
                    return false;
                } else {
                    checked_products[product.id] = {
                        price_per_unit: product.pivot.price_per_unit,
                        customer_product_amount: product.customer_product_amount,
                        amount: product.amount,
                        checked: product.checked,
                        tmp_job_no: product.tmp_job_no
                    };
                }
            }
        }
        return checked_products;
    }

    public get num_products(): number {
        let num: number;
        num = 0;
        for (let i = 0; i < this.products.length; i++) {
            const product: ProductModel = this.products[i];
            if (product && product.checked === true) {
                num++;
            }
        }
        return num;
    }

    public delete(): void {
        if (this.payment.status === 1) {
            return;
        }
        const name: string = (this.tmp_payment.purchase_order_no)
            ? this.tmp_payment.purchase_order_no : this.tmp_payment.document.name;
        this.swal.confirm('คุณต้องการลบเอกสารใบสั่งซื้อ "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.tmp_payment.task_id = this.task.id;
                    this.modelApi.delete(this.tmp_payment)
                        .subscribe((response: any): void => {
                            const index: number = this.quotation.payments.findIndex(i => i.id === this.tmp_payment.id);
                            if (index > -1) {
                                this.quotation.payments.splice(index, 1);
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    private _loadJobs(product_ids) {
        this.save()
            .then(() => {
                this.tmp_payment.product_ids = product_ids;
                const job_nos: string[] = this.get_job_nos();
                if (this.tmp_payment.product_ids) {
                    this.swal.confirm('เริ่มสั่งการผลิตสินค้าตามใบสั่งซื้อสินค้า ' +
                        '<u>' + this.tmp_payment.purchase_order_no + '</u>' +
                        ', ออกหมายเลขงาน <u>' + job_nos.join(', ') + '</u>' +
                        // ' เพื่อส่งให้กับลูกค้าสำหรับการตรวจสอบสถานะการผลิตสินค้าใช่หรือไม่?', true)
                        ' ใช่หรือไม่?', true)
                        .then((result: boolean): void => {
                            if (result === true) {
                                this.save()
                                    .then(() => {
                                        this.loaderService.show();
                                        this.loadJobs(this.tmp_payment.product_ids, this.task.id, this.tmp_payment.id)
                                            .then(jobs => {
                                                this.loaderService.hide();
                                                this.bsModalRef.content.jobs = jobs;
                                                this.bsModalRef.content.is_submit = true;
                                                this.bsModalRef.content.checked_products = this.tmp_payment.product_ids;
                                                this.bsModalRef.content.load_product_jobs = this.products;
                                                this.bsModalRef.hide();
                                            }, error => {
                                                this.loaderService.hide();
                                                this.swal.danger(error);
                                            });
                                    });
                            } else {
                                //
                            }
                        });
                } else {
                    this.swal.danger('กรุณาเลือกรายการอย่างน้อย 1 รายการ');
                }
            });
    }

    public complete(): void {
        if (this.payment.status === 1) {
            return;
        } else if (!this.tmp_payment.verified) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากหลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง');
        // } else if (!this.checked_products_amount()) {
        //     this.swal.danger('จำนวนสินค้าที่ต้องการผลิตมีค่าน้อยกว่าจำนวนที่ลูกค้าต้องการ');
        } else if (!this.tmp_payment.purchase_order_no) {
            this.swal.danger('โปรดระบุรหัสการสั่งซื้อจากลูกค้า P/O');
        } else {
            const product_ids = this.get_checked_products();
            if (product_ids === false) {
                this.swal.danger('ไม่สามารถโหลดงานมากกว่า 1 งานได้ เนื่องจากเป็นสินค้าประเภทเดียวกัน');
            } else {
                const tmpGrandTotal = (this.tmp_payment.grand_total) ? this.tmp_payment.grand_total.toString() : '0';
                const tmpGrandTotalNumber = (tmpGrandTotal) ? +tmpGrandTotal.split(',').join('') : +tmpGrandTotal;
                if (tmpGrandTotalNumber < this.quotation.grand_total) {
                    this.swal.confirm('ยืนยันมลูค่ายอดสุทธิ "' + tmpGrandTotal + '" น้อยกว่ายอดของใบเสนอราคา "' + this.quotation.grand_total + '" ใช่หรือไม่?')
                        .then(result2 => {
                            if (result2 === true) {
                                if (!this.checked_products_amount()) {
                                    this.swal.confirm('จำนวนสินค้าที่ต้องการผลิตมีค่าน้อยกว่าจำนวนที่ลูกค้าต้องการ ยืนยันการผลิตใช่หรือไม่?')
                                        .then((result: boolean): void => {
                                            if (result && result === true) {
                                                this._loadJobs(product_ids);
                                            }
                                        });
                                } else {
                                    this._loadJobs(product_ids);
                                }
                            }
                        });
                } else {
                    if (!this.checked_products_amount()) {
                        this.swal.confirm('จำนวนสินค้าที่ต้องการผลิตมีค่าน้อยกว่าจำนวนที่ลูกค้าต้องการ ยืนยันการผลิตใช่หรือไม่?')
                            .then((result: boolean): void => {
                                if (result && result === true) {
                                    this._loadJobs(product_ids);
                                }
                            });
                    } else {
                        this._loadJobs(product_ids);
                    }
                }
            }
        }
    }

    public loadJobs(product_ids: any[], task_id: string, payment_id: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.jobService.loadJobs(product_ids, task_id, payment_id)
                .then(jobs => {
                    resolve(jobs);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.tmp_payment.product_ids = this.get_checked_products();
            this.tmp_payment.task_id = this.task.id;
            const tmpGrandTotal = this.tmp_payment.grand_total.toString();
            const tmpGrandTotalNumber = (tmpGrandTotal) ? +tmpGrandTotal.split(',').join('') : +tmpGrandTotal;
            this.tmp_payment.grand_total = tmpGrandTotalNumber;
            this.modelApi.update(this.tmp_payment, [
                'purchase_order_no', 'started_at', 'verified', 'product_ids', 'required_at', 'task_id', 'remark', 'sale_admin_required_at',
                'grand_total', 'customer_required_at'
            ], null, null, null, false).subscribe((response: any): void => {
                for (let i = 0; i < this.products.length; i++) {
                    const product: ProductModel = this.products[i];
                    const index: number = this.payment.products.findIndex(p => p.id === product.id);
                    if (index > -1) {
                        const payment_product: any = this.payment.products[index];
                        payment_product.pivot.customer_product_amount = product.customer_product_amount;
                        payment_product.pivot.price_per_unit = product.pivot.price_per_unit;
                        payment_product.pivot.amount = product.amount;
                        payment_product.pivot.checked = product.checked;
                    } else {
                        let new_product: ProductModel;
                        new_product = new ProductModel();
                        new_product.id = product.id;
                        new_product.name = product.name;
                        new_product.pivot.customer_product_amount = product.customer_product_amount;
                        new_product.pivot.price_per_unit = product.pivot.price_per_unit;
                        new_product.pivot.amount = product.amount;
                        new_product.pivot.checked = product.checked;
                        if (product.checked) {
                            this.payment.products.push(new_product);
                        } else {
                            //
                        }
                    }
                }
                this.payment.id = this.tmp_payment.id;
                this.payment.purchase_order_no = this.tmp_payment.purchase_order_no;
                this.payment.started_at = this.tmp_payment.started_at;
                this.payment.required_at = this.tmp_payment.required_at;
                this.payment.customer_required_at = this.tmp_payment.customer_required_at;
                this.payment.verified = this.tmp_payment.verified;
                this.payment.remark = this.tmp_payment.remark;
                this.payment.grand_total = this.tmp_payment.grand_total;

                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSubmit(form: NgForm): void {
        const tmpGrandTotal = this.tmp_payment.grand_total.toString();
        const tmpGrandTotalNumber = (tmpGrandTotal) ? +tmpGrandTotal.split(',').join('') : +tmpGrandTotal;
        /*if (this.payment.status === 1) {
            return;
        } else */if (this.payment.verified === undefined) {
            this.swal.danger('กรุณาระบุความถูกต้องของหลักฐานการเงิน');
        } else if (tmpGrandTotalNumber < this.quotation.grand_total) {
            this.swal.confirm('ยืนยันมลูค่ายอดสุทธิ "' + tmpGrandTotal + '" น้อยกว่ายอดของใบเสนอราคา "' + this.quotation.grand_total + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.save()
                            .then(() => {
                                this.swal.success('บันทึกข้อมูลหลักฐานการสั่งซื้อ หรือ P/O สำเร็จ');
                                this.bsModalRef.hide();
                            });
                    }
                });
        } else {
            const tmpGrandTotalNumber2 = this.decimalPipe.transform(tmpGrandTotalNumber);
            this.swal.confirm('ยืนยันมลูค่ายอดสุทธิ "' + tmpGrandTotalNumber2 + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.save()
                            .then(() => {
                                this.swal.success('บันทึกข้อมูลหลักฐานการสั่งซื้อ หรือ P/O สำเร็จ');
                                this.bsModalRef.hide();
                            });
                    }
                });
        }
    }

    onCustomerProductAmountChange(event, pricePerUnit) {
        const amount = (event) ? +event : 0;
        const _pricePerUnit = (pricePerUnit) ? +pricePerUnit : 0;
        this.tmp_payment.grand_total = amount * _pricePerUnit;
    }

}
