import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthService} from '../../now/user/auth.service';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {SwalService} from '../../services/swal.service';
import {UserService} from '../../services/user.service';
import {SkinService} from '../../services/skin.service';

const AUTH_INVALID = 'ชื่อเรียกหรือรหัสผ่านไม่ถูกต้อง';

@Component({
    selector: 'app-auth-component',
    templateUrl: 'auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, AfterViewInit {

    public bg: string;
    public textImage: string;
    public message: string;

    constructor(
        private skinService: SkinService,
        private authService: AuthService,
        private router: Router,
        private swal: SwalService,
        private userService: UserService,
    ) {
        //
    }

    ngOnInit() {
        const authUser = this.authService.user;
        if (authUser && authUser.id) {
            this.router.navigateByUrl('/room');
            return;
        }
    }

    ngAfterViewInit(): void {
        this.getSkin();
    }

    public getSkin() {
        this.bg = '../../assets/images/bg_auth.jpg';
        this.skinService.getSkin()
            .then((data: any) => {
                if (data && data.skin) {
                    this.bg = (data.skin.bg) ? data.skin.bg : '../../assets/images/bg_auth.jpg';
                    this.textImage = (data.skin.text_image) ? data.skin.text_image : '';
                    this.message = (data.skin.message) ? data.skin.message : '';
                }
            });
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            let password: string;
            // password = Md5.hashStr(form.value.password).toString();
            password = form.value.password;
            this.authService.login(form.value.username, password)
                .then((result: boolean): void => {
                    if (result) {
                        if (this.authService.onAuthHandler) {
                            this.authService.onAuthHandler();
                        }
                        this.authService.profile()
                            .then(user => {
                                console.log(user);
                                this.userService.setUser(user);
                            });
                    } else {
                        this.swal.danger(AUTH_INVALID);
                    }
                }, error => {
                    this.swal.danger(JSON.stringify(error));
                });
        } else {
            this.swal.danger(AUTH_INVALID);
        }
    }

}
