import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
// import {BsDatepickerDirective, BsLocaleService} from 'ngx-bootstrap';
import {BsDatepickerDirective, BsLocaleService} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
    selector: 'datepicker-component',
    templateUrl: 'datepicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DatepickerComponent),
        }
    ],
    styleUrls: ['datepicker.component.scss']
})
export class DatepickerComponent implements ControlValueAccessor, AfterViewInit {

    @ViewChild(BsDatepickerDirective, { static: true }) datepicker: BsDatepickerDirective;

    @Input() startDate;
    @Input() minDate;
    @Input() maxDate;
    @Input() deletable: boolean;

    @Input('left') left: boolean;
    @Input('icon') icon_text: string;
    @Input('display') display_text: string;
    @Input() dateCustomClasses: any;
    @Input() config: any;

    @Input('highLightDates')
    highLightDates: any[];

    public time_HH_mm_ss: string;
    public the_model: any;

    public mask: any;
    public tmp_datepicker: any;
    public timeout: any;

    @Input()
    public set disabled(value: boolean) {
        this._disabled = value;
    }

    @Input('placeholder') placeholder: string;
    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

    public _disabled: boolean;
    public ready: boolean;
    public date_time_string: string;
    public propagateChange: any = (_: any): void => {};

    constructor(
        private localeService: BsLocaleService,
        private el: ElementRef
    ) {
        //
        this._disabled = false;
        this.ready = false;
        this.localeService.use('th');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.placeholder = (this.placeholder || this.placeholder === '') ? this.placeholder : 'เลือกวัน';
            this.config = Object.assign({
                // dateInputFormat: 'YYYY-MM-DD HH:mm:ss'
            }, this.config);
        }, 0);
    }

    public onOpenCalendar(container: any): void {
        if (this.config && this.config.dateInputFormat) {
            if (this.config.dateInputFormat === 'YYYY') {
                if (container) {
                    container.yearSelectHandler = (e: any): void => {
                        container._store.dispatch(container._actions.select(e.date));
                    };
                    container.setViewMode('year');
                }
            } else if (this.config.dateInputFormat === 'MM/YYYY') {
                if (container) {
                    container.monthSelectHandler = (e: any): void => {
                        container._store.dispatch(container._actions.select(e.date));
                    };
                    container.setViewMode('month');
                }
            } else if (this.config.dateInputFormat === 'DD/MM/YYYY') {
                //
            }
        }
    }

    public trigger(e: any): void {
        if (e) {
            e.stopPropagation();
        }
        if (this._disabled) {
            //
        } else {
            this.datepicker.show();
        }
    }

    public reset(): void {
        this.writeValue(null);
        this.propagateChange(null);
        this.the_model = null;
    }

    public onValueChange(e: any): void {
        if (e && moment.isDate(e)) {
            // const moment_object = moment(new Date(e.getTime() - e.getTimezoneOffset() * 60 * 1000)).clone();
            const moment_object = moment(e).clone();
            const date_string = moment_object.format('YYYY-MM-DD ') + this.time_HH_mm_ss;
            console.log(moment(e).format('YYYY-MM-DD HH:mm:ss'));
            console.log(e);
            console.log(date_string);
            this.propagateChange(date_string);
        } else if (e) {
            const moment_object = moment(e, 'YYYY-MM-DD HH:mm:ss').clone();
            const date_string = moment_object.format('YYYY-MM-DD ') + this.time_HH_mm_ss;
            console.log(date_string);
            this.propagateChange(date_string);
        }
    }

    writeValue(obj: any): void {
        if (moment.isDate(obj)) {
            // const clone = moment(new Date(obj.getTime() - obj.getTimezoneOffset() * 60 * 1000)).locale('th').clone();
            const clone = moment(obj).locale('th').clone();
            this.the_model = clone.toDate();
            this.date_time_string = clone.format('DD/MM/YYYY');
            this.time_HH_mm_ss = clone.format('HH:mm:ss');
        } else if (moment.isMoment(obj)) {
            const clone = obj.locale('th').clone();
            this.the_model = clone.toDate();
            this.date_time_string = clone.format('DD/MM/YYYY');
            this.time_HH_mm_ss = clone.format('HH:mm:ss');
        } else if (obj) {
            const clone = moment(obj, 'YYYY-MM-DD HH:mm:ss', 'th').locale('th').clone();
            this.the_model = clone.toDate();
            this.date_time_string = clone.format('DD/MM/YYYY');
            this.time_HH_mm_ss = clone.format('HH:mm:ss');
        }
        if (!this.time_HH_mm_ss) {
            this.time_HH_mm_ss = '08:00:00';
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        //
    }

}
