import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {ProductModel} from '../../models/product.model';
import {TaskModel} from '../../models/task.model';
import {MaterialModel} from '../../models/material.model';
import {MaterialCreateModal} from '../../pages/task/view/product/materialCreate/materialCreate.modal';
import {ModalService} from '../../services/modal.service';
import {FormModel} from '../../now/formModel';
import {Api} from '../../now/api/api';
import {SwalService} from '../../services/swal.service';
import {ProductMaterial} from '../../models/productMaterial';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {RemarkModal} from '../../modals/remark/remark.modal';
import {ItemModel} from '../../models/item.model';
import {LoaderService} from '../loader/loader.service';

@Component({
    selector: 'material-table-component',
    templateUrl: 'materialTable.component.html',
    styleUrls: ['materialTable.component.scss']
})
export class MaterialTableComponent implements AfterViewInit, OnDestroy {

    @ViewChild('remarkModal', { static: true }) remarkModal: RemarkModal;

    @Input() public editable: boolean;
    @Input() public submit_text: string;
    @Input() job_id: string;
    @Input() task: TaskModel;
    @Input() viewable: boolean;

    @Input('product')
    set _product(val: ProductModel) {
        this.product = val;
        if (this.product && this.product.materials) {
            this.material_check();
            this.material_children();
        }
    }

    public product: ProductModel;
    public current_material: ProductMaterial;

    @Output('onMaterialInvalid') onMaterialInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output('onGotoPurchase') onGotoPurchase: EventEmitter<any> = new EventEmitter<any>();

    private onchange_timeout: any;

    constructor(
        public loader: LoaderService,
        public userService: UserService,
        private modal: ModalService,
        private ngZone: NgZone,
        private api: Api,
        private smartModalService: NgxSmartModalService,
        private swal: SwalService
    ) {
        //
        this.viewable = false;
    }

    ngAfterViewInit(): void {
        // this.pusherService.subscribe('erpst.store');
        // this.pusherService.bind('erpst.store', 'store.update', response => {
        //     this.update_store(response.data);
        // });

        setTimeout(() => {
            this.update();
        }, 500);
    }

    ngOnDestroy(): void {
        // this.pusherService.unsubscribe('erpst.store');
    }

    private update_store(data: any): void {
        if (data && this.product.materials && this.product.materials.length > 0) {
            for (let i = 0; i < this.product.materials.length; i++) {
                const material: any = this.product.materials[i];
                console.log(material.item.id);
                if (material && material.item && material.item.id && data[material.item.id] > 0) {
                    material.item.reserved_amount = data[material.item.id].reserved_amount;
                    material.item.amount = data[material.item.id].amount;
                    if (material.children && material.children.length) {
                        for (let j = 0; j < material.children.length; j++) {
                            const child_material: any = material.children[j];
                            if (child_material && child_material.item && child_material.item.id && data[child_material.item.id] > 0) {
                                child_material.item.reserved_amount = data[child_material.item.id].reserved_amount;
                                child_material.item.amount = data[child_material.item.id].amount;
                            }
                        }
                    }
                }
            }
        }
    }

    public update(): void {
        this.material_check();
        this.material_children();
    }

    public onInputChange(): void {
        this.onChangeTimeout();
    }

    private onChangeTimeout(): void {
        this.clearOnChangeTimeout();
        this.onchange_timeout = setTimeout(() => {
            this.material_check();
            this.onChange.emit();
            this.clearOnChangeTimeout();
        }, 1000);
    }

    private clearOnChangeTimeout(): void {
        if (this.onchange_timeout) {
            clearTimeout(this.onchange_timeout);
            this.onchange_timeout = null;
        }
    }

    public onMaterialStatusChange(e?: any, material?: ProductMaterial): void {
        if (e && parseInt(e) === 5 && material) {
            // material.cutting = new ProductMaterial();
            // material.cutting = new ItemModel();
            this.addMoreMaterial(material, 2);
        } else {
            for (let i = 0; i < material.children.length; i++) {
                const child: any = material.children[i];
                if (child && child.status === 2) {
                    material.children.splice(i, 1);
                    material.prepared_status = 0;
                    if (child.id) {
                        this.deleteEstimate(material.children, child, i, 'materials', true);
                    }
                    break;
                }
            }
        }
        this.onChangeTimeout();
    }

    public onMaterialMoreHandler(material: MaterialModel, e: any): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : false,
            editable        : false,
            // type            : 'frw',
            submit_text     : this.submit_text,
            item_id         : material.item.id,
            item_name       : material.item.search_value,
            material_model  : material.item,
            current_item    : material.item
        }, {
            class: 'modal-xl'
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e && e.event && e.event.select) {
                    e.event.select(data.item);
                    setTimeout(() => {
                        this.onChangeTimeout();
                    }, 500);
                } else {
                    //
                }
            }
        });
    }

    public onMaterialCreating(material: MaterialModel, e: any): void {
        setTimeout(() => { // add delay when model to select
            this.onClickCreateCuttingMaterial(material.item, e);
        }, 0);
    }

    public onMaterialTypeaheadSelect(material: MaterialModel, i: number, e?: any): void {
        if (e && e.id) {
            //
        } else {
            this.deleteEstimate(this.product.materials, material, i, 'materials', true);
        }
        material.pivot.price = (material.price) ? material.price : material.modelable.price;
        this.onChangeTimeout();
    }

    public showMaterialDetail(product_material: ProductMaterial): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : true,
            editable        : (this.editable === true) ? true : false,
            type            : 'frw',
            submit_text     : this.submit_text,
            item_id         : product_material.item.id,
            item_name       : product_material.item.search_value,
            material_model  : product_material.item,
            current_item    : product_material.item
        }, {
            //
        }).then((data: any): void => {
            //
        });
    }

    public addRemark(material: any): void {
        if (this.viewable) {
            //
        } else {
            this.current_material = material;
            this.remarkModal.open(this.current_material.remark);
        }
    }

    public onRemarkModalSubmit(e: any): void {
        if (this.current_material) {
            this.current_material.remark = e.remark_message;
            this.onChangeTimeout();
        }
        this.current_material = null;
    }

    public toggleEstimate(models: FormModel[], model: FormModel, index: number, model_name?: string): void {
        if (models && index > -1) {
            model.checked = !model.checked;
        } else {
            //
        }
        this.onChangeTimeout();
    }

    public purchase(material: any): void {
        let _: any;
        if (material && material.item && material.item.id) {
            _ = material.item;
        } else if (material) {
            _ = material;
        }
        this.swal.confirm(_.search_value + ' ไม่มีอยู่ในคลัง คุณต้องการให้แผนก STORE ทำการตรวจสอบรายการก่อนใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.onGotoPurchase.emit({});
                } else {
                    //
                }
            });
    }

    public onClickCreateCuttingMaterial(item: ItemModel, e: any): void {
        // create a item into store
        this.modal.show(MaterialCreateModal, {
            // editable        : (this.editable === true) ? true : false,
            warehouse       : false,
            editable        : true,
            viewable        : true,
            type            : 'frw',
            submit_text     : this.submit_text,
            item_name       : (item.search_value) ? item.search_value : item.name,
            current_item    : item,
            material_model  : item
        }, {
            class: 'modal-md'
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e && e.event && e.event.select) {
                    e.event.select(data.item);
                    setTimeout(() => {
                        this.onChangeTimeout();
                    }, 500);
                } else {
                    //
                }
            }
        });
    }

    public materialCheck(): any {
        return this.material_check();
    }

    private material_children(): void {
        for (const material of this.product.materials) {
            if (material && material.prepared_status === 5) {
                if (!material.children || !material.children.length) {
                    this.addMoreMaterial(material, 2);
                }
            }
        }
    }

    private material_check(): any {
        if (this.product.materials && this.product.materials.length > 0) {
            for (let i = 0; i < this.product.materials.length; i++) {
                const material: any = this.product.materials[i];
                // console.log(material.checked, material.dateTime, material.prepared_status, material.amount, material.cutting_status);
                if (material && material.item && material.item.id && material.checked && !material.dateTime
                    && (!material.prepared_status || parseInt(material.prepared_status) === 5)) {
                    //
                    if ((!material.amount || material.amount > material.item.amount) && !material.prepared_status/* && !material.cutting_status*/) {
                        this.onMaterialInvalid.emit(material);
                        return material;
                    }
                    if (material.children && material.children.length) {
                        for (let j = 0; j < material.children.length; j++) {
                            const child_material: any = material.children[j];
                            if (child_material && child_material.item && child_material.item.id && child_material.checked && !child_material.dateTime/* && !child_material.cutting_status*/) {
                                if (!child_material.amount || child_material.amount > child_material.item.amount) {
                                    this.onMaterialInvalid.emit(child_material);
                                    return child_material;
                                }
                            }
                        }
                    }
                } else if (material && material.item && material.item.id && !material.checked && !material.dateTime && parseInt(material.prepared_status) === 3) {
                    if (material.children && material.children.length) {
                        for (let j = 0; j < material.children.length; j++) {
                            const child_material: any = material.children[j];
                            // console.log(child_material.cutting_status);
                            if (child_material && child_material.item && child_material.item.id && child_material.checked && !child_material.dateTime/* && !child_material.cutting_status*/) {
                                if (!child_material.amount || child_material.amount > child_material.item.amount) {
                                    this.onMaterialInvalid.emit(child_material);
                                    return child_material;
                                }
                            }
                        }
                    }
                }
            }
        }
        this.onMaterialInvalid.emit(false);
        return false;
    }

    public deleteEstimate(models: FormModel[], model: FormModel, index: number, name: string, force?: boolean): void {
        if (models && model && index > -1) {
            if (force === true) {
                if (model.id) {
                    this.loader.show();
                    this.api.request('product/' + name + '/delete', 'POST', {}, {
                        id: model.id
                    }, null, null, null, null, true)
                        .subscribe((response: any): void => {
                            models.splice(index, 1);
                            model.is_delete = true;
                            this.material_check();
                            this.loader.hide();
                            // this.onChangeTimeout();
                        }, error => {
                            this.swal.danger(error);
                            this.loader.hide();
                        });
                } else {
                    models.splice(index, 1);
                    model.is_delete = true;
                    this.onChangeTimeout();
                }
            } else {
                this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            if (model.id) {
                                this.loader.show();
                                this.api.request('product/' + name + '/delete', 'POST', {}, {
                                    id: model.id
                                }, null, null, null, null, true)
                                    .subscribe((response: any): void => {
                                        models.splice(index, 1);
                                        model.is_delete = true;
                                        this.material_check();
                                        this.loader.hide();
                                        // this.onChangeTimeout();
                                    }, error => {
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                            } else {
                                models.splice(index, 1);
                                model.is_delete = true;
                                this.onChangeTimeout();
                            }
                        } else {
                            //
                        }
                    });
            }
        } else {
            //
        }
    }

    public addMoreMaterial(parent_material?: MaterialModel, status?: number): ProductMaterial {
        let product_material: ProductMaterial;
        product_material = new ProductMaterial();
        product_material.parent_id = (parent_material && parent_material.id) ? parent_material.id : '';
        product_material.current_role = (this.task.current_role) ? this.task.current_role : 'estimate';
        product_material.checked = true;
        product_material.product_id = this.product.id;
        product_material.job_id = this.job_id;
        product_material.status = status;
        if (parent_material) {
            if (!parent_material.children) {
                parent_material.children = [];
            }
            parent_material.children.push(product_material);
        } else {
            this.product.materials.push(product_material);
        }
        return product_material;
    }

}
