import {Component, OnDestroy, OnInit} from '@angular/core';
import {SkinService} from '../../../services/skin.service';
import {SwalService} from '../../../services/swal.service';

@Component({
    selector: 'app-setting-page',
    templateUrl: 'setting.page.html',
    styleUrls: ['setting.page.scss']
})
export class SettingPage implements OnInit, OnDestroy {

    public isLoading: boolean;
    public ready: boolean;
    public message: string;
    public bg: string;

    constructor(
        private swal: SwalService,
        private skinService: SkinService,
    ) {
    }

    ngOnInit() {
        this.ready = false;
        this.skinService.getSkin()
            .then((data: any) => {
                if (data && data.skin) {
                    this.bg = (data.skin.bg) ? data.skin.bg : '';
                    this.message = (data.skin.message) ? data.skin.message : '';
                }
                this.ready = true;
            });
    }

    ngOnDestroy() {
    }

    public onSubmit(form) {
        //
    }

    public save() {
        if (this.isLoading === true) {
            return;
        }
        this.isLoading = true;
        this.skinService.updateSkin({
            bg: (this.bg) ? this.bg : '',
            message: (this.message) ? this.message.trim() : '',
            image: '',
        }).then((response: any) => {
            if (response) {
                this.swal.success('บันทึกข้อมูลสำเร็จ');
            } else {
                this.swal.danger('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
            }
            this.isLoading = false;
        });
    }

    public removeBg() {
        this.swal.confirm('คุณต้องการลบภาพพื้นฐาน ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.bg = '';
                }
            });
    }

    public onUploadedSuccess(event) {
        if (event && event.path) {
            this.bg = event.path;
        } else {
            //
        }
    }

    public onUploadError(event) {
        console.log(event);
    }

}
