import {AfterViewInit, Directive, EventEmitter, Input, OnDestroy, Output, Self} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UserService} from '../services/user.service';
import {TaskModel} from '../models/task.model';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs/internal/Subscription';

const DEFAULT_DELAY = 1000;

@Directive({
    selector: '[autoSave]',
    exportAs: 'autoSaveDirective'
})
export class AutoSaveDirective implements AfterViewInit, OnDestroy {

    @Output('save-api') saveApiCallback: EventEmitter<any> = new EventEmitter<any>();

    @Input() skipTask: boolean;
    @Input() delay: number;
    @Input() task: TaskModel;
    @Input() ready: boolean;

    private ngForm: NgForm;
    public rev: number;

    private subscriptions: Subscription[] = [];

    constructor(private userService: UserService, @Self() ngForm: NgForm) {
        this.ngForm = ngForm;
    }

    ngAfterViewInit(): void {
        this.rev = 0;
        this.subscriptions.push(
            this.ngForm.valueChanges
                .pipe(debounceTime(DEFAULT_DELAY))
                .subscribe(_ => this.onValueChange(_))
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(i => i.unsubscribe());
    }

    public onValueChange(data: any): void {
        console.log('onValueChange', this.ready);
        if (this.ready === true) {
            if (this.canUpdate()) {
                this.rev++;
                console.log('saveApiCallback.emit()');
                this.saveApiCallback.emit({
                    data: data,
                    rev: this.rev
                });
            }
        }
    }

    public canUpdate(): boolean {
        console.log('this.skipTask', this.skipTask);
        console.log('this.task.id', this.task.id);
        console.log('this.userService.getID()', this.userService.getID());
        console.log('this.task.current_user_id', this.task.current_user_id);
        return this.skipTask === true ||
            (
                this.task && this.task.id && this.task.current_user_id &&
                this.task.current_user_id === this.userService.getID()
            );
    }

}
