import {Model} from '../now/model';
import {MachineModel} from './machine.model';
import {UserModel} from '../now/user/user.model';
import {SupplierModel} from './supplier.model';

export class FMTModel extends Model {

    public machine_status: boolean;
    public checked_at: string;
    public requested_at: string;
    public approved_at: string;
    public remarks: any[];
    public supplier_id: any;
    public documents: any[];
    public document_no: string;
    public rejected_at: string;
    public fixed_at: string;
    public fixed_description: string;
    public fixed_summary: any;
    public fixed_summary_at: string;
    public machine_id: any;
    public machine_type: string;
    public equipments: any[];
    public informed_date: string;
    public warranty: string;
    public user_informer: UserModel;
    public user_informer_id: any;
    public user_informant: UserModel;
    public user_checked_id: any;
    public user_checked: UserModel;
    public checked_date: string;
    public issued_date: string;
    public place_name: string;
    public issued_time: string;
    public supplier: SupplierModel;
    public machine: MachineModel;
    public response_date: string;
    public estimate_description: string;
    public type: string;
    public other: string;
    public before_description: string;
    public before_reason: string;
    public user_request: any;
    public can_repaired: any;
    public estimated_repaired_at: any;
    public request_date: any;
    public user_approved: any;
    public approved_date: any;
    public after_description: string;
    public finished_date: any;
    public finished_time: any;
    public gap_time: number;
    public summary_results: any;
    public user: UserModel;
    public sumamry_results: any;
    public status: number;

    constructor() {
        super('fmts', 'App\\Fmt');
        //
        this.equipments = [];
        this.status = 1;
        this.machine = new MachineModel();
        this.user_informant = new UserModel();
        this.user_informer = new UserModel();
        this.supplier = new SupplierModel();
    }

}
