import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {StoreModel} from '../../../../models/store.model';
import {RequisitionModel} from '../../../../models/requisition.model';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {TaskService} from '../../../../services/task.service';
import {AuthService} from '../../../../now/user/auth.service';
import {ModalService} from '../../../../services/modal.service';
import {TaskModel} from '../../../../models/task.model';
import {ViewTask} from '../../view/viewTask';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../../now/user/user.model';
import {RemarkModal} from '../../../../modals/remark/remark.modal';
import {Viewer} from '../../../../services/viewer';
import {JobModel} from '../../../../models/job.model';
import {Remark} from '../../../../app/api/remark';

@Component({
    selector: 'requisition-store-component',
    templateUrl: 'requisitionStore.component.html',
    styleUrls: ['requisitionStore.component.scss']
})
export class RequisitionStoreComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild('remarkModal', { static: true }) remarkModal: RemarkModal;
    @ViewChild('prepareRemarkModal', { static: true }) prepareRemarkModal: RemarkModal;

    public store: StoreModel;
    public requisition: RequisitionModel;
    public remarks: Remark[];

    constructor(
        public viewer: Viewer,
        public location: Location,
        public api: Api,
        public modelApi: ModelApi,
        public swal: SwalService,
        public taskService: TaskService,
        private route: ActivatedRoute,
        public authService: AuthService,
        public modal: ModalService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.remarks = [];
        this.task = new TaskModel();
        this.requisition = new RequisitionModel();
    }

    ngAfterViewInit(): void {
        this.route.params
            .subscribe(params => {
                this.viewTaskInit();
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            this.onSuccess(this.task);
                        });
                }
            });
    }

    ngOnDestroy() {
        //
    }

    public accept(): void {
        for (let i = 0; i < this.requisition.materials.length; i++) {
            const material: any = this.requisition.materials[i];
            if (material && material.id && material.pivot.status !== 2) {
                this.swal.danger('กรุณาเตรียมการวัสดุให้สำเร็จก่อนส่งดำเนินการต่อไป');
                return;
            } else if (material && material.id && !material.pivot.return_action) {
                this.swal.danger('กรุณาระบุการคืนวัตถุดิบ/วัสดุ');
                return;
            }
        }
        this.prepareRemarkModal.open();
    }

    public reject(): void {
        this.swal.confirm('ยืนยันปฏิเสธการร้องขอเบิกวัตถุดิบ/วัสดุเลขที่ "' + this.requisition.document_no + '" ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.remarkModal.open();
                }
            });
    }

    private get_items(): any {
        let ids: any[];
        ids = [];
        for (const material of this.requisition.materials) {
            if (material && material.id) {
                ids.push({
                    id: material.id,
                    return_action: material.pivot.return_action,
                    status: material.pivot.status,
                });
            }
        }
        return ids;
    }

    public onPrepareRemarkModalSubmit(e: any): void {
        this.api.request('requisitions/prepare', 'POST', {}, {
            id: this.requisition.id,
            task_id: this.task_id,
            remark: e.remark_message,
            items: this.get_items()
        }).subscribe((response: any): void => {
            if (response && response.success === true) {
                this.taskService.setStatus(
                    this.task,
                    this.task.status,
                    'prepared_request_requisition',
                    'prepared-requisition',
                    'prepared-requisition',
                    false,
                    this.requisition.man.id,
                    e.remark_message
                ).then(() => {
                    this.swal.success('ดำเนินการสำเร็จ');
                    this.leave(true);
                });
            }
        });
    }

    public onRemarkModalSubmit(e: any): void {
        if ((this.requisition.man && this.requisition.man.id) || (this.requisition.request_man && this.requisition.request_man.id) || (this.requisition.job && this.requisition.job.id)) {
            this.api.request('requisitions/reject', 'POST', {}, {
                id: this.requisition.id,
                remark: e.remark_message,
                task_id: this.task_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    const user_id = (this.requisition.man && this.requisition.man.id) ? this.requisition.man.id : (((this.requisition.man && this.requisition.man.id)) ? this.requisition.man.id : this.task.user_id);
                    this.taskService.setStatus(
                        this.task,
                        this.task.status,
                        'reject_request_requisition',
                        'request-requisition',
                        'request-requisition',
                        false,
                        user_id,
                        e.remark_message
                    ).then(() => {
                        this.swal.success('ปฏิเสธสำเร็จ');
                        this.leave(true);
                    });
                }
            });
        } else {
            this.swal.danger('เกิดข้อผิดพลาด โปรดติดต่อผู้ดูแลระบบ');
        }
    }

    public onSuccess(task: TaskModel): void {
        this.requisition.id = task.modelable_id;
        this.getRequisition()
            .then(requisition => {
                this.requisition = requisition;
                this.remarks = this.requisition.remarks;
                if (!this.remarks) {
                    this.remarks = [];
                }
            });
    }

    public getRequisition(): Promise<RequisitionModel> {
        let promise: Promise<RequisitionModel>;
        promise = new Promise<RequisitionModel>((resolve, reject) => {
            this.api.request('requisitions/' + this.requisition.id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    let requisition: RequisitionModel;
                    requisition = new RequisitionModel();
                    requisition.clone(response.data);
                    if (response.data.man && response.data.man.id) {
                        let man: UserModel;
                        man = new UserModel();
                        man.clone(response.data.man);
                        requisition.man = man;
                    } else {
                        requisition.man = new UserModel();
                    }
                    if (response.data.job && response.data.job.id) {
                        const job = new JobModel();
                        job.clone(response.data.job);
                        requisition.job = job;
                    } else {
                        requisition.job = new JobModel();
                    }
                    resolve(requisition);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

}
