import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import * as moment from 'moment';
import {DeliveryNoteModel} from '../../../../models/deliveryNote.model';
import {CustomerModel} from '../../../../models/customer.model';
import {JobModel} from '../../../../models/job.model';
import {ProductModel} from '../../../../models/product.model';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'app-delivery-note-detail-component',
    templateUrl: 'deliveryNoteDetail.component.html',
    styleUrls: ['deliveryNoteDetail.component.scss']
})
export class DeliveryNoteDetailComponent implements OnInit {

    public deliveryNote: DeliveryNoteModel;
    public tmp_deliveryNote: DeliveryNoteModel;
    public credit_terms: number;
    public percent: number;
    public task: TaskModel;
    public products: ProductModel[];
    public customer: CustomerModel;
    public job: JobModel;
    public model: any;
    public modelable_type: string;
    public modelable_id: string;
    public is_new: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService,
        public viewer: Viewer,
    ) {
        //
        this.deliveryNote = new DeliveryNoteModel();
        this.tmp_deliveryNote = new DeliveryNoteModel();
        this.products = [];
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.model) {
                this.model = this.bsModalRef.content.model;
                if (this.model && this.model.jobs) {
                    for (const job of this.model.jobs) {
                        if (job && job.id) {
                            job._checked = true;
                        }
                    }
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.products) {
                this.products = this.bsModalRef.content.products;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.modelable_type) {
                this.modelable_type = this.bsModalRef.content.modelable_type;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.modelable_id) {
                this.modelable_id = this.bsModalRef.content.modelable_id;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.customer) {
                this.customer = this.bsModalRef.content.customer;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.deliveryNote) {
                // if (!this.bsModalRef.content.deliveryNote.started_at) {
                //     this.bsModalRef.content.deliveryNote.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                // }
                this.deliveryNote = this.bsModalRef.content.deliveryNote;
                this.tmp_deliveryNote.id = this.deliveryNote.id;
                this.tmp_deliveryNote.started_at = this.deliveryNote.started_at;
                this.tmp_deliveryNote.delivery_date = this.deliveryNote.delivery_date;
                // this.tmp_deliveryNote.ended_at = this.deliveryNote.ended_at;
                this.tmp_deliveryNote.delivery_note_no = this.deliveryNote.delivery_note_no;
                this.tmp_deliveryNote.required_at = this.deliveryNote.required_at;
                this.tmp_deliveryNote.due_date = this.deliveryNote.due_date;
                this.tmp_deliveryNote.balance = this.deliveryNote.balance;
                this.tmp_deliveryNote.remark = this.deliveryNote.remark;
                this.tmp_deliveryNote.status = (this.deliveryNote.status) ? this.deliveryNote.status : 2;

                /*if (!this.tmp_deliveryNote.ended_at && this.tmp_deliveryNote.started_at && this.credit_terms > 0) {
                    this.tmp_deliveryNote.ended_at = moment(this.tmp_deliveryNote.started_at, 'YYYY-MM-DD HH:mm:ss')
                        .add(this.credit_terms, 'days')
                        .format('YYYY-MM-DD HH:mm:ss');
                }*/

                if (this.customer && this.customer.credit_terms > 0) {
                    this.credit_terms = this.customer.credit_terms;
                } else {
                    this.credit_terms = 0;
                }

                if (!this.tmp_deliveryNote.started_at) {
                    this.tmp_deliveryNote.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                if (!this.tmp_deliveryNote.delivery_date) {
                    this.tmp_deliveryNote.delivery_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                if (!this.tmp_deliveryNote.due_date && this.credit_terms > 0) {
                    const delivery_date = moment(this.tmp_deliveryNote.delivery_date, 'YYYY-MM-DD HH:mm:ss').add(this.credit_terms, 'day');
                    this.tmp_deliveryNote.due_date = delivery_date.format('YYYY-MM-DD HH:mm:ss');
                }

                if (!this.tmp_deliveryNote.due_date) {
                    const dueDate = this.calculateDueDate();
                    this.tmp_deliveryNote.delivery_date = dueDate;
                }

                this.is_new = this.deliveryNote.isNew;
            }
        }, 0);
    }

    private calculateDueDate() {
        if (this.customer.due_day) {
            const dueDay = this.customer.due_day;
            return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        } else {
            return null;
        }
    }

    public onStartedChanged(e: any): void {
        /*if (e) {
            const started_moment_object: any = moment(e, 'YYYY-MM-DD HH:mm:ss');
            const ended_moment_object: any = moment(this.tmp_deliveryNote.ended_at, 'YYYY-MM-DD HH:mm:ss');
            this.credit_terms = moment.duration(ended_moment_object.diff(started_moment_object)).asDays();
        }*/
    }

    public onDeliveryChange(e: any): void {
        setTimeout(() => {
            if (this.credit_terms > 0) {
                const delivery_date = moment(this.tmp_deliveryNote.delivery_date, 'YYYY-MM-DD HH:mm:ss').add(this.credit_terms, 'day');
                this.tmp_deliveryNote.due_date = delivery_date.format('YYYY-MM-DD HH:mm:ss');
            }
        });
    }

    public onDueDateChange(e: any): void {
        //
    }

    public delete(): void {
        if (this.deliveryNote.status === 1) {
            return;
        }
        const name: string = (this.tmp_deliveryNote.delivery_note_no)
            ? this.tmp_deliveryNote.delivery_note_no : 'ใบวางเงินมัดจำ';
        this.swal.confirm('คุณต้องการลบเอกสารใบสั่งซื้อ "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.modelApi.delete(this.tmp_deliveryNote)
                        .subscribe((response: any): void => {
                            const index: number = this.model.delivery_notes.findIndex(i => i.id === this.tmp_deliveryNote.id);
                            if (index > -1) {
                                this.model.delivery_notes.splice(index, 1);
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public viewDeliveryNote(): void {
        this.viewer.deliveryNote(this.tmp_deliveryNote);
    }

    private get_jobs(): any {
        let job_ids: any;
        job_ids = {};
        if (this.model && this.model.jobs) {
            for (const job of this.model.jobs) {
                if (job && job._checked) {
                    job_ids[job.id] = {
                        amount: job.pivot.amount,
                        price_per_unit: job.pivot.price_per_unit
                    };
                }
            }
        }
        return job_ids;
    }

    public update(): void {
        if (this.tmp_deliveryNote && !this.tmp_deliveryNote.id) {
            this.swal.confirm('ยืนยันการออกใบส่งสินค้าใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('delivery/notes', 'PUT', {}, {
                            customer_id: (this.customer) ? this.customer.id : this.model.customer_id,
                            model_id: this.model.id,
                            due_date: this.tmp_deliveryNote.due_date,
                            balance: this.tmp_deliveryNote.balance,
                            bill_id: this.model.bill_id,
                            customer_address_id: this.model.customer_address_id,
                            contact_id: this.model.contact_id,
                            incoterm_code: this.model.incoterm_code,
                            status: this.tmp_deliveryNote.status,
                            started_at: this.tmp_deliveryNote.started_at,
                            delivery_date: this.tmp_deliveryNote.delivery_date,
                            total_price: this.tmp_deliveryNote.total_price,
                            remark: this.tmp_deliveryNote.remark,
                            jobs: this.get_jobs()
                        }).subscribe((response: any): void => {
                            this.swal.success('ออกใบวางเงินมัดจำสำเร็จ');
                            this.bsModalRef.content.deliveryNote = response.data;
                            this.bsModalRef.content.is_create = true;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        } else {
            this.swal.confirm('ยืนยันการแก้ไขรายละเอียดเอกสาร?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('delivery/notes/status', 'POST', {}, {
                            status: this.tmp_deliveryNote.status,
                            id: this.tmp_deliveryNote.id
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.bsModalRef.content.deliveryNote.status = this.tmp_deliveryNote.status;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        }
    }

    private get_product_ids(): any[] {
        let product_ids: any[];
        product_ids = [];
        for (let i = 0; i < this.products.length; i++) {
            product_ids.push(this.products[i].id);
        }
        return product_ids;
    }

    public onSubmit(form: NgForm): void {
        /*this.save()
            .then(() => {
                this.swal.success('บันทักข้อมูลใบวางเงินมัดจำสำเร็จ');
                this.bsModalRef.content.is_submit = this.is_new;
                this.bsModalRef.hide();
            });*/
    }

}
