import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'itemType'
})
export class ItemTypePipe implements PipeTransform {

    transform(type: string): string {
        const _type: string = (type) ? type.toLowerCase() : type;
        if (_type === 'atl') {
            return 'วัสดุอุปกรณ์โรงงาน';
        } else if (_type === 'frw') {
            return 'วัตถุดิบ';
        } else if (_type === 'sn') {
            return 'สินค้าสำเร็จรูป';
        } else if (_type === 'fes') {
            return 'อุปกรณ์สิ้นเปลืองโรงงาน';
        } else if (_type === 'fct') {
            return 'อุปกรณ์เครื่องมือตัด';
        } else if (_type === 'fas') {
            return 'วัสดุประกอบ';
        } else if (_type === 'ost') {
            return 'ค่าอุปกรณ์ เครื่องเขียน แบบพิมพ์';
        } else if (_type === 'other') {
            return 'อุปกรณ์อื่นๆ';
        }
        return type;
    }

}
