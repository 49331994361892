import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserModel} from '../../../now/user/user.model';
import {Api} from '../../../now/api/api';
import {AuthService} from '../../../now/user/auth.service';
import * as moment from 'moment';

@Component({
    selector: 'leave-histories-page',
    templateUrl: 'leaveHistories.page.html',
    styleUrls: ['leaveHistories.page.scss']
})
export class LeaveHistoriesPage implements OnInit, OnDestroy {

    public user: UserModel;
    public user_id: string;

    public currentDate;
    public currentYear;
    public leaveHistories = [];
    public today: string;
    public absence_num: number;
    public leave_num: number;
    public late_num: number;

    public activities;
    public sum_vacation = 0;
    public sum_personal = 0;
    public sum_sick = 0;
    public sum_maternity = 0;
    public sum_force = 0;
    public sum_other = 0;

    constructor(
        private auth: AuthService,
        private api: Api,
    ) {
        this.user = new UserModel();
        this.currentDate = moment(new Date());
        this.currentYear = this.currentDate.format('YYYY');
    }

    ngOnInit() {
        const user = this.auth.user;
        console.log(user);
        this.getLeaveHistories(user);
    }

    ngOnDestroy() {
    }

    public getLeaveHistories(user): Promise<any> {
        const promise = new Promise<any>(resolve => {
            const userId = user.id;
            this.api.request('users/' + userId + '/leaves', 'GET')
                .subscribe((res: any): void => {
                    this.leaveHistories = [];
                    if (res && res.data) {
                        for (const dat of res.data) {
                            this.leaveHistories.push(dat);
                        }
                    }
                    if (this.leaveHistories && this.leaveHistories.length) {
                        for (const activity of this.leaveHistories) {
                            if (activity && !activity.canceled_at) {
                                const subject = activity.subject;
                                if (subject === 'VACATION') {
                                    this.sum_vacation += (activity.days) ? activity.days : 0;
                                } else if (subject === 'PERSONAL') {
                                    this.sum_personal += (activity.days) ? activity.days : 0;
                                } else if (subject === 'SICK') {
                                    this.sum_sick += (activity.days) ? activity.days : 0;
                                } else if (subject === 'MATERNITY') {
                                    this.sum_maternity += (activity.days) ? activity.days : 0;
                                } else if (subject === 'FORCE') {
                                    this.sum_force += (activity.days) ? activity.days : 0;
                                } else if (subject === 'OTHER') {
                                    this.sum_other += (activity.days) ? activity.days : 0;
                                }
                            }
                        }
                    }
                    console.log(this.leaveHistories);
                    resolve([]);
                });
        });
        return promise;
    }

    public getUserProfile(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.user_id) {
                this.api.request('users/' + this.user_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.onSuccess(response.data);
                        resolve(response.data);
                    }, error => {
                        this.onError(error);
                        reject(error);
                    });
            } else {
                resolve(this.user);
            }
        });
        return promise;
    }

    public onSuccess(data: any): void {
        if (data) {
            this.user.clone(data);
            if (this.user.activities && this.user.activities.absence && this.user.activities.absence.value > 0) {
                this.absence_num = this.user.activities.absence.value;
            }
            if (this.user.activities && this.user.activities.late && this.user.activities.late.value > 0) {
                this.late_num = this.user.activities.late.value;
            }
            if (this.user.activities && this.user.activities.leave && this.user.activities.leave.value > 0) {
                this.leave_num = this.user.activities.leave.value;
            }
        }
    }

    public onError(e: any): void {
        //
    }

}
