import {Model} from '../now/model';
import {CustomerAddressModel} from './customerAddress.model';
import {QuotationModel} from './quotation.model';
import {ContactModel} from './contact.model';
import {BillModel} from './bill.model';

export class CustomerModel extends Model {

    public name: string;
    public auto_mail: boolean;
    public avatar: string;
    public type: number;
    public type_note: string;
    public ranking: number;
    public tax_no: string;
    public branch: string;
    public due_day: string;
    public telephone: string;
    public telephone2: string;
    public fax: string;
    public email: string;
    public email2: string;
    public website: string;
    public address: string;
    public city: string;
    public state: string;
    public country: string;
    public postal_code: string;
    public shipping_day: any;

    public bills: BillModel[];

    public credit_terms: number;

    public num_contacts: number;

    public contacts: ContactModel[];
    public addresses: CustomerAddressModel[];
    public documents: any[];

    public quotations: QuotationModel[];

    constructor() {
        super('customers');
        this.addresses = [];
        this.documents = [];
        this.quotations = [];
        this.contacts = [];
        this.bills = [];
        this.auto_mail = false;
        this.shipping_day = {
            mon: null,
            tue: null,
            wed: null,
            thu: null,
            fri: null,
            sat: null,
            sun: null
        };
    }

    public clone(data: any): Model {
        if (data) {
            if (data.addresses) {
                if (!this.addresses) {
                    this.addresses = [];
                } else {
                    this.addresses.splice(0, this.addresses.length);
                }
                for (let i = 0; i < data.addresses.length; i++) {
                    const dat: any = data.addresses[i];
                    if (dat && dat.id) {
                        let customer_address: CustomerAddressModel;
                        customer_address = new CustomerAddressModel();
                        customer_address.clone(dat);
                        this.addresses.push(customer_address);
                    }
                }
                delete data.addresses;
            }
            if (data.bills) {
                if (!this.bills) {
                    this.bills = [];
                } else {
                    this.bills.splice(0, this.bills.length);
                }
                for (let i = 0; i < data.bills.length; i++) {
                    const dat: any = data.bills[i];
                    if (dat && dat.id) {
                        let bill: BillModel;
                        bill = new BillModel();
                        bill.clone(dat);
                        this.bills.push(bill);
                    }
                }
                delete data.bills;
            }
            if (data.contacts) {
                if (!this.contacts) {
                    this.contacts = [];
                } else {
                    this.contacts.splice(0, this.contacts.length);
                }
                for (let i = 0; i < data.contacts.length; i++) {
                    const dat: any = data.contacts[i];
                    if (dat && dat.id) {
                        let contact: ContactModel;
                        contact = new ContactModel();
                        contact.clone(dat);
                        this.contacts.push(contact);
                    }
                }
                delete data.contacts;
            }
            if (data.shipping_day) {
                this.shipping_day.mon = data.shipping_day.mon;
                this.shipping_day.tue = data.shipping_day.tue;
                this.shipping_day.wed = data.shipping_day.wed;
                this.shipping_day.thu = data.shipping_day.thu;
                this.shipping_day.fri = data.shipping_day.fri;
                this.shipping_day.sat = data.shipping_day.sat;
                this.shipping_day.sun = data.shipping_day.sun;
            } else {
                //
            }
            delete data.shipping_day;
        }
        super.clone(data);
        return this;
    }

    public get full_address(): string {
        let address: string;
        let city: string;
        let state: string;
        let postal_code: string;
        let country: string;

        address     = (this.address) ? this.address : '';
        city        = (this.city) ? this.city : '';
        state       = (this.state) ? this.state : '';
        postal_code = (this.postal_code) ? this.postal_code : '';
        country     = (this.country) ? this.country : '';

        return address + ' ' + city + ' ' + state + ' ' + postal_code + ' ' + country;
    }

}
