import {AfterViewInit, Component, Inject, NgZone, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {InvoiceModel} from '../../../models/invoice.model';
import {ProductModel} from '../../../models/product.model';
import {TaskModel} from '../../../models/task.model';
import {UserService} from '../../../services/user.service';
import {CustomerModel} from '../../../models/customer.model';
import {CustomerAddressModel} from '../../../models/customerAddress.model';
import {Api} from '../../../now/api/api';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {environment} from '../../../environments/environment';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {IncotermService} from '../../../services/incoterm.service';
import {CarrierService} from '../../../services/carrier.service';
import * as moment from 'moment';
import {TaskService} from '../../../services/task.service';
import {PurchaseOrderDetailComponent} from '../view/purchaseOrderDetail/purchaseOrderDetail.component';
import {ModalService} from '../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../now/user/auth.service';
import {ViewTask} from '../view/viewTask';
import {DocumentComponent} from '../../../modals/document/document.component';
import {DrawingModel} from '../../../models/drawing.model';
import {PaymentModel} from '../../../models/payment.model';
import {TypeaheadComponent} from '../../../components/typeahead/typeahead.component';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {ReportModalComponent} from '../../../modals/reportModal/reportModal.component';
import {DepositModel} from '../../../models/deposit.model';
import {DepositDetailComponent} from '../view/depositDetail/depositDetail.component';
import {ContactModel} from '../../../models/contact.model';
import {BillModel} from '../../../models/bill.model';
import {AddRemarkComponent} from '../view/addRemark/addRemark.component';
import {DocumentModel} from '../../../models/document.model';
import {LoaderService} from '../../../components/loader/loader.service';
import {JobService} from '../../../services/job.service';
import {Viewer} from '../../../services/viewer';
import {JobModel} from '../../../models/job.model';

const MAX_PRODUCT = 10;
const DEFAULT_PRICE_VALIDITY = 30;

@Component({
    selector: 'invoice-task-component',
    templateUrl: 'invoiceTask.component.html',
    styleUrls: ['invoiceTask.component.scss'],
    providers: [DecimalPipe]
})
export class InvoiceTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChildren(TypeaheadComponent) typeaheadComponents: QueryList<TypeaheadComponent>;

    public invoice: InvoiceModel;
    public invoice_id: string;
    public sale: UserModel;
    public customerAddress: CustomerAddressModel;
    public tmp_customer: CustomerModel;
    public customer: CustomerModel;
    public tmp_product: any;
    public tmp_customer_address: any;
    public tmp_contact: any;
    public tmp_sale: any;
    public current_tab: string;
    public incoterms: string[];
    public carriers: CarrierModel[];
    public tmp_incoterm: any;
    public tmp_carrier: any;
    public tmp_currency: any;
    public currency_list: any[];
    public tmp_contact_ref: any;
    public new_tasks: TaskModel[];
    public products: ProductModel[];

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private loader: LoaderService,
        private router: Router,
        private swal: SwalService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private ngZone: NgZone,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        public location: Location,
        private route: ActivatedRoute,
        private jobService: JobService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.invoice = new InvoiceModel();
        this.current_tab = '#information';
        this.tmp_customer = new CustomerModel();
        // this.customer = new CustomerModel();
        this.sale = new UserModel();
        this.customerAddress = new CustomerAddressModel();
        this.tmp_product = {};
        this.tmp_customer_address = {};
        this.tmp_sale = new UserModel();
        this.incoterms = [];
        this.carriers = [];
        this.tmp_incoterm = {};
        this.tmp_carrier = {};
        this.tmp_currency = {};
        this.tmp_contact_ref = {};
        this.products = [];

        this.new_tasks = [];

        this.getNewTasks()
            .then(() => {});
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.invoice_id = params['id'];
                    this.viewTaskInit();
                    this.getInvoice()
                        .then(() => {
                            if (!this.invoice.sale_id) {
                                const user = this.authService.user;
                                this.invoice.sale_id = user.id;
                                this.invoice.sale = new UserModel();
                                this.invoice.sale.clone(user);
                            }
                            this.customerAddress = this.invoice.customer_address;
                            for (let i = 0; i < 10; i++) {
                                this.addJob();
                            }

                            this.tmp_currency = {
                                id: this.invoice.currency,
                            };
                        });
                });

            this.getIncoterms();
            this.getCarriers();
            this.getCurrencies();
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public createDeposit(): void {
        const deposit: DepositModel = new DepositModel();
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            invoice   : this.invoice,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    // this.invoice.deposits.push(deposit);
                } else {
                    //
                }
            });
    }

    public onDiscountPercentChange(e?: any): void {
        setTimeout(() => {
            // this.invoice.discount_price = Math.round(this.invoice.material_price * (this.invoice.discount_percent / 100) * 100) / 100;
            this.invoice.cal_total_job_price();
        }, 0);
    }

    public onDiscountPriceChange(e?: any): void {
        setTimeout(() => {
            // this.invoice.discount_percent = Math.round(this.invoice.discount_price * (100 / this.invoice.material_price) * 100) / 100;
            this.invoice.cal_total_job_price();
        }, 0);
    }

    public getInvoice(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.invoice.products) {
                this.invoice.products.splice(0, this.invoice.products.length);
            } else {
                this.invoice.products = [];
            }
            if (this.invoice.jobs) {
                this.invoice.jobs.splice(0, this.invoice.jobs.length);
            } else {
                this.invoice.jobs = [];
            }
            if (this.invoice_id) {
                this.api.request('invoices/' + this.invoice_id, 'GET')
                    .subscribe((response: any): void => {
                        if (response.data) {
                            this.invoice.clone(response.data);

                            const customer = new CustomerModel();
                            customer.clone(response.data.customer);
                            this.invoice.customer = customer;

                            const contact = new ContactModel();
                            contact.clone(response.data.contact);
                            this.invoice.contact = contact;

                            const bill = new BillModel();
                            bill.clone(response.data.bill);
                            this.invoice.bill = bill;

                            const customer_address = new CustomerAddressModel();
                            customer_address.clone(response.data.customer_address);
                            this.invoice.customer_address = customer_address;

                            const carrier = new CarrierModel();
                            carrier.clone(response.data.carrier);
                            this.invoice.carrier = carrier;
                        }
                        for (let i = 0; i < Math.max(MAX_PRODUCT, this.invoice.jobs.length); i++) {
                            let job: JobModel;
                            if (this.invoice.jobs[i] && this.invoice.jobs[i].id) {
                                job = this.invoice.jobs[i];
                                job.index = i;
                                // product.price_per_unit = (product.pivot.price_per_unit)
                                //     ? product.pivot.price_per_unit : product.price / Math.min(product.customer_product_amount, product.amount);
                                // product.processing_times = (product.pivot.processing_times)
                                //     ? product.pivot.processing_times : product.processing_times;
                            } else {
                                job = new JobModel();
                                job.index = i;
                                this.invoice.jobs.push(job);
                            }
                            // product.cal_price();
                        }
                        if (!this.invoice.sale || !this.invoice.sale.id) {
                            this.invoice.sale = new UserModel();
                            this.invoice.sale.clone(this.authService.user);
                            this.invoice.sale_id = this.authService.user.id;
                        }
                        if (this.invoice && !this.invoice.shipping) {
                            this.invoice.shipping = {};
                        }
                        this.invoice.cal_total_job_price();
                        resolve(this.invoice);
                    }, error => {
                        reject(error);
                    });
            } else {
                this.invoice = new InvoiceModel();
                this.invoice.currency = 'THB';
                this.invoice.currency_rate = 1;
                for (let i = 0; i < MAX_PRODUCT; i++) {
                    let product: ProductModel;
                    product = new ProductModel();
                    product.index = i;
                    product.pivot.wh = 'TH';
                    this.invoice.products.push(product);
                }
                this.invoice.price_validity = DEFAULT_PRICE_VALIDITY;
                resolve(this.invoice);
            }
        });
        return promise;
    }

    public getNewTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks', 'GET', {
                process_slug: 'estimate',
                status: 9
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat = response.data[i];
                        if (dat) {
                            let task: TaskModel;
                            task = new TaskModel();
                            task.clone(dat);
                            this.new_tasks.push(task);
                        }
                    }
                }
                resolve(this.new_tasks);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onCustomerBillChange(e: any): void {
        this.invoice.bill_id = (e && e.id) ? e.id : null;
    }

    private getCurrencies(): void {
        this.api.request('assets/json/currencies.json', 'GET', null, null, null, environment.host, '')
            .subscribe((response: any): void => {
                this.currency_list = [];
                if (response && response.results) {
                    for (const key in response.results) {
                        if (key) {
                            const value: any = response.results[key];
                            this.currency_list.push(value);
                        }
                    }
                }
            }, error => {
                //
            });
    }

    private getCurrencyRate(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const current_currency_code: string = this.invoice.currency;
            this.api.request('currency/covert', 'GET', {
                from: 'THB',
                to: current_currency_code
            }).subscribe((response: any): void => {
                if (response && response.data && response.data['THB_' + current_currency_code]) {
                    resolve(response.data['THB_' + current_currency_code].val);
                }
            }, error => {
                reject(error);
            });
            /*this.api.request('convert?q=THB_' + current_currency_code + '&compact=y',
            'GET', null, null, null, 'http://free.currencyconverterapi.com/', 'api/v5/')
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });*/
        });
        return promise;
    }

    private getIncoterms(): void {
        this.incotermService.getIncoterms()
            .then((incoterms: IncotermModel[]): void => {
                this.incoterms = [];
                for (const incoterm of incoterms) {
                    this.incoterms.push(incoterm.name);
                }
            });
    }

    private getCarriers(): void {
        this.carrierService.getCarriers()
            .then((carriers: CarrierModel[]): void => {
                this.carriers = carriers;
            });
    }

    public onProductDelete(product: ProductModel, e?: any): void {
        if (product) {
            product.name = '';
            product.market_price = 0;
            product.price = 0;
            product.customer_product_amount = 0;
            product.description = '';
        } else {
            //
        }
    }

    public onJobChange(event: any, i: number): void {
        if (event && event.id) {
            event.pivot = {
                placeholder_amount: '',
                placeholder_price_per_unit: event.sale_estimated_price,
                amount: 1,
                price_per_unit: ''
            };
        } else {
            event.id = null;
            event.job_no = '';
            event.pivot = {
                placeholder_amount: '',
                placeholder_price_per_unit: '',
                amount: '',
                price_per_unit: ''
            };
        }
    }

    public onProductSelect(product: ProductModel, data: any): void {
        if (!product.wh) {
            product.wh = 'TH';
        }
        // if (!product.placeholder_price) {
        //     product.placeholder_price = Math.max(product.total_estimated_price, product.total_market_price);
        // }
    }

    private task_invoice_clone(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/clone', 'POST', {}, {
                action: 'edit_invoice',
                id: this.task.id
            }).subscribe((response: any): void => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private generate_no(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (!this.invoice.invoice_no) {
                this.api.request('invoices/no', 'POST', {
                    task_id: this.task.id,
                    id: this.invoice.id
                }).subscribe((response: any): void => {
                    if (response && response.data && response.data.invoice_no) {
                        this.invoice.invoice_no = response.data.invoice_no;
                        resolve(this.invoice);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            } else {
                resolve(this.invoice);
            }
        });
        return promise;
    }

    public editInvoice(): void {
        if (this.invoice.invoice_no) {
            this.swal.confirm('คุณต้องการแก้ไขใบแจ้งหนี้ "' + this.invoice.invoice_no + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.task_invoice_clone()
                            .then((response: any): void => {
                                if (response && response.data && response.data.id) {
                                    this.taskService.hire(response.data, null, true)
                                        .then((hire_response: any): void => {
                                            // Go Go!
                                        }, error => {
                                            //
                                        });
                                } else {
                                    //
                                }
                            });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public createInvoice(): void {
        if (this.invoice) {
            if (!this.invoice.customer_address_id && this.tmp_customer_address && this.tmp_customer_address.id) {
                this.invoice.customer_address_id = this.tmp_customer_address.id;
            }
            // if (!this.invoice.sale_id && this.tmp_sale && this.tmp_sale.id) {
            //     this.invoice.sale_id = this.tmp_sale.id;
            // }
            if (!this.invoice.customer || !this.invoice.customer.id) {
                if (this.tmp_customer && this.tmp_customer.id) {
                    this.invoice.customer = new CustomerModel();
                    this.invoice.customer.clone(this.tmp_customer);
                }
            }
        }
        if (!this.invoice.customer || !this.invoice.customer.id) {
            this.swal.danger('ไม่สามารถออกใบแจ้งหนี้ได้เนื่องจากไม่ได้ระบุข้อมูลลูกค้า');
            this.current_tab = '#information';
        } else {
            if (!this.invoice.invoice_no) {
                this.swal.confirm('คุณต้องการออกใบแจ้งหนี้ใช้หรือไม่?', true)
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.loader.show();
                            this._save()
                                .then(() => {
                                    this.swal.success('ออกใบแจ้งหนี้สำเร็จ');
                                    this.loader.hide();
                                    this.router.navigateByUrl('/invoice/' + this.invoice.id);
                                });
                        }
                    });
            } else {
                this.viewInvoice(this.invoice);
            }
        }
    }

    public onSelectSaleContactRef(event: any): void {
        if (this.invoice.requirement
            && this.invoice.requirement.id
            && this.invoice.requirement.customer
            && this.invoice.requirement.customer.id) {
            //
            this.invoice.customer_id = this.invoice.requirement.customer.id;
            this.invoice.customer.clone(this.invoice.requirement.customer);
            this.task.customer_id = this.invoice.customer_id;
            if (this.invoice.customer.addresses && this.invoice.customer.addresses[0]
                && this.invoice.customer.addresses[0].id) {
                //
                this.invoice.customer_address.clone(this.invoice.customer.addresses[0]);
                this.invoice.customer_address_id = this.invoice.customer.addresses[0].id;
            }
        }
    }

    public addJob(): void {
        if (!this.invoice.jobs) {
            this.invoice.jobs = [];
        }
        let new_job: JobModel;
        new_job = new JobModel();
        new_job.pivot = {
            placeholder_amount: '',
            placeholder_price_per_unit: '',
            amount: 0,
            price_per_unit: ''
        };
        this.invoice.jobs.push(new JobModel());
    }

    public addProduct(): void {
        let product: ProductModel;
        product = new ProductModel();
        product.index = this.invoice.products.push(product) - 1;
        product.pivot.wh = 'TH';
        product.cal_price();
        this.invoice.cal_total_job_price();
    }

    public addRemark(product: ProductModel): void {
        this.modal.show(RemarkModalComponent, {
            description: product.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                product.description = content.description;
                this.save(true);
            } else {
                //
            }
        });
    }

    private send_job_no(task_ids: string[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (task_ids && task_ids.length > 0) {
                this.api.request('mail/job', 'POST', {}, {
                    task_ids: task_ids
                }).subscribe(() => {
                    resolve();
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    /*private create_planning(product_ids: any, payment?: PaymentModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/products', 'PUT', null, {
                payment_id      : (payment) ? payment.id : null,
                id              : this.task.id,
                product_ids     : product_ids,
                current_role    : 'pd-planning',
                process_slug    : 'planning',
                previous_role   : 'estimate',
                customer_id     : this.task.customer_id,
                action          : 'create_planning',
                parent_id       : this.task.id,

                // payment
                // required_at     : payment.required_at,
                // started_at      : payment.started_at
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    resolve(response.data);
                } else {
                    resolve(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }*/

    /*public complete(): void {
        if (!this.invoice.payments || this.invoice.payments.length === 0) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากยังไม่ได้รับใบสั่งซื้อสินค้าเข้าระบบ')
                .then(() => {
                    this.current_tab = '#purchase_order';
                    this.pageScrollService.start(PageScrollInstance.simpleInstance(this.document, '#purchase_order'));
                });
        } else if (!this.invoice.validPayment()) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากหลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง')
                .then(() => {
                    this.current_tab = '#purchase_order';
                    this.pageScrollService.start(PageScrollInstance.simpleInstance(this.document, '#purchase_order'));
                });
        } else {
            this.modal.show(SelectPaymentComponent, {
                payments: this.invoice.payments
            }, { class: 'modal-lg' }).then((content: any): void => {
                if (content && content.checked_payments && content.is_submit === true) {
                    this.create_planning(content.checked_products)
                        .then((planning_response: any): void => {
                            this.send_job_no(planning_response)
                                .then(() => {
                                    this.swal.success('ส่งต่อกระบวนการการวางแผนการผลิตสินค้าสำเร็จ')
                                        .then(() => {
                                            //
                                        });
                                });
                        });
                } else {
                    //
                }
            });
        }
    }*/

    public warning(): void { // task status is 12
        this.modal.show(ReportModalComponent, {
            task: this.task
        }).then((content: any): void => {
            if (content && content.is_submit === true) {
                this.taskService.setStatus(this.task, this.task.status, 'feedback_invoice')
                    .then(() => {
                        this.swal.success('รอการตอบกลับจากลูกค้าภายหลัง');
                    }, error => {
                        this.swal.danger(error);
                    });
            } else {
                //
            }
        });
    }

    public rejectInvoice(): void {
        /*let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ยืนยันใบแจ้งหนี้ไม่ผ่านการอนุมัติสำเร็จ');
                        this.leave(true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;*/
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.taskService.reject(this.task)
                        .then(() => {
                            this.swal.success('แจ้งใบแจ้งหนี้ไม่ผ่านการอนุมัติสำเร็จ');
                            this.leave(true);
                        }, error => {
                            //
                        });
                    return;
                }
                //
            });
    }

    public approveInvoice(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this._save()
                        .then(() => {
                            this.taskService.approve(this.task)
                                .then(() => {
                                    this.swal.success('ผ่านการอนุมัติใบแจ้งหนี้สำเร็จ');
                                    this.leave(true);
                                }, error => {
                                    //
                                });
                        });
                } else {
                    //
                }
            });
    }

    public viewDepositDetail(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            invoice   : this.invoice,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public viewPaymentDetail(payment: PaymentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PurchaseOrderDetailComponent, {
            payment     : payment,
            invoice   : this.invoice,
            task        : this.task,
            products    : this.invoice.products
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.checked_products && content.is_submit === true) {
                    if (payment.verified === true) {
                        this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order')
                            .then(() => {
                                //
                            });
                        //
                        payment.status = 1;
                        this.swal.success('ส่งต่อกระบวนการการวางแผนการผลิตสินค้าสำเร็จ')
                            .then(() => {
                                //
                            });
                    } else {
                        //
                    }
                } else {
                    //
                }
            });
    }

    public onPaymentUploadedSuccess(data: any): void {
        this.api.request('invoices/payment', 'PUT', {}, {
            task_id: this.task.id,
            document: data,
            id: this.invoice.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_payment: PaymentModel;
                new_payment = new PaymentModel();
                new_payment.clone(response.data);
                this.invoice.payments.push(new_payment);
                this.viewPaymentDetail(new_payment);
            }
        }, error => {
            //
        });
    }

    public onPaymentUploadError(data: any): void {
        //
    }

    public onPaymentSelect(product: ProductModel, e?: any): void {
        //
    }

    public onPaymentDelete(product: ProductModel, e?: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('invoices/document', 'PUT', {}, {
            document    : data,
            id          : this.invoice.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.invoice.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public createProduct(product_id?: string, force?: boolean): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'estimate';
        task.current_role = 'estimate';
        task.action = 'create_product';
        task.modelable_type = 'App\\Product';
        if (product_id) {
            task.modelable_id = product_id;
        } else {
            //
        }
        this.taskService.hire(task, null, ((!force) ? false : true), task.action, task.action)
            .then(() => {
                // Go Go!
            });
    }

    private getDrawings(): DrawingModel[] {
        this.invoice.document.checked = true;
        let drawings: DrawingModel[];
        drawings = [];
        for (let i = 0; i < this.invoice.products.length; i++) {
            const product: ProductModel = this.invoice.products[i];
            /*if (product && product.documents && product.documents.length > 0) {
                documents = documents.concat(this.invoice.products[i].documents);
            }*/
            if (product && product.drawings && product.drawings.length > 0) {
                for (let j = 0; j < product.drawings.length; j++) {
                    const drawing: DrawingModel = product.drawings[j];
                    if (drawing && drawing.document) {
                        drawing.checked = true;
                        drawings.push(drawing);
                    }
                }
            }
        }
        return drawings;
    }

    public verifyInvoice(): void {
        if (this.invoice.invoice_no && this.invoice.customer) {
            super.issueTask()
                .then(() => {
                    this.leave(true);
                });
        } else {
            //
        }
    }

    public sendInvoice(): void {
        /*if (this.invoice.invoice_no && this.invoice.customer) {
            this.modal.show(DocumentComponent, {
                customer    : this.invoice.customer,
                invoice   : this.invoice,
                drawings    : this.getDrawings(),
                requirement : this.invoice.requirement,
                documents   : this.invoice.documents
            }, {
                class: 'modal-lg'
            }).then((content: any): void => {
                if (content && content.submit === true) {
                    let document_ids: string[];
                    let drawing_ids: string[];
                    let requirement_ids: string[];
                    document_ids = [];
                    drawing_ids = [];
                    requirement_ids = [];
                    if (content.documents) {
                        for (let i = 0; i < content.documents.length; i++) {
                            if (content.documents[i].checked === true) {
                                document_ids.push(content.documents[i].id);
                            }
                        }
                    }
                    if (content.drawings) {
                        for (let i = 0; i < content.drawings.length; i++) {
                            if (content.drawings[i].checked === true) {
                                drawing_ids.push(content.drawings[i].id);
                            }
                        }
                    }
                    if (content.requirement && content.requirement.checked === true) {
                        requirement_ids.push(content.requirement.id);
                    }
                    if (this.invoice.customer.email) {
                        this.swal.confirm('คุณต้องการส่งใบแจ้งหนี้ <span>"' + this.invoice.invoice_no + '"</span><br/>' +
                            ' กับลูกค้า <i>' + this.invoice.customer.name + '</i><br/>' +
                            ' ผ่านทางอีเมล <strong class="text-primary">"' + this.invoice.customer.email + '"</strong>' +
                            ' ใช่หรือไม่?', true)
                            .then((result: boolean): void => {
                                if (result === true) {
                                    this.loader.show();
                                    this.send_invoice(document_ids, drawing_ids, requirement_ids)
                                        .then(() => {
                                            this.swal.success('ส่งใบแจ้งหนี้ "' + this.invoice.invoice_no + '"' +
                                                ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                                .then(() => {
                                                    this.leave(true);
                                                });
                                            this.loader.hide();
                                        }, error => {
                                            this.swal.danger('ไม่สามารถส่งใบแจ้งหนี้ได้ กรุณาลองใหม่อีกครั้ง');
                                            this.loader.hide();
                                        });
                                } else {
                                    //
                                }
                            });
                    } else {
                        this.swal.input('กรุณากรอกอีเมลลูกค้าสำหรับการส่งใบแจ้งหนี้', 'ข้อมูลอีเมล', 'อีเมล', 'ส่งใบแจ้งหนี้')
                            .then((result: any): void => {
                                if (result) {
                                    this.api.request('customers/email', 'POST', {}, {
                                        id: this.task.customer_id,
                                        email: result
                                    }).subscribe((customer_response: any): void => {
                                        if (customer_response && customer_response.success === false) {
                                            this.swal.danger(customer_response.message);
                                        } else {
                                            this.loader.show();
                                            this.send_invoice(document_ids, drawing_ids, requirement_ids)
                                                .then(() => {
                                                    this.swal.success('ส่งใบแจ้งหนี้ "' + this.invoice.invoice_no + '"' +
                                                        ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                                        .then(() => {
                                                            this.leave(true);
                                                        });
                                                    this.loader.hide();
                                                }, error => {
                                                    this.swal.danger('ไม่สามารถส่งใบแจ้งหนี้ได้ กรุณาลองใหม่อีกครั้ง');
                                                    this.loader.hide();
                                                });
                                        }
                                    }, error => {
                                        this.swal.danger(error);
                                    });
                                } else {
                                    //
                                }
                            }, error => {
                                //
                            });
                    }
                }
            });
        }*/
        if (this.invoice.customer.email) {
            this.swal.confirm('คุณต้องการส่งใบแจ้งหนี้ <span>"' + this.invoice.invoice_no + '"</span><br/>' +
                ' กับลูกค้า <i>' + this.invoice.customer.name + '</i><br/>' +
                ' ผ่านทางอีเมล <strong class="text-primary">"' + this.invoice.customer.email + '"</strong>' +
                ' ใช่หรือไม่?', true)
                .then((result: boolean): void => {
                    if (result === true) {
                        this.loader.show();
                        this.api.request('mail/invoice', 'POST', null, {
                            // task_id         : this.task.id,
                            invoice_id      : this.invoice.id,
                            // document_ids    : document_ids,
                            // drawing_ids     : drawing_ids,
                            // requirement_ids : requirement_ids
                        }).subscribe((response: any): void => {
                            this.swal.success('ส่งใบแจ้งหนี้ "' + this.invoice.invoice_no + '"' +
                                ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                .then(() => {
                                    this.leave(true);
                                });
                            this.loader.hide();
                        }, error => {
                            this.swal.danger('ไม่สามารถส่งใบแจ้งหนี้ได้ กรุณาลองใหม่อีกครั้ง');
                            this.loader.hide();
                        });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.input('กรุณากรอกอีเมลลูกค้าสำหรับการส่งใบแจ้งหนี้', 'ข้อมูลอีเมล', 'อีเมล', 'ส่งใบแจ้งหนี้')
                .then((result: any): void => {
                    if (result) {
                        this.api.request('customers/email', 'POST', {}, {
                            id: this.task.customer_id,
                            email: result
                        }).subscribe((customer_response: any): void => {
                            if (customer_response && customer_response.success === false) {
                                this.swal.danger(customer_response.message);
                            } else {
                                this.loader.show();
                                this.api.request('mail/invoice', 'POST', null, {
                                    // task_id         : this.task.id,
                                    invoice_id      : this.invoice.id,
                                    // document_ids    : document_ids,
                                    // drawing_ids     : drawing_ids,
                                    // requirement_ids : requirement_ids
                                }).subscribe((response: any): void => {
                                    this.swal.success('ส่งใบแจ้งหนี้ "' + this.invoice.invoice_no + '"' +
                                        ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                        .then(() => {
                                            this.leave(true);
                                        });
                                    this.loader.hide();
                                }, error => {
                                    this.swal.danger('ไม่สามารถส่งใบแจ้งหนี้ได้ กรุณาลองใหม่อีกครั้ง');
                                    this.loader.hide();
                                });
                            }
                        }, error => {
                            this.swal.danger(error);
                        });
                    } else {
                        //
                    }
                }, error => {
                    //
                });
        }
    }

    private send_invoice(document_ids?: string[], drawing_ids?: string[], requirement_ids?: string[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let to_role: string;
            let to_process: string;
            console.log('CREDIT TERMS [' + this.invoice.customer.name + '] : ' + this.invoice.customer.credit_terms);
            if (this.invoice.customer && this.invoice.customer.credit_terms > 0) {
                to_role = 'purchase-order';
                to_process = this.task.process_slug;
            } else {
                to_role = 'payment';
                to_process = 'deposit';
            }
            this.taskService.setStatus(this.task, 2, 'send_invoice', to_role, to_process)
                .then((task_response: any): void => {
                    this.api.request('mail/invoice', 'POST', null, {
                        task_id         : this.task.id,
                        invoice_id    : this.invoice.id,
                        document_ids    : document_ids,
                        drawing_ids     : drawing_ids,
                        requirement_ids : requirement_ids
                    }).subscribe((response: any): void => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
                });
        });
        return promise;
    }

    public onSelectTypeaheadCurrency(data: any): void {
        this.invoice.currency_symbol = data.currencySymbol;
        this.invoice.currency = data.id;
        if (this.invoice && this.invoice.currency) {
            this.getCurrencyRate()
                .then((response: any): void => {
                    this.invoice.currency_rated_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    this.invoice.currency_rate = response;
                });
        }
    }

    /*public onSelectTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (model && data) {
            model.clone(data);
        }
        if (model_parent && field_name) {
            model_parent[field_name] = data.id;
        }
    }

    public onSelectTypeaheadProduct(model: any, data: any, model_parent?: any, field_name?: string): void {
        this.onSelectTypeahead(model, data, model_parent, field_name);
        if (model) {
            model.amount = 1;
            model.price = Math.max(model.market_price, model.price, model.cost);
        }
        this.invoice.cal_total_job_price();
    }

    public onSelectCustomerTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (data && data.addresses && data.addresses.length > 0) {
            this.tmp_customer_address = data.addresses[0];
        }
        this.onSelectTypeahead(model, data, model_parent, field_name);
    }*/

    public onBillTypeaheadDeleted(event?: any): void {
        this.invoice.bill = new BillModel();
    }

    public onSaleTypeaheadDeleted(event?: any): void {
        this.invoice.sale = new UserModel();
    }

    public onCustomerTypeaheadDeleted(event?: any): void {
        this.invoice.customer = new CustomerModel();
        this.invoice.customer.credit_terms = 0;

        this.invoice.contact = new ContactModel();
        this.invoice.customer_address = new CustomerAddressModel();
    }

    public onCustomerAddressTypeaheadDeleted(event?: any): void {
        this.invoice.customer_address = new CustomerAddressModel();
    }

    public onCustomerChange(e: any): void {
        this.invoice.customer_id = (e && e.id) ? e.id : null;
    }

    public onContactChange(e: any): void {
        this.invoice.contact_id = (e && e.id) ? e.id : null;
    }

    public onBillChange(e: any): void {
        this.invoice.bill_id = (e && e.id) ? e.id : null;
    }

    public onCustomerAddressChange(e: any): void {
        this.invoice.customer_address_id = (e && e.id) ? e.id : null;
    }

    public onCarrierChange(e: any): void {
        this.invoice.carrier_id = (e && e.id) ? e.id : null;
    }

    public onBlur(e: any): void {
        if (this.invoice) {
            this.invoice.cal_total_job_price();
        }
    }

    public productPriceChange(product: ProductModel, e?: any): void {
        //
    }

    public onProductPriceBlur(e?: any): void {
        // this.vatUpdate();
    }

    public onModelChanged(e?: any): void {
        this.ngZone.run(() => {
            if (this.invoice) {
                this.invoice.cal_total_job_price();
            }
        });
    }

    private getCheckedJobs(): any[] {
        let checked_jobs: any[];
        checked_jobs = [];
        let job_length: number;
        job_length = 0;
        for (let i = 0; i < this.invoice.jobs.length; i++) {
            const _job = this.invoice.jobs[i];
            if (_job && _job.id) {
                job_length++;
                checked_jobs.push({
                    purchase_order_no       : _job.purchase_order_no,
                    amount                  : _job.pivot.amount,
                    price                   : this.get_job_price(_job),
                    price_per_unit          : _job.pivot.price_per_unit,
                    job_id                  : _job.id,
                    product_id              : _job.product_id,
                });
            }
        }
        return checked_jobs;
    }

    private get_job_price(job: any): number {
        const amount = (job.pivot.amount > 0) ? job.pivot.amount : 0;
        const price_per_unit = (job.pivot.price_per_unit > 0) ? job.pivot.price_per_unit : 0;
        if (this.invoice.is_include_vat) {
            return (amount * price_per_unit) / (1 + (this.invoice.vat_percent / 100));
        } else {
            return amount * price_per_unit;
        }
    }

    private getCheckedProducts(): any {
        let checked_products: any[];
        checked_products = [];
        let product_length: number;
        product_length = 0;
        for (let i = 0; i < this.invoice.products.length; i++) {
            const _product: ProductModel = this.invoice.products[i];
            if (_product && _product.id) {
                product_length++;
                checked_products.push({
                    customer_product_amount : _product.customer_product_amount,
                    purchase_order_no       : _product.purchase_order_no,
                    amount                  : _product.amount,
                    price                   : _product.price,
                    price_per_unit          : _product.price_per_unit,
                    description             : _product.description,
                    // processing_times        : _product.processing_times,
                    wh                      : _product.wh,
                    product_id              : _product.id
                });
            }
        }
        return checked_products;
    }

    public archive(): void {
        this.taskService.archive(this.task)
            .then(() => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public onSelectIncotermCode(e: any): void {
        if (e && e.name) {
            this.invoice.incoterm_code = e.name;
        } else {
            this.invoice.incoterm_code = e;
        }
    }

    public save(skip?: boolean): void {
        if (skip === true) {
            //
        } else {
            this.loader.show();
        }
        this._save(skip)
            .then(() => {
                if (skip === true) {
                    //
                } else {
                    this.swal.success('ทำการบันทึกข้อมูลสำเร็จ', null, 2000);
                    this.loader.hide();
                }
            }, error => {
                if (skip === true) {
                    //
                } else {
                    this.swal.danger(error);
                    this.loader.hide();
                }
            });
    }

    private create_or_update_sale(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.invoice.sale && this.invoice.sale.id) {
                this.modelApi.update(this.invoice.sale, ['full_name', 'telephone', 'email'], 'users/contact')
                    .subscribe((result: any): void => {
                        resolve(this.invoice.sale);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private create_or_update_contact(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.tmp_contact && this.tmp_contact.id) {
                resolve(this.tmp_contact);
            } else if (this.tmp_contact && this.tmp_contact.full_name) {
                this.tmp_contact.customer_id = this.task.customer_id;
                this.modelApi.create(this.tmp_contact, ['full_name', 'position', 'telephone', 'email', 'customer_id'], 'customers/contact')
                    .subscribe((result: any): void => {
                        resolve(this.tmp_contact);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private _save(skip?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.invoice.job_ids = this.getCheckedJobs();
            const put_or_post_data: string[] = [
                'shipping_id', 'customer_id', 'contact_id', 'task_id', 'product_ids',
                'job_ids', 'customer_address_id', 'sale_id', 'currency', 'currency_symbol',
                'currency_rate', 'currency_rated_at', 'delivery_date', 'bill_id',
                'shipping', 'incoterm_code', 'carrier_id', 'discount_percent', 'vat_percent', 'frt_sh', 'misc_chgs',
                'contact_ref_id', 'wh', 'requirement_id', 'ref_no', 'is_include_vat',
                'price_validity', 'processing_times', 'discount_type', 'discount_price'
            ];
            if (this.invoice.isNew) {
                this.modelApi.create(this.invoice, put_or_post_data, 'invoices')
                    .subscribe((invoice_response: any): void => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
            } else {
                this.modelApi.update(this.invoice, put_or_post_data, null, null, null, false)
                    .subscribe((invoice_response: any): void => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
            }
        });
        return promise;
    }

    public vatUpdate(e?: any): void {
        setTimeout(() => {
            const vat_percent: number = (this.invoice.vat_percent) ? this.invoice.vat_percent : 0;
            for (let i = 0; i < this.invoice.products.length; i++) {
                const product: ProductModel = this.invoice.products[i];
                /*if (product && product.id) {
                    if (this.invoice.is_include_vat === true) {
                        product.price_per_unit = Math.round(product.price / (1 + (vat_percent / 100)));
                        product.vat_price = Math.round(product.price - product.price_per_unit);
                    } else {
                        product.price_per_unit = Math.round(product.price * (1 + (vat_percent / 100)));
                        product.vat_price = Math.round(product.price * (vat_percent / 100));
                    }
                } else {
                    //
                }*/
            }
        }, 0);
    }

    public nextProcess(): void {
        if (!this.invoice.payments || this.invoice.payments.length === 0) {
            this.swal.danger('ยังไม่ได้รับใบสั่งซื้อสินค้าเข้าระบบ');
        } else if (!this.invoice.validPayment()) {
            this.swal.danger('หลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง');
        } else {
            // this.loader.show();
            this._save()
                .then(() => {
                    // this.loader.hide();
                    this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order', 'proforma', 'proforma')
                        .then(() => {
                            this.leave(true);
                            this.swal.success('ส่งต่อให้กับแผนก Sale Admin สำเร็จ');
                        }, error => {
                            this.swal.danger(error);
                        });
                });
        }
    }

    public get total_price(): number {
        if (this.invoice) {
            this.invoice.cal_total_job_price();
            return this.invoice.grand_total;
        }
        return 0;
    }

    public onSaveApiCallingBack(e: any): void {
        if (this.task.approved) {
            //
        } else {
            this.save(true);
        }
    }

}
